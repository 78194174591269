import React from 'react';
import PropTypes from 'prop-types';

const RightArrow = ({
  width = 15,
  height = 14,
  className = '',
  fill = '#000',
  onClick = () => {},
}) => (
  <div className={`la-icon icon-svg-edit ${className}`} onClick={onClick}>
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 240.823 240.823"
      width={width}
      height={height}
    >
      <g>
        <path
          fill={fill}
          id="Chevron_Right_1_"
          d="M183.189,111.816L74.892,3.555c-4.752-4.74-12.451-4.74-17.215,0c-4.752,4.74-4.752,12.439,0,17.179
          l99.707,99.671l-99.695,99.671c-4.752,4.74-4.752,12.439,0,17.191c4.752,4.74,12.463,4.74,17.215,0l108.297-108.261
          C187.881,124.315,187.881,116.495,183.189,111.816z"
        />
      </g>
    </svg>
  </div>
);

RightArrow.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default RightArrow;
