import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Input from 'components/Input';
import Field from 'components/Field';
import {
  uploadDocumentMessage,
  setFileSource,
  clearMessagePopup,
  removeAttachementsFile,
  showSuccessMessage,
} from 'actions/claimDocument';
import { sendMessage } from 'actions/threads';
import paperclip from 'assets/images/paper-clip.png';
import { getBase64 } from 'utils/fileInputUpload';
import { isThompsons, checkFileSize } from 'utils/helpers';
import checkValidations from 'utils/validator';
import Button from '../Button';
import ProgressBar from '../ProgressBar';
import validation, { fileTypeValidation } from './validation';
import InputErrors from '../InputErrors';
import Icons from 'common/Icons';

import './style.scss';

class MessagePopUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {
        email: this.props.defaultEmail || '',
        subject: _.get(this.props, 'claimDocument.formData.subject', ''),
        text: _.get(this.props, 'claimDocument.formData.text', ''),
        claim: _.get(this.props, 'claimDocument.formData.claim', {
          id: _.get(this.props, 'claim.data.id', ''),
          value:
            this.props.options.find(
              (opt) => opt.id === _.get(this.props, 'claim.data.id', '')
            ) &&
            this.props.options.find(
              (opt) => opt.id === _.get(this.props, 'claim.data.id', '')
            ).value
              ? this.props.options.find(
                  (opt) => opt.id === _.get(this.props, 'claim.data.id', '')
                ).value
              : '',
        }),
        attachments: _.get(
          this.props,
          'claimDocument.formData.attachments',
          []
        ),
      },
      showSuccess: false,
      errors: {},
      disabled: false,
      showProgressState: false,
    };
  }

  handleChange(name, val) {
    this.setState({ fields: { ...this.state.fields, [name]: val } });
  }

  changeSelect(val, name) {
    const { options } = this.props;

    this.setState({
      fields: {
        ...this.state.fields,
        [name]: {
          value: val,
          id: options.find((item) => item.value === val).id,
        },
      },
    });
  }

  onChangeFile(name, e) {
    const { id } = this.state.fields.claim;
    const claimIdObj = { id };
    const currentUserId = this.props.session.data.id
      ? this.props.session.data.id
      : null;
    const file = e.target.files[0];
    const fileSize = { byteValue: 1e7, title: '10 MB' };
    let errors = {};

    errors = checkValidations(fileTypeValidation, {
      fileType: file.name.split('.').pop(),
    });

    const errorSize = checkFileSize(file.size, fileSize);
    if (errorSize && Object.keys(errors).length === 0) {
      errors = { ...errors, ...errorSize };
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return false;
    }

    this.setState({ showProgressState: true, errors: {} });

    getBase64(file, (result) => {
      const document = {
        // content: result,
        // type: file.type,
        // userId: currentUserId,
        // filename: file.name,
        // id: uniq()

        file: result,
        description: '',
        userId: currentUserId,
        // claimDocumentsAttributes: [claimIdObj],
        associations: { claims: [claimIdObj] },
        filename: file.name,
      };

      this.props.setFileSource(document);
      this.props.uploadDocumentMessage(id, document, this.state);

      setTimeout(() => {
        this.setState({
          fields: {
            ...this.state.fields,
            [name]: [...this.state.fields[name], document],
          },
          showProgressState: false,
        });
      }, 1000);
    });
  }

  handleSend() {
    const { fields } = this.state;
    const clameCopyValue = fields.claim.value;
    const errors = checkValidations(validation, {
      ...fields,
      claim: clameCopyValue,
    });

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
    } else {
      this.setState({ disabled: true });
      sendMessage({
        body: fields.text,
        subject: fields.subject,
        claimId: fields.claim.id,
        attachments: fields.attachments,
      }).then((data) => {
        if (data) {
          this.setState({
            showSuccess: true,
          });
        }
        this.props.showSuccessMessage();
      });
    }
  }

  removeAttachment = (item) => {
    this.setState({
      fields: {
        ...this.state.fields,
        attachments: this.state.fields.attachments.filter(function (
          attachment
        ) {
          return attachment.id !== item.id;
        }),
      },
    });
    this.props.removeAttachementsFile(item.id);
  };

  render() {
    const { onCrossClick, options, enabled } = this.props;
    const { fields, errors, disabled } = this.state;
    const { id } = fields.claim;
    const { fileUploadError, showSuccessMsg, disableUpload, showProgress } =
      this.props.claimDocument;
    const textAreaFilled = fields.text ? 'text-area-filled' : '';

    // if(!this.props.isOpen) {
    //   clearMessagePopup(this.props.clearMessagePopup(_.get(claimDocument, 'formData.attachments', [])), showSuccessMsg);
    // }

    const isDisabled = !id ? true : false;
    const disabledStyles = isDisabled ? 'disabled' : '';
    const isUploadDisabled = disableUpload ? 'disabled' : '';

    return (
      <div
        className="message-popup white-bordered-popup popup-simple flex direction-column"
        onClick={(e) => e.stopPropagation(e)}
      >
        <div
          className="cross-wrapper"
          onClick={() => {
            onCrossClick();
          }}
        >
          <div className="cross" />
        </div>
        {showSuccessMsg ? (
          <div className="success-message">
            {isThompsons() && (
              <Fragment>
                <Icons iconName="tick" fill="#4ebd31" />
                <div className="tmp-success-text">
                  <span>Message sent</span>
                  <span>
                    Thank you for your message, a member of our team will be in
                    contact as soon as possible.
                  </span>
                </div>
              </Fragment>
            )}
            {!isThompsons() && (
              <Fragment>
                <Icons iconName="tick" fill="#4ebd31" />
                <span>Message sent</span>
              </Fragment>
            )}
          </div>
        ) : (
          <Fragment>
            <h2>{isThompsons() ? 'Send us a message' : 'Contact us'}</h2>

            {isThompsons() ? null : (
              <Input
                value={fields.subject}
                label="Subject*"
                name="subject"
                onChange={(val) => {
                  this.handleChange('subject', val);
                }}
                labelAnimate={true}
                type="text"
                className="grey-input"
                errors={errors.subject}
              />
            )}

            <Field
              element="select"
              type="select"
              label="Claim*"
              options={options}
              name="claim"
              onChange={(val, name) => this.changeSelect(val, name)}
              selectValue={fields.claim.value}
              disabled={
                !enabled ||
                (fields.attachments && fields.attachments.length > 0)
              }
              className={'contact-us-modal-select'}
              errors={errors.claim}
              labelInside={true}
            />

            <div className="textarea-holder">
              <textarea
                value={fields.text}
                name="text"
                id="text"
                onChange={(e) => {
                  this.handleChange('text', e.target.value);
                }}
                type="text"
                className="grey-input textarea"
              />
              <label
                className={`textarea-label form-control-label ${
                  fields.text.length > 0 ? 'not-empty' : ''
                } ${textAreaFilled}`}
                htmlFor="text"
              >
                Your message*
              </label>
              <InputErrors errors={errors.text} />
            </div>

            <div className="flex direction-column justify-center overflow-hidden relative  mb20 attachment-list-wrap">
              <div className="attachment-list">
                {fields.attachments.length > 0 &&
                  fields.attachments.map((item, index) => (
                    <div
                      key={+index}
                      className="mb10 mt10 justify-between flex"
                    >
                      <div>
                        <img src={paperclip} alt="paper-clip" />
                        <span>{item.filename}</span>
                      </div>
                      <div
                        className="delete-doc"
                        onClick={() => {
                          this.removeAttachment(item);
                        }}
                      >
                        <span>X</span>
                      </div>
                    </div>
                  ))}
              </div>

              <div className="mt10">
                <InputErrors errors={errors.fileType || fileUploadError} />
              </div>
              <div className="mt10">
                <InputErrors errors={errors.fileSize} />
              </div>

              {showProgress && <ProgressBar />}
            </div>
            <div className="flex justify-between">
              <label
                htmlFor="loadPfg"
                className={`btn btn-success flex justify-center items-center plus ${disabledStyles} ${isUploadDisabled}`}
              >
                <span>+ Upload file</span>
                <input
                  id="loadPfg"
                  type="file"
                  onChange={(e) => this.onChangeFile('attachments', e)}
                  style={{ visibility: 'hidden' }}
                  disabled={isDisabled || isUploadDisabled}
                />
              </label>
              <Button
                type="success"
                value="Send"
                className={`message-complete-btn ${
                  disabled ? 'btn-normal' : ''
                } ${isUploadDisabled}`}
                disabled={disabled}
                onClick={() => {
                  if (!isUploadDisabled) {
                    this.handleSend();
                  }
                }}
              />
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  documents: state.claimDocument,
  claim: state.claim,
  session: state.session,
  browser: state.browser,
  claimDocument: state.claimDocument,
});

const mapDispatchToProps = {
  uploadDocumentMessage,
  setFileSource,
  clearMessagePopup,
  removeAttachementsFile,
  showSuccessMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(MessagePopUp);
