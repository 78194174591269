import React from 'react';
import PropTypes from 'prop-types';

import InputErrors from '../../InputErrors';

import '../styles.scss';

const InputInner = ({
  name,
  type,
  label,
  className,
  wrapperClass,
  isRequired,
  errors = [],
  disabled,
  autoFocus,
  labelAnimate,
  labelInside,
  value = '',
  onChange,
  onBlur,
  onKeyPress,
  newDesign,
}) => {
  const activeClass =
    (labelInside || labelAnimate) && value && value.length > 0
      ? 'form-control-valid'
      : 'form-control-empty';
  const labelWrapperClass =
    labelInside || labelAnimate
      ? 'form-control-label-animate'
      : 'form-control-label-static';
  const errorArray = errors;
  const errorClass =
    errorArray && errorArray.length > 0 ? 'has-error' : 'no-error';
  const labelInsideClass = labelInside
    ? 'form-control-wrapper-label-inside'
    : '';

  const onInputChange = (e) => {
    onChange(e.target.value, e.target.name);
  };

  const setHTML = (label, isRequired) => {
    const newDesignPromptStyles = newDesign ? 'new-design-prompt' : '';

    const sup = isRequired ? '<sup class="text-color-gray">*</sup>' : '';
    return {
      __html: `<div class="ellipsis"><span class="pointer-without-events ${newDesignPromptStyles}">${label}<span>${sup}</div>`,
    };
  };

  const filledInputLabelClass = value ? 'form-control-label-filled' : '';
  const inputLabel = label && (
    <label
      htmlFor={name}
      className={`form-control-label ${filledInputLabelClass}`}
      dangerouslySetInnerHTML={setHTML(label, isRequired)}
    ></label>
  );

  return (
    <div
      className={`form-control-wrapper ${wrapperClass} ${
        !labelAnimate && !labelInside ? 'form-control-wrapper-label-static' : ''
      } ${labelInsideClass}`}
    >
      {!labelAnimate && !labelInside && inputLabel}
      <div
        className={`form-control-container ${labelWrapperClass} ${activeClass}`}
      >
        <input
          className={`form-control form-control-text ${className} ${errorClass}`}
          id={name}
          name={name}
          type={type}
          value={value}
          disabled={disabled}
          autoFocus={autoFocus} //eslint-disable-line
          onBlur={onBlur}
          onChange={onInputChange}
          onKeyPress={onKeyPress}
          autoComplete="off" //eslint-disable-line
        />
        {(labelInside || labelAnimate) && inputLabel}
      </div>
      <InputErrors errors={errorArray} />
    </div>
  );
};

InputInner.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.string,
  className: PropTypes.string,
  wrapperClass: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  errors: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ),
  labelAnimate: PropTypes.bool,
  labelInside: PropTypes.bool,
};

export default InputInner;
