export const loadingTableData = [
  // {
  //   id: 1,
  //   type: 'type',
  //   claimId: 'claimId',
  //   refId: 'refId',
  //   claimRefId: 'claimRefId',
  //   claimType: 'claimType',
  //   customerFullName: 'customerFullName',
  //   status: 'status',
  //   userFullName: 'userFullName',
  //   dueDate: 'dueDate',
  //   createdAt: 'createdAt',
  //   updatedAt: 'updatedAt',
  //   externalRef: 'externalRef',
  //   firstName: 'firstName',
  //   lastName: 'lastName',
  //   phone: 'phone',
  //   dateOfBirth: 'dateOfBirth',
  //   contentType: 'contentType',
  //   filename: 'filename',
  //   internalRef: 'internalRef',
  //   fullUrl: 'fullUrl',
  // },
  // {
  //   id: 2,
  //   type: 'type',
  //   claimId: 'claimId',
  //   refId: 'refId',
  //   claimRefId: 'claimRefId',
  //   claimType: 'claimType',
  //   customerFullName: 'customerFullName',
  //   status: 'status',
  //   userFullName: 'userFullName',
  //   dueDate: 'dueDate',
  //   createdAt: 'createdAt',
  //   updatedAt: 'updatedAt',
  //   externalRef: 'externalRef',
  //   firstName: 'firstName',
  //   lastName: 'lastName',
  //   phone: 'phone',
  //   dateOfBirth: 'dateOfBirth',
  //   contentType: 'contentType',
  //   filename: 'filename',
  //   internalRef: 'internalRef',
  //   fullUrl: 'fullUrl',
  // },
  // {
  //   id: 3,
  //   type: 'type',
  //   claimId: 'claimId',
  //   refId: 'refId',
  //   claimRefId: 'claimRefId',
  //   claimType: 'claimType',
  //   customerFullName: 'customerFullName',
  //   status: 'status',
  //   userFullName: 'userFullName',
  //   dueDate: 'dueDate',
  //   createdAt: 'createdAt',
  //   updatedAt: 'updatedAt',
  //   externalRef: 'externalRef',
  //   firstName: 'firstName',
  //   lastName: 'lastName',
  //   phone: 'phone',
  //   dateOfBirth: 'dateOfBirth',
  //   contentType: 'contentType',
  //   filename: 'filename',
  //   internalRef: 'internalRef',
  //   fullUrl: 'fullUrl',
  // },
  // {
  //   id: 4,
  //   type: 'type',
  //   claimId: 'claimId',
  //   refId: 'refId',
  //   claimRefId: 'claimRefId',
  //   claimType: 'claimType',
  //   customerFullName: 'customerFullName',
  //   status: 'status',
  //   userFullName: 'userFullName',
  //   dueDate: 'dueDate',
  //   createdAt: 'createdAt',
  //   updatedAt: 'updatedAt',
  //   externalRef: 'externalRef',
  //   firstName: 'firstName',
  //   lastName: 'lastName',
  //   phone: 'phone',
  //   dateOfBirth: 'dateOfBirth',
  //   contentType: 'contentType',
  //   filename: 'filename',
  //   internalRef: 'internalRef',
  //   fullUrl: 'fullUrl',
  // },
  // {
  //   id: 5,
  //   type: 'type',
  //   claimId: 'claimId',
  //   refId: 'refId',
  //   claimRefId: 'claimRefId',
  //   claimType: 'claimType',
  //   customerFullName: 'customerFullName',
  //   status: 'status',
  //   userFullName: 'userFullName',
  //   dueDate: 'dueDate',
  //   createdAt: 'createdAt',
  //   updatedAt: 'updatedAt',
  //   externalRef: 'externalRef',
  //   firstName: 'firstName',
  //   lastName: 'lastName',
  //   phone: 'phone',
  //   dateOfBirth: 'dateOfBirth',
  //   contentType: 'contentType',
  //   filename: 'filename',
  //   internalRef: 'internalRef',
  //   fullUrl: 'fullUrl',
  // },
  // {
  //   id: 6,
  //   type: 'type',
  //   claimId: 'claimId',
  //   refId: 'refId',
  //   claimRefId: 'claimRefId',
  //   claimType: 'claimType',
  //   customerFullName: 'customerFullName',
  //   status: 'status',
  //   userFullName: 'userFullName',
  //   dueDate: 'dueDate',
  //   createdAt: 'createdAt',
  //   updatedAt: 'updatedAt',
  //   externalRef: 'externalRef',
  //   firstName: 'firstName',
  //   lastName: 'lastName',
  //   phone: 'phone',
  //   dateOfBirth: 'dateOfBirth',
  //   contentType: 'contentType',
  //   filename: 'filename',
  //   internalRef: 'internalRef',
  //   fullUrl: 'fullUrl',
  // },
  // {
  //   id: 7,
  //   type: 'type',
  //   claimId: 'claimId',
  //   refId: 'refId',
  //   claimRefId: 'claimRefId',
  //   claimType: 'claimType',
  //   customerFullName: 'customerFullName',
  //   status: 'status',
  //   userFullName: 'userFullName',
  //   dueDate: 'dueDate',
  //   createdAt: 'createdAt',
  //   updatedAt: 'updatedAt',
  //   externalRef: 'externalRef',
  //   firstName: 'firstName',
  //   lastName: 'lastName',
  //   phone: 'phone',
  //   dateOfBirth: 'dateOfBirth',
  //   contentType: 'contentType',
  //   filename: 'filename',
  //   internalRef: 'internalRef',
  //   fullUrl: 'fullUrl',
  // },
  // {
  //   id: 8,
  //   type: 'type',
  //   claimId: 'claimId',
  //   refId: 'refId',
  //   claimRefId: 'claimRefId',
  //   claimType: 'claimType',
  //   customerFullName: 'customerFullName',
  //   status: 'status',
  //   userFullName: 'userFullName',
  //   dueDate: 'dueDate',
  //   createdAt: 'createdAt',
  //   updatedAt: 'updatedAt',
  //   externalRef: 'externalRef',
  //   firstName: 'firstName',
  //   lastName: 'lastName',
  //   phone: 'phone',
  //   dateOfBirth: 'dateOfBirth',
  //   contentType: 'contentType',
  //   filename: 'filename',
  //   internalRef: 'internalRef',
  //   fullUrl: 'fullUrl',
  // },
  // {
  //   id: 9,
  //   type: 'type',
  //   claimId: 'claimId',
  //   refId: 'refId',
  //   claimRefId: 'claimRefId',
  //   claimType: 'claimType',
  //   customerFullName: 'customerFullName',
  //   status: 'status',
  //   userFullName: 'userFullName',
  //   dueDate: 'dueDate',
  //   createdAt: 'createdAt',
  //   updatedAt: 'updatedAt',
  //   externalRef: 'externalRef',
  //   firstName: 'firstName',
  //   lastName: 'lastName',
  //   phone: 'phone',
  //   dateOfBirth: 'dateOfBirth',
  //   contentType: 'contentType',
  //   filename: 'filename',
  //   internalRef: 'internalRef',
  //   fullUrl: 'fullUrl',
  // },
  // {
  //   id: 10,
  //   type: 'type',
  //   claimId: 'claimId',
  //   refId: 'refId',
  //   claimRefId: 'claimRefId',
  //   claimType: 'claimType',
  //   customerFullName: 'customerFullName',
  //   status: 'status',
  //   userFullName: 'userFullName',
  //   dueDate: 'dueDate',
  //   createdAt: 'createdAt',
  //   updatedAt: 'updatedAt',
  //   externalRef: 'externalRef',
  //   firstName: 'firstName',
  //   lastName: 'lastName',
  //   phone: 'phone',
  //   dateOfBirth: 'dateOfBirth',
  //   contentType: 'contentType',
  //   filename: 'filename',
  //   internalRef: 'internalRef',
  //   fullUrl: 'fullUrl',
  // },
  // {
  //   id: 11,
  //   type: 'type',
  //   claimId: 'claimId',
  //   refId: 'refId',
  //   claimRefId: 'claimRefId',
  //   claimType: 'claimType',
  //   customerFullName: 'customerFullName',
  //   status: 'status',
  //   userFullName: 'userFullName',
  //   dueDate: 'dueDate',
  //   createdAt: 'createdAt',
  //   updatedAt: 'updatedAt',
  //   externalRef: 'externalRef',
  //   firstName: 'firstName',
  //   lastName: 'lastName',
  //   phone: 'phone',
  //   dateOfBirth: 'dateOfBirth',
  //   contentType: 'contentType',
  //   filename: 'filename',
  //   internalRef: 'internalRef',
  //   fullUrl: 'fullUrl',
  // },
  // {
  //   id: 12,
  //   type: 'type',
  //   claimId: 'claimId',
  //   refId: 'refId',
  //   claimRefId: 'claimRefId',
  //   claimType: 'claimType',
  //   customerFullName: 'customerFullName',
  //   status: 'status',
  //   userFullName: 'userFullName',
  //   dueDate: 'dueDate',
  //   createdAt: 'createdAt',
  //   updatedAt: 'updatedAt',
  //   externalRef: 'externalRef',
  //   firstName: 'firstName',
  //   lastName: 'lastName',
  //   phone: 'phone',
  //   dateOfBirth: 'dateOfBirth',
  //   contentType: 'contentType',
  //   filename: 'filename',
  //   internalRef: 'internalRef',
  //   fullUrl: 'fullUrl',
  // },
  // {
  //   id: 13,
  //   type: 'type',
  //   claimId: 'claimId',
  //   refId: 'refId',
  //   claimRefId: 'claimRefId',
  //   claimType: 'claimType',
  //   customerFullName: 'customerFullName',
  //   status: 'status',
  //   userFullName: 'userFullName',
  //   dueDate: 'dueDate',
  //   createdAt: 'createdAt',
  //   updatedAt: 'updatedAt',
  //   externalRef: 'externalRef',
  //   firstName: 'firstName',
  //   lastName: 'lastName',
  //   phone: 'phone',
  //   dateOfBirth: 'dateOfBirth',
  //   contentType: 'contentType',
  //   filename: 'filename',
  //   internalRef: 'internalRef',
  //   fullUrl: 'fullUrl',
  // },
  // {
  //   id: 14,
  //   type: 'type',
  //   claimId: 'claimId',
  //   refId: 'refId',
  //   claimRefId: 'claimRefId',
  //   claimType: 'claimType',
  //   customerFullName: 'customerFullName',
  //   status: 'status',
  //   userFullName: 'userFullName',
  //   dueDate: 'dueDate',
  //   createdAt: 'createdAt',
  //   updatedAt: 'updatedAt',
  //   externalRef: 'externalRef',
  //   firstName: 'firstName',
  //   lastName: 'lastName',
  //   phone: 'phone',
  //   dateOfBirth: 'dateOfBirth',
  //   contentType: 'contentType',
  //   filename: 'filename',
  //   internalRef: 'internalRef',
  //   fullUrl: 'fullUrl',
  // },
  // {
  //   id: 15,
  //   type: 'type',
  //   claimId: 'claimId',
  //   refId: 'refId',
  //   claimRefId: 'claimRefId',
  //   claimType: 'claimType',
  //   customerFullName: 'customerFullName',
  //   status: 'status',
  //   userFullName: 'userFullName',
  //   dueDate: 'dueDate',
  //   createdAt: 'createdAt',
  //   updatedAt: 'updatedAt',
  //   externalRef: 'externalRef',
  //   firstName: 'firstName',
  //   lastName: 'lastName',
  //   phone: 'phone',
  //   dateOfBirth: 'dateOfBirth',
  //   contentType: 'contentType',
  //   filename: 'filename',
  //   internalRef: 'internalRef',
  //   fullUrl: 'fullUrl',
  // },
  // {
  //   id: 16,
  //   type: 'type',
  //   claimId: 'claimId',
  //   refId: 'refId',
  //   claimRefId: 'claimRefId',
  //   claimType: 'claimType',
  //   customerFullName: 'customerFullName',
  //   status: 'status',
  //   userFullName: 'userFullName',
  //   dueDate: 'dueDate',
  //   createdAt: 'createdAt',
  //   updatedAt: 'updatedAt',
  //   externalRef: 'externalRef',
  //   firstName: 'firstName',
  //   lastName: 'lastName',
  //   phone: 'phone',
  //   dateOfBirth: 'dateOfBirth',
  //   contentType: 'contentType',
  //   filename: 'filename',
  //   internalRef: 'internalRef',
  //   fullUrl: 'fullUrl',
  // },
  // {
  //   id: 17,
  //   type: 'type',
  //   claimId: 'claimId',
  //   refId: 'refId',
  //   claimRefId: 'claimRefId',
  //   claimType: 'claimType',
  //   customerFullName: 'customerFullName',
  //   status: 'status',
  //   userFullName: 'userFullName',
  //   dueDate: 'dueDate',
  //   createdAt: 'createdAt',
  //   updatedAt: 'updatedAt',
  //   externalRef: 'externalRef',
  //   firstName: 'firstName',
  //   lastName: 'lastName',
  //   phone: 'phone',
  //   dateOfBirth: 'dateOfBirth',
  //   contentType: 'contentType',
  //   filename: 'filename',
  //   internalRef: 'internalRef',
  //   fullUrl: 'fullUrl',
  // },
  // {
  //   id: 18,
  //   type: 'type',
  //   claimId: 'claimId',
  //   refId: 'refId',
  //   claimRefId: 'claimRefId',
  //   claimType: 'claimType',
  //   customerFullName: 'customerFullName',
  //   status: 'status',
  //   userFullName: 'userFullName',
  //   dueDate: 'dueDate',
  //   createdAt: 'createdAt',
  //   updatedAt: 'updatedAt',
  //   externalRef: 'externalRef',
  //   firstName: 'firstName',
  //   lastName: 'lastName',
  //   phone: 'phone',
  //   dateOfBirth: 'dateOfBirth',
  //   contentType: 'contentType',
  //   filename: 'filename',
  //   internalRef: 'internalRef',
  //   fullUrl: 'fullUrl',
  // },
  // {
  //   id: 19,
  //   type: 'type',
  //   claimId: 'claimId',
  //   refId: 'refId',
  //   claimRefId: 'claimRefId',
  //   claimType: 'claimType',
  //   customerFullName: 'customerFullName',
  //   status: 'status',
  //   userFullName: 'userFullName',
  //   dueDate: 'dueDate',
  //   createdAt: 'createdAt',
  //   updatedAt: 'updatedAt',
  //   externalRef: 'externalRef',
  //   firstName: 'firstName',
  //   lastName: 'lastName',
  //   phone: 'phone',
  //   dateOfBirth: 'dateOfBirth',
  //   contentType: 'contentType',
  //   filename: 'filename',
  //   internalRef: 'internalRef',
  //   fullUrl: 'fullUrl',
  // },
  // {
  //   id: 20,
  //   type: 'type',
  //   claimId: 'claimId',
  //   refId: 'refId',
  //   claimRefId: 'claimRefId',
  //   claimType: 'claimType',
  //   customerFullName: 'customerFullName',
  //   status: 'status',
  //   userFullName: 'userFullName',
  //   dueDate: 'dueDate',
  //   createdAt: 'createdAt',
  //   updatedAt: 'updatedAt',
  //   externalRef: 'externalRef',
  //   firstName: 'firstName',
  //   lastName: 'lastName',
  //   phone: 'phone',
  //   dateOfBirth: 'dateOfBirth',
  //   contentType: 'contentType',
  //   filename: 'filename',
  //   internalRef: 'internalRef',
  //   fullUrl: 'fullUrl',
  // },
];

export default loadingTableData;
