import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from '../Input';
import List from '../List';

import './styles.scss';
import Icons from 'common/Icons';

class Autocomplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSelected: false,
      selectedSuggestion: {},
      value: props.value || '',
      preloadedDataLoaded: false,
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.listener, false);
  }

  componentDidUpdate() {
    const { isSelected, preloadedDataLoaded } = this.state;

    if (
      !isSelected &&
      !preloadedDataLoaded &&
      Object.keys(this.props.preloadedData).length > 0
    ) {
      this.setPreloadedData(this.props.preloadedData);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.listener, false);
  }

  onChange = (value, name, selected) => {
    this.setState({ value, clickedOutside: false });
    if (!/^ *$/.test(value)) this.props.onChange(value, name, selected);
  };

  onSuggestionClick = (suggestion) => {
    const { name, onSuggestionClick } = this.props;
    const selected = true;

    this.setState({
      isSelected: true,
      selectedSuggestion: suggestion,
    });

    if (onSuggestionClick) {
      onSuggestionClick(suggestion);
    }

    this.onChange(suggestion.id, name, selected);
  };

  onLinkClick = (url) => {
    window.open(url, '_blank');
  };

  onDeleteClick = () => {
    const { name } = this.props;
    const selected = false;

    this.setState({
      value: '',
      isSelected: false,
      selectedSuggestion: {},
    });
    this.props.onChange('', name, selected);
  };

  setPreloadedData = (data) => {
    this.setState({
      isSelected: true,
      selectedSuggestion: data,
      preloadedDataLoaded: true,
    });
  };

  listener = (event) => {
    const { clickedOutside } = this.state;

    if (!event.target.closest('.suggestions-list-item') && !clickedOutside) {
      this.setState({ clickedOutside: true });
    }
  };

  handleSufixClick = (...params) => {
    const { onSufixClick, clearOnSufix } = this.props;
    if (clearOnSufix) {
      this.setState({ value: '' }, () => {
        onSufixClick(...params);
      });
    }
  };

  render() {
    const {
      name,
      label,
      className,
      errors,
      autoFocus,
      sufix,
      secondSufix,
      prefix,
      labelAnimate,
      placeholder,
      labelInside,
      button,
      SuggestionSelected,
      Suggestion,
      suggestions,
      searchInput,
      isRequired,
      disabled,
      onSufixClick,
      onSecondSufixClick,
      hideRedDot,
      style,
      clearOnSufix,
      fullWidth,
      showClose = false,
      removeField = () => {},
    } = this.props;
    const { isSelected, selectedSuggestion, value, clickedOutside } =
      this.state;

    return (
      <>
        <div className={`autocomplete-wrapper ${className}`} style={style}>
          <div className="autocomplete-form-control-wrapper">
            {isSelected &&
              SuggestionSelected(
                selectedSuggestion,
                this.onLinkClick,
                this.onDeleteClick,
                label,
                labelInside,
                isRequired,
                hideRedDot,
                errors,
                fullWidth
              )}
            {!isSelected && (
              <Input
                {...this.props}
                value={value || ''}
                name={name}
                type="text"
                onChange={this.onChange}
                label={label}
                errors={errors}
                autoFocus={autoFocus}
                sufix={sufix}
                onSufixClick={
                  clearOnSufix ? this.handleSufixClick : onSufixClick
                }
                secondSufix={secondSufix}
                onSecondSufixClick={onSecondSufixClick}
                prefix={prefix}
                labelAnimate={labelAnimate}
                placeholder={placeholder}
                labelInside={labelInside}
                disabled={isSelected || disabled}
                SuggestionSelected={SuggestionSelected}
                selectedSuggestion={selectedSuggestion}
                searchInput={searchInput}
                isRequired={isRequired}
              />
            )}
            {!isSelected &&
              !clickedOutside &&
              value &&
              suggestions &&
              suggestions.length > 0 && (
                <div className="autocomplete-dropdown suggestions">
                  <List type="clear" className="with-scroll-y">
                    {suggestions.map((item) =>
                      Suggestion(item, this.onSuggestionClick, fullWidth)
                    )}
                  </List>
                  {button}
                </div>
              )}
          </div>
        </div>
        {showClose ? (
          <div
            className="close-holder"
            onClick={() => removeField(name)}
            style={{
              position: 'absolute',
              top: '10px',
              right: '-10px',
              borderRadius: '50%',
              backgroundColor: '#ECECEC',
              zIndex: 100,
            }}
          >
            <Icons iconName="close" />
          </div>
        ) : null}
      </>
    );
  }
}

Autocomplete.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  autoFocus: PropTypes.bool,
  errors: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ),
  sufix: PropTypes.node,
  secondSufix: PropTypes.node,
  prefix: PropTypes.node,
  labelAnimate: PropTypes.bool,
  labelInside: PropTypes.bool,
  onChange: PropTypes.func,
  button: PropTypes.node,
  SuggestionSelected: PropTypes.func,
  Suggestion: PropTypes.func,
  suggestions: PropTypes.arrayOf(Object),
  searchInput: PropTypes.bool,
  isRequired: PropTypes.bool,
  onSuggestionClick: PropTypes.func,
  preloadedData: PropTypes.shape(),
  fullWidth: PropTypes.bool,
};

Autocomplete.defaultProps = {
  name: '',
  placeholder: '',
  label: null,
  className: '',
  errors: [],
  autoFocus: false,
  sufix: null,
  prefix: null,
  labelAnimate: false,
  labelInside: false,
  onChange: null,
  button: null,
  SuggestionSelected: null,
  Suggestion: null,
  suggestions: [],
  searchInput: false,
  isRequired: false,
  onSuggestionClick: null,
  preloadedData: {},
  hideRedDot: false,
  fullWidth: false,
};

export default Autocomplete;
