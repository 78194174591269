export const getBase64 = (file, cb) => {
  const reader = new FileReader();

  reader.readAsDataURL(file);
  reader.onload = () => {
    cb(reader.result);
  };
  reader.onerror = (error) => {
    cb(error);
  };
};

const fileInputUpload = {
  getBase64,
};

export default fileInputUpload;
