import React from 'react';
import PropTypes from 'prop-types';

const Unarchive = ({
  width = 17,
  height = 17,
  className = '',
  fill = 'white',
  style = {},
}) => (
  <div className={`la-icon icon-svg-tick ${className}`} style={style}>
    <svg
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 120.625 120.625"
    >
      <g>
        <path
          fill={fill}
          d="M19.179,101.123c2.082,0,3.77,1.686,3.77,3.764c0,2.086-1.688,3.776-3.77,3.776c-2.085,0-3.773-1.69-3.773-3.776
          C15.406,102.808,17.094,101.123,19.179,101.123z"
        />
        <path
          fill={fill}
          d="M117.943,99.328L107.48,5.212C107.163,2.351,104.285,0,101.078,0h-81.53c-3.206,0-6.089,2.351-6.404,5.212L2.681,99.328
          c0,0.114-0.054,0.208-0.054,0.323v10.483c0,5.784,4.706,10.49,10.484,10.49h94.401c5.779,0,10.485-4.706,10.485-10.49V99.651
          C117.998,99.536,117.943,99.437,117.943,99.328z M60.312,26.241L75.48,45.204h-9.478v18.96H54.625v-18.96h-9.476L60.312,26.241z
          M112.756,110.134c0,2.895-2.364,5.248-5.248,5.248H13.111c-2.881,0-5.246-2.354-5.246-5.248V99.651
          c0-2.895,2.365-5.248,5.246-5.248h94.401c2.879,0,5.243,2.354,5.243,5.248V110.134L112.756,110.134z"
        />
      </g>
    </svg>
  </div>
);

Unarchive.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default Unarchive;
