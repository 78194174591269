import React from 'react';
import PropTypes from 'prop-types';

const Message = ({
  width = 15,
  height = 14,
  className = '',
  fill = '#FFF',
}) => (
  <div className={`la-icon icon-svg-message ${className}`}>
    <svg width={width} height={height} viewBox="0 0 15 13" opacity="1">
      <path
        fill={fill}
        fillRule="nonzero"
        d="M13.351.49H1.649C.845.49.186 1.15.186 1.955v8.776c0 .804.659 1.463 1.463 1.463h11.702c.804 0 1.463-.659 1.463-1.463V1.954c0-.805-.659-1.463-1.463-1.463zm0 2.926L7.5 7.073 1.649 3.416V1.954L7.5 5.61l5.851-3.656v1.462z"
      />
    </svg>
  </div>
);

Message.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default Message;
