import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { sendRecoveryEmail } from 'actions/auth';

import Tick from 'common/Icons/Tick';
import './style.scss';

const ReSendButton = ({ userEmail = '', sendRecoveryEmail = () => {} }) => {
  const [passwordResent, setPasswordResent] = useState(false);
  const handleResendPassword = () => {
    if (userEmail) {
      sendRecoveryEmail({ email: userEmail });
      setPasswordResent(true);
    }
  };

  return (
    <Fragment>
      <button
        onClick={handleResendPassword}
        className={`resend-password-button btn btn-link btn-success ${
          passwordResent ? 're-sent' : ''
        }`}
        type="button"
      >
        <span>{passwordResent ? 'Password re-sent' : 'Re-send Password'}</span>
        {passwordResent && <Tick />}
      </button>
    </Fragment>
  );
};

ReSendButton.propTypes = {
  sendRecoveryEmail: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  sendRecoveryEmail,
};

export default connect(null, mapDispatchToProps)(ReSendButton);
