import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import InputInner from './utils/Input';

import Select from '../Select';

import './styles.scss';
import { titleOptions } from './utils/constants';

class SmartNameInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.getValue('title') || '',
      firstName: this.getValue('firstName') || '',
      lastName: this.getValue('lastName') || '',
    };
    this.timeoutDelay = null;
  }

  onChange = (val, key) => {
    this.setState({ [key]: val }, () => {
      let totalValue = this.getTotalValue();

      if (/^ *$/.test(totalValue)) totalValue = '';

      if (this.timeoutDelay) clearTimeout(this.timeoutDelay);

      this.timeoutDelay = setTimeout(() => {
        this.props.onChange(totalValue, this.props.name);
        clearTimeout(this.timeoutDelay);
      }, 300);
    });
  };

  getValue = (key) => {
    const { value } = this.props;
    const normalzedValue = value.replace(/\s+/g, ' ').trim();

    switch (key) {
      case 'title':
        return normalzedValue.split(' ')[0];
      case 'firstName':
        return normalzedValue.split(' ')[1];
      case 'lastName':
        return normalzedValue.split(' ')[2];
      default:
        return false;
    }
  };

  getTotalValue = () => {
    const { title, firstName, lastName } = this.state;

    return `${title} ${firstName} ${lastName}`;
  };

  render() {
    const {
      name,
      type,
      label,
      className,
      wrapperClass,
      disabled,
      autoFocus,
      labelAnimate,
      labelInside,
      style,
      mandatory,
      newDesign,
    } = this.props;
    let isRequiredLabel = mandatory ? `${label} *` : label;
    const { title, firstName, lastName } = this.state;
    const isRequired = !!title || !!firstName || !!lastName;
    const filledInputLabelClass =
      title || firstName || lastName ? 'form-control-label-filled' : '';
    const inputLabel = isRequiredLabel && (
      <label htmlFor={name} className="form-control-label">
        <span className={filledInputLabelClass}>{isRequiredLabel}</span>
      </label>
    );

    const newDesignStyles = newDesign ? 'new-design-field' : null;
    const newDesignSmartInputLabel = newDesign
      ? 'new-design-smart-input-label'
      : '';
    const newDesignSmartInputSelect = newDesign
      ? 'new-design-smart-input-select'
      : '';

    const newDesignSmartInputRight = newDesign
      ? 'new-design-smart-input-right '
      : '';

    return (
      <Fragment>
        <div className={`smart-input-wrapper ${newDesignStyles}`} style={style}>
          {/* <div className="smart-input-wrapper" style={style}> */}
          {isRequiredLabel && (
            <div className={`smart-input-label ${newDesignSmartInputLabel}`}>
              {inputLabel}
            </div>
            // <div className="smart-input-label">{inputLabel}</div>
          )}
          <div className="smart-input-list">
            <Select
              wrapperClass={wrapperClass}
              labelInside={labelInside}
              className={`${className} ${newDesignSmartInputSelect}`}
              disabled={disabled}
              autoFocus={autoFocus}
              labelAnimate={labelAnimate}
              name="title"
              type={type || 'text'}
              selectValue={title || ''}
              onChange={this.onChange}
              isRequired={isRequired}
              label="Title"
              options={titleOptions}
              newDesign={newDesign}
              smartNameSelect={true}
            />
            <InputInner
              wrapperClass={wrapperClass}
              labelInside={labelInside}
              className={className}
              disabled={disabled}
              autoFocus={autoFocus}
              labelAnimate={labelAnimate}
              name="firstName"
              type={type || 'text'}
              value={firstName || ''}
              onChange={this.onChange}
              isRequired={isRequired}
              label="First name"
              newDesign={newDesign}
            />
            <InputInner
              wrapperClass={wrapperClass}
              labelInside={labelInside}
              className={`${className} ${newDesignSmartInputRight}`}
              disabled={disabled}
              autoFocus={autoFocus}
              labelAnimate={labelAnimate}
              name="lastName"
              type={type || 'text'}
              value={lastName || ''}
              onChange={this.onChange}
              isRequired={isRequired}
              label="Last name"
              newDesign={newDesign}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

SmartNameInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  wrapperClass: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  labelAnimate: PropTypes.bool,
  labelInside: PropTypes.bool,
  label: PropTypes.string,
};

SmartNameInput.defaultProps = {
  value: '',
  className: null,
  wrapperClass: null,
  errors: [],
  disabled: false,
  autoFocus: false,
  labelAnimate: false,
  labelInside: false,
  onChange: null,
  onKeyPress: null,
  onBlur: null,
  label: '',
};

export default SmartNameInput;
