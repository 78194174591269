import { organizationsAppPath } from "utils/paths";

// APP paths
export const listPath = `${organizationsAppPath}`;
export const rootPath = `${organizationsAppPath}`;
export const newPath = `${organizationsAppPath}/new`;
export const infoPath = `${organizationsAppPath}/:id`;
export const editPath = `${organizationsAppPath}/:id/edit`;
export const documentsPath = `${organizationsAppPath}/:id/documents`;
export const claimsPath = `${organizationsAppPath}/:id/claims`;
