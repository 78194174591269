import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Input from 'components/Input';
import List from 'components/List';

import './styles.scss';
import ListItem from './ListItem';
import { filteredSearchPath } from 'utils/paths';
import { Suggestion } from './Suggestion';

const SYMBOLS_LIMIT = 1;
class FilteredSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSelected: false,
      selectedSuggestion: {},
      value: props.value || '',
      preloadedDataLoaded: false,
      suggestions: [],
      closeSuggestions: false,
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.listener, false);
  }

  componentDidUpdate() {
    const { isSelected, preloadedDataLoaded } = this.state;

    if (
      !isSelected &&
      !preloadedDataLoaded &&
      Object.keys(this.props.preloadedData).length > 0
    ) {
      this.setPreloadedData(this.props.preloadedData);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.listener, false);
  }

  onChange = (value, name) => {
    const { searchUrl, symbolsLimit } = this.props;
    this.setState({
      value,
      clickedOutside: false,
      closeSuggestions: false,
    });
    const runSearch = async () => {
      await axios
        .get(filteredSearchPath({ searchUrl, questionId: name, value }))
        .then(({ data }) => {
          this.setState({ suggestions: data });
        });
    };

    if (value && value.length >= (symbolsLimit ?? SYMBOLS_LIMIT)) {
      runSearch();
    }
  };

  onSuggestionClick = (suggestion) => {
    const { name, onSuggestionClick } = this.props;
    const selected = true;

    if (onSuggestionClick) {
      onSuggestionClick(suggestion);
    }

    this.onChange(suggestion.name, name, selected);
    this.props.onChange(suggestion.name, name);

    this.setState({
      suggestions: [],
      closeSuggestions: true,
      isSelected: false,
      selectedSuggestion: {},
    });
  };

  onLinkClick = (url) => {
    window.open(url, '_blank');
  };

  onDeleteClick = () => {
    const { name } = this.props;
    const selected = false;

    this.setState({
      value: '',
      isSelected: false,
      selectedSuggestion: {},
    });
    this.props.onChange('', name, selected);
  };

  setPreloadedData = (data) => {
    this.setState({
      isSelected: true,
      selectedSuggestion: data,
      preloadedDataLoaded: true,
    });
  };

  listener = (event) => {
    const { clickedOutside } = this.state;

    if (!event.target.closest('.suggestions-list-item') && !clickedOutside) {
      this.setState({ clickedOutside: true });
    }
  };

  handleSufixClick = (...params) => {
    const { onSufixClick, clearOnSufix } = this.props;
    if (clearOnSufix) {
      this.setState({ value: '' }, () => {
        onSufixClick(...params);
      });
    }
  };

  render() {
    const {
      name,
      label,
      className,
      errors,
      autoFocus,
      sufix,
      secondSufix,
      prefix,
      labelAnimate,
      placeholder,
      labelInside,
      button,
      searchInput,
      isRequired,
      disabled,
      onSufixClick,
      onSecondSufixClick,
      style,
      clearOnSufix,
      fullWidth,
    } = this.props;
    const {
      selectedSuggestion,
      value,
      clickedOutside,
      suggestions,
      closeSuggestions,
    } = this.state;

    return (
      <div className={`autocomplete-wrapper ${className}`} style={style}>
        <div className="autocomplete-form-control-wrapper">
          <Input
            {...this.props}
            value={value || ''}
            name={name}
            type="text"
            onChange={this.onChange}
            label={label}
            errors={errors}
            autoFocus={autoFocus}
            sufix={sufix}
            onSufixClick={clearOnSufix ? this.handleSufixClick : onSufixClick}
            secondSufix={secondSufix}
            onSecondSufixClick={onSecondSufixClick}
            prefix={prefix}
            labelAnimate={labelAnimate}
            placeholder={placeholder}
            labelInside={labelInside}
            disabled={disabled}
            SuggestionSelected={ListItem}
            selectedSuggestion={selectedSuggestion}
            searchInput={searchInput}
            isRequired={isRequired}
          />
          {!closeSuggestions &&
            !clickedOutside &&
            value &&
            suggestions &&
            suggestions.length > 0 && (
              <div className="autocomplete-dropdown suggestions">
                <List type="clear" className="with-scroll-y">
                  {suggestions.map((item) =>
                    Suggestion(item, this.onSuggestionClick, fullWidth)
                  )}
                </List>
                {button}
              </div>
            )}
        </div>
      </div>
    );
  }
}

FilteredSearch.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  autoFocus: PropTypes.bool,
  errors: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ),
  sufix: PropTypes.node,
  secondSufix: PropTypes.node,
  prefix: PropTypes.node,
  labelAnimate: PropTypes.bool,
  labelInside: PropTypes.bool,
  onChange: PropTypes.func,
  button: PropTypes.node,
  // SuggestionSelected: PropTypes.func,
  Suggestion: PropTypes.func,
  suggestions: PropTypes.arrayOf(Object),
  searchInput: PropTypes.bool,
  isRequired: PropTypes.bool,
  onSuggestionClick: PropTypes.func,
  preloadedData: PropTypes.shape(),
  fullWidth: PropTypes.bool,
};

FilteredSearch.defaultProps = {
  name: '',
  placeholder: '',
  label: null,
  className: '',
  errors: [],
  autoFocus: false,
  sufix: null,
  prefix: null,
  labelAnimate: false,
  labelInside: false,
  onChange: null,
  button: null,
  SuggestionSelected: null,
  Suggestion: null,
  suggestions: [],
  searchInput: false,
  isRequired: false,
  onSuggestionClick: null,
  preloadedData: {},
  hideRedDot: false,
  fullWidth: false,
};

export default FilteredSearch;
