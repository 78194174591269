import Base from './Base';
import Auth from './Auth';

import './styles.scss';

const Layout = {
  Base,
  Auth,
};

export default Layout;
