import { customersAppPath } from "utils/paths";

// APP paths
export const listPath = `${customersAppPath}`;
export const rootPath = `${customersAppPath}`;
export const newPath = `${customersAppPath}/new`;
export const infoPath = `${customersAppPath}/:id`;
export const editPath = `${customersAppPath}/:id/edit`;
export const documentsPath = `${customersAppPath}/:id/documents`;
export const claimsPath = `${customersAppPath}/:id/claims`;
