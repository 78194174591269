import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

import Icons from 'common/Icons';

import { isThompsons } from 'utils/helpers';

import NewEntityPopup from 'components/NewEntityPopup';

import { loadingTableData } from 'utils/mock/loadingTableData';

import './styles.scss';
import { STATUSES } from 'utils/constants';

class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navigate: '',
    };
  }

  onRowClick = (row) => {
    if (!row.disabled) {
      const { pathBase, pathField, navSubPath, pathBaseSecond } = this.props;
      if (pathBase && pathField && row[pathField]) {
        if (pathBaseSecond && row.roles) {
          this.setState({
            navigate: `${pathBaseSecond}/${row[pathField]}${
              `/${navSubPath}` || ''
            }`,
          });
        } else {
          this.setState({
            navigate:
              pathField && row[pathField]
                ? `${pathBase}/${row[pathField]}${`/${navSubPath}`}`
                : '',
          });
        }
      } else {
        this.props.onRowClick(row);
      }
    }
    return false;
  };

  onRowPupupClick = (id) => {
    this.props.setEntityId(id);
  };

  onIconClick = (e, cell) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(cell);
  };

  customizeCell = (cell, row, extraData) => {
    const {
      wrapperClass,
      titleUser,
      titleRef,
      addUserContent,
      addRefContent,
      cellSpanUser,
      cellSpanRef,
      autocomplete,
      entityType,
      primaryColor,
      onDownloadFileClick,
      switchEventsModal,
      popupAdditionalClassName,
      isCustomer,
    } = this.props;

    let cellLink = cell;

    const tableKeys = Object.keys(row)
      .map((key) => {
        if (row[key] === cell) return key;
        return false;
      })
      .filter((el) => el);

    tableKeys.forEach((key) => {
      if (key === 'status' && isThompsons() && entityType === 'tasks') {
        const isCompleted =
          cellLink === STATUSES.completed ||
          cellLink === STATUSES.completed_form;
        cellLink = (
          <div>
            <span className={`dot ${isCompleted ? 'completed' : ''}`} />
            {isCompleted ? 'completed' : 'pending'}
          </div>
        );
      } else if (key === 'event' && cellLink === 'outgoing') {
        cellLink = (
          <Icons className="flex justify-center" iconName="activeLinkArrow" />
        );
      } else if (key === 'event' && cellLink === 'incoming') {
        cellLink = <Icons iconName="unActiveLinkArrow" />;
      } else if (key === 'url') {
        cellLink = (
          <div
            onClick={(e) => {
              e.stopPropagation();
              onDownloadFileClick(cell, row.filename);
            }}
            className="download-document"
          >
            <Icons iconName="download" />
          </div>
        );
      } else if (key === 'correspondenceEvents' && !isCustomer) {
        cellLink = (
          <div
            onClick={(e) => {
              e.persist();
              e.stopPropagation();
              switchEventsModal(row);
            }}
            className="download-document"
          >
            <Icons iconName="infoCircle" />
          </div>
        );
      } else if (
        (key === 'createdAt' ||
          key === 'updatedAt' ||
          key === 'dateOfBirth' ||
          key === 'dueDate') &&
        cell &&
        cell !== key
      ) {
        const withoutUTC = cell.replace(' UTC', '');
        cellLink = moment(withoutUTC).format('DD MMM YYYY');
      } else if (key === 'userFullName' && !cellLink) {
        cellLink = (
          <NewEntityPopup
            id={row.id}
            wrapperClass={wrapperClass}
            title={titleUser}
            content={addUserContent}
            cellSpan={cellSpanUser}
            autocomplete={autocomplete}
            onRowPupupClick={this.onRowPupupClick}
            primaryColor={primaryColor}
            additionalClassName={popupAdditionalClassName}
          />
        );
      } else if (
        extraData === 'externalRef' &&
        !cellLink &&
        (entityType === 'claim' || entityType === 'customer')
      ) {
        cellLink = (
          <NewEntityPopup
            id={row.id}
            wrapperClass={wrapperClass}
            title={titleRef}
            content={addRefContent}
            cellSpan={cellSpanRef}
            onRowPupupClick={this.onRowPupupClick}
            primaryColor={primaryColor}
            additionalClassName={popupAdditionalClassName}
          />
        );
      } else if (
        extraData === 'externalRef' &&
        cellLink &&
        (entityType === 'claim' || entityType === 'customer')
      ) {
        const alreadyHasRef = !!(cell && typeof cell === 'string');
        const cellSpan = alreadyHasRef ? cell : cellSpanRef;

        cellLink = (
          <NewEntityPopup
            id={row.id}
            wrapperClass={wrapperClass}
            title={titleRef}
            content={addRefContent}
            cellSpan={cellSpan}
            onRowPupupClick={this.onRowPupupClick}
            primaryColor={primaryColor}
            alreadyHasRef={alreadyHasRef}
            additionalClassName={popupAdditionalClassName}
          />
        );
      } else if (key === 'from') {
        cellLink = <span className="unset-text-transform ">{cellLink}</span>;
      } else if (
        key === 'documents' &&
        row['documents'] &&
        row['documents'].length > 0
      ) {
        cellLink = (
          <div
            onClick={(e) => {
              e.stopPropagation();
              onDownloadFileClick(cell, row.filename);
            }}
            className="attached-documents"
          >
            <Icons iconName="paperclip" fill="#000000" />
          </div>
        );
      } else if (
        key === 'subject' &&
        entityType === 'correspondence' &&
        (row.type === 'Message' || row.type === 'Email') &&
        (!cellLink || cellLink === 'Unavailable')
      ) {
        if (row.body.length > 8) {
          cellLink = `${row.body}...`;
        } else {
          cellLink = row.body;
        }
      }
    });
    return cellLink;
  };

  columnClassNameFormat = (fieldValue, row, rowIdx, colIdx) => {
    if (row && row.type === 'email') {
      return 'unset-capitalize';
    } else if (row.type === 'Message' && colIdx === 0) {
      return 'unset-capitalize';
    }
    return '';
  };

  render() {
    const {
      className,
      tHead,
      tBody,
      selectRow,
      options,
      pagination,
      pathBase,
      trClassName,
      loading,
    } = this.props;
    const tableBody = loading ? loadingTableData : tBody;

    if (this.state.navigate) {
      return <Redirect to={this.state.navigate} push />;
    }

    return (
      <BootstrapTable
        pagination={pagination}
        className={`table-wrapper ${className}`}
        trClassName={trClassName}
        data={tableBody}
        selectRow={selectRow}
        pathBase={pathBase}
        options={{
          ...options,
          sortIndicator: false,
          onRowClick: this.onRowClick,
          onSortChange: this.props.onSortChange,
          noDataText: loading ? (
            <div className="lds-dual-ring" />
          ) : (
            'There is no data to display'
          ),
        }}
      >
        {tHead.map((item) => (
          <TableHeaderColumn
            hidden={item.hidden}
            key={item.dataField}
            dataField={item.dataField}
            formatExtraData={item.dataField}
            isKey={item.isKey}
            dataSort={item.dataSort}
            dataFormat={this.customizeCell}
            columnClassName={this.columnClassNameFormat}
            width={item.width ? item.width : ''}
          >
            {item.dataValue}
          </TableHeaderColumn>
        ))}
      </BootstrapTable>
    );
  }
}

Table.propTypes = {
  className: PropTypes.string,
  tHead: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  tBody: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ),
  selectRow: PropTypes.instanceOf(Object),
  options: PropTypes.instanceOf(Object),
  pathBase: PropTypes.string,
  pagination: PropTypes.bool,
  pathField: PropTypes.string,
  onSortChange: PropTypes.func,
  navSubPath: PropTypes.string,
  onRowClick: PropTypes.func,
  setEntityId: PropTypes.func,
  trClassName: PropTypes.string,
  loading: PropTypes.bool,
  entityType: PropTypes.string,
  wrapperClass: PropTypes.string,
  titleUser: PropTypes.string,
  titleRef: PropTypes.string,
  addUserContent: PropTypes.node,
  addRefContent: PropTypes.node,
  cellSpanUser: PropTypes.string,
  cellSpanRef: PropTypes.string,
  autocomplete: PropTypes.shape(),
  primaryColor: PropTypes.string,
};

Table.defaultProps = {
  className: '',
  multiColumnSort: 1,
  selectRow: {},
  options: {},
  pathBase: '',
  pagination: false,
  pathField: '',
  onSortChange: null,
  navSubPath: '',
  onRowClick: null,
  setEntityId: null,
  trClassName: '',
  loading: false,
  entityType: '',
  wrapperClass: '',
  titleUser: '',
  titleRef: '',
  addUserContent: null,
  addRefContent: null,
  cellSpanUser: '',
  cellSpanRef: '',
  autocomplete: {},
  primaryColor: '',
  tBody: [],
};

export default Table;
