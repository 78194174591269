import React from 'react';
import PropTypes from 'prop-types';

const Search = ({
  width = 11,
  height = 11,
  className = '',
  fill = '#0F2241',
}) => (
  <div className={`la-icon icon-svg-search ${className}`}>
    <svg width={width} height={height} viewBox="0 0 11 11">
      <path
        fill={fill}
        fillRule="nonzero"
        d="M10.504 9.512l-1.67-1.817a4.706 4.706 0 0 0 1.085-3.01C9.919 2.1 7.845 0 5.296 0 2.746 0 .672 2.101.672 4.684s2.074 4.684 4.624 4.684c.957 0 1.869-.292 2.648-.847l1.691 1.838a.595.595 0 0 0 .853.017.617.617 0 0 0 .016-.864zm-5.208-8.29c1.884 0 3.417 1.553 3.417 3.462 0 1.91-1.533 3.462-3.417 3.462-1.885 0-3.418-1.553-3.418-3.462 0-1.909 1.533-3.462 3.418-3.462z"
      />
    </svg>
  </div>
);

Search.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default Search;
