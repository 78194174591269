import React from 'react';
import PropTypes from 'prop-types';

const Edit = ({ width = 15, height = 14, className = '', fill = '#FFF' }) => (
  <div className={`la-icon icon-svg-edit ${className}`}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 1000 1000"
    >
      <g>
        <g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
          <path
            fill={fill}
            d="M6885.4,4593.7c-210.1-44.8-439-122.7-616-212.4c-335.1-172.3-420.1-250.2-3028.1-2853.4C1830,118.9,609.8-1113.1,531.9-1209.9c-167.6-210-280.9-427.2-363.5-703.3c-85-280.8-92-743.4-14.2-1033.7c179.4-660.8,649-1161.2,1302.8-1383c198.3-68.5,262-77.9,542.8-87.3c266.7-7.1,351.7-2.4,531,40.1c254.9,61.4,535.8,193.5,743.5,354c85,63.7,951.1,915.7,1925.9,1895.2L6972.8-350.8l99.1,200.6c54.3,110.9,113.3,276.1,132.2,365.8C7395.2,1129,6708.4,1974,5776.2,1974c-240.7,0-420.1-42.5-660.8-155.8c-169.9-80.2-224.2-129.8-1545.9-1449.1L2200.5-999.9l259.6-259.6l259.6-259.6L4029.6-209.2C5188.5,947.3,5356.1,1105.4,5478.8,1162.1c354,160.5,750.5,26,927.6-318.6c66.1-127.4,77.9-172.3,77.9-316.3c0-323.3,80.3-226.6-1859.8-2171.3C3619-2652,2828.3-3423.7,2750.4-3478c-210.1-141.6-413-200.6-679.7-198.3c-169.9,0-262,14.2-384.7,54.3C959.1-3376.5,633.4-2552.8,989.8-1863.7c63.7,122.7,439,509.8,2648.1,2723.6c1416.1,1418.5,2638.7,2629.2,2716.6,2688.2c321,243.1,637.2,346.9,1050.3,346.9c321,0,495.6-40.1,767.1-174.6c481.5-236,809.5-646.7,934.6-1168.3c40.1-174.6,47.2-257.3,37.8-472c-16.5-368.2-113.3-656.1-318.6-944.1c-44.8-63.7-1045.5-1080.9-2220.9-2261L4466.3-3265.6l259.6-259.6l259.6-259.6l2218.6,2223.3C9226.7,465.8,9434.4,680.6,9538.3,860c299.7,507.4,420.1,1099.8,335.1,1649.8c-160.5,1047.9-915.7,1857.4-1947.1,2086.4C7654.8,4655.1,7166.3,4655.1,6885.4,4593.7z"
          />
        </g>
      </g>
    </svg>
  </div>
);

Edit.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default Edit;
