import React from 'react';
import PropTypes from 'prop-types';

const File = ({ width = 15, height = 14, className = '', fill = '#FFF' }) => (
  <div className={`la-icon icon-svg-File ${className}`}>
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <linearGradient
        id="a"
        gradientUnits="userSpaceOnUse"
        x1="256"
        x2="256"
        y1="18"
        y2="466"
      >
        <stop offset="0" stop-color="#00efd1" />
        <stop offset="1" stop-color="#00acea" />
      </linearGradient>
      <g fill="url(#a)">
        <path
          fill={fill}
          d="m294 267.47c0-5.523-4.324-10.47-9.847-10.47h-107.4c-5.523 0-9.753 4.947-9.753 10.47v101.666a9.676 9.676 0 0 0 9.753 9.864h107.4a9.755 9.755 0 0 0 9.847-9.864v-21.566l37.074 16.612a10.179 10.179 0 0 0 4.134.877 9.657 9.657 0 0 0 5.33-1.605 9.9 9.9 0 0 0 4.462-8.395v-72.991a9.888 9.888 0 0 0 -4.443-8.395 10.029 10.029 0 0 0 -9.544-.727l-37.013 16.611zm-20 91.53h-87v-82h87zm51-61.48v42.087l-31-13.96v-14.167z"
        />
        <path
          fill={fill}
          d="m411.333 163.146-104.509-104.012-3.1-3.159a10.028 10.028 0 0 0 -7.058-2.975h-150.152a51.682 51.682 0 0 0 -51.514 51.8v302.4a51.682 51.682 0 0 0 51.514 51.8h218.972a51.682 51.682 0 0 0 51.514-51.8v-234.1a9.907 9.907 0 0 0 -2.831-7.07zm-103.333-74.941 74.458 74.795h-69.107c-3.111 0-5.351-2.949-5.351-6.059zm89 318.995a31.66 31.66 0 0 1 -31.514 31.8h-218.972a31.66 31.66 0 0 1 -31.514-31.8v-302.4a31.66 31.66 0 0 1 31.514-31.8h141.486v83.941c0 14.138 11.212 26.059 25.351 26.059h83.649z"
        />
      </g>
    </svg>
  </div>
);

File.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default File;
