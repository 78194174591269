import React from 'react';
import PropTypes from 'prop-types';
import Form from 'components/Form';

const AccountForm = ({ disabled, onChange, values = {}, errors = {} }) => (
  <Form
    gap={20}
    disabled={disabled}
    onChange={onChange}
    className="col-lg-12 col-md-12 col-xs-12"
    fields={[
      [
        {
          element: 'input',
          name: 'firstName',
          value: values.firstName || '',
          type: 'text',
          label: 'First name',
          labelInside: true,
          inputCombine: false,
          errors: errors.firstName || [],
        },
        {
          element: 'input',
          name: 'lastName',
          value: values.lastName || '',
          type: 'text',
          label: 'Last name',
          labelInside: true,
          inputCombine: false,
          errors: errors.lastName || [],
        },
      ],
      [
        {
          element: 'input',
          name: 'company',
          value: values.companyName || '',
          type: 'text',
          label: 'Company',
          labelInside: true,
          inputCombine: false,
          errors: errors.companyName || [],
          disabled: true,
        },
        {
          element: 'input',
          name: 'role',
          value: values.roles ? values.roles[0].name : '',
          type: 'select',
          label: 'Role',
          labelInside: true,
          inputCombine: false,
          errors: errors.role || [],
          disabled: true,
          className: 'capitalize',
        },
      ],
      [
        {
          element: 'input',
          name: 'password',
          value: values.password,
          type: 'password',
          label: 'Password',
          labelInside: true,
          inputCombine: false,
          errors: errors.password || [],
        },
        {
          element: 'input',
          name: 'confirmPassword',
          value: values.confirmPassword,
          type: 'password',
          label: 'Confirm password',
          labelInside: true,
          inputCombine: false,
          errors: errors.confirmPassword || [],
        },
      ],
      {
        element: 'input',
        name: 'email',
        value: values.email || '',
        type: 'text',
        label: 'Email',
        labelInside: true,
        inputCombine: false,
        errors: errors.email || [],
      },
    ]}
  />
);

AccountForm.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  values: PropTypes.shape({
    surname: PropTypes.string,
  }),
  errors: PropTypes.shape(),
  editMode: PropTypes.bool,
};

export default AccountForm;
