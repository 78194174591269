import React from 'react';
import PropTypes from 'prop-types';

const Person = ({
  width = 22,
  height = 20,
  className = '',
  fill = '#000',
  onClick = () => {},
}) => (
  <div
    className={`la-icon icon-svg-menu ${className}`}
    onClick={onClick}
    role="presentation"
  >
    <svg width={width} height={height} viewBox="0 0 32 32">
      <path
        fill={fill}
        d="M16 15.503A5.041 5.041 0 1 0 16 5.42a5.041 5.041 0 0 0 0 10.083zm0 2.215c-6.703 0-11 3.699-11 5.5v3.363h22v-3.363c0-2.178-4.068-5.5-11-5.5z"
      />
    </svg>
  </div>
);

Person.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default Person;
