import React from 'react';
import PropTypes from 'prop-types';

const StarUnactive = ({
  width = 18,
  height = 17,
  className = '',
  fill = '#CFD3D9',
}) => (
  <div className={`la-icon icon-svg-star ${className}`}>
    <svg width={width} height={height} viewBox="0 0 18 17">
      <path
        fill={fill}
        fillRule="nonzero"
        d="M9.558.868l2.134 5.05 5.464.47a.43.43 0 0 1 .245.755l-4.144 3.59 1.242 5.342a.43.43 0 0 1-.642.465L9.162 13.71 4.466 16.54a.43.43 0 0 1-.641-.465l1.242-5.342L.92 7.142a.43.43 0 0 1 .246-.755l5.464-.47L8.765.868a.43.43 0 0 1 .793 0z"
      />
    </svg>
  </div>
);

StarUnactive.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default StarUnactive;
