export const REPAIR_STATUSES = {
  'Repairer Instructed': 'The repairer has been instructed',
  'Vehicle Estimated': 'Your vehicle is being estimated',
  'Repairs Authorised': 'The repair costs are being authorised',
  'Repairs Started': 'The repairs have started on your vehicle',
  'Estimated Completion Date': 'Your repairs are due to be completed',
  'Repairs Completed': 'Your repairs have now been completed',
};

export const REPAIR_STATUSES_MAPPING = {
  'Repairer Instructed': 'RepairerInstructed',
  'Vehicle Estimated': 'EstimateToEngneer',
  'Repairs Authorised': 'EstimateBID',
  'Repairs Started': 'RepairStartDate',
  'Estimated Completion Date': 'EstimatedRepairCompletion',
  'Repairs Completed': 'RepairCompletionDate',
};

const keys = {
  repairerDetails: 'Repairer details',
  vehicleDetails: 'Vehicle details',
  insuranceDetails: 'Your insurance details',
  accidentDetails: 'Accident details',
};

export const response = {
  [keys.repairerDetails]: {},
};
