import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';

import Layout from 'components/Layout';
import Ring from 'components/Ring';

import { newPath, listPath, infoPath } from './utils/paths';

const TaskList = lazy(() => import('./containers/ListV2'));
// const TaskList = lazy(() => import('./containers/List'));
const TaskNew = lazy(() => import('./containers/New'));
const TaskDetails = lazy(() => import('./containers/Details'));

class TaskPage extends React.Component {
  render() {
    return (
      <Layout.Base>
        <Suspense fallback={<Ring />}>
          <Switch>
            <Route
              exact
              path={listPath}
              component={(props) => <TaskList {...props} />}
            />
            <Route
              path={newPath}
              component={(props) => <TaskNew {...props} />}
            />
            <Route
              path={infoPath}
              component={(props) => <TaskDetails {...props} />}
            />
          </Switch>
        </Suspense>
      </Layout.Base>
    );
  }
}

const mapStateToProps = ({ session }) => ({
  session,
});

export default withRouter(connect(mapStateToProps)(TaskPage));
