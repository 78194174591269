import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { rootAppPath } from 'utils/paths';

import './styles.scss';

const Logo = ({
  src = '',
  className = '',
  fill = '#FF6081',
  width = 22,
  height = 22,
  pathTo = rootAppPath,
  onClick = () => {},
}) =>
  onClick ? (
    <div onClick={onClick} className={`brand ${className}`}>
      {src && <img src={src} alt="" />}
      {!src && (
        <svg width={width} height={height} viewBox="0 0 22 22">
          <g fill={fill} fillRule="nonzero">
            <path d="M19.871 11.22a8.891 8.891 0 1 0-17.782 0 8.891 8.891 0 0 0 17.782 0zm1.84 0c0 5.926-4.805 10.73-10.731 10.73C5.053 21.95.249 17.146.249 11.22.249 5.292 5.053.487 10.979.487c5.927 0 10.732 4.805 10.732 10.731z" />
            <path d="M11.9 15.512h-1.84V6.774h1.84v8.738zm0-5.213h8.749v1.84h-8.75v3.373H10.06V6.774h1.84v3.525z" />
          </g>
        </svg>
      )}
    </div>
  ) : (
    <Link to={pathTo} className={`brand ${className}`}>
      {src && <img src={src} alt="" />}
      {!src && (
        <svg width={width} height={height} viewBox="0 0 22 22">
          <g fill={fill} fillRule="nonzero">
            <path d="M19.871 11.22a8.891 8.891 0 1 0-17.782 0 8.891 8.891 0 0 0 17.782 0zm1.84 0c0 5.926-4.805 10.73-10.731 10.73C5.053 21.95.249 17.146.249 11.22.249 5.292 5.053.487 10.979.487c5.927 0 10.732 4.805 10.732 10.731z" />
            <path d="M11.9 15.512h-1.84V6.774h1.84v8.738zm0-5.213h8.749v1.84h-8.75v3.373H10.06V6.774h1.84v3.525z" />
          </g>
        </svg>
      )}
    </Link>
  );

Logo.propTypes = {
  className: PropTypes.string,
  pathTo: PropTypes.string,
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  src: PropTypes.string,
};

export default Logo;
