import React, { Component } from 'react';

import Layout from 'components/Layout';

import Fingerprints from "./containers/Fingerprints";

class FingerprintsPage extends Component {
  render() {
    return (
      <Layout.Base>
        <Fingerprints />
      </Layout.Base>
    );
  }
}

export default FingerprintsPage;
