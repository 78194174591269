import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const Alert = ({
  className = '',
  type = '',
  title = '',
  description = '',
  secondary = false,
}) => {
  const alertType = secondary ? `alert-${type}-secondary` : `alert-${type}`;
  return (
    <div className={`alert ${alertType} ${className}`}>
      {title && <div className="alert-title">{title}</div>}
      {description && <div className="alert-description">{description}</div>}
    </div>
  );
};

Alert.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  secondary: PropTypes.bool,
};

export default Alert;
