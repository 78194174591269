import React from 'react';
import paperclip from 'assets/images/paper-clip.png';
import RemoveDocumentButton from './RemoveDocumentButton';
import './styles.scss';

const DocumentPlaceholder = ({ onClick, disabled, removeFile }) => {
  return (
    <div className="media-upload-document-placeholder">
      {!disabled && <RemoveDocumentButton removeFile={removeFile} />}
      <img
        src={paperclip}
        alt="paper-clip"
        className="media-upload-image"
        onClick={onClick}
      />
      {/* <span className="form-control-file-sublabel media-upload-file-name">
        {fileName}
      </span> */}
    </div>
  );
};

export default DocumentPlaceholder;
