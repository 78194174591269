import React from 'react';
import PropTypes from 'prop-types';

const ActiveLinkArrow = ({
  width = 11,
  height = 11,
  className = '',
  fill = '#1E201D',
}) => (
  <div className={`la-icon icon-svg-link ${className}`}>
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 22.924 22.924"
      width={width}
      height={height}
    >
      <path
        fill={fill}
        d="M22.924,15.206c0,0.619-0.508,1.111-1.127,1.111c-0.619,0-1.111-0.492-1.111-1.111V3.825
        L1.909,22.603c-0.429,0.429-1.143,0.429-1.587,0c-0.429-0.444-0.429-1.143,0-1.587L19.099,2.254H7.734
        c-0.619,0-1.127-0.508-1.127-1.127S7.115,0,7.734,0h14.063c0.619,0,1.127,0.508,1.127,1.127V15.206z"
      />
    </svg>
  </div>
);

ActiveLinkArrow.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default ActiveLinkArrow;
