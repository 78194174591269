import React from 'react';
import PropTypes from 'prop-types';

import Form from 'components/Form';
import Icons from 'common/Icons';
import { disableFieldsTmp, isCustomerRole, isThompsons } from 'utils/helpers';

const maritalStatusOptions = [
  {
    id: 1,
    name: 'Married',
    value: 'married',
  },
  {
    id: 2,
    name: 'Not married',
    value: 'not_married',
  },
  {
    id: 3,
    name: 'Divorced',
    value: 'divorced',
  },
  {
    id: 4,
    name: 'Widowed',
    value: 'widowed',
  },
];

const titleOptions = [
  {
    id: 1,
    name: 'Mr',
    value: 'Mr',
  },
  {
    id: 2,
    name: 'Mrs',
    value: 'Mrs',
  },
  {
    id: 3,
    name: 'Ms',
    value: 'Ms',
  },
  {
    id: 4,
    name: 'Miss',
    value: 'Miss',
  },
  {
    id: 5,
    name: 'Mx',
    value: 'Mx',
  },
  {
    id: 6,
    name: 'Dr',
    value: 'Dr',
  },
];

const CustomerAccountForm = ({
  values = {},
  errors = {},
  disabled,
  onChange = () => {},
  className = '',
  onSufixClick = () => {},
  roles,
}) => (
  <Form
    gap={20}
    onChange={onChange}
    disabled={disabled}
    className={className}
    fields={disableFieldsTmp([
      [
        {
          element: 'select',
          type: 'text',
          label: 'Title',
          name: 'title',
          value: values.title || '',
          errors: errors.title || [],
          labelInside: true,
          selectValue: values.title || '',
          defaultValue: values.title || '',
          options: titleOptions,
          isRequired: true,
          disabled: isThompsons() && isCustomerRole(roles) ? true : false,
        },
        {
          element: 'input',
          type: 'text',
          label: 'First name',
          name: 'firstName',
          value: values.firstName || '',
          errors: errors.firstName || [],
          labelInside: true,
          isRequired: true,
          disabled: isThompsons() && isCustomerRole(roles) ? true : false,
        },
      ],
      [
        {
          element: 'input',
          type: 'text',
          label: 'Middle name',
          name: 'middleName',
          value: values.middleName || '',
          errors: errors.middleName || [],
          labelInside: true,
          disabled: isThompsons() && isCustomerRole(roles) ? true : false,
        },
        {
          element: 'input',
          type: 'text',
          label: 'Last name',
          name: 'lastName',
          value: values.lastName || '',
          errors: errors.lastName || [],
          labelInside: true,
          isRequired: true,
          disabled: isThompsons() && isCustomerRole(roles) ? true : false,
        },
      ],
      [
        {
          element: 'date',
          type: 'date',
          label: 'Date of birth',
          name: 'dateOfBirth',
          value: values.dateOfBirth || '',
          errors: errors.dateOfBirth || [],
          labelInside: true,
          noFutureDates: true,
          withPopup: true,
          disabled: isThompsons() && isCustomerRole(roles) ? true : false,
        },
        {
          element: 'internationalPhone',
          label: 'Phone mobile',
          name: 'mobilePhone',
          value: values.mobilePhone || '',
          errors: errors.phone || [],
          labelInside: true,
          isRequired: true,
          onSufixClick,
          preference: 0,
          disabled: isThompsons() && isCustomerRole(roles) ? true : false,
        },
      ],
      {
        element: 'clickToAddress',
        type: 'text',
        label: 'Address',
        name: 'address',
        value: values.address || '',
        errors: errors.address || [],
        labelInside: true,
        addressType: 'home',
        disabled: isThompsons() && isCustomerRole(roles) ? true : false,
      },
      [
        {
          element: 'internationalPhone',
          label: 'Phone home',
          name: 'homePhone',
          value: values.homePhone || '',
          errors: errors.homePhone || [],
          labelInside: true,
          onSufixClick,
          preference: 1,
          disabled: isThompsons() && isCustomerRole(roles) ? true : false,
        },
        {
          element: 'input',
          type: 'text',
          label: 'Email work',
          name: 'email',
          // name: "workEmail",
          // value: values.workEmail || "",
          value: values.email || values.workEmail || '',
          errors:
            errors.workEmail ||
            (values.emailType === 0 ||
            values.emailType === undefined ||
            values.emailType === null
              ? errors.email
              : []) ||
            [],
          labelInside: true,
          sufix: (
            <Icons
              iconName={`${
                values.emailType === 0 || values.emailType === 'work_email'
                  ? 'starActive'
                  : 'starUnactive'
              }`}
            />
          ),
          onSufixClick,
          preference: 0,
        },
      ],
      [
        {
          element: 'input',
          type: 'text',
          label: 'Email home',
          name: 'homeEmail',
          value: values.homeEmail || '',
          errors:
            errors.homeEmail ||
            (values.emailType === 1 ||
            values.emailType === undefined ||
            values.emailType === null
              ? errors.email
              : []) ||
            [],
          labelInside: true,
          sufix: (
            <Icons
              iconName={`${
                values.emailType === 1 || values.emailType === 'home_email'
                  ? 'starActive'
                  : 'starUnactive'
              }`}
            />
          ),
          onSufixClick,
          preference: 1,
          disabled: isThompsons() && isCustomerRole(roles) ? true : false,
        },
        {
          element: 'select',
          type: 'text',
          label: 'Marital status',
          name: 'maritalStatus',
          selectValue: values.maritalStatus || '',
          errors: errors.maritalStatus || [],
          labelInside: true,
          options: maritalStatusOptions,
          disabled: isThompsons() && isCustomerRole(roles) ? true : false,
        },
      ],
      [
        {
          element: 'input',
          type: 'text',
          label: 'Occupation',
          name: 'occupation',
          value: values.occupation || '',
          errors: errors.occupation || [],
          labelInside: true,
          disabled: isThompsons() && isCustomerRole(roles) ? true : false,
        },
        {
          element: 'input',
          type: 'text',
          label: 'National insurance number',
          name: 'nationalInsuranceNumber',
          value: values.nationalInsuranceNumber || '',
          errors: errors.nationalInsuranceNumber || [],
          labelInside: true,
          disabled: isThompsons() && isCustomerRole(roles) ? true : false,
        },
      ],
      [
        {
          element: 'input',
          type: 'password',
          label: 'Password',
          name: 'password',
          value: values.password || '',
          errors: errors.password || [],
          labelInside: true,
        },
        {
          element: 'input',
          type: 'password',
          label: 'Confirm password',
          name: 'confirmPassword',
          value: values.confirmPassword || '',
          errors: errors.confirmPassword || [],
          labelInside: true,
        },
      ],
    ])}
  />
);

CustomerAccountForm.propTypes = {
  values: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    firstName: PropTypes.string,
    middleName: PropTypes.string,
    lastName: PropTypes.string,
    dateOfBirth: PropTypes.string,
    dateOfDeath: PropTypes.string,
    maritalStatus: PropTypes.string,
    occupation: PropTypes.string,
    nationalInsuranceNumber: PropTypes.string,
    phones: PropTypes.shape({
      mobilePhone: PropTypes.string,
      homePhone: PropTypes.string,
    }),
    emails: PropTypes.shape({
      workEmail: PropTypes.string,
      homeEmail: PropTypes.string,
    }),
  }),
  errors: PropTypes.shape(),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  onSufixClick: PropTypes.func,
};

export default CustomerAccountForm;
