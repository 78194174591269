/* TYPES */
import { SET_MOBILE_HEADER } from "./types";

/* ACTIONS */
export const toggleMobileHeader = payload => dispatch => {
  dispatch({
    type: SET_MOBILE_HEADER,
    payload
  });
};
