import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Grid from 'components/Layout/Grid';
import Button from 'components/Button';
import checkValidations, { validateDiff } from 'utils/validator';
import { createCustomer } from 'actions/customer';

import { customersAppPath } from 'utils/paths';
import mapAddress from 'utils/mapCraftyObject';
import { isThompsons } from 'utils/helpers';

import CustomerForm from '../components/CustomerForm';
import validations, { dateOfDeathValidation } from '../utils/validations';

class CustomerNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      enteredFields: {},
    };
  }

  componentDidUpdate(prevProps) {
    const { created, hasErrors, errors } = this.props.customer;

    if (!prevProps.customer.created && created && !hasErrors) {
      const { id } = this.props.customer.data;

      if (id) this.navigate(id);
    }

    if (!prevProps.customer.hasErrors && hasErrors) {
      this.setApiErrors(errors);
    }
  }

  onChange = (val, key) => {
    const { enteredFields } = this.state;
    const { emailType } = enteredFields;
    const addressType = 'home';

    this.setState(
      {
        enteredFields: {
          ...enteredFields,
          [key]: typeof val === 'object' ? mapAddress(val, addressType) : val,
        },
      },
      () => {
        if ((emailType == null || emailType === undefined) && val) {
          this.setInitialEmailPreference(key);
        }
      }
    );
  };

  onSufixClick = (name, value, preference) => {
    const { enteredFields } = this.state;

    if (!value && enteredFields.emailType === undefined) return false;

    if (!value && enteredFields.emailType === preference) {
      this.clearPreferences(enteredFields, value, name);
    }

    if (value && name.includes('Email')) {
      this.setState({
        enteredFields: {
          ...enteredFields,
          emailType: preference,
        },
      });
    }
    return false;
  };

  onSubmit = () => {
    const { enteredFields } = this.state;
    const { dateOfBirth, dateOfDeath } = enteredFields;
    const errors = checkValidations(validations, this.state.enteredFields);

    if (dateOfBirth && dateOfDeath) {
      const error = validateDiff(
        dateOfDeathValidation,
        dateOfBirth,
        dateOfDeath
      );

      if (error) errors.dateOfDeath = [error];
    }

    const fieldsWithPreferedPhone = {
      ...enteredFields,
      phoneType: 0,
    };

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
    } else {
      this.props.createCustomer(fieldsWithPreferedPhone);
    }
  };

  onClear = () => {
    this.setState({ errors: {}, enteredFields: {} });
  };

  setInitialEmailPreference = (key) => {
    const { enteredFields } = this.state;
    let pref;

    switch (key) {
      case 'workEmail':
        pref = 0;
        break;
      case 'homeEmail':
        pref = 1;
        break;
      default:
        return false;
    }
    return this.setState({
      enteredFields: {
        ...enteredFields,
        emailType: pref,
      },
    });
  };

  setApiErrors = (errors) => {
    this.setState({ errors });
  };

  clearPreferences = (fields, val, key) => {
    const { emailType } = fields;

    if (emailType !== undefined && !val && key.includes('Email')) {
      const newObj = Object.assign({}, fields);
      delete newObj.emailType;

      this.setState({ enteredFields: newObj });
    }
  };

  navigate = (path) => {
    this.setState({
      navigate: path,
      enteredFields: {},
      errors: {},
    });
  };

  render() {
    const { errors, enteredFields, navigate } = this.state;
    const { creating } = this.props.customer;
    const { isMobile } = this.props.browser;
    const isTmpMobile = isMobile && isThompsons() ? true : false;

    if (navigate) {
      return <Redirect to={`${customersAppPath}/${navigate}`} />;
    }

    return (
      <Fragment>
        <Grid.Controls>
          <div className="">
            <h2 className="h2">New customer</h2>
          </div>
          <div className="">
            <Button
              type="success"
              value={creating ? <div className="loader" /> : 'Create'}
              onClick={this.onSubmit}
              className="update"
            />
          </div>
        </Grid.Controls>

        <Grid.Main>
          <Grid.Content className="justify-center">
            <CustomerForm
              onChange={this.onChange}
              values={enteredFields}
              errors={errors}
              onSufixClick={this.onSufixClick}
              className="mt50 pb60 with-stars"
              isTmpMobile={isTmpMobile}
            />
          </Grid.Content>
        </Grid.Main>
      </Fragment>
    );
  }
}

CustomerNew.propTypes = {
  customer: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.number,
    }),
    hasErrors: PropTypes.bool,
    errors: PropTypes.object,
    created: PropTypes.bool,
    creating: PropTypes.bool,
  }).isRequired,
  createCustomer: PropTypes.func.isRequired,
};

CustomerNew.defaultProps = {};

const mapStateToProps = (state) => ({
  customer: state.customer,
  browser: state.browser,
});

const mapDispatchToProps = {
  createCustomer,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerNew);
