import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';

import Link from 'components/Link';
import Well from 'components/Well';
import Form from 'components/Form';
import Input from 'components/Input';
import Button from 'components/Button';
import XceedanceLogo from 'components/Logos/Xceedance';
import checkValidations from 'utils/validator';

import { sendRecoveryEmail } from 'actions/auth';
import { tasksAppPath } from 'utils/paths';
import { isThompsons, isXceedance, setHeroImageStyles } from 'utils/helpers';

import { recoverySuccessPath } from '../utils/paths';
import { recoveryValidations } from '../utils/validation';
import '../styles.scss';

class Recovery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      loading: false,
      errors: {},
    };
  }

  UNSAFE_componentWillMount() {
    const token = cookie.load('token');

    if (token) this.props.history.push(tasksAppPath);
  }

  componentDidUpdate(prevProps) {
    const { sendFinished, hasErrors } = this.props.auth;
    const { email } = this.state;

    if (prevProps.auth.sendStarted && sendFinished && !hasErrors) {
      this.props.history.push({
        pathname: recoverySuccessPath,
        state: { email },
      });
    }
  }

  onChange = (val) => {
    this.setState({
      email: val,
    });
  };

  onClick = () => {
    const { email } = this.state;
    const errors = checkValidations(recoveryValidations, { email });

    if (Object.keys(errors).length > 0) {
      this.setState({ errors, loading: false });
    } else {
      this.setState({ errors: {}, loading: true });
      this.props.sendRecoveryEmail({ email });
    }
  };

  render() {
    const { email, loading, errors } = this.state;
    const { hasErrors } = this.props.auth;
    const toLoginLink = isThompsons() ? 'to-login-link-tmp' : 'to-login-link';
    const recoveryClass = isThompsons() ? 'login-left-tmp' : 'login-left';

    return (
      <Fragment>
        {isXceedance() ? (
          <XceedanceLogo
            className="margin-30-bottom xceedance-logo"
            width={300}
            height={70}
            fullLogo
          />
        ) : null}
        <div className={`${recoveryClass}`}>
          <Well size="small" gutter={40} className="login-well margin-100-top">
            <Form className="form-compact" onSubmit={() => {}}>
              <Input
                name={'email'}
                label="Enter your email address..."
                wrapperClass="wrapper-class-name"
                value={email}
                type="text"
                onChange={this.onChange}
                onKeyPress={this.onKeyPress}
                errors={errors.email || []}
                labelAnimate
                labelClass="login-label"
                isTmp={isThompsons()}
              />
              <Button
                size="large"
                className="landing btn-login"
                value={
                  loading && !hasErrors ? (
                    <div className="loader" />
                  ) : (
                    'Send reset'
                  )
                }
                onClick={this.onClick}
              />
            </Form>
            {hasErrors && (
              <div className="flex justify-center text-color-danger padding-20-top">
                Email does not exist
              </div>
            )}
          </Well>
          <Link
            className={`${toLoginLink}`}
            pathTo="/login"
            value="Back to login"
          />
        </div>
        {!isThompsons() && (
          <div style={setHeroImageStyles} className="recovery-hero-img" />
        )}
      </Fragment>
    );
  }
}

Recovery.propTypes = {
  sendRecoveryEmail: PropTypes.func.isRequired,
  auth: PropTypes.shape({
    created: PropTypes.bool,
    hasErrors: PropTypes.bool,
    sendFinished: PropTypes.bool,
  }),
  history: ReactRouterPropTypes.history.isRequired,
};

Recovery.defaultProps = {
  auth: {},
};

const mapDispatchToProps = {
  sendRecoveryEmail,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Recovery)
);
