import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const RootLayout = ({ children = [] }) => (
  <Fragment>
    <Helmet>
      <meta property="title" content={'title'} />
      <meta charset="utf-8" />
      <meta httpEquiv="x-ua-compatible" content="ie=edge" />
      <meta
        name="viewport"
        content="width=device-width, height=device-height, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no, minimal-ui"
      />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black" />
      <meta name="description" content="Claimtech" />

      <meta property="og:title" content="Claimtech" />
      <meta property="og:description" content="Claimtech descritpion" />
      <meta
        property="og:image"
        content="https://www.grandparentsplus.org.uk/GetImage.aspx?IDMF=a6a73973-9b09-4a9f-a122-0b05ccf1fbb7&w=240&h=135&src=mc"
      />
      <meta
        property="og:image:secure_url"
        content="https://www.grandparentsplus.org.uk/GetImage.aspx?IDMF=a6a73973-9b09-4a9f-a122-0b05ccf1fbb7&w=240&h=135&src=mc"
      />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image:width" content="400" />
      <meta property="og:image:height" content="300" />
      <meta
        property="og:image:alt"
        content="A shiny red apple with a bite taken out"
      />
      <meta
        property="og:url"
        content="https://www.grandparentsplus.org.uk/GetImage.aspx?IDMF=a6a73973-9b09-4a9f-a122-0b05ccf1fbb7&w=240&h=135&src=mc"
      />

      <link
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700"
        rel="stylesheet"
      />

      {/* <title>Claimtech</title> */}
    </Helmet>
    {children}
  </Fragment>
);

RootLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default withRouter(connect(null, {})(RootLayout));
