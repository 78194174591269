import React, { Component, Fragment } from "react";
import moment from "moment";

import _ from "lodash";

import Button from "components/Button";
import Input from "components/Input";
import Form from "components/Form";
import Well from "components/Well";
import Logo from "components/Logo";
import Link from "components/Link";
import List from "components/List";
import Breadcrumb from "components/Breadcrumb";
import Badge from "components/Badge";
import ButtonGroup from "components/ButtonGroup";
import RadioGroup from "components/RadioGroup";
import Table from "components/Table";
import Alert from "components/Alert";
import DatePicker from "components/DatePicker";
import Select from "components/Select";

import Icons from "common/Icons";

const dataTable = _.range(1, 60).map(x => ({
  id: x,
  price: `${(Math.random() * 10000).toFixed(2)} $`,
  name: `Name ${x}`,
  status: `status ${x}`,
  modified: moment(Math.random() * 1000000000000).format("D MMM YY")
}));

class ReusableComponents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [
        {
          id: 0,
          value: "Link"
        },
        {
          id: 1,
          value: "Select 1"
        },
        {
          id: 2,
          value: "Select 2"
        },
        {
          id: 3,
          value: "Select 3"
        }
      ],
      menu: [
        {
          id: 0,
          value: "link primary",
          type: "primary",
          pathTo: "http://flexboxgrid.com/"
        },
        {
          id: 1,
          value:
            'Passing the name property to all input[type="radio"] that are in the same',
          type: "info",
          pathTo: "http://flexboxgrid.com/"
        },
        {
          id: 2,
          value:
            "Sale of BH24 1AX United Kingdom Hampshire  Garson & Purchase of BH24 1AG United Kingdom Hampshire Gerald Beauchamp Photography Northumberland Court",
          type: "danger",
          pathTo: "http://flexboxgrid.com/"
        },
        {
          id: 3,
          value: "Qoute Details",
          type: "warning",
          pathTo: "http://flexboxgrid.com/"
        }
      ],
      list: [
        { id: 1, value: "a", label: "Option 1" },
        { id: 2, value: "b", label: "Option 2" },
        { id: 3, value: "c", label: "Option 3" },
        { id: 4, value: "d", label: "Option 4", disabled: true },
        { id: 5, value: "e", label: "Option 5" }
      ],
      newList: [
        { id: 1, value: "a", label: "Option 1" },
        { id: 2, value: "b", label: "Option 2" },
        { id: 3, value: "c", label: "Option 3" },
        { id: 4, value: "d", label: "Option 4", disabled: true },
        { id: 5, value: "e", label: "Option 5" },
        { id: 6, value: "f", label: "Option 6" },
        { id: 7, value: "g", label: "Option 7" }
      ],
      nav: [
        {
          id: 0,
          value: "Home",
          type: "primary",
          pathTo: "http://flexboxgrid.com/"
        },
        {
          id: 1,
          value: "Dasboard",
          type: "info",
          pathTo: "http://flexboxgrid.com/"
        },
        {
          id: 2,
          value: "Profile",
          type: "danger",
          pathTo: "http://flexboxgrid.com/"
        },
        {
          id: 3,
          value: "Settings",
          type: "warning",
          pathTo: "http://flexboxgrid.com/"
        },
        {
          id: 4,
          value: "Gallery",
          type: "warning",
          pathTo: "http://flexboxgrid.com/"
        },
        {
          id: 5,
          value: "Radio",
          type: "success",
          pathTo: "http://flexboxgrid.com/"
        },
        {
          id: 6,
          value: "Form control",
          type: "info",
          pathTo: "http://flexboxgrid.com/"
        },
        {
          id: 7,
          value: "React botstrap table",
          type: "warning",
          pathTo: "http://flexboxgrid.com/"
        }
      ],
      tBody: [
        {
          id: 1,
          name: "Product1",
          price: 120,
          status: "finished",
          modified: moment().format("D MMM YY")
        },
        {
          id: 2,
          name: "Product2",
          price: 80,
          status: "in progress",
          modified: moment().format("D MMM YY")
        }
      ],
      tHead: [
        {
          dataField: "id",
          dataValue: "Product id",
          isKey: true,
          dataSort: false
        },
        {
          dataField: "name",
          dataValue: "Product name",
          isKey: false,
          dataSort: true
        },
        {
          dataField: "price",
          dataValue: "Product price",
          isKey: false,
          dataSort: true
        },
        {
          dataField: "status",
          dataValue: "Status",
          isKey: false,
          dataSort: true
        },
        {
          dataField: "modified",
          dataValue: "Date modified",
          isKey: false,
          dataSort: true
        }
      ],
      selectRowSettings: {
        mode: "checkbox",
        onSelect: this.onRowSelect,
        onSelectAll: this.onSelectAll
      },
      selectedRows: [],
      paginationOptions: {
        page: 1, // which page you want to show as default
        sizePerPageList: [
          {
            text: "5",
            value: 5
          },
          {
            text: "10",
            value: 10
          }
        ], // you can change the dropdown list for size per page
        sizePerPage: 6, // which size per page you want to locate as default
        pageStartIndex: 0, // where to start counting the pages
        paginationSize: 5, // the pagination bar size.
        prePage: "Prev", // Previous page button text
        nextPage: "Next", // Next page button text
        firstPage: "First", // First page button text
        lastPage: "Last", // Last page button text
        paginationPosition: "top", // default is bottom, top and both is all available
        hideSizePerPage: true // You can hide the dropdown for sizePerPage
        // alwaysShowAllBtns: true // Always show next and previous button
        // withFirstAndLast: false > Hide the going to First and Last page button
      },
      responseList: [],
      responseListGlobalCount: 0,
      autocompleteList: [
        {
          id: "5bbf66e156dc800004580bdd",
          name: "awd awd +44 (0) 2131 231 231",
          type: "customer"
        },
        {
          id: "5bcee853e4723800044ef607",
          name: "Iryna Shvaiko +44 (0) 8888 888 888",
          type: "customer"
        },
        {
          id: "5bd07c66e6a58700044fdfd3",
          name: "Artem Senhxhf +44 (0) 8682 548 454",
          type: "customer"
        },
        {
          id: "5bc09cd99ccce20004b2a6d4",
          name: "Kobzon Podeshah +44 (0) 2141 241 241",
          type: "customer"
        },
        {
          id: "5bbf699c56dc800004580bf0",
          name: "awd awd +44 (0) 1231 231 231",
          type: "customer"
        },
        {
          id: "5bc8a764c7016d00044f13ca",
          name: "Artem Semenko +44 (0) 2131 741 279",
          type: "customer"
        }
      ]
    };
  }

  onChange = (value, name) => {
    this.setState({ ...this.state, [name]: value });
  };

  onSelectChange = target => {
    this.setState({
      [target.id]: {
        selectId: target.id,
        item: {
          id: target.options.selectedIndex,
          value: target.value
        },
        selectedIndex: target.options.selectedIndex
      }
    });
  };

  onKeyPress = (value, name, keyCode) => {
    this.setState({
      ...this.state,
      [name]: value,
      keyCode
    });
  };

  onRadioChange = item => {
    this.setState({ ...this.state, checkedItem: item });
  };

  onClick = e => {
    this.setState({
      ...this.state,
      button: e.target.value || e.target.name
    });
    console.warn("click on", e.target.innerText);
  };

  onRowSelect = (row, isSelected, e) => {
    const { selectedRows } = this.state;

    if (!isSelected && selectedRows && selectedRows.length > 0) {
      const cell = selectedRows.find(item => item.id === row.id);
      const cellIndex = selectedRows.indexOf(cell);

      selectedRows.splice(cellIndex, 1);
    }

    if (isSelected && !selectedRows.find(item => item.id === row.id)) {
      selectedRows.push(row);
    }

    this.setState({ ...this.state, selectedRow: row });
  };

  onSelectAll = (isSelected, rows) => {
    if (isSelected) this.setState({ ...this.state, selectedRows: rows });
    else this.setState({ ...this.state, selectedRows: [] });
  };

  onPrefixClick = () => {
    this.setState({ prefix: "prefix callback -> ..." });
  };

  getTableList = () => {
    const responseListGlobalCount = dataTable.length;

    this.setState({
      responseList: dataTable,
      responseListGlobalCount
    });
  };

  handleDataPickerClick = date => {
    this.setState({ selected: date._d });
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-12 margin-40-top">
            <h1 className="h0 text-capitalize">reusable components</h1>

            <div className="box">
              <h1 className="h2">FLex Grid</h1>

              <dl className="dl-horizontal margin-40-bottom">
                <dt>Documentation</dt>
                <dd>
                  <Link
                    className="text-capitalize"
                    type="primary"
                    pathTo="http://flexboxgrid.com/"
                    value="more details"
                    targetBlank
                  />
                </dd>
              </dl>

              <div className="flex-example-wrapper">
                <b>Usage</b> <code>className="flex flex-grid"</code>
                <div className="flex flex-grid flex-example margin-10-top">
                  <span className="flex-item">1</span>
                  <span className="flex-item">2</span>
                  <span className="flex-item">3</span>
                </div>
              </div>

              <div className="flex-example-wrapper margin-40-top">
                <code>
                  className="flex flex-grid direction-row
                  <mark>-rev</mark>"
                </code>
                <div className="flex flex-grid direction-row-rev flex-example margin-10-top">
                  <span className="flex-item">1</span>
                  <span className="flex-item">2</span>
                  <span className="flex-item">3</span>
                </div>
              </div>

              <div className="flex-example-wrapper margin-40-top">
                <code>className="flex flex-grid direction-column"</code>
                <div className="flex flex-grid direction-column flex-example margin-10-top">
                  <span className="flex-item">1</span>
                  <span className="flex-item">2</span>
                  <span className="flex-item">3</span>
                </div>
              </div>

              <div className="flex-example-wrapper margin-40-top">
                <code>
                  className="flex flex-grid direction-column
                  <mark>-rev</mark>"
                </code>
                <div className="flex flex-grid direction-column-rev flex-example margin-10-top">
                  <span className="flex-item">1</span>
                  <span className="flex-item">2</span>
                  <span className="flex-item">3</span>
                </div>
              </div>

              <div className="flex-example-wrapper margin-40-top">
                <code>className="flex justify-between"</code>
                <div className="flex justify-between flex-example margin-10-top">
                  <span className="flex-item">1</span>
                  <span className="flex-item">2</span>
                  <span className="flex-item">3</span>
                </div>
              </div>

              <div className="flex-example-wrapper margin-40-top">
                <code>
                  className="flex justify<mark>-around</mark>"
                </code>
                <div className="flex justify-around flex-example margin-10-top">
                  <span className="flex-item">1</span>
                  <span className="flex-item">2</span>
                  <span className="flex-item">3</span>
                </div>
              </div>

              <div className="flex-example-wrapper margin-40-top">
                <code>
                  className="flex justify<mark>-evenly</mark>"
                </code>
                <div className="flex justify-evenly flex-example margin-10-top">
                  <span className="flex-item">1</span>
                  <span className="flex-item">2</span>
                  <span className="flex-item">3</span>
                </div>
              </div>

              <div className="flex-example-wrapper margin-40-top">
                <code>
                  className="flex justify<mark>-center</mark>"
                </code>
                <div className="flex justify-center flex-example margin-10-top">
                  <span className="flex-item">1</span>
                  <span className="flex-item">2</span>
                  <span className="flex-item">3</span>
                </div>
              </div>

              <div className="flex-example-wrapper margin-40-top">
                <code>
                  className="flex justify<mark>-end</mark>"
                </code>
                <div className="flex justify-end flex-example margin-10-top">
                  <span className="flex-item">1</span>
                  <span className="flex-item">2</span>
                  <span className="flex-item">3</span>
                </div>
              </div>

              <div className="flex-example-wrapper margin-40-top">
                <code>className="flex items-center"</code>
                <div className="flex items-center flex-example margin-10-top">
                  <span className="flex-item" style={{ height: 15 }}>
                    1
                  </span>
                  <span className="flex-item">2</span>
                  <span className="flex-item" style={{ height: 25 }}>
                    3
                  </span>
                </div>
              </div>

              <div className="flex-example-wrapper margin-40-top">
                <code>
                  className="flex items<mark>-start</mark>"
                </code>
                <div
                  className="flex items-start flex-example margin-10-top"
                  style={{ height: 100 }}
                >
                  <span className="flex-item" style={{ height: 15 }}>
                    1
                  </span>
                  <span className="flex-item">2</span>
                  <span className="flex-item" style={{ height: 25 }}>
                    3
                  </span>
                </div>
              </div>

              <div className="flex-example-wrapper margin-40-top">
                <code>
                  className="flex items<mark>-end</mark>"
                </code>
                <div
                  className="flex items-end flex-example margin-10-top"
                  style={{ height: 100 }}
                >
                  <span className="flex-item" style={{ height: 15 }}>
                    1
                  </span>
                  <span className="flex-item">2</span>
                  <span className="flex-item" style={{ height: 25 }}>
                    3
                  </span>
                </div>
              </div>

              <div className="flex-example-wrapper margin-40-top">
                <code>className="flex items-center"</code>
                <div
                  className="flex items-center flex-example margin-10-top"
                  style={{ height: 100 }}
                >
                  <span className="flex-item self-end">
                    1 <code>self-end</code>
                  </span>
                  <span className="flex-item">2</span>
                  <span className="flex-item self-start">
                    3 <code>self-start</code>
                  </span>
                </div>
              </div>

              <div className="flex-example-wrapper margin-40-top">
                <code>
                  className="flex items<mark>-stretch</mark>"
                </code>
                <div
                  className="flex items-stretch flex-example margin-10-top"
                  style={{ height: 100 }}
                >
                  <span className="flex-item">1</span>
                  <span className="flex-item">2</span>
                  <span className="flex-item">3</span>
                </div>
              </div>
              <div className="flex-example-wrapper margin-40-top">
                <Button
                  type="success"
                  value="Flex grid DOCUMENTATION"
                  pathTo="http://flexboxgrid.com/"
                  icon={<Icons fill="#fff" iconName="link" />}
                  targetBlank
                />
              </div>
            </div>

            <div className="box">
              <h1 className="h2">Colors</h1>
              <dl className="dl-horizontal">
                <dt>$default</dt>
                <dd>
                  <svg width="15" height="15">
                    <rect width="15" height="15" fill="#0f2241" />
                  </svg>
                  <span className="usel-select margin-5-left">#0f2241</span>
                </dd>
                <dt>$success</dt>
                <dd>
                  <svg width="15" height="15">
                    <rect width="15" height="15" fill="#4fb051" />
                  </svg>
                  <span className="usel-select margin-5-left">#4fb051</span>
                </dd>
                <dt>$primary</dt>
                <dd>
                  <svg width="15" height="15">
                    <rect width="15" height="15" fill="#4c5884" />
                  </svg>
                  <span className="usel-select margin-5-left">#4c5884</span>
                </dd>
                <dt>$danger</dt>
                <dd>
                  <svg width="15" height="15">
                    <rect width="15" height="15" fill="#d0021b" />
                  </svg>
                  <span className="usel-select margin-5-left">#d0021b</span>
                </dd>
                <dt>$danger-secondary</dt>
                <dd>
                  <svg width="15" height="15">
                    <rect width="15" height="15" fill="#fcf2f3" />
                  </svg>
                  <span className="usel-select margin-5-left">#fcf2f3</span>
                </dd>
                <dt>$gray-light</dt>
                <dd>
                  <svg width="15" height="15">
                    <rect width="15" height="15" fill="#f5f6f8" />
                  </svg>
                  <span className="usel-select margin-5-left">#f5f6f8</span>
                </dd>
                <dt>$muted</dt>
                <dd>
                  <svg width="15" height="15">
                    <rect width="15" height="15" fill="#e0e0df" />
                  </svg>
                  <span className="usel-select margin-5-left">#e0e0df</span>
                </dd>
              </dl>
            </div>

            <div className="box">
              <h1 className="h2">Headers</h1>
              <dl className="dl-horizontal">
                <dt>
                  <code>{"<h1></h1>"}</code>
                </dt>
                <dd>
                  <h1 className="h1 text-capitalize">
                    Header h1 <small>small</small>
                  </h1>
                </dd>

                <dt>
                  <code>{"<h2></h2>"}</code>
                </dt>
                <dd>
                  <h2 className="h2 text-capitalize">
                    Header h2 <small>small</small>
                  </h2>
                </dd>

                <dt>
                  <code>{"<h3></h3>"}</code>
                </dt>
                <dd>
                  <h3 className="h3 text-capitalize">
                    Header h3 <small>small</small>
                  </h3>
                </dd>

                <dt>
                  <code>{"<h4></h4>"}</code>
                </dt>
                <dd>
                  <h4 className="h4 text-capitalize">
                    Header h4 <small>small</small>
                  </h4>
                </dd>

                <dt>
                  <code>{"<h5></h5>"}</code>
                </dt>
                <dd>
                  <h5 className="h5 text-capitalize">
                    Header h5 <small>small</small>
                  </h5>
                </dd>

                <dt>
                  <code>{"<h6></h6>"}</code>
                </dt>
                <dd>
                  <h6 className="h6 text-capitalize">
                    Header h6 <small>small</small>
                  </h6>
                </dd>
              </dl>
            </div>

            <div className="box">
              <h1 className="h2">
                Typography{" "}
                <small className="text-normal">
                  <code>text-transform</code>, <code>text-decoration</code>
                </small>
              </h1>
              <div className="text-capitalize">text-capitalize</div>
              <div className="text-uppercase">text-uppercase</div>
              <div className="text-underline">text-underline</div>
              <div className="text-line-through">text-line-through</div>
              <div className="text-center">text-center</div>
              <div className="text-left">text-left</div>
              <div className="text-right">text-right</div>
            </div>

            <div className="box">
              <h1 className="h2">Button</h1>
              <Button type="success" value="Complete" onClick={this.onClick} />
              <Button type="muted" value="Save" onClick={this.onClick} />
              <Button type="info" value="Details" onClick={this.onClick} />
              <Button type="warning" value="Cancel" onClick={this.onClick} />
              <Button type="danger" value="Delete" onClick={this.onClick} />

              <div className="col-xs-12 margin-40-top">
                <h4>Click on: {this.state.button}</h4>
              </div>

              <figure className="highlight">
                <pre>
                  {`

    import Button from '../../components/Button';
    import Icons from '../../components/Icons';


    <Button
      type="success"
      value="Complete"
      onClick={this.onClick}
    />
                  `}
                </pre>
              </figure>
            </div>

            <div className="box">
              <h1 className="h2 flex items-center">
                Button Link{" "}
                <small className="text-normal margin-10-left">
                  {" "}
                  <kbd>contentReverse</kbd>
                </small>
              </h1>
              <div className="flex">
                <Button
                  type="success"
                  value="ReactJS Datepicker"
                  pathTo="https://reactdatepicker.com/"
                  icon={<Icons fill="#fff" iconName="link" />}
                />
                <Button
                  type="warning"
                  value="ReactJS Datepicker"
                  pathTo="https://reactdatepicker.com/"
                  icon={<Icons fill="#fff" iconName="link" />}
                  contentReverse
                />
              </div>

              <figure className="highlight">
                <pre>
                  {`
    import Button from '../../components/Button';
    import Icons from '../../components/Icons';


    <Button
      type="success"
      value="ReactJS Datepicker"
      pathTo="https://reactdatepicker.com/"
      icon={<Icons fill="#fff" iconName="link" />}
    />
    <Button
      type="warning"
      value="ReactJS Datepicker"
      pathTo="https://reactdatepicker.com/"
      icon={<Icons fill="#fff" iconName="link" />}
      contentReverse
    />
                  `}
                </pre>
              </figure>
            </div>

            <div className="box">
              <h1 className="h2">
                Button{" "}
                <small className="text-normal">
                  <code>icon</code>
                </small>
              </h1>
              <div className="flex">
                <Button
                  type="success"
                  value="Add to favorite"
                  icon={<Icons iconName="star" />}
                  onClick={this.onClick}
                />
                <Button
                  name="search"
                  type="muted"
                  icon={<Icons iconName="search" />}
                  onClick={this.onClick}
                />
              </div>
              <div className="col-xs-12 margin-40-top">
                <h4>Click on: {this.state.button}</h4>
              </div>

              <figure className="highlight">
                <pre>
                  {`
    import Button from '../../components/Button';
    import Icons from '../../components/Icons';


    <Button
      type="success"
      value="Add to favorite"
      icon={<Icons iconName="star" />}
      onClick={this.onClick}
    />
    <Button
      name="search"
      type="muted"
      icon={<Icons iconName="search" />}
      onClick={this.onClick}
    />
                  `}
                </pre>
              </figure>
            </div>

            <div className="box">
              <h1 className="h2">ButtonGroup</h1>
              <Well gutter={20}>
                <ButtonGroup>
                  <Button value="button 1" onClick={this.onClick} />
                  <Button value="button 2" onClick={this.onClick} />
                  <Button value="button 3" onClick={this.onClick} />
                </ButtonGroup>
              </Well>

              <div className="col-xs-12 margin-40-top">
                <h4>Click on: {this.state.button}</h4>
              </div>

              <figure className="highlight">
                <pre>
                  {`
    import Button from '../../components/Button';
    import ButtonGroup from '../../components/ButtonGroup';


    <ButtonGroup>
      <Button
        value="button 1"
        onClick={this.onClick}
      />
      <Button
        value="button 2"
        onClick={this.onClick}
      />

      ...

    </ButtonGroup>
                  `}
                </pre>
              </figure>
            </div>

            <div className="box">
              <h1 className="h2">
                Input{" "}
                <small className="text-normal">
                  <code>{"<Form />"}</code>, <code>labelAnimate</code>
                </small>
              </h1>

              <div className="row">
                <div className="col-xs-12 col-sm-9">
                  <div
                    className="flex justify-center items-center direction-column padding-20"
                    style={{ background: "#4c5884" }}
                  >
                    <Logo className="margin-40-bottom" />
                    <Well size="small" gutter={40}>
                      <Form className="form-compact">
                        <Input
                          name={"userName"}
                          label="Username..."
                          className="custom-class-name"
                          wrapperClass="wrapper-class-name"
                          value={this.state.userName}
                          type="text"
                          onChange={this.onChange}
                          onKeyPress={this.onKeyPress}
                          labelAnimate
                        />
                        <Input
                          name={"password"}
                          label="Password..."
                          className="custom-class-name"
                          wrapperClass="wrapper-class-name"
                          value={this.state.password}
                          type="password"
                          onChange={this.onChange}
                          onKeyPress={this.onKeyPress}
                          labelAnimate
                        />
                        <Button
                          type="success"
                          size="large"
                          className="text-uppercase letter-space-md landing"
                          value="log in"
                          onClick={this.onClick}
                        />
                      </Form>
                    </Well>
                    <figure className="highlight">
                      <pre>
                        {`
  import Button from '../../components/Button';
  import Input from '../../components/Input';
  import Form from '../../components/Form';
  import Well from '../../components/Well';
  import Logo from '../../components/Logo';


  <Well size="small" gutter={40}>
    <Form className="form-compact">
      <Input
        name={'userName'}
        label="Username..."
        className="custom-class-name"
        wrapperClass="wrapper-class-name"
        value={this.state.userName}
        type="text"
        onChange={this.onChange}
        onKeyPress={this.onKeyPress}
        labelAnimate
      />
      <Input
        name={'password'}
        label="Password..."
        className="custom-class-name"
        wrapperClass="wrapper-class-name"
        value={this.state.password}
        type="password"
        onChange={this.onChange}
        onKeyPress={this.onKeyPress}
        labelAnimate
      />
      <Button
        type="success"
        size="large"
        className="text-uppercase letter-space-md landing"
        value="log in"
        onClick={this.onClick}
      />
    </Form>
  </Well>
                        `}
                      </pre>
                    </figure>

                    <Well className="margin-20-top" gutter={40}>
                      <Form className="form-compact">
                        <Input
                          name={"primary"}
                          label="Required"
                          inputType="primary"
                          wrapperClass="wrapper-class-name"
                          placeholder="This field is required"
                          value={this.state.primary}
                          type="text"
                          onChange={this.onChange}
                          onKeyPress={this.onKeyPress}
                          isRequired
                        />
                        <Input
                          name={"warning"}
                          label="Warning input"
                          inputType="warning"
                          wrapperClass="wrapper-class-name"
                          value={this.state.warning}
                          type="text"
                          onChange={this.onChange}
                          onKeyPress={this.onKeyPress}
                          placeholder="Some placeholder"
                          sufix={<Icons iconName="link" />}
                          prefix={<Icons iconName="star" />}
                        />
                        <Input
                          name={"email"}
                          label="Label inside"
                          inputType="primary"
                          className="custom-class-name"
                          wrapperClass="wrapper-class-name"
                          value={this.state.email}
                          type="email"
                          onChange={this.onChange}
                          onKeyPress={this.onKeyPress}
                          labelInside
                        />
                      </Form>
                    </Well>

                    <figure className="highlight">
                      <pre>
                        {`
  <Well className="margin-20-top" gutter={40}>
    <Form className="form-compact">
      <Input
        name={'primary'}
        label="Required"
        inputType="primary"
        wrapperClass="wrapper-class-name"
        placeholder="This field is required"
        value={this.state.primary}
        type="text"
        onChange={this.onChange}
        onKeyPress={this.onKeyPress}
        isRequired
      />
      <Input
        name={'warning'}
        label="Warning input"
        inputType="warning"
        wrapperClass="wrapper-class-name"
        value={this.state.warning}
        type="text"
        onChange={this.onChange}
        onKeyPress={this.onKeyPress}
        placeholder="Some placeholder"
        sufix={<Icons iconName="link" />}
        prefix={<Icons iconName="star" />}
      />
      <Input
        name={'email'}
        label="Label inside"
        inputType="primary"
        className="custom-class-name"
        wrapperClass="wrapper-class-name"
        value={this.state.email}
        type="email"
        onChange={this.onChange}
        onKeyPress={this.onKeyPress}
        labelInside
      />

    </Form>
  </Well>
                        `}
                      </pre>
                    </figure>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-3">
                  <h4>Input value: {this.state.userName}</h4>
                  <h4>Key Code: {this.state.keyCode}</h4>
                  <h4>Click on: {this.state.button}</h4>
                </div>
              </div>
            </div>

            <div className="box">
              <div className="row">
                <div className="col-xs-12 col-sm-6">
                  <h1 className="h2">
                    Input{" "}
                    <small className="text-normal">
                      <code>form-inline-combine</code> - add <b>[attr]</b> -{" "}
                      <code>inputCombine</code>
                    </small>
                  </h1>
                  <Form className="form-compact">
                    <Input
                      name="postCode"
                      label="Post Code"
                      inputType="primary"
                      className="custom-class-name"
                      wrapperClass="wrapper-class-name"
                      value={this.state.postCode}
                      type="text"
                      onChange={this.onChange}
                      onKeyPress={this.onKeyPress}
                      labelInside
                      inputCombine
                    />
                    {[
                      <Input
                        name="address1"
                        label="Primary address"
                        inputType="primary"
                        className="custom-class-name"
                        wrapperClass="wrapper-class-name"
                        value={this.state.address1}
                        type="text"
                        onChange={this.onChange}
                        onKeyPress={this.onKeyPress}
                        labelInside
                        inputCombine
                      />,
                      <Input
                        name="address2"
                        label="Secondary address"
                        inputType="primary"
                        className="custom-class-name"
                        wrapperClass="wrapper-class-name"
                        value={this.state.address2}
                        type="text"
                        onChange={this.onChange}
                        onKeyPress={this.onKeyPress}
                        labelInside
                        inputCombine
                      />
                    ]}
                  </Form>
                  <figure className="highlight">
                    <pre>
                      {`
  <Form className="form-compact">
    <Input
      name="postCode"
      label="Post Code"
      inputType="primary"
      className="custom-class-name"
      wrapperClass="wrapper-class-name"
      value={this.state.postCode}
      type="text"
      onChange={this.onChange}
      onKeyPress={this.onKeyPress}
      labelInside
      inputCombine
    />
    {[
      <Input
        name="address1"
        label="Primary address"
        inputType="primary"
        className="custom-class-name"
        wrapperClass="wrapper-class-name"
        value={this.state.address1}
        type="text"
        onChange={this.onChange}
        onKeyPress={this.onKeyPress}
        labelInside
        inputCombine
      />,
      <Input
        name="address2"
        label="Secondary address"
        inputType="primary"
        className="custom-class-name"
        wrapperClass="wrapper-class-name"
        value={this.state.address2}
        type="text"
        onChange={this.onChange}
        onKeyPress={this.onKeyPress}
        labelInside
        inputCombine
      />,
    ]}
  </Form>
                        `}
                    </pre>
                  </figure>
                </div>
                <div className="col-xs-12 col-sm-6">
                  <h1 className="h2">
                    Input{" "}
                    <small className="text-normal">
                      <code>form-inline-divided</code> - default
                    </small>
                  </h1>
                  <Form className="form-compact">
                    <Input
                      name="testField"
                      label="Some input title"
                      inputType="primary"
                      className="custom-class-name"
                      wrapperClass="wrapper-class-name"
                      value={this.state.testField}
                      type="text"
                      onChange={this.onChange}
                      onKeyPress={this.onKeyPress}
                      inputCombine
                    />
                    {[
                      <Input
                        name="givenName"
                        label="Given name"
                        inputType="primary"
                        className="custom-class-name"
                        wrapperClass="wrapper-class-name"
                        value={this.state.givenName}
                        type="text"
                        onChange={this.onChange}
                        onKeyPress={this.onKeyPress}
                      />,
                      <Input
                        name="familyName"
                        label="Family name"
                        inputType="primary"
                        className="custom-class-name"
                        wrapperClass="wrapper-class-name"
                        value={this.state.familyName}
                        type="text"
                        onChange={this.onChange}
                        onKeyPress={this.onKeyPress}
                      />
                    ]}
                  </Form>

                  <figure className="highlight">
                    <pre>
                      {`
  <Form className="form-compact">
    <Input
      name="testField"
      label="Some input title"
      inputType="primary"
      className="custom-class-name"
      wrapperClass="wrapper-class-name"
      value={this.state.testField}
      type="text"
      onChange={this.onChange}
      onKeyPress={this.onKeyPress}
      inputCombine
    />
    {[
      <Input
        name="givenName"
        label="Given name"
        inputType="primary"
        className="custom-class-name"
        wrapperClass="wrapper-class-name"
        value={this.state.givenName}
        type="text"
        onChange={this.onChange}
        onKeyPress={this.onKeyPress}
      />,
      <Input
        name="familyName"
        label="Family name"
        inputType="primary"
        className="custom-class-name"
        wrapperClass="wrapper-class-name"
        value={this.state.familyName}
        type="text"
        onChange={this.onChange}
        onKeyPress={this.onKeyPress}
      />,
    ]}
  </Form>
                        `}
                    </pre>
                  </figure>
                </div>
              </div>
            </div>

            <div className="box">
              <h1 className="h2">
                Link{" "}
                <small className="text-normal">
                  type<code>primary/success ...</code>
                </small>
              </h1>
              <List type="inline">
                {this.state.menu.map(item => (
                  <Link
                    key={item.id}
                    className="text-uppercase letter-space-sm"
                    type={item.type}
                    pathTo={item.pathTo}
                    value={item.value}
                    targetBlank
                  />
                ))}
              </List>
            </div>

            <div className="box">
              <h1 className="h2">
                Link{" "}
                <small className="text-normal">
                  <code>role: button</code>
                </small>
              </h1>
              <Link
                pathTo="http://flexboxgrid.com/"
                value="Flex grid documentation"
                sufix={<Icons iconName="link" />}
                prefix={<Icons iconName="cross" fill="#d0021b" />}
                targetBlank
                linkButton
              />

              <figure className="highlight">
                <pre>
                  {`
    import Link from '../../components/Link';
    import Icons from '../../comon/Icons';


    <Link
      pathTo="http://flexboxgrid.com/"
      value="Flex grid documentation"
      sufix={<Icons iconName="cross" fill="#d0021b" />}
      prefix={<Icons iconName="link" />}
      targetBlank
      linkButton
    />
                  `}
                </pre>
              </figure>
            </div>

            <div className="box">
              <h1 className="h2">
                Link{" "}
                <small className="text-normal">
                  <code>
                    redirect by <b>sufix</b>
                  </code>
                  <b>add attr</b> - <kbd>sufixLink</kbd>
                </small>
              </h1>
              <Link
                pathTo="http://flexboxgrid.com/"
                value="Flex grid documentation"
                sufix={<Icons iconName="link" />}
                prefix={<Icons iconName="cross" fill="#d0021b" />}
                sufixLink
                onPrefixClick={this.onPrefixClick}
                linkButton
              />

              {this.state.prefix && (
                <div className="margin-20-top">
                  <kbd>{this.state.prefix}</kbd>
                </div>
              )}

              <figure className="highlight">
                <pre>
                  {`
    import Link from '../../components/Link';
    import Icons from '../../comon/Icons';


    <Link
      pathTo="http://flexboxgrid.com/"
      value="Flex grid documentation"
      sufix={<Icons iconName="link" />}
      prefix={<Icons iconName="cross" fill="#d0021b" />}
      sufixLink
      linkButton
      // sufixTarget - You can change the sufixTarget to "_self | _parent | _top". Same as in base HTML link
                       "_blank" - is default.
    />
                  `}
                </pre>
              </figure>
            </div>

            <div className="box">
              <h1 className="h2">Breadcrumb</h1>
              <Breadcrumb type="inline">
                {this.state.menu.map(item => (
                  <Fragment key={item.id}>
                    <Link
                      className="text-capitalize"
                      pathTo={item.pathTo}
                      value={item.value}
                      targetBlank
                    />
                    {/* <Tooltip className="link" content={item.value} /> */}
                  </Fragment>
                ))}
              </Breadcrumb>

              <figure className="highlight">
                <pre>
                  {`
    import Breadcrumb from '../../components/Breadcrumb';


    <Breadcrumb type="inline">
      {this.state.menu.map(item => (
        <Fragment key={item.id}>
          <Link
            className="text-capitalize"
            pathTo={item.pathTo}
            value={item.value}
            targetBlank
          />
          <Tooltip className="link" content={item.value} />
        </Fragment>
      ))}
    </Breadcrumb>
                  `}
                </pre>
              </figure>
            </div>

            <div className="box" id="badge-box">
              <h1 className="h2">Badge </h1>
              <dl className="dl-horizontal">
                <dt>success</dt>
                <dd>
                  <Badge type="success" />
                </dd>
                <dt>danger</dt>
                <dd>
                  <Badge type="danger" />
                </dd>
                <dt>primary</dt>
                <dd>
                  <Badge type="primary" text="Primary text" />
                </dd>
                <dt>info</dt>
                <dd>
                  <Badge type="info" text="Info text" />
                </dd>
              </dl>

              <figure className="highlight">
                <pre>
                  {`
    import Badge from '../../components/Badge';


    <Badge type="success" />
    <Badge type="info" text="Info text" />
                  `}
                </pre>
              </figure>
            </div>

            <div className="box" id="badge-box">
              <h1 className="h2">
                List + Link + Badge{" "}
                <small className="text-normal">
                  <code>{'type="nav-side"'}</code>
                </small>
              </h1>
              <List type="nav-side">
                {this.state.nav.map(item => (
                  <Link
                    key={item.id}
                    className={`text-capitalize ${
                      item.id === 3 ? "link-active" : "link-inactive"
                    }`}
                    activeClassName="link-active"
                    pathTo={item.pathTo}
                    value={<Badge type={item.type} text={item.value} />}
                    targetBlank
                  />
                ))}
              </List>
              <figure className="highlight">
                <pre>
                  {`
    import List from '../../components/List';
    import Link from '../../components/Link';
    import Badge from '../../components/Badge';


    <List type="nav-side">
      {this.state.menu.map(item => (
        <Link
          key={item.id}
          className="text-capitalize"
          activeClassName="link-active"
          pathTo={item.pathTo}
          value={<Badge type={item.type} text={item.value} />}
          targetBlank
        />
      ))}
    </List>
                  `}
                </pre>
              </figure>
            </div>

            <div className="box" id="radio-box">
              <h1 className="h2">
                Radio{" "}
                <small className="text-normal">
                  <code>text</code>
                </small>
              </h1>
              <RadioGroup
                groupName="radio-group-1"
                list={this.state.list}
                onChange={this.onRadioChange}
              />
              <div className="margin-40-top">
                <div>
                  Checked:{" "}
                  <code className="h6">
                    {this.state.checkedItem
                      ? `
                  {
                    id: ${this.state.checkedItem.id},
                    value: ${this.state.checkedItem.value},
                    label: ${this.state.checkedItem.label}
                  }
                `
                      : "none"}
                  </code>
                </div>
              </div>

              <figure className="highlight">
                <pre>
                  {`
    import RadioGroup from '../../components/RadioGroup';


    const list = [
      { id: 0, value: 'b', label: 'Option 2' },
      { id: 1, value: 'c', label: 'Option 3' },
      { id: 2, value: 'a', label: 'Option 1' },
      { id: 3, value: 'd', label: 'Option 4', disabled: true },
      { id: 4, value: 'e', label: 'Option 5' },
    ];

    <RadioGroup
      groupName="radio-group-1"
      list={this.state.list}
      onChange={this.onRadioChange}
    />
                  `}
                </pre>
              </figure>
            </div>

            <div className="box" id="radio-box">
              <h1 className="h2">
                Table{" "}
                <small className="text-normal">
                  <code>text</code>
                </small>
              </h1>
              <dl className="dl-horizontal">
                <dt>Documentation -</dt>
                <dd>
                  <Link
                    className="text-capitalize"
                    type="info"
                    pathTo="http://allenfang.github.io/react-bootstrap-table/example.html#sort"
                    value="react-bootstrap-table"
                    targetBlank
                  />
                </dd>
              </dl>
              <Table
                className="table-сlaimtech"
                multiColumnSort={this.state.tBody.length}
                tHead={this.state.tHead}
                tBody={this.state.tBody}
              />

              <figure className="highlight">
                <pre>
                  {`
    import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
    import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';


    const tBody = [{
      id: 1,
      name: 'Product1',
      price: 120,
      status: 'finished',
      modified: moment().format('D MMM YY'),
    }, {
      id: 2,
      name: 'Product2',
      price: 80,
      status: 'in progress',
      modified: moment().format('D MMM YY'),
    }];

    const tHead = [
      {
        dataField: 'id',
        dataValue: 'Product id',
        isKey: true,
        dataSort: false,
      }, {
        dataField: 'name',
        dataValue: 'Product name',
        isKey: false,
        dataSort: true,
      }, {
        dataField: 'price',
        dataValue: 'Product price',
        isKey: false,
        dataSort: true,
      }, {
        dataField: 'status',
        dataValue: 'Status',
        isKey: false,
        dataSort: true,
      }, {
        dataField: 'modified',
        dataValue: 'Date modified',
        isKey: false,
        dataSort: true,
      },
    ];

    <Table
      className="table-сlaimtech"
      multiColumnSort={this.state.tBody.length}
      tHead={this.state.tHead}
      tBody={this.state.tBody}
    />
                  `}
                </pre>
              </figure>
            </div>

            <div className="box" id="radio-box">
              <h1 className="h2">
                Table{" "}
                <small className="text-normal">
                  <code>row-selection</code>
                </small>
              </h1>
              <Table
                className="table-сlaimtech"
                multiColumnSort={this.state.tBody.length}
                tHead={this.state.tHead}
                tBody={this.state.tBody}
                selectRow={this.state.selectRowSettings}
              />

              <figure className="highlight">
                <pre>
                  {`
    import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
    import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';


    const selectRowSettings = {
      mode: 'checkbox',
      onSelect: this.onRowSelect,
      onSelectAll: this.onSelectAll,
    };

    <Table
      className="table-сlaimtech"
      multiColumnSort={this.state.tBody.length}
      tHead={this.state.tHead}
      tBody={this.state.tBody}
      selectRow={this.state.selectRowSettings}
    />

                  `}
                </pre>
              </figure>

              <div className="landing margin-40-top" id="radio-box">
                <div>
                  selectedRow/unselectedRow:{" "}
                  <code className="h6">
                    {this.state.selectedRow
                      ? `
                    {
                      id: ${this.state.selectedRow.id},
                      name: ${this.state.selectedRow.name},
                      price: ${this.state.selectedRow.price},
                      status: ${this.state.selectedRow.status},
                      modified: ${this.state.selectedRow.modified}
                    }
                  `
                      : "none"}
                  </code>
                </div>
                <div className="flex direction-column">
                  selectedRows:{" "}
                  {(this.state.selectedRows &&
                    this.state.selectedRows.length > 0 &&
                    this.state.selectedRows.map(item => (
                      <code className="h6">
                        {item
                          ? `
                      {
                        id: ${item.id},
                        name: ${item.name},
                        price: ${item.price},
                        status: ${item.status},
                        modified: ${item.modified}
                      }
                    `
                          : "none"}
                      </code>
                    ))) ||
                    "none"}
                </div>
              </div>
            </div>

            <div className="box" id="radio-box">
              <h1 className="h2">
                Table with Pagination
                <small className="text-normal">
                  <code>row-selection</code>
                </small>
              </h1>
              <h6>count: {this.state.responseListGlobalCount}</h6>
              <Table
                className="table-сlaimtech"
                tHead={this.state.tHead}
                tBody={this.state.responseList}
                selectRow={this.state.selectRowSettings}
                options={this.state.paginationOptions}
                pagination={this.state.responseList.length > 0}
              />
              {this.state.responseList.length === 0 && (
                <Button
                  className="margin-20-top"
                  type="warning"
                  value="Get list"
                  onClick={this.getTableList}
                />
              )}

              <figure className="highlight">
                <pre>
                  {`
    import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
    import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';


    const selectRowSettings = paginationOptions: {
      page: 1, // which page you want to show as default
      sizePerPageList: [{
        text: '5', value: 5,
      }, {
        text: '10', value: 10,
      }], // you can change the dropdown list for size per page
      sizePerPage: 6, // which size per page you want to locate as default
      pageStartIndex: 0, // where to start counting the pages
      paginationSize: 5, // the pagination bar size.
      prePage: 'Prev', // Previous page button text
      nextPage: 'Next', // Next page button text
      firstPage: 'First', // First page button text
      lastPage: 'Last', // Last page button text
      paginationPosition: 'top', // default is bottom, top and both is all available
      hideSizePerPage: true, // You can hide the dropdown for sizePerPage
      // alwaysShowAllBtns: true // Always show next and previous button
      // withFirstAndLast: false > Hide the going to First and Last page button
    };

    <Table
      className="table-сlaimtech"
      tHead={this.state.tHead}
      tBody={this.state.responseList}
      selectRow={this.state.selectRowSettings}
      options={this.state.paginationOptions}
      pagination={this.state.responseListGlobalCount}
    />
    {this.state.responseList.length === 0 && <Button
      className="margin-20-top"
      type="warning"
      value="Get list"
      onClick={this.getTableList}
    />}

                  `}
                </pre>
              </figure>
            </div>

            <div className="box">
              <div className="row">
                <div className="col-xs-12 col-sm-6">
                  <h1 className="h2">Alert</h1>
                  <Alert
                    type="success"
                    className="demo-class-name"
                    title="Alert tite"
                    description="You must attempt to an initial call to the Client before completing this Task. If you have attempted the call but not made customer with the Client on the first attempt, proceed to complete the Task. "
                  />
                  <Alert
                    type="warning"
                    className="demo-class-name"
                    title="Alert tite"
                    description="You must attempt to an initial call to the Client before completing this Task. If you have attempted the call but not made customer with the Client on the first attempt, proceed to complete the Task. "
                  />
                  <Alert
                    type="danger"
                    className="demo-class-name"
                    title="Alert tite"
                    description="You must attempt to an initial call to the Client before completing this Task. If you have attempted the call but not made customer with the Client on the first attempt, proceed to complete the Task. "
                  />
                  <Alert
                    type="info"
                    className="demo-class-name"
                    title="Alert tite"
                    description="You must attempt to an initial call to the Client before completing this Task. If you have attempted the call but not made customer with the Client on the first attempt, proceed to complete the Task. "
                  />
                  <Alert
                    type="muted"
                    className="demo-class-name"
                    title="Alert tite"
                    description="You must attempt to an initial call to the Client before completing this Task. If you have attempted the call but not made customer with the Client on the first attempt, proceed to complete the Task. "
                  />

                  <figure className="highlight">
                    <pre>
                      {`
      import Alert from '../../components/Alert';


      <Alert
        type="success"
        className="demo-class-name"
        title="Alert tite"
        description="
            Your text here ...
          "
      />
                    `}
                    </pre>
                  </figure>
                </div>
                <div className="col-xs-12 col-sm-6">
                  <h1 className="h2">
                    Alert{" "}
                    <small className="text-normal">
                      <code>secondary</code>
                    </small>
                  </h1>
                  <Well gutter={10}>
                    <Alert
                      type="success"
                      className="demo-class-name"
                      title="Alert tite"
                      description="You must attempt to an initial call to the Client before completing this Task. If you have attempted the call but not made customer with the Client on the first attempt, proceed to complete the Task. "
                      secondary
                    />
                    <Alert
                      type="warning"
                      className="demo-class-name"
                      title="Alert tite"
                      description="You must attempt to an initial call to the Client before completing this Task. If you have attempted the call but not made customer with the Client on the first attempt, proceed to complete the Task. "
                      secondary
                    />
                    <Alert
                      type="danger"
                      className="demo-class-name"
                      title="Alert tite"
                      description="You must attempt to an initial call to the Client before completing this Task. If you have attempted the call but not made customer with the Client on the first attempt, proceed to complete the Task. "
                      secondary
                    />
                    <Alert
                      type="info"
                      className="demo-class-name"
                      title="Alert tite"
                      description="You must attempt to an initial call to the Client before completing this Task. If you have attempted the call but not made customer with the Client on the first attempt, proceed to complete the Task. "
                      secondary
                    />
                    <Alert
                      type="muted"
                      className="demo-class-name"
                      title="Alert tite"
                      description="You must attempt to an initial call to the Client before completing this Task. If you have attempted the call but not made customer with the Client on the first attempt, proceed to complete the Task. "
                      secondary
                    />
                  </Well>

                  <figure className="highlight">
                    <pre>
                      {`
        import Alert from '../../components/Alert';


        <Alert
          type="success"
          className="demo-class-name"
          title="Alert tite"
          description="Your text here ..."
          secondary
        />
                      `}
                    </pre>
                  </figure>
                </div>
              </div>
            </div>

            <div className="box">
              <h1 className="h2">
                Alert{" "}
                <small className="text-normal">
                  <code>
                    without <b>Title</b> or <b>Description</b>
                  </code>
                </small>
              </h1>
              <Well gutter={10}>
                <Alert
                  type="success"
                  className="demo-class-name"
                  description="You must attempt to an initial call to the Client before completing this Task. If you have attempted the call but not made customer with the Client on the first attempt, proceed to complete the Task. "
                  secondary
                />
                <Alert
                  type="warning"
                  className="demo-class-name"
                  title="Alert tite"
                  secondary
                />
                <Alert
                  type="danger"
                  className="demo-class-name"
                  title="Alert tite"
                />
                <Alert
                  type="info"
                  className="demo-class-name"
                  description="You must attempt to an initial call to the Client before completing this Task. If you have attempted the call but not made customer with the Client on the first attempt, proceed to complete the Task. "
                />
                <Alert
                  type="muted"
                  className="demo-class-name"
                  title="Alert tite"
                  description="You must attempt to an initial call to the Client before completing this Task. If you have attempted the call but not made customer with the Client on the first attempt, proceed to complete the Task. "
                  secondary
                />
              </Well>

              <figure className="highlight">
                <pre>
                  {`
        import Alert from '../../components/Alert';


        <Alert
          type="success"
          className="demo-class-name"
          title="Alert tite" // You can hide the title, just delet this line
          description="Your text here ..." // You can hide the description, just deleted this line
          secondary
        />
                      `}
                </pre>
              </figure>
            </div>

            <div className="box">
              <h1 className="h2 flex items-center justify-between">
                DatePicker
                <small className="text-normal">
                  <Button
                    type="success"
                    value="ReactJS Datepicker"
                    pathTo="https://reactdatepicker.com/"
                    icon={<Icons fill="#fff" iconName="link" />}
                  />
                </small>
              </h1>
              <DatePicker
                onChange={this.handleDataPickerClick}
                dateFormat="D MMM YY"
              />
              {this.state.selected && (
                <div className="margin-20-top">
                  <kbd>{moment(this.state.selected).format("D MMM YY")}</kbd>
                </div>
              )}

              <figure className="highlight">
                <pre>
                  {`
  import DatePicker from '../../components/DatePicker';

  <DatePicker
    onChange={this.handleDataPickerClick}
    dateFormat="D MMM YY" // You can use the all moment.js date formats
  />
                      `}
                </pre>
              </figure>
            </div>

            <div className="box">
              <h1 className="h2 flex items-center justify-between">
                <span className="flex items-center">
                  DatePicker{" "}
                  <code>
                    calendarSize <b>large</b>
                  </code>
                </span>
                <small className="text-normal">
                  <Button
                    type="success"
                    value="ReactJS Datepicker"
                    pathTo="https://reactdatepicker.com/"
                    icon={<Icons fill="#fff" iconName="link" />}
                  />
                </small>
              </h1>
              <DatePicker
                onChange={this.handleDataPickerClick}
                dateFormat="D MMM YY"
                calendarSize="large"
              />
              {this.state.selected && (
                <div className="margin-20-top">
                  <kbd>{moment(this.state.selected).format("D MMM YY")}</kbd>
                </div>
              )}

              <figure className="highlight">
                <pre>
                  {`
  import DatePicker from '../../components/DatePicker';

  <DatePicker
    onChange={this.handleDataPickerClick}
    calendarSize="large"
    dateFormat="D MMM YY" // You can use the all moment.js date formats
  />
                      `}
                </pre>
              </figure>
            </div>

            <div className="box">
              <h1 className="h2">ButtonGroup + DatePicker</h1>
              <Well gutter={20}>
                <ButtonGroup>
                  <Button value="button 1" onClick={this.onClick} />
                  <Button
                    presentation
                    value={
                      <div className="flex items-center">
                        <span className="btn-value-label muted">Open</span>
                        <div className="date">25 Oct 2018</div>
                      </div>
                    }
                  />
                  <Button
                    presentation
                    value={
                      <div className="flex items-center">
                        <span className="btn-value-label muted">Due Date</span>
                        <DatePicker
                          dateFormat="D MMM YY"
                          className="date-picker-clear btn-clear"
                          calendarSize="large"
                          onChange={this.handleDataPickerClick}
                          customInput={
                            <Button
                              value={moment(this.state.selected).format(
                                "D MMM YY"
                              )}
                            />
                          }
                        />
                      </div>
                    }
                  />
                  <Button value="button 3" onClick={this.onClick} />
                </ButtonGroup>
              </Well>

              {this.state.selected && (
                <div className="margin-20-top">
                  <kbd>{moment(this.state.selected).format("D MMM YY")}</kbd>
                </div>
              )}

              <figure className="highlight">
                <pre>
                  {`
    import Button from '../../components/Button';
    import ButtonGroup from '../../components/ButtonGroup';


    <ButtonGroup>
      <Button
        value="button 1"
        onClick={this.onClick}
      />
      <Button
        presentation
        value={
          <div className="flex items-center">
            <span className="btn-value-label muted">Open</span>
            <div className="date">25 Oct 2018</div>
          </div>
        }
      />
      <Button
        presentation
        value={
          <div className="flex items-center">
            <span className="btn-value-label muted">Due Date</span>
            <DatePicker
              dateFormat="D MMM YY"
              className="date-picker-clear btn-clear"
              onChange={this.handleDataPickerClick}
              customInput={
                <Button
                  value={moment(this.state.selected).format('D MMM YY')}
                />
              }
            />
          </div>
        }
      />
      <Button
        value="button 3"
        onClick={this.onClick}
      />
    </ButtonGroup>
                  `}
                </pre>
              </figure>
            </div>

            <div className="box">
              <h1 className="h2 flex items-center">
                Select / Dropdown{" "}
                <small>
                  <code>isRequired</code>
                </small>
              </h1>
              <div className="row">
                <div className="col-xs-12 col-sm-6">
                  <Select
                    selectId={"selectAddress"}
                    selectValue={
                      this.state.selectAddress
                        ? this.state.selectAddress.item.value
                        : "a"
                    }
                    options={this.state.list}
                    label="Address"
                    wrapperClass="form-control-group"
                    onChange={this.onSelectChange}
                  />
                </div>
                <div className="col-xs-12 col-sm-6">
                  <Select
                    selectId={"selectCountry"}
                    selectValue={
                      this.state.selectCountry
                        ? this.state.selectCountry.item.value
                        : "d"
                    }
                    options={this.state.list}
                    label="Country"
                    subLabel="Preselected disabled value"
                    wrapperClass="form-control-group"
                    onChange={this.onSelectChange}
                    isRequired
                  />
                </div>
              </div>
              <div className="margin-20-top">
                <div className="flex">
                  Selected object{" "}
                  <kbd className="margin-10-left">
                    {JSON.stringify(
                      this.state.selectAddress || this.state.selectCountry
                    ) || "null"}
                  </kbd>
                </div>
              </div>

              <figure className="highlight">
                <pre>
                  {`
    import Select from '../../components/Select';

    const options = [
      { id: 1, value: 'a', label: 'Option 1' },
      { id: 2, value: 'b', label: 'Option 2' },
      { id: 3, value: 'c', label: 'Option 3' },
      { id: 4, value: 'd', label: 'Option 4', disabled: true },
      { id: 5, value: 'e', label: 'Option 5' },
    ],

    <Select
      selectId={'selectTestId'}
      selectValue={this.state.selectTestId ? this.state.selectTestId.item.value : ''}
      options={this.state.list}
      label="Country"
      wrapperClass="form-control-group"
      onChange={this.onSelectChange}
      isRequired // Added the star
    />

                  `}
                </pre>
              </figure>
            </div>

            <div className="box">
              <h1 className="h2 flex items-center">
                Select / Dropdown{" "}
                <small>
                  <code>labelInside</code>
                </small>
              </h1>
              <Select
                selectId={"selectLabelInside"}
                selectValue={
                  this.state.selectLabelInside
                    ? this.state.selectLabelInside.item.value
                    : ""
                }
                options={this.state.newList}
                label="Address"
                wrapperClass="form-control-group"
                onChange={this.onSelectChange}
                labelInside
              />
              <div className="margin-20-top">
                <div className="flex">
                  Selected object{" "}
                  <kbd className="margin-10-left">
                    {JSON.stringify(this.state.selectLabelInside) || "null"}
                  </kbd>
                </div>
              </div>

              <figure className="highlight">
                <pre>
                  {`
    import Select from '../../components/Select';

    Select
      selectId={'selectTestId'}
      selectValue={this.state.selectLabelInside ? this.state.selectLabelInside.item.value : ''}
      options={this.state.list}
      label="Address"
      wrapperClass="form-control-group"
      onChange={this.onSelectChange}
      labelInside
    />

                  `}
                </pre>
              </figure>
            </div>

            <div className="box">
              <div className="row">
                <div className="col-xs-12 col-sm-6">
                  <h1 className="h2">Select + Form</h1>
                  <Form className="form-compact">
                    <Input
                      name="postCode1"
                      label="Post Code"
                      inputType="primary"
                      className="custom-class-name"
                      wrapperClass="wrapper-class-name"
                      value={this.state.postCode1}
                      type="text"
                      onChange={this.onChange}
                      onKeyPress={this.onKeyPress}
                      labelInside
                      inputCombine
                    />
                    {[
                      <Select
                        selectId={"selectAddress"}
                        selectValue={
                          this.state.selectAddress
                            ? this.state.selectAddress.item.value
                            : ""
                        }
                        options={this.state.newList}
                        label="Address"
                        onChange={this.onSelectChange}
                        labelInside
                        inputCombine
                      />,
                      <Input
                        name="address22"
                        label="Secondary address"
                        inputType="primary"
                        className="custom-class-name"
                        wrapperClass="wrapper-class-name"
                        value={this.state.address22}
                        type="text"
                        onChange={this.onChange}
                        onKeyPress={this.onKeyPress}
                        labelInside
                        inputCombine
                      />
                    ]}
                  </Form>
                  <figure className="highlight">
                    <pre>
                      {`
  <Form className="form-compact">
    <Input
      name="postCode"
      label="Post Code"
      inputType="primary"
      className="custom-class-name"
      wrapperClass="wrapper-class-name"
      value={this.state.postCode}
      type="text"
      onChange={this.onChange}
      onKeyPress={this.onKeyPress}
      labelInside
      inputCombine
    />
    {[
      <Select
        selectId={'selectAddress'}
        selectValue={this.state.selectAddress ? this.state.selectAddress.item.value : ''}
        options={this.state.newList}
        label="Address"
        wrapperClass="form-control-group"
        onChange={this.onSelectChange}
        labelInside
      />,
      <Input
        name="address2"
        label="Secondary address"
        inputType="primary"
        className="custom-class-name"
        wrapperClass="wrapper-class-name"
        value={this.state.address2}
        type="text"
        onChange={this.onChange}
        onKeyPress={this.onKeyPress}
        labelInside
        inputCombine
      />,
    ]}
  </Form>
                        `}
                    </pre>
                  </figure>
                </div>
                <div className="col-xs-12 col-sm-6">
                  <h1 className="h2">
                    Input{" "}
                    <small className="text-normal">
                      <code>form-inline-divided</code> - default
                    </small>
                  </h1>
                  <Form className="form-compact">
                    <Input
                      name="testField1"
                      label="Some input title"
                      inputType="primary"
                      className="custom-class-name"
                      wrapperClass="wrapper-class-name"
                      value={this.state.testField1}
                      type="text"
                      onChange={this.onChange}
                      onKeyPress={this.onKeyPress}
                      inputCombine
                    />
                    {[
                      <Select
                        selectId={"selectGroup"}
                        selectValue={
                          this.state.selectAddress2
                            ? this.state.selectAddress2.item.value
                            : ""
                        }
                        options={this.state.list}
                        label="Group"
                        subLabel="secondary title"
                        wrapperClass="form-control-group"
                        onChange={this.onSelectChange}
                      />,
                      <Input
                        name="familyName1"
                        label="Family name"
                        inputType="primary"
                        className="custom-class-name"
                        wrapperClass="wrapper-class-name"
                        value={this.state.familyName1}
                        type="text"
                        onChange={this.onChange}
                        onKeyPress={this.onKeyPress}
                      />
                    ]}
                  </Form>

                  <figure className="highlight">
                    <pre>
                      {`
  <Form className="form-compact">
    <Input
      name="testField"
      label="Some input title"
      inputType="primary"
      className="custom-class-name"
      wrapperClass="wrapper-class-name"
      value={this.state.testField}
      type="text"
      onChange={this.onChange}
      onKeyPress={this.onKeyPress}
      inputCombine
    />
    {[
      <Select
        selectId={'selectAddress2'}
        selectValue={this.state.selectAddress2 ? this.state.selectAddress2.item.value : ''}
        options={this.state.list}
        label="Address 2"
        subLabel="secondary address"
        wrapperClass="form-control-group"
        onChange={this.onSelectChange}
      />,
      <Input
        name="familyName"
        label="Family name"
        inputType="primary"
        className="custom-class-name"
        wrapperClass="wrapper-class-name"
        value={this.state.familyName}
        type="text"
        onChange={this.onChange}
        onKeyPress={this.onKeyPress}
      />,
    ]}
  </Form>
                        `}
                    </pre>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReusableComponents;
