import React from 'react';

import './styles.scss';

const InputErrors = ({ errors = [] }) => (
  <div className="form-errors">
    {errors && errors.length > 0 && (
      <p key={errors[0].type}>{errors[0].message || errors[0]}</p>
    )}
  </div>
);

export default InputErrors;
