export const tasksSearchFields = {
  cont: [
    'form_type_name',
    'claim_ref_id',
    'claim_claim_type_name',
    'claim_users_first_name',
    'claim_users_last_name',
  ],
  eq: [],
};

export const claimsSearchFields = {
  cont: [
    'claim_type_name',
    'ref_id',
    'external_ref',
    'users_first_name',
    'users_last_name',
  ],
  eq: [],
};

export const customersSearchFields = {
  cont: ['first_name', 'last_name', 'ref_id', 'phone'],
};

export const createClaimSearchUserFields = {
  cont: ['full_name', 'ref_id', 'phone', 'email'],
};

export const personSearchFields = {
  cont: ['first_name', 'last_name', 'mobile_phone', 'home_phone'],
};
export const organizationSearchFields = {
  cont: ['name'],
};

export const documentsSearchFields = {
  cont: ['filename'],
};

export const correspondenceSearchField = {
  cont: ['from', 'subject'],
};

export const linkedClaimsSearchFields = {
  cont: [
    'claim_ref_id',
    'claim_claim_type_name',
    'claim_users_full_name',
    'claim_external_ref',
  ],
  eq: [],
};

export const linkingClaimsSearchFields = {
  cont: [
    'ref_id',
    'claim_type_name',
    'users_first_name',
    'users_last_name',
    'external_ref',
  ],
  eq: [],
};
