import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ErrorBoundary from './ErrorBoundary';
import RootLayout from './RootLayout';
import { setAuthBackground, setAuthWrapper } from './helpers';

const AuthLayout = ({ children, settings }) => {
  const authWrapper = setAuthWrapper();
  const inlineStyles = setAuthBackground({ settings });

  return (
    <ErrorBoundary>
      <Fragment>
        <RootLayout>
          <main
            className={`${authWrapper} flex direction-column justify-center items-center`}
            style={{ ...inlineStyles }}
          >
            {children}
          </main>
        </RootLayout>
      </Fragment>
    </ErrorBoundary>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

const mapStateToProps = ({ settings }) => ({
  settings,
});

export default withRouter(connect(mapStateToProps)(AuthLayout));
