import React, { Component } from "react";
import PropTypes from "prop-types";

import "./styles.scss";

class RadioGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedItem: {}
    };
  }

  onChange = item => {
    this.setState({ checkedItem: item });
    this.props.onChange(item);
  };

  render() {
    const { className, list, type, groupName } = this.props;
    const groupType = type ? `radio-group-${type}` : "";
    return (
      <nav className="radio-group-wrapper">
        <ul className={`radio-group ${className} ${groupType}`}>
          {list.length > 0 &&
            list.map(item => (
              <li key={list.indexOf(item)} className="radio-group-item-wrapper">
                <div
                  className={`radio ${
                    item.disabled ? "radio-disabled" : "radio-enabled"
                  }`}
                >
                  <input
                    id={item.id}
                    type="radio"
                    name={groupName}
                    // checked={checked}
                    disabled={item.disabled}
                    value={item.value}
                    onChange={() => this.onChange(item)}
                  />
                  <label htmlFor={item.id} className="radio-label">
                    <span>{item.label}</span>
                  </label>
                </div>
              </li>
            ))}
        </ul>
      </nav>
    );
  }
}

RadioGroup.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  groupName: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired
};

RadioGroup.defaultProps = {
  className: "",
  type: ""
};

export default RadioGroup;
