import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import checkValidation from 'utils/validator';
import { fileTypeValidation } from '../utils/validation';

import fingerprintModule from 'utils/fingerprint';

import Grid from "components/Layout/Grid";

// const fingerprintModule = require("utils/fingerprint");
class AccountDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fingerprint: ''
    }
  }

  onChange = async (e) => {
    e.persist();
    const file = e.target.files[0];
    const errors = checkValidation(fileTypeValidation, {
      fileType: file.name.split('.').pop(),
    });

    if (Object.keys(errors).length === 0) {
      let reader = new FileReader();
      await reader.readAsArrayBuffer(file);

      let attestivFingerprint;
        fingerprintModule()
          .then(async instance => {
            console.log("Loaded fingerprint module " + await instance.get_version());   
            attestivFingerprint = instance;

            let fingerprint = await attestivFingerprint.fingerprint_jpg(reader.result);
            this.setState({
              fingerprint
            })
          });
    }

    this.setState({
      errors,
    });
  };

  render() {
    const isMobile = this.props.browser.windowSize[0] < 576;

    return (
      <Fragment>
        <Grid.Main className="height-auto">
          <Grid.Content className="mb20">
            <Grid.Controls>
              <div
                className={`flex justify-between container-fluid ${
                  isMobile ? "direction-column" : ""
                }`}
              >
                <div>
                  <h2 className="h2">Get fingerprints</h2>
                </div>
                <div>
                  <div className="flex direction-column justify-center items-center overflow-hidden relative">
                    <label htmlFor="loadPfg" className="btn btn-success flex justify-center items-center plus">
                      <span>+ Upload new</span>
                      {/* {creating ? <div className="loader" /> : <span>+ Upload new</span>} */}
                      <input id="loadPfg" type="file" onChange={this.onChange} />
                    </label>
                  </div>
                </div>
              </div>
            </Grid.Controls>

            <div
              className="container-fluid flex row col-xs-8 justify-start mt0 mb20 col-sd-12"
            >
              <div>{this.state.fingerprint}</div>
            </div>
          </Grid.Content>
        </Grid.Main>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ browser }) => ({
  browser
});

export default connect(mapStateToProps)(AccountDetails);
