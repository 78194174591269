import React, { Fragment } from "react";

import Icons from "common/Icons";
import { customersAppPath } from "utils/paths";

import InputErrors from "components/InputErrors";

export const Suggestion = (item, onSuggestionClick) => (
  <button
    className="suggestions-list-item flex justify-between items-center"
    key={item.id}
    onClick={() => onSuggestionClick(item)}
  >
    <div className="suggestions-left flex justify-center items-center">
      <Icons iconName="claim" />
      <span>
        {item.firstName} {item.lastName} {item.phone}
      </span>
    </div>
    <div className="suggestions-right flex justify-center items-center">
      <Icons iconName="link" />
      <Icons iconName="plus" />
    </div>
  </button>
);

export const PopupSuggestion = (item, onSuggestionClick) => (
  <button
    className="suggestions-list-item windowed flex justify-between items-center"
    key={item.id}
    onClick={() => onSuggestionClick(item)}
  >
    <div className="suggestions-left flex justify-center items-center">
      <span>
        {item.firstName} {item.lastName}
      </span>
    </div>
  </button>
);

export const SuggestionSelected = (
  suggestion,
  onLinkClick,
  onDeleteClick,
  label,
  labelInside,
  isRequired,
  _,
  errors
) => (
  <Fragment>
    {!labelInside && (
      <label className="form-control-label">
        <span>{label}</span>
        {isRequired && <sup className="text-color-danger">*</sup>}
      </label>
    )}
    <div className="suggestions-selected flex justify-between items-center">
      <div className="suggestions-left flex justify-center items-center">
        <Icons iconName="claim" />
        <span>
          {suggestion.firstName} {suggestion.lastName} {suggestion.phone}
        </span>
      </div>
      <div className="suggestions-right flex justify-center items-center">
        <button
          onClick={() => onLinkClick(`${customersAppPath}/${suggestion.id}`)}
        >
          <Icons iconName="link" />
        </button>
        <button onClick={onDeleteClick}>
          <Icons iconName="close" />
        </button>
      </div>
    </div>
    <InputErrors errors={errors} />
  </Fragment>
);
