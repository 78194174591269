import React, { Component } from "react";
import PropTypes from "prop-types";

import InputErrors from "../InputErrors";

import "./styles.scss";

class SelectTitle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.selectValue,
      options: props.options,
      placeholder: props.placeholder
    };

    if (props.options.length === 1) {
      if (props.onChange) {
        props.onChange(props.options[0].value, props.name);
      }
      this.state.value = props.options[0].value;
    }
  }

  componentDidMount() {
    const { initialValue } = this.props;

    if (initialValue && Object.keys(initialValue).length > 0) {
      this.setInitialValue(initialValue);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { initialValue } = this.props;

    if (
      initialValue &&
      Object.keys(initialValue).length > 0 &&
      !prevState.value
    ) {
      this.setInitialValue(initialValue);
    }
  }

  onChange = e => {
    this.setState({ value: e.target.value });
    this.props.onChange(e.target.value, e.target.name);
  };

  setInitialValue = initialValue => {
    this.setState({ value: initialValue });
  };

  updateValue = (value, name) => {
    this.setState({ value });
    this.props.onChange(value, name);
  };

  setHTML = (label, isRequired, subLabel, isTmpMobile) => {
    const sup = isRequired ? !isTmpMobile ? '<sup className="text-color-gray">*</sup>' : '' : "";
    const subLabelEl = subLabel
      ? `<span className="sub-label">${subLabel}</span>`
      : "";
    return {
      __html: `<div class="pointer-without-events"><span>${label}<span>${sup}${subLabelEl}</div>`
    };
  };

  render() {
    const {
      selectId,
      selectValue,
      className,
      wrapperClass,
      isRequired,
      labelInside,
      subLabel,
      disabled,
      name,
      defaultValue,
      options,
      errors,
      mandatory,
      switchLableColor,
      additionalClassName,
      isTmpMobile,
      style
    } = this.props;

    let label = mandatory ? `${this.props.label} *` : this.props.label;
    const { value } = this.state;
    const errorArray = this.state.errors || errors;
    const errorClass =
      errorArray && errorArray.length > 0 ? "has-error" : "no-error";
    const activeClass =
      (value && Object.keys(value).length > 0) ||
      (selectValue &&
        (Object.keys(selectValue).length > 0 ||
          typeof selectValue === "number"))
        ? "control-active"
        : "control-empty";
    const labelInsideClass = labelInside
      ? "form-control-select-label-inside"
      : "form-control-select-label-outside";

    return (
      <div
        className={`${wrapperClass} form-control-select-wrapper ${activeClass} ${labelInsideClass}`}
        style={style}
      >
        {!labelInside && label && (
          <label htmlFor={selectId} className="form-control-select-label">
            <span className="form-control-select-label-inner">{label}</span>
            {isRequired && (
              <sup
                className={`${
                  !!this.state.value && switchLableColor
                    ? "text-color-danger"
                    : "text-color-gray"
                }`}
              >
                *
              </sup>
            )}
            {subLabel && <span className="sub-label">{subLabel}</span>}
          </label>
        )}
        <div className="form-control-select-group">
          {!disabled && <i className="la-icon-angle la-icon-angle-down" />}
          <select
            id={selectId}
            value={value || defaultValue}
            className={`form-control form-control-select ${errorClass} ${className} ${additionalClassName}`}
            onChange={this.onChange}
            disabled={disabled}
            name={name}
          >
            <option value={value || defaultValue} disabled>
              {value || defaultValue}
            </option>
            {options.map(item => (
              <option
                value={item.value || item.id}
                key={item.id}
                disabled={item.disabled}
              >
                {(item.placeholderValue ? item.placeholderValue : item.name || item.value)}
                {/* {item.name || item.value} */}
              </option>
            ))}
          </select>
          {labelInside && (
            <label
              htmlFor={selectId}
              className="form-control-select-label"
              dangerouslySetInnerHTML={this.setHTML(
                label,
                isRequired,
                subLabel,
                isTmpMobile
              )}
            ></label>
          )}
        </div>
        <InputErrors errors={errorArray} />
      </div>
    );
  }
}

SelectTitle.propTypes = {
  selectId: PropTypes.string,
  selectValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })
  ),
  label: PropTypes.string,
  className: PropTypes.string,
  wrapperClass: PropTypes.string,
  onChange: PropTypes.func,
  isRequired: PropTypes.bool,
  labelInside: PropTypes.bool,
  subLabel: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  errors: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  )
};

SelectTitle.defaultProps = {
  selectId: null,
  selectValue: null,
  options: [],
  label: null,
  className: "",
  wrapperClass: null,
  onChange: () => {},
  isRequired: false,
  labelInside: false,
  subLabel: "",
  disabled: false,
  placeholder: "",
  name: "",
  defaultValue: "",
  errors: [],
  initialValue: ""
};

export default SelectTitle;
