export const titleOptions = [
  {
    id: 1,
    name: 'Mr',
    value: 'Mr',
  },
  {
    id: 2,
    name: 'Mrs',
    value: 'Mrs',
  },
  {
    id: 3,
    name: 'Ms',
    value: 'Ms',
  },
  {
    id: 4,
    name: 'Miss',
    value: 'Miss',
  },
  {
    id: 5,
    name: 'Mx',
    value: 'Mx',
  },
  {
    id: 6,
    name: 'Dr',
    value: 'Dr',
  },
];
