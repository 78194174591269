import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import ReSendButton from 'pages/People/components/ReSendButton';
import VideoPlayer from 'components/VideoPlayer';
import GlobalPopup from 'components/PopupGlobal';
import VehicleInspector from 'components/VehicleInspector';
import Icons from 'common/Icons';

import {
  containsEscapedHTML,
  isHorwich,
  MAX_ANSWER_LENGTH,
} from 'utils/helpers';

import Field from '../Field';
import './styles.scss';

const fieldElement = (
  field,
  onChange,
  onKeyPress,
  disabled,
  gap,
  SuggestionSelected,
  suggestions,
  preloadedData,
  previewLoaded,
  withoutAutosave,
  additionalClass,
  formType,
  isTmp,
  key
) => {
  const { type, value, name, inline, element, help, withPopup } = field;
  const isLongText =
    type === 'text' &&
    value &&
    value.length >= MAX_ANSWER_LENGTH &&
    !containsEscapedHTML(value);
  const labelClass = formType === 'login' ? 'login-label' : '';

  return (
    <div
      key={name}
      className={`form-group ${additionalClass} ${
        inline ? 'form-inline splitted' : ''
      } ${withPopup ? 'with-popup' : ''} ${gap ? `mt${gap}` : ''} ${
        element === 'clickToAddress' ? 'no-within' : 'focus-within'
      }`}
    >
      <Field
        onChange={onChange}
        onKeyPress={onKeyPress}
        disabled={disabled}
        key={name || key}
        SuggestionSelected={SuggestionSelected}
        suggestions={suggestions}
        preloadedData={preloadedData}
        previewLoaded={previewLoaded}
        withoutAutosave={withoutAutosave}
        {...field}
        isShowMap={true}
        labelClass={labelClass}
        isTmp={isTmp}
      />
      {help || isLongText ? (
        <div>
          <button className="tooltip-icon" data-tip data-for={name}>
            ?
          </button>
          <ReactTooltip
            place="right"
            type="dark"
            effect="solid"
            multiline={true}
            id={name}
            className="tooltip-wrapper-custom"
          >
            <span style={{ whiteSpace: 'pre-line' }}>{help || value}</span>
          </ReactTooltip>
        </div>
      ) : null}
    </div>
  );
};

const Form = ({
  className = '',
  onSubmit,
  onChange,
  onKeyPress,
  children = [],
  fields = [],
  title = '',
  gap = 30,
  disabled = false,
  SuggestionSelected,
  suggestions = [],
  preloadedData = {},
  resendButton,
  userEmail,
  formType = '',
  isTmp = false,
  notificationsMessage,
  formError,
  warningMessage = false,
  isCreatePerson = false,
  taskWarningMessage = false,
}) => {
  const [isModalOpen, switchModal] = useState(false);
  const [isBdeoModalOpen, switchBdeoModal] = useState(false);
  const [bdeoUrl, setBdeoUrl] = useState(null);

  return (fields && fields.length > 0) || (children && children.length > 0) ? (
    <form
      className={`direction-column form flex ${className} ${
        disabled ? 'form-disabled' : ''
      }`}
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      {title ? <h3 className="form-header mb30">{title}</h3> : null}
      <GlobalPopup
        isOpen={isModalOpen}
        onBackdropClick={() => {
          switchModal((isModalOpen) => !isModalOpen);
        }}
        content={<VideoPlayer src={fields[0] ? fields[0].value : null} />}
      />
      <GlobalPopup
        isOpen={isBdeoModalOpen}
        onBackdropClick={() =>
          switchBdeoModal((isBdeoModalOpen) => !isBdeoModalOpen)
        }
        content={<VehicleInspector url={bdeoUrl} />}
      />
      {fields && fields.length > 0
        ? fields.map((field) =>
            Array.isArray(field) && field.length > 0 ? (
              <div
                key={fields.indexOf(field)}
                className={`form-inline mt${gap} ${
                  field.find((f) => f.inputCombine)
                    ? 'form-inline-combine'
                    : 'form-inline-divide'
                }`}
              >
                {field.map((f) =>
                  fieldElement(
                    f,
                    onChange,
                    onKeyPress,
                    disabled,
                    formType,
                    isTmp
                  )
                )}
              </div>
            ) : (
              fieldElement(
                field,
                field.type === 'videoThumbnail'
                  ? () => {
                      switchModal((isModalOpen) => !isModalOpen);
                    }
                  : field.element === 'bdeo'
                  ? () => {
                      switchBdeoModal((isBdeoModalOpen) => !isBdeoModalOpen);
                      setBdeoUrl(field.value);
                    }
                  : onChange,
                onKeyPress,
                disabled,
                gap,
                SuggestionSelected,
                suggestions,
                preloadedData,
                null,
                null,
                '-ml-10 -mr-10',
                formType,
                isTmp
              )
            )
          )
        : (children &&
            children.length > 0 &&
            children.map((child) =>
              child.length > 0 ? (
                <div
                  key={children.indexOf(child)}
                  className={`form-inline mt${gap} ${
                    child.find((c) => c.props.inputCombine)
                      ? 'form-inline-combine'
                      : 'form-inline-divide'
                  }`}
                >
                  {child.map((c) => (
                    <div key={child.indexOf(c)} className="form-group">
                      {c}
                    </div>
                  ))}
                </div>
              ) : (
                <div
                  key={child.props.name || child.props.value}
                  className={`form-group mt${gap}`}
                >
                  {child}
                </div>
              )
            )) || <div className={`form-group mt${gap}`}>{children}</div>}
      {notificationsMessage && (
        <h4 className="notificaions-error mt20">
          There is no mobile number or email address associated with this
          person. Please update their record to send a notification.
        </h4>
      )}
      {resendButton && <ReSendButton userEmail={userEmail} />}
      {formError &&
        formError.length > 0 &&
        formError.map((e) => {
          return (
            <div className="flex justify-center text-color-danger padding-20-top">
              {e}
            </div>
          );
        })}
      {warningMessage && isCreatePerson && isHorwich() && (
        <div className="form-warning-message">
          <Icons iconName="warning" fill="#000000" width={21} height={21} />
          <p className="ml20 mt20">
            As you are handling sensitive personal information, please ensure
            you confirm with the customer the spelling and accuracy of the email
            address and / or phone number before saving. When you create the
            claim, all correspondence and documentation will automatically be
            sent to the mobile and / or email address entered here.
          </p>
        </div>
      )}
      {taskWarningMessage && isHorwich() && (
        <div className="form-warning-message">
          <Icons iconName="warning" fill="#000000" width={21} height={21} />
          <p className="ml20 mt20">
            New Tasks within a Claim should only be created in exceptional
            circumstances. If you need assistance, please contact Horwich
            Farrelly before creating a new Task. If you do need to create a new
            Task, then please ensure that you select the correct Task type. E.g.
            if you require a Liability signature, you should select “Document
            Signing – Liability” and not “Document Signing – Induced"
          </p>
        </div>
      )}
    </form>
  ) : null;
};

Form.propTypes = {
  className: PropTypes.string,
  fields: PropTypes.arrayOf(Object),
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  gap: PropTypes.number,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  SuggestionSelected: PropTypes.func,
  suggestions: PropTypes.arrayOf(Object),
  preloadedData: PropTypes.shape(),
  formType: PropTypes.string,
  isTmp: PropTypes.bool,
  taskWarningMessage: PropTypes.bool,
  warningMessage: PropTypes.bool,
  isCreatePerson: PropTypes.bool,
};

export default Form;
