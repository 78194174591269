import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const List = ({ className = '', children, type, gap }) => {
  const listType = type ? `list-${type}` : '';
  const listGap = gap ? `list-${gap}` : '';

  return (
    <nav className="list-wrapper">
      <ul className={`list ${className} ${listType} ${listGap}`}>
        {(children.length > 0 &&
          children.map((item) => (
            <li key={children.indexOf(item)} className="list-item-wrapper">
              {item}
            </li>
          ))) || <li className="list-item-wrapper">{children}</li>}
      </ul>
    </nav>
  );
};

List.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.node,
  gap: PropTypes.number,
};

export default List;
