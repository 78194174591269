import { peoplesAppPath } from "utils/paths";

// APP paths
export const listPath = `${peoplesAppPath}`;
export const rootPath = `${peoplesAppPath}`;
export const newPath = `${peoplesAppPath}/new`;
export const infoPath = `${peoplesAppPath}/:id`;
export const editPath = `${peoplesAppPath}/:id/edit`;
export const documentsPath = `${peoplesAppPath}/:id/documents`;
export const claimsPath = `${peoplesAppPath}/:id/claims`;
