import React from 'react';
import PropTypes from 'prop-types';

const Analytics = ({
  width = 16,
  height = 16,
  className = '',
  fill = '#0F2241',
}) => (
  <div className={`la-icon icon-svg-search ${className}`}>
    <svg width={width} height={height} viewBox="0 0 16 16">
      <path
        fill={fill}
        d="M746.667 106.667H1173.33V1493.33H746.667V106.667ZM533.333 533.333H106.667V1493.33H533.333V533.333ZM1920 1706.67H0V1824H1920V1706.67ZM1813.33 746.667H1386.67V1493.33H1813.33V746.667Z"
      />
    </svg>
  </div>
);

Analytics.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default Analytics;
