import moment from 'moment';

const isArrayNotEmpty = (value) => Array.isArray(value) && value.length > 0;

const decodeDate = (date) => {
  let clearedDate;
  let result;

  if (date && typeof date === 'string') {
    if (/[0-9]st/.test(date)) {
      clearedDate = date.replace('st', '');
      result = moment(clearedDate).format();
    } else if (/[0-9]nd/.test(date)) {
      clearedDate = date.replace('nd', '');
      result = moment(clearedDate).format();
    } else if (/[0-9]rd/.test(date)) {
      clearedDate = date.replace('rd', '');
      result = moment(clearedDate).format();
    } else if (/[0-9]th/.test(date)) {
      clearedDate = date.replace('th', '');
      result = moment(clearedDate).format();
    } else {
      result = moment(date).format();
    }
  }

  return result;
};

export const validate = (validation, value, values) => {
  let isValid;
  let yearsOld;
  let hoursOld;
  let daysOld;
  let formattedDate;

  switch (validation.type) {
    case 'email':
      isValid =
        !value ||
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i.test(
          value
        );
      break;
    case 'loginPassword':
    case 'allInfoPassword':
      // eslint-disable-next-line
      isValid =
        // eslint-disable-next-line
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#+\-{}\[\]$!%*?&()\\£*`.+,%=?\/<>~‘;:¬'])[A-Za-z\d@#+\-{}\[\]$!%*?&()\\£*`.+,%=?\/<>~‘;:¬']{8,}$/.test(
          value
        );
      break;
    case 'number':
      isValid = /[^\d,]/g.test(value);
      break;
    case 'typeNumber':
      isValid = !isNaN(value);
      break;
    case 'tel':
      isValid = !value || /^(.{4})(.*)(.{6})$/g.test(value);
      break;
    case 'comparePasswords':
      isValid = value && value.newPassword === value.confirmPassword;
      break;
    case 'isRequired':
      isValid = value && value !== null && value.toString().trim() !== '';
      break;
    case 'isEquivalent':
      isValid =
        (!value && !validation.condition) || value === validation.condition;
      break;
    case 'minSize':
    case 'minLength':
      isValid = value && value.length >= validation.condition;
      break;
    case 'maxSize':
    case 'maxLength':
      isValid = !value || value.length <= validation.condition;
      break;
    case 'date':
      yearsOld = moment().diff(value, 'years');
      isValid = yearsOld > 18 && yearsOld < 150;
      break;
    case 'futureDate':
    case 'noPastDates':
      formattedDate = decodeDate(value);
      daysOld = moment().diff(formattedDate, 'days');
      isValid = !formattedDate || daysOld <= 0;
      break;
    case 'pastDate':
    case 'noFutureDates':
      formattedDate = decodeDate(value);
      hoursOld = moment().diff(formattedDate, 'hours');
      isValid = !formattedDate || hoursOld >= 0;
      break;
    case 'invalidPassword':
    case 'EMAIL_NOT_FOUND':
      isValid = false;
      break;
    case 'nin':
      isValid =
        !value ||
        /^(?!FY)(?!GB)(?!NK)(?!TN)(?!TT)(?!ZZ)(?:[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z])(?:\s*\d\s*){6}([A-D]|\s)$/i.test(
          value
        );
      break;
    case 'isSame':
      isValid = values.length === 2 && values[0] === values[1];
      break;
    case 'symbolsLength':
      isValid = value.length >= 6;
      break;
    case 'fileType':
      isValid =
        !value ||
        /^(pdf|jpg|jpeg|png|gif|heic|doc|docx|pptx|ppt|xls|xlsx|csv|PDF|JPG|JPEG|PNG|GIF|HEIC|DOC|DOCX|PPTX|PPT|XLS|XLSX|CSV)$/.test(
          value
        );
      break;
    case 'thompsonsPassword':
      // eslint-disable-next-line
      isValid =
        // eslint-disable-next-line
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#+\-{}\[\]$!%*?&()\\£*`.+,%=?\/<>~‘;:¬'])[A-Za-z\d@#+\-{}\[\]$!%*?&()\\£*`.+,%=?\/<>~‘;:¬']{8,}$/.test(
          value
        );
      break;
    default:
      break;
  }
  if (!isValid) {
    return validation;
  }
  return false;
};

export const validateDiff = (validation, first, last) => {
  let isValid;
  let days;

  switch (validation.type) {
    case 'difference':
      days = moment(first).diff(last, 'days');
      isValid = days <= 0;
      break;
    default:
      break;
  }

  if (!isValid) {
    return validation;
  }
  return false;
};

export const validateAll = (validations, values) => {
  const valids = Object.keys(validations);

  if (valids.length > 0) {
    return valids.reduce((acc, key) => {
      if (isArrayNotEmpty(validations[key])) {
        const result = validations[key]
          .map((v) => validate(v, values[key], Object.values(values)))
          .filter((e) => !!e);

        return isArrayNotEmpty(result) ? { ...acc, [key]: result } : acc;
      }
      return acc;
    }, {});
  }
  return {};
};

export default validateAll;
