import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ErrorBoundary from './ErrorBoundary';
import Header from '../Header';
import RootLayout from './RootLayout';

const BaseLayout = ({ children = [] }) => (
  <ErrorBoundary>
    <Fragment>
      <Header />
      <RootLayout>{children}</RootLayout>
    </Fragment>
  </ErrorBoundary>
);

BaseLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

const actionCreators = {};

export default withRouter(connect(null, actionCreators)(BaseLayout));
