import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { setAccFields } from 'actions/account';
import { fetchCustomer } from 'actions/customer';

import Layout from 'components/Layout';

import AccountEdit from './containers/Edit';
import AccountDetails from './containers/Details';

import { rootPath, editPath } from './utils/paths';

class AccountPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { session } = this.props;

    this.props.fetchCustomer(session.data.id, true);
  }

  render() {
    const { session, user, account, customer } = this.props;

    return (
      <Layout.Base>
        <Switch>
          <Route
            exact
            path={rootPath}
            render={(props) => (
              <AccountDetails session={session} account={customer} {...props} />
            )}
          />
          <Route
            path={editPath}
            render={(props) => (
              <AccountEdit
                session={session}
                account={account}
                user={user}
                {...props}
              />
            )}
          />
        </Switch>
      </Layout.Base>
    );
  }
}

AccountPage.propTypes = {
  session: PropTypes.shape(),
  setAccFields: PropTypes.func.isRequired,
};

AccountPage.defaultProps = {
  session: {},
};

const mapDispatchToProps = {
  setAccFields,
  fetchCustomer,
};

const mapStateToProps = (state) => ({
  session: state.session,
  user: state.user,
  account: state.account,
  customer: state.customer,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AccountPage)
);
