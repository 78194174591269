import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

import InputErrors from '../InputErrors';

import './styles.scss';

class TimePickerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDate: this.checkType(props.value)
        ? moment(props.value, 'HH:mm')
        : null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentDate } = this.state;
    const prevDate = prevState.currentDate;
    const formattedCurrentDate = this.formatDate(currentDate);
    const formattedPrevDate = this.formatDate(prevDate);
    // const formattedNewDate = this.formatDate(this.props.value);
    const isSame = moment(formattedCurrentDate).isSame(
      formattedPrevDate,
      'day'
    );
    const isValid =
      formattedCurrentDate &&
      formattedCurrentDate.isValid() &&
      formattedPrevDate &&
      formattedPrevDate.isValid();

    if (!isSame && isValid) {
      this.updateValue(formattedCurrentDate);
    }

    // if (!currentDate && formattedNewDate) {
    //   this.updateValue(formattedNewDate);
    // }
  }

  onKeyPress = (e) => {
    this.handleChange(e.target.value);
  };

  handleChange = (date) => {
    const { name } = this.props;
    const formattedDate = moment(date, 'HH:mm');

    if (date && formattedDate) {
      this.setState({ currentDate: formattedDate });
      this.props.onChange(formattedDate.format('HH:mm'), name);
    } else {
      this.setState({ currentDate: null });
      this.props.onChange(null, name);
    }
  };

  checkType = (el) => {
    let isValid = false;

    if (
      typeof el === 'string' &&
      el &&
      el.length > 0 &&
      !el.includes('Invalid')
    ) {
      isValid = true;
    } else if (typeof el === 'object' && el && Object.keys(el).length > 0) {
      isValid = true;
    }
    return isValid;
  };

  formatDate = (date) => {
    let result = null;
    const checkedDate = this.checkType(date);

    if (
      checkedDate &&
      date &&
      typeof date === 'string' &&
      date.includes('UTC')
    ) {
      const cutted = date.replace(' UTC', '');
      result = moment(cutted, 'HH:mm');
    } else if (checkedDate && date) {
      result = moment(date, 'HH:mm');
    }
    return result;
  };

  updateValue = (date) => {
    this.setState({ currentDate: date });
  };

  render() {
    const {
      className,
      shouldCloseOnSelect,
      calendarSize,
      wrapperClass,
      labelAnimate,
      labelInside,
      isRequired,
      mandatory,
      value,
      prefix,
      sufix,
      errors,
      name,
      disabled,
      style,
      newDesign,
    } = this.props;
    let label = mandatory ? `${this.props.label} *` : this.props.label;

    const { currentDate } = this.state;

    const errorClass = errors && errors.length > 0 ? 'has-error' : 'no-error';
    const sufixClass = sufix ? 'form-control-has-sufix' : '';
    const prefixClass = prefix ? 'form-control-has-prefix' : '';
    const activeClass =
      (labelInside || labelAnimate) && value && Object.keys(value).length > 0
        ? 'form-control-valid'
        : 'form-control-empty';
    const sizeClass = calendarSize
      ? `date-picker-${calendarSize}`
      : 'date-picker-default';
    const labelWrapperClass =
      labelInside || labelAnimate
        ? 'form-control-label-animate'
        : 'form-control-label-static';
    const labelInsideClass = labelInside
      ? 'form-control-wrapper-label-inside'
      : '';
    const newDesignPromptStyles = newDesign ? 'new-design-prompt' : '';
    const inputLabel = label && (
      <label htmlFor={name} className="form-control-label">
        <span className={`${newDesignPromptStyles}`}>{label}</span>
        {isRequired && <sup className="text-color-gray">*</sup>}
      </label>
    );

    const newDesignStyles = newDesign ? 'new-design-field' : null;

    return (
      <div
        className={`form-control-wrapper datepicker ${wrapperClass} ${
          !labelAnimate && !labelInside
            ? 'form-control-wrapper-label-static'
            : ''
        } ${labelInsideClass}`}
        style={style}
      >
        {!labelAnimate && !labelInside && inputLabel}
        <div
          className={`form-control-container ${labelWrapperClass} ${activeClass}`}
        >
          {prefix && <div className="form-control-prefix">{prefix}</div>}
          <DatePicker
            id={name}
            name={name}
            disabled={disabled}
            className={`form-control date-picker-wrapper form-control-date ${className} ${errorClass} ${sufixClass} ${prefixClass} ${newDesignStyles}`}
            calendarClassName={sizeClass}
            selected={currentDate}
            onChange={this.handleChange}
            onChangeRaw={(event) => this.handleChange(event.target.value)}
            onKeyDown={this.onKeyPress}
            shouldCloseOnSelect={shouldCloseOnSelect}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            dateFormat="h:mm a"
            timeCaption="Time"
          />
          {(labelInside || labelAnimate) && inputLabel}
          {sufix && (
            <div
              onClick={() => {}}
              className="form-control-sufix"
              role="button"
              tabIndex={0}
            >
              {sufix}
            </div>
          )}
        </div>
        <InputErrors errors={errors} />
      </div>
    );
  }
}

TimePickerComponent.propTypes = {
  className: PropTypes.string,
  calendarSize: PropTypes.string,
  onChange: PropTypes.func,
  shouldCloseOnSelect: PropTypes.bool,
  wrapperClass: PropTypes.string,
  labelAnimate: PropTypes.bool,
  labelInside: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  prefix: PropTypes.node,
  sufix: PropTypes.node,
  errors: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ),
  name: PropTypes.string,
  disabled: PropTypes.bool,
};

TimePickerComponent.defaultProps = {
  className: '',
  shouldCloseOnSelect: true,
  calendarSize: '',
  wrapperClass: '',
  labelAnimate: false,
  labelInside: false,
  isRequired: false,
  label: '',
  value: {},
  prefix: null,
  sufix: null,
  errors: [],
  name: '',
  disabled: false,
  onChange: null,
};

export default TimePickerComponent;
