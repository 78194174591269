import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import * as qs from 'query-string';

import Logo from 'components/Logo';
import Well from 'components/Well';
import Button from 'components/Button';

import { refreshEmail } from 'actions/auth';

import { loginAppPath } from 'utils/paths';

const RefreshEmail = (props) => {
  const [emailError, setEmailError] = useState('');
  const [status, setStatus] = useState(false);

  const onClick = () => {
    setEmailError('');

    const parsedParams = qs.parse(window.location.search);
    const { t } = parsedParams;

    refreshEmail(t, (err, data) => {
      if (err) {
        setStatus(false);
        setEmailError(err.error);
        setTimeout(() => {
          redirectTo(loginAppPath);
        }, 7000);
      }
      if (data) {
        setStatus(true);
        setEmailError(
          'Your email was changed. You will be redirected to the login page in 2 seconds'
        );
        setTimeout(() => {
          redirectTo(loginAppPath);
        }, 2000);
      }
    });
  };

  const redirectTo = (path) => {
    props.history.push(path);
  };

  const { companyLogo } = props.settings.data;

  return (
    <Fragment>
      <Logo
        src={companyLogo}
        className="margin-40-bottom max-width-80"
        width={175}
        height={20}
        fullLogo
      />
      <Well size="small" gutter={40}>
        <Button
          type="success"
          size="large"
          className="text-uppercase letter-space-md landing"
          value="Confirm Email Address Change"
          onClick={onClick}
        />
        {emailError && (
          <div
            className={`flex justify-center text-color-${
              status ? 'success-default' : 'danger'
            } padding-20-top`}
          >
            {emailError}
          </div>
        )}
      </Well>
    </Fragment>
  );
};

RefreshEmail.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  session: PropTypes.shape({
    created: PropTypes.bool,
    errorText: PropTypes.string,
    hasErrors: PropTypes.bool,
    newPasswordSaved: PropTypes.bool,
  }).isRequired,
  settings: PropTypes.shape({
    data: PropTypes.shape({
      companyLogo: PropTypes.string,
    }),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  session: state.session,
  settings: state.settings,
});

export default connect(mapStateToProps, null)(RefreshEmail);
