import axios from 'axios';
import { camelizeKeys } from 'humps';
import moment from 'moment';
import _ from 'lodash';

/* UTILS */
import RestActions from 'utils/rest/actions';
import serialize from 'utils/rest/serialize';

/* PATH */
import { claimsPathSecond, usersPathSecond } from 'utils/paths';

/* TYPES */
import { CLEAR_SUGGESTIONS } from './types';

const restAutocompleteActions = new RestActions('autocomplete');

const { fetchAllStart, fetchAllFinished } = restAutocompleteActions;

/* ACTIONS */
export const fetchCustomersSuggestions =
  (key, params = {}, queryParam = '', searchFullValue = false) =>
  (dispatch) => {
    // searchFullValue parameter should be true in case we need to make a search
    // with full value, including whitespaces. For example, "test customer"

    dispatch(fetchAllStart());
    const path =
      params && Object.keys(params).length > 0
        ? `${usersPathSecond}${serialize(params, true, searchFullValue)}`
        : `${usersPathSecond}${queryParam}`;

    return axios.get(path).then(
      ({ data }) => {
        const users = data.result ? data.result : data.users;
        const result = {
          result: camelizeKeys(users),
          meta: camelizeKeys(data.meta),
          key,
        };
        dispatch(fetchAllFinished(result));
        return users;
      },
      (error) => {
        dispatch(fetchAllFinished(key, error));
      }
    );
  };

export const fetchClaimsSuggestions =
  (key, params = {}) =>
  (dispatch) => {
    dispatch(fetchAllStart());
    return axios
      .get(`${claimsPathSecond}${serialize(params, true)}&status_not_eq=2`)
      .then(
        ({ data }) => {
          const formattedClaims = data.claims.map((claim) => ({
            ...claim,
            formattedCreatedAt: moment(claim.createdAt).format('DD MMM YYYY'),
            type: claim.claim_type.name,
            customerFullName: claim.customer
              ? `${claim.customer.first_name || ''} ${
                  claim.customer.last_name || ''
                }`
              : '',
            customerRefId: claim.customer ? claim.customer.ref_id : '',
          }));

          const res = {
            ...data,
            result: _.uniqBy(formattedClaims, 'id'),
            key,
          };

          dispatch(fetchAllFinished(camelizeKeys(res)));
        },
        (error) => {
          dispatch(fetchAllFinished(error));
        }
      );
  };

export const clearSuggestions = () => (dispatch) => {
  dispatch({
    type: CLEAR_SUGGESTIONS,
  });
};
