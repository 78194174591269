import React from 'react';
import PropTypes from 'prop-types';

const Warning2 = ({
  width = 17,
  height = 17,
  className = '',
  fill = '#CFD3D9',
}) => (
  <div className={`la-icon icon-svg-warning ${className}`}>
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 512 512"
    >
      <g>
        <path
          fill={fill}
          d="M256,34.297L0,477.703h512L256,34.297z M256,422.05c-9.22,0-16.696-7.475-16.696-16.696s7.475-16.696,16.696-16.696
            c9.22,0,16.696,7.475,16.696,16.696S265.22,422.05,256,422.05z M239.304,344.137V177.181h33.391v166.956H239.304z"
        />
      </g>
    </svg>
  </div>
);

Warning2.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default Warning2;
