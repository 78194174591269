/*global clickToAddress*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import config from 'utils/config';
import { mapAddress } from 'utils/mapCraftyObject';

import InputErrors from '../InputErrors';

import './styles.scss';

const craftyClicks = new clickToAddress({
  accessToken: config.clickToAddressToken,
  domMode: 'name',
  texts: {
    default_placeholder: ' ',
    country_placeholder: ' ',
    country_button: ' ',
  },
  gfxMode: 2,
  countrySelector: true,
  defaultCountry: 'GBR',
  getIpLocation: false,
});

class AddressComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: props.value || '',
      attach: false,
    };
  }

  componentDidMount() {
    const { name } = this.props;

    craftyClicks.attach(
      {
        search: name,
        town: `addr_town-${name}`,
        postcode: `addr_postcode-${name}`,
        county: `addr_county-${name}`,
        line_1: `addr_line1-${name}`,
      },
      {
        onResultSelected: (c2a, elements, address) => {
          const { addressType } = this.props;
          const addressObj = {
            postcode: address.postal_code,
            countryName: address.country_name,
            altProvince: address.alternative_province,
            companyName: address.company_name,
            buildingName: address.building_name,
            addressLine_1: address.line_1,
            addressLine_2: address.line_2,
            addressLine_3: address.line_3,
            addressLine_4: address.line_4,
            addressLine_5: address.line_5,
            townLocality: address.locality,
            country: address.country.country_name,
            countyCity: address.province_name,
          };
          const mappedResult = mapAddress(addressObj, addressType);

          this.props.onChange(addressObj, name);
          this.setState({ address: mappedResult });
        },
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        address: this.props.value,
      });
    }
  }

  onBlur = () => {
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  };

  onChange = (address) => {
    this.setState({ address });
  };

  onKeyPress = (e) => {
    if (e.charCode === 13) {
      this.props.onChange(e.target.value, e.target.name);
      return;
    }

    if (this.props.onKeyPress) {
      this.props.onKeyPress(e.target.value, e.target.name, e.charCode);
    }
  };

  setHTML = (label, isRequired) => {
    const { newDesign } = this.props;
    const newDesignPromptStyles = newDesign ? 'new-design-prompt' : '';
    const sup = isRequired ? '<sup class="text-color-gray">*</sup>' : '';
    return {
      __html: `<div class="ellipsis"><span class="pointer-without-events ${newDesignPromptStyles}">${label}<span>${sup}</div>`,
    };
  };

  render() {
    const {
      name,
      className,
      wrapperClass,
      isRequired,
      errors,
      disabled,
      labelAnimate,
      inputType,
      labelInside,
      value,
      prefix,
      sufix,
      mandatory,
      style,
      newDesign,
    } = this.props;
    const { address } = this.state;
    let label = mandatory ? `${this.props.label} *` : this.props.label;
    const withValue =
      (address && address.length > 0) || (value && value.length > 0);

    const activeClass =
      (labelInside || labelAnimate) && withValue
        ? 'form-control-valid'
        : 'form-control-empty';
    const labelWrapperClass =
      labelInside || labelAnimate
        ? 'form-control-label-animate'
        : 'form-control-label-static';
    const errorArray = this.state.errors || errors;
    const formControlType = inputType ? `form-control-${inputType}` : '';
    const errorClass =
      errorArray && errorArray.length > 0 ? 'has-error' : 'no-error';
    const labelInsideClass = labelInside
      ? 'form-control-wrapper-label-inside'
      : '';
    const sufixClass = sufix ? 'form-control-has-sufix' : '';
    const prefixClass = prefix ? 'form-control-has-prefix' : '';
    const inputLabel = label && (
      <label
        htmlFor={name}
        className="form-control-label max-index"
        dangerouslySetInnerHTML={this.setHTML(label, isRequired)}
      />
    );

    const labelStatic =
      !labelAnimate && !labelInside ? 'form-control-wrapper-label-static' : '';

    const newDesignStyles = newDesign ? 'new-design-field' : null;
    const newDesignLabel = newDesign
      ? 'new-design-click-to-address-label'
      : null;

    return (
      <div
        className={`form-control-wrapper click-to-address ${wrapperClass} ${labelStatic} ${labelInsideClass} ${newDesignStyles}`}
        style={style}
      >
        {!labelAnimate && !labelInside && inputLabel}
        <div
          className={`form-control-container ${labelWrapperClass} ${activeClass}`}
        >
          {prefix && <div className="form-control-prefix">{prefix}</div>}

          <input
            id={name}
            type="text"
            name={name}
            onChange={(e) => this.onChange(e.target.value)}
            onBlur={this.onBlur}
            value={address || ''}
            className={`form-control ${className} ${formControlType} ${errorClass} ${sufixClass} ${prefixClass} ${newDesignLabel}`}
            disabled={disabled}
          />

          {(labelInside || labelAnimate) && inputLabel}
          {sufix && (
            <div className="form-control-sufix" role="button" tabIndex={0}>
              {sufix}
            </div>
          )}
        </div>
        <InputErrors errors={errorArray} />
      </div>
    );
  }
}

AddressComplete.propTypes = {
  name: PropTypes.string.isRequired,
  inputType: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.string,
  className: PropTypes.string,
  wrapperClass: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  errors: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ),
  labelAnimate: PropTypes.bool,
  labelInside: PropTypes.bool,
  prefix: PropTypes.node,
  sufix: PropTypes.node,
  addressType: PropTypes.string,
};

AddressComplete.defaultProps = {
  value: '',
  label: null,
  className: null,
  wrapperClass: null,
  isRequired: false,
  errors: [],
  disabled: false,
  labelAnimate: false,
  labelInside: false,
  inputType: '',
  onChange: null,
  onKeyPress: null,
  onBlur: null,
  prefix: null,
  sufix: null,
  addressType: '',
};

export default AddressComplete;
