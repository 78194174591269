export const dateOfDeathValidation = {
  type: 'difference',
  message: 'Please provide a valid date of death',
};

export const fileTypeValidation = {
  fileType: [
    {
      type: 'fileType',
      message:
        'Only these types of file (pdf, jpg, png, gif) are supported for uploading.',
    },
  ],
};

const validation = {
  title: [
    {
      type: 'isRequired',
      message: 'Please choose your title.',
    },
  ],
  firstName: [
    {
      type: 'isRequired',
      message: 'Please enter your first name.',
    },
    {
      type: 'maxSize',
      message: 'The field should not contain more than 30 characters.',
      condition: 30,
    },
  ],
  lastName: [
    {
      type: 'isRequired',
      message: 'Please enter your last name.',
    },
    {
      type: 'maxSize',
      message: 'The field should not contain more than 30 characters.',
      condition: 30,
    },
  ],
  mobilePhone: [
    {
      type: 'isRequired',
      message: 'Please enter your mobile phone number.',
    },
    {
      type: 'tel',
      message: 'Please provide a valid phone number.',
    },
  ],
  homePhone: [
    {
      type: 'tel',
      message: 'Please provide a valid phone number.',
    },
  ],
  workPhone: [
    {
      type: 'tel',
      message: 'Please provide a valid phone number.',
    },
  ],
  workEmail: [
    {
      type: 'email',
      message: 'Please provide a valid email.',
    },
  ],
  homeEmail: [
    {
      type: 'email',
      message: 'Please provide a valid email.',
    },
  ],
  dateOfBirth: [
    {
      type: 'pastDate',
      message: 'Please provide a valid date of birth.',
    },
  ],
  dateOfDeath: [
    {
      type: 'pastDate',
      message: 'Please provide a valid date of death.',
    },
  ],
  nationalInsuranceNumber: [
    {
      type: 'nin',
      message: 'Please provide a valid national insurance number',
    },
  ],
};

export default validation;
