import React from 'react';
import PropTypes from 'prop-types';

const Claim = ({ width = 15, height = 14, className = '' }) => (
  <div className={`la-icon icon-svg-account ${className}`}>
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      width={width}
      height={height}
    >
      <g>
        <g>
          <path
            d="M309.851,148.522H119.62c-8.303,0-15.034,6.731-15.034,15.034s6.731,15.034,15.034,15.034h190.231
            c8.303,0,15.034-6.731,15.034-15.034S318.153,148.522,309.851,148.522z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M309.851,206.515H119.62c-8.303,0-15.034,6.731-15.034,15.034c0,8.303,6.731,15.034,15.034,15.034h190.231
            c8.303,0,15.034-6.731,15.034-15.034C324.884,213.246,318.153,206.515,309.851,206.515z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M309.851,266.824H119.62c-8.303,0-15.034,6.731-15.034,15.034c0,8.303,6.731,15.034,15.034,15.034h190.231
            c8.303,0,15.034-6.731,15.034-15.034C324.884,273.556,318.153,266.824,309.851,266.824z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M196.111,326.673h-76.49c-8.303,0-15.034,6.731-15.034,15.034c0,8.303,6.731,15.034,15.034,15.034h76.49
            c8.303,0,15.034-6.731,15.034-15.034C211.145,333.404,204.413,326.673,196.111,326.673z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M385.057,318.029V42.384C385.057,19.013,366.044,0,342.674,0h-198.68c-4.061,0-7.887,1.575-10.821,4.481L48.896,88.727
            c-2.892,2.891-4.484,6.735-4.484,10.823v301.164c0,23.371,19.013,42.384,42.384,42.384h189.962
            C289.031,482.951,326.196,512,370.022,512c53.797,0,97.565-43.767,97.565-97.565C467.589,365.749,431.743,325.28,385.057,318.029z
            M128.961,51.207v33.31h-0.001H95.639L128.961,51.207z M272.477,413.031H86.797c-6.791,0-12.316-5.524-12.316-12.316v-286.13
            h69.513c8.303,0,15.034-6.731,15.034-15.034c0-0.118-0.015-0.233-0.018-0.35c0.003-0.117,0.018-0.232,0.018-0.35V30.068h183.646
            c6.79,0,12.316,5.524,12.316,12.316v275.645C308.755,325.21,273.16,364.974,272.477,413.031z M370.023,481.932
            c-37.218,0-67.496-30.279-67.496-67.497c0-37.218,30.278-67.496,67.496-67.496s67.497,30.278,67.497,67.496
            C437.521,451.653,407.241,481.932,370.023,481.932z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M370.023,361.329c-8.303,0-15.034,6.731-15.034,15.034v38.072c0,8.303,6.731,15.034,15.034,15.034
            s15.034-6.731,15.034-15.034v-38.072C385.057,368.06,378.326,361.329,370.023,361.329z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M384.98,452.743c-0.05-0.481-0.119-0.972-0.221-1.453c-0.09-0.481-0.21-0.962-0.361-1.433
            c-0.14-0.461-0.311-0.932-0.49-1.383c-0.19-0.451-0.401-0.902-0.631-1.333s-0.481-0.852-0.752-1.263
            c-0.272-0.411-0.571-0.802-0.883-1.183c-0.311-0.381-0.641-0.752-0.991-1.102c-0.341-0.341-0.712-0.682-1.092-0.992
            c-0.382-0.311-0.782-0.601-1.193-0.872c-0.401-0.271-0.832-0.531-1.263-0.762c-0.432-0.231-0.882-0.441-1.333-0.621
            c-0.451-0.19-0.912-0.361-1.383-0.501c-0.472-0.14-0.942-0.261-1.423-0.361c-0.481-0.09-0.973-0.17-1.463-0.221
            c-0.982-0.1-1.975-0.1-2.957,0c-0.481,0.05-0.972,0.13-1.453,0.221c-0.481,0.1-0.962,0.22-1.433,0.361
            c-0.472,0.14-0.933,0.311-1.383,0.501c-0.451,0.18-0.902,0.391-1.333,0.621c-0.432,0.231-0.853,0.491-1.264,0.762
            c-0.41,0.271-0.812,0.561-1.183,0.872c-0.391,0.311-0.752,0.651-1.103,0.992c-0.352,0.351-0.682,0.722-0.992,1.102
            c-0.312,0.381-0.601,0.772-0.882,1.183c-0.272,0.411-0.522,0.832-0.752,1.263c-0.231,0.431-0.441,0.882-0.621,1.333
            c-0.19,0.451-0.361,0.922-0.501,1.383c-0.14,0.471-0.261,0.952-0.361,1.433c-0.1,0.481-0.17,0.972-0.22,1.453
            c-0.05,0.491-0.07,0.992-0.07,1.483c0,0.491,0.021,0.982,0.07,1.473c0.05,0.491,0.12,0.982,0.22,1.463
            c0.101,0.481,0.221,0.962,0.361,1.423c0.14,0.471,0.311,0.932,0.501,1.383c0.18,0.461,0.391,0.902,0.621,1.333
            c0.23,0.431,0.48,0.862,0.752,1.263c0.281,0.411,0.57,0.812,0.882,1.193c0.311,0.381,0.641,0.752,0.992,1.092
            c0.351,0.351,0.712,0.682,1.103,0.992c0.371,0.311,0.773,0.611,1.183,0.882c0.411,0.271,0.832,0.521,1.264,0.752
            c0.431,0.231,0.882,0.441,1.333,0.631c0.45,0.18,0.911,0.351,1.383,0.491c0.471,0.15,0.951,0.271,1.433,0.361
            c0.481,0.1,0.972,0.17,1.453,0.221c0.491,0.05,0.992,0.08,1.482,0.08c0.491,0,0.982-0.03,1.474-0.08
            c0.49-0.05,0.981-0.12,1.463-0.221c0.48-0.09,0.951-0.21,1.423-0.361c0.471-0.14,0.932-0.311,1.383-0.491
            c0.451-0.19,0.901-0.401,1.333-0.631c0.431-0.231,0.861-0.481,1.263-0.752c0.411-0.271,0.812-0.571,1.193-0.882
            c0.381-0.311,0.752-0.641,1.092-0.992c0.351-0.341,0.682-0.712,0.991-1.092c0.312-0.381,0.611-0.782,0.883-1.193
            c0.271-0.401,0.521-0.832,0.752-1.263c0.23-0.431,0.44-0.872,0.631-1.333c0.18-0.451,0.351-0.912,0.49-1.383
            c0.15-0.461,0.272-0.942,0.361-1.423c0.101-0.481,0.17-0.972,0.221-1.463c0.051-0.491,0.08-0.982,0.08-1.473
            C385.06,453.735,385.031,453.234,384.98,452.743z"
          />
        </g>
      </g>
    </svg>
  </div>
);

Claim.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default Claim;
