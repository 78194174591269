import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';

/* UTILS */
import RestActions from 'utils/rest/actions';
import serialize from 'utils/rest/serialize';

/* TYPES */
import { CLEAR_DOCUMENT_LIST } from './types';

/* PATH */
import { getCustomerDocumentsPath } from 'utils/paths';

const restCustomerDocumentActions = new RestActions('customerDocument');
const { fetchAllStart, fetchAllFinished, createStart, createFinished } =
  restCustomerDocumentActions;

/* ACTIONS */
export const fetchCustomerDocuments =
  (id, params = {}) =>
  (dispatch) => {
    dispatch(fetchAllStart());
    axios.get(`${getCustomerDocumentsPath(id)}${serialize(params, true)}`).then(
      ({ data }) => {
        const formattedDocuments = data?.result?.map((doc) => ({
          ...doc,
          fullUrl: doc.url?.original ?? doc.url?.large ?? doc.url?.medium,
        }));
        const res = {
          ...data,
          result: formattedDocuments,
        };
        dispatch(fetchAllFinished(camelizeKeys(res)));
      },
      (error) => {
        dispatch(fetchAllFinished(error));
      }
    );
  };

export const uploadDocument = (id, doc) => (dispatch) => {
  dispatch(createStart());
  axios.post(getCustomerDocumentsPath(id), decamelizeKeys(doc)).then(
    ({ result }) => {
      dispatch(createFinished(result));
      dispatch(fetchCustomerDocuments(id));
    },
    (error) => {
      dispatch(createFinished(error));
    }
  );
};

export const clearDocumentList = () => (dispatch) => {
  dispatch({
    type: CLEAR_DOCUMENT_LIST,
  });
};
