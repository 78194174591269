import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import List from '../List';
import Link from '../Link';
import Breadcrumb from '../Breadcrumb';
import Button from 'components/Button';

import './styles.scss';
import { STATUSES } from 'utils/constants';
import { processHeaderLinks } from 'utils/helpers';

class EntityInfoBuiltInHeader extends Component {
  render() {
    const {
      title,
      rootPath,
      links,
      breadcrumbs,
      status,
      closeClaim,
      openClaim,
      tasksPath,
      session,
      disableNavigation,
      claimTask,
    } = this.props;
    const { roles } = session.data;

    const loaded =
      breadcrumbs.filter((el) => el.value.split(' ').join('') === '').length ===
      0;

    const isClaimsRoot = rootPath.split('/')[1] === 'claims' ? true : false;
    const isPeopleRoot = rootPath.split('/')[1] === 'people' ? true : false;
    const withoutTabs = links ? '' : 'without-tabs';
    const tasks = claimTask?.list?.length ? claimTask?.list : null;

    const processedHeaderLinks = links
      ? processHeaderLinks({ links, tasks, roles })
      : null;

    return (
      <section className="built-in-header customer-built-in-header flex direction-row justify-between">
        <div className="direction-column flex full-height justify-between full-width breadcrumb">
          {title && <h2 className="h2">{title}</h2>}
          {breadcrumbs && breadcrumbs.length > 0 && (
            <Breadcrumb
              className={
                loaded && !disableNavigation
                  ? ''
                  : breadcrumbs && disableNavigation
                  ? ''
                  : 'empty-breadcrumbs'
              }
              type="inline"
              withoutTabs={withoutTabs}
            >
              {breadcrumbs.map((item) => (
                <Fragment key={item.id}>
                  <Link
                    className="text-capitalize"
                    pathTo={item.pathTo}
                    value={item.value}
                    disableNavigation={disableNavigation}
                  />
                </Fragment>
              ))}
            </Breadcrumb>
          )}
          {processedHeaderLinks ? (
            <List type="nav-side" className="flex list-inline">
              {processedHeaderLinks.map((item) =>
                item ? (
                  <Link
                    exact={item.exact}
                    key={item.id}
                    className="text-capitalize"
                    activeClassName="link-active"
                    pathTo={`${item.pathTo === 'forms' ? tasksPath : rootPath}${
                      item.pathTo ? `/${item.pathTo}` : ''
                    }`}
                    value={item.value}
                    roles={roles}
                    disableNavigation={disableNavigation}
                  />
                ) : null
              )}
            </List>
          ) : null}
        </div>
        <div className="full-height flex hide-on-mobile">
          {status ? (
            <div className="full-height flex">
              {isClaimsRoot && (
                <div className="claim-status-holder">
                  <p>
                    Claim Status:{' '}
                    {status
                      .toLowerCase()
                      .replace(/_/g, ' ')
                      .replace(/^\w/, (c) => c.toUpperCase())}
                  </p>
                </div>
              )}
              {isPeopleRoot && (
                <div className="claim-status-holder">
                  <p>
                    Onfido Status:{' '}
                    {status
                      .toLowerCase()
                      .replace(/_/g, ' ')
                      .replace(/^\w/, (c) => c.toUpperCase())}
                  </p>
                </div>
              )}
            </div>
          ) : null}
          {closeClaim && closeClaim.check && (
            <div className="full-height flex">
              <Button
                type="normal"
                onClick={closeClaim.onClick}
                value={closeClaim.value
                  .toLowerCase()
                  .replace(/_/g, ' ')
                  .replace(/^\w/, (c) => c.toUpperCase())}
                className="status-btn"
              />
            </div>
          )}
          {openClaim && status === STATUSES.closed && (
            <div className="full-height flex">
              <Button
                type="normal"
                onClick={openClaim.onClick}
                value={openClaim.value
                  .toLowerCase()
                  .replace(/_/g, ' ')
                  .replace(/^\w/, (c) => c.toUpperCase())}
                className="status-btn"
              />
            </div>
          )}
        </div>
      </section>
    );
  }
}

EntityInfoBuiltInHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rootPath: PropTypes.string,
  links: PropTypes.arrayOf(Object),
  breadcrumbs: PropTypes.arrayOf(Object),
};

EntityInfoBuiltInHeader.defaultProps = {
  title: '',
  rootPath: '',
  breadcrumbs: [],
  tasksPath: '',
  links: [],
};

const mapStateToProps = ({ customer, session, claimTask }) => ({
  customer,
  session,
  claimTask,
});

export default connect(mapStateToProps, {})(EntityInfoBuiltInHeader);
