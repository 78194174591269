import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import * as qs from 'query-string';

import Logo from 'components/Logo';
import Well from 'components/Well';
import Form from 'components/Form';
import Button from 'components/Button';
import Link from 'components/Link';

import { setNewPassword } from 'actions/session';
import checkValidations from 'utils/validator';

import { setPassValidations } from '../utils/validation';
import { signInPath } from '../utils/paths';
import './style.scss';
import { isThompsons, isXceedance, setHeroImageStyles } from 'utils/helpers';
import XceedanceLogo from 'components/Logos/Xceedance';
import { setNewPasswordClass } from '../utils/helpers';

class NewPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      credentials: {
        password: '',
        confirmPassword: '',
      },
      errors: {},
    };
  }

  componentDidUpdate(prevProps) {
    const { errorText, newPasswordSaved } = this.props.session;
    if (!prevProps.session.newPasswordSaved && newPasswordSaved && !errorText) {
      this.redirectTo(signInPath);
    }

    if (!prevProps.session.hasErrors && this.props.session.hasErrors) {
      this.clearPassword();
    }
  }

  onChange = (value, key) => {
    this.setState(
      {
        credentials: {
          ...this.state.credentials,
          [key]: value,
        },
      },
      () => {
        if (isThompsons()) {
          setPassValidations.password.push({
            type: 'thompsonsPassword',
            message:
              'Your password should be at least 8 characters in length and contain uppercase and lowercase letters as well as at least one number and one symbol.',
          });
          setPassValidations.confirmPassword.push({
            type: 'thompsonsPassword',
            message:
              'Your password should be at least 8 characters in length and contain uppercase and lowercase letters as well as at least one number and one symbol.',
          });
        } else {
          setPassValidations.password.push({
            type: 'allInfoPassword',
            message:
              'Your password should be at least 8 characters in length and contain uppercase and lowercase letters as well as at least one number and one symbol.',
          });
          setPassValidations.confirmPassword.push({
            type: 'thompsonsPassword',
            message:
              'Your password should be at least 8 characters in length and contain uppercase and lowercase letters as well as at least one number and one symbol.',
          });
        }

        const errors = checkValidations(
          setPassValidations,
          this.state.credentials
        );
        if (Object.keys(errors).length > 0) {
          this.setState({ errors });
        } else if (Object.keys(errors).length < 1) {
          this.setState({ errors: {} });
        }
      }
    );
  };

  onClick = () => {
    const { credentials } = this.state;
    const parsedParams = qs.parse(window.location.search);
    const { token } = parsedParams;

    if (isThompsons()) {
      setPassValidations.password.push({
        type: 'thompsonsPassword',
        message:
          'Your password should be at least 8 characters in length and contain uppercase and lowercase letters as well as at least one number and one symbol.',
      });
      setPassValidations.confirmPassword.push({
        type: 'thompsonsPassword',
        message:
          'Your password should be at least 8 characters in length and contain uppercase and lowercase letters as well as at least one number and one symbol.',
      });
    } else {
      setPassValidations.password.push({
        type: 'allInfoPassword',
        message:
          'Your password should be at least 8 characters in length and contain uppercase and lowercase letters as well as at least one number and one symbol.',
      });
      setPassValidations.confirmPassword.push({
        type: 'thompsonsPassword',
        message:
          'Your password should be at least 8 characters in length and contain uppercase and lowercase letters as well as at least one number and one symbol.',
      });
    }

    const errors = checkValidations(setPassValidations, credentials);
    const payload = {
      password_recovery: {
        token,
        password: credentials.password,
      },
    };

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
    } else {
      this.setState({ errors: {} });
      this.props.setNewPassword(payload);
    }
  };

  onKeyPress = (val, name, charCode) => {
    const { credentials } = this.state;

    if (charCode === 13) {
      const parsedParams = qs.parse(window.location.search);
      const { token } = parsedParams;

      if (isThompsons()) {
        setPassValidations.password.push({
          type: 'thompsonsPassword',
          message:
            'Your password should be at least 8 characters in length and contain uppercase and lowercase letters as well as at least one number and one symbol.',
        });
        setPassValidations.confirmPassword.push({
          type: 'thompsonsPassword',
          message:
            'Your password should be at least 8 characters in length and contain uppercase and lowercase letters as well as at least one number and one symbol.',
        });
      } else {
        setPassValidations.password.push({
          type: 'allInfoPassword',
          message:
            'Your password should be at least 8 characters in length and contain uppercase and lowercase letters as well as at least one number and one symbol.',
        });
        setPassValidations.confirmPassword.push({
          type: 'allInfoPassword',
          message:
            'Your password should be at least 8 characters in length and contain uppercase and lowercase letters as well as at least one number and one symbol.',
        });
      }

      const errors = checkValidations(setPassValidations, credentials);
      const payload = {
        password_recovery: {
          token,
          password: credentials.password,
        },
      };

      if (Object.keys(errors).length > 0) {
        this.setState({ errors });
      } else {
        this.setState({ errors: {} });
        this.props.setNewPassword(payload);
      }
    }
  };

  redirectTo = (path) => {
    this.props.history.push(path);
  };

  clearPassword = () => {
    this.setState({
      credentials: {
        ...this.state.credentials,
        password: '',
        confirmPassword: '',
      },
    });
  };

  render() {
    const { credentials, errors } = this.state;
    const { errorText } = this.props.session;
    const { companyLogo } = this.props.settings.data;
    const recoveryClass = setNewPasswordClass();
    const isResetBtnDisabled =
      errors && Object.keys(errors).length > 0 ? true : false;
    const wellStyles = isXceedance() ? 'xceedance-login-well' : null;

    return (
      <Fragment>
        {isXceedance() ? (
          <XceedanceLogo
            className="margin-30-bottom xceedance-logo"
            width={300}
            height={70}
            fullLogo
          />
        ) : null}
        <div className={`${recoveryClass} set-password`}>
          {!isXceedance() ? (
            <Logo
              src={companyLogo}
              className="margin-40-bottom max-width-80 set-password-logo"
              width={175}
              height={20}
              fullLogo
            />
          ) : null}
          <Well
            size="small"
            className={`login-well set-password-well ${wellStyles}`}
          >
            <Form
              className="form-compact"
              onSubmit={this.onClick}
              formType="login"
              fields={[
                {
                  element: 'input',
                  type: 'password',
                  label: 'Password...',
                  name: 'password',
                  onChange: this.onChange,
                  onKeyPress: this.onKeyPress,
                  labelAnimate: true,
                  errors: errors.password || [],
                  value: credentials.password,
                },
                {
                  element: 'input',
                  type: 'password',
                  label: 'Confirm password...',
                  name: 'confirmPassword',
                  onChange: this.onChange,
                  onKeyPress: this.onKeyPress,
                  labelAnimate: true,
                  errors: errors.confirmPassword || [],
                  value: credentials.confirmPassword,
                },
              ]}
            />
            <Button
              size="large"
              className="letter-space-md landing btn-login btn-set-password"
              value="Reset password"
              onClick={this.onClick}
              disabled={isResetBtnDisabled}
            />
            {errorText && (
              <div className="flex justify-center text-color-danger padding-20-top">
                {errorText}
              </div>
            )}
          </Well>
          <Link
            className="to-login-link"
            pathTo="/login"
            value="Back to login"
          />
        </div>
        {!isThompsons() && (
          <div style={setHeroImageStyles} className="set-password-hero" />
        )}
      </Fragment>
    );
  }
}

NewPassword.propTypes = {
  setNewPassword: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  session: PropTypes.shape({
    created: PropTypes.bool,
    errorText: PropTypes.string,
    hasErrors: PropTypes.bool,
    newPasswordSaved: PropTypes.bool,
  }).isRequired,
  settings: PropTypes.shape({
    data: PropTypes.shape({
      companyLogo: PropTypes.string,
    }),
  }).isRequired,
};

const mapDispatchToProps = {
  setNewPassword,
};

const mapStateToProps = (state) => ({
  session: state.session,
  settings: state.settings,
});

export default connect(mapStateToProps, mapDispatchToProps)(NewPassword);
