import { ENTITIES, LINK_ENTITY_NAME, PERMISSION_TYPE } from './constants';
import { isPrimaryRole } from './helpers';

export const fillInPermissions = (permissionsData) => {
  const filledInPermissions = {};
  if (permissionsData) {
    // eslint-disable-next-line
    Object.keys(permissionsData).map((p) => {
      filledInPermissions[p] = permissionsData[p];
      if (
        permissionsData[p].find(
          (p) =>
            p === PERMISSION_TYPE.create ||
            p === PERMISSION_TYPE.update ||
            p === PERMISSION_TYPE.delete
        ) &&
        !permissionsData[p].find((p) => p === PERMISSION_TYPE.read)
      ) {
        filledInPermissions[p].push(PERMISSION_TYPE.read);
      }
    });
  }

  return filledInPermissions;
};

const isPermitted = (roles = [], entity, action) => {
  if (roles.length > 0) {
    if (isPrimaryRole(roles)) return true;

    let permissions = {};

    roles.forEach((r) => {
      permissions = {
        ...permissions,
        ...fillInPermissions(r.permittedPermissions),
      };
    });
    const isAllowed = permissions[entity]
      ? !!permissions[entity].find((p) => p === action)
      : false;
    return isAllowed;
  }

  return false;
};

export const isPermittedLink = (roles, entityName, action) => {
  switch (entityName) {
    case LINK_ENTITY_NAME.contacts:
      return isPermitted(roles, ENTITIES.contact, PERMISSION_TYPE[action]);
    case LINK_ENTITY_NAME.documents:
      return isPermitted(roles, ENTITIES.document, PERMISSION_TYPE[action]);
    case LINK_ENTITY_NAME.correspondence:
      return isPermitted(
        roles,
        ENTITIES.deliveredNotification,
        PERMISSION_TYPE[action]
      );
    case LINK_ENTITY_NAME.tasks:
      return isPermitted(roles, ENTITIES.form, PERMISSION_TYPE[action]);
    case LINK_ENTITY_NAME.people:
      return isPermitted(roles, ENTITIES.person, PERMISSION_TYPE[action]);
    case LINK_ENTITY_NAME.organisations:
      return isPermitted(roles, ENTITIES.organisation, PERMISSION_TYPE[action]);
    default:
      return true;
  }
};

export default isPermitted;
