import React from "react";
import { Route, Switch } from "react-router-dom";

import Layout from "../../components/Layout";
import CustomerList from "./containers/List";
import CustomerNew from "./containers/New";
import CustomerDetails from "./containers/Details";

import { newPath, listPath, rootPath } from "./utils/paths";

const customersPage = () => (
  <Layout.Base>
    <Switch>
      <Route exact path={listPath} component={CustomerList} />
      <Route path={newPath} component={CustomerNew} />
      <Route path={rootPath} component={CustomerDetails} />
    </Switch>
  </Layout.Base>
);

export default customersPage;
