import { REPAIR_STATUSES } from 'pages/RepairStatus/constants';
import ProgressionPoint from './ProgressionPoint';
import ProgressionPointConnector from './ProgressionPointConnector';
import '../style.scss';

const RepairStatusProgress = ({ repairProgressStatus }) => {
  const statuses = Object.keys(REPAIR_STATUSES);
  let currentProgressIndex;

  const progressPoints = statuses.map((key, index) => {
    const activeClass =
      key === repairProgressStatus ? 'repair-status-progress-point-active' : '';
    if (activeClass) {
      currentProgressIndex = index;
    }
    const notAchievedClass =
      index > currentProgressIndex
        ? 'repair-status-progress-point-achieve'
        : '';

    if (index === statuses.length - 1) {
      return (
        <div className="repair-status-progress-step" key={index}>
          <div className="repair-status-progress-step-progression">
            <ProgressionPoint
              activeClass={activeClass}
              notAchievedClass={notAchievedClass}
            />
          </div>
          <div className="repair-status-progress-step-text">
            {REPAIR_STATUSES[key]}
          </div>
        </div>
      );
    } else {
      return (
        <div className="repair-status-progress-step" key={index}>
          <div className="repair-status-progress-step-progression">
            <ProgressionPoint
              activeClass={activeClass}
              notAchievedClass={notAchievedClass}
            />
            <ProgressionPointConnector />
          </div>
          <div className="repair-status-progress-step-text">
            {REPAIR_STATUSES[key]}
          </div>
        </div>
      );
    }
  });
  return <div className="repair-status-progress">{progressPoints}</div>;
};

export default RepairStatusProgress;
