import React from 'react';
import PropTypes from 'prop-types';

import Form from 'components/Form';
import Icons from 'common/Icons';

import './styles.scss';

const maritalStatusOptions = [
  {
    id: 1,
    name: 'Married',
    value: 'married',
  },
  {
    id: 2,
    name: 'Not married',
    value: 'not_married',
  },
  {
    id: 3,
    name: 'Divorced',
    value: 'divorced',
  },
  {
    id: 4,
    name: 'Widowed',
    value: 'widowed',
  },
];

const titleOptions = [
  {
    id: 1,
    name: 'Mr',
    value: 'Mr',
  },
  {
    id: 2,
    name: 'Mrs',
    value: 'Mrs',
  },
  {
    id: 3,
    name: 'Ms',
    value: 'Ms',
  },
  {
    id: 4,
    name: 'Miss',
    value: 'Miss',
  },
  {
    id: 5,
    name: 'Mx',
    value: 'Mx',
  },
  {
    id: 6,
    name: 'Dr',
    value: 'Dr',
  },
];

const CustomerForm = ({
  values = {},
  errors = {},
  disabled,
  onChange = () => {},
  className = '',
  onSufixClick = () => {},
  isTmpMobile,
}) => (
  <Form
    gap={20}
    onChange={onChange}
    disabled={disabled}
    className={`col-lg-6 col-md-6 col-xs-12 customer-form ${className}`}
    fields={[
      [
        {
          element: 'select',
          type: 'text',
          label: isTmpMobile ? 'Title' : 'Title *',
          name: 'title',
          value: values.title || '',
          errors: errors.title || [],
          labelInside: true,
          selectValue: values.title || '',
          options: titleOptions,
        },
        {
          element: 'input',
          type: 'text',
          label: 'First name',
          name: 'firstName',
          value: values.firstName || '',
          errors: errors.firstName || [],
          labelInside: true,
          isRequired: true,
          isTmpMobile: isTmpMobile,
        },
      ],
      [
        {
          element: 'input',
          type: 'text',
          label: 'Middle name',
          name: 'middleName',
          value: values.middleName || '',
          errors: errors.middleName || [],
          labelInside: true,
        },
        {
          element: 'input',
          type: 'text',
          label: 'Last name',
          name: 'lastName',
          value: values.lastName || '',
          errors: errors.lastName || [],
          labelInside: true,
          isRequired: true,
          isTmpMobile: isTmpMobile,
        },
      ],
      [
        {
          element: 'date',
          type: 'date',
          label: 'Date of birth',
          name: 'dateOfBirth',
          value: values.dateOfBirth || '',
          errors: errors.dateOfBirth || [],
          labelInside: true,
          noFutureDates: true,
          withPopup: true,
        },
        {
          element: 'date',
          type: 'date',
          label: 'Date of death',
          name: 'dateOfDeath',
          value: values.dateOfDeath || '',
          errors: errors.dateOfDeath || [],
          labelInside: true,
          noFutureDates: true,
          withPopup: true,
        },
      ],
      [
        {
          element: 'select',
          type: 'text',
          label: 'Marital status',
          name: 'maritalStatus',
          selectValue: values.maritalStatus || '',
          errors: errors.maritalStatus || [],
          labelInside: true,
          options: maritalStatusOptions,
        },
        {
          element: 'input',
          type: 'text',
          label: 'Occupation',
          name: 'occupation',
          value: values.occupation || '',
          errors: errors.occupation || [],
          labelInside: true,
        },
      ],
      [
        {
          element: 'input',
          type: 'text',
          label: 'National insurance number',
          name: 'nationalInsuranceNumber',
          value: values.nationalInsuranceNumber || '',
          errors: errors.nationalInsuranceNumber || [],
          labelInside: true,
        },
        {
          element: 'internationalPhone',
          label: 'Phone mobile',
          name: 'mobilePhone',
          value: values.mobilePhone || '',
          errors: errors.mobilePhone || errors.phone || [],
          labelInside: true,
          isRequired: true,
          onSufixClick,
          preference: 0,
          isTmpMobile: isTmpMobile,
        },
      ],
      [
        {
          element: 'internationalPhone',
          label: 'Phone home',
          name: 'homePhone',
          value: values.homePhone || '',
          errors: errors.homePhone || [],
          labelInside: true,
          onSufixClick,
          preference: 1,
        },
        {
          element: 'internationalPhone',
          label: 'Phone work',
          name: 'workPhone',
          value: values.workPhone || '',
          errors: errors.workPhone || [],
          labelInside: true,
          onSufixClick,
          preference: 2,
        },
      ],
      [
        {
          element: 'input',
          type: 'text',
          label: 'Email work',
          name: 'workEmail',
          value: values.email || values.workEmail || '',
          errors:
            errors.workEmail ||
            (values.emailType === 0 ||
            values.emailType === undefined ||
            values.emailType === null
              ? errors.email
              : []) ||
            [],
          labelInside: true,
          sufix: (
            <Icons
              iconName={`${
                values.emailType === 0 || values.emailType === 'work_email'
                  ? 'starActive'
                  : 'starUnactive'
              }`}
            />
          ),
          onSufixClick,
          preference: 0,
        },
        {
          element: 'input',
          type: 'text',
          label: 'Email home',
          name: 'homeEmail',
          value: values.homeEmail || '',
          errors:
            errors.homeEmail ||
            (values.emailType === 1 ||
            values.emailType === undefined ||
            values.emailType === null
              ? errors.email
              : []) ||
            [],
          labelInside: true,
          sufix: (
            <Icons
              iconName={`${
                values.emailType === 1 || values.emailType === 'home_email'
                  ? 'starActive'
                  : 'starUnactive'
              }`}
            />
          ),
          onSufixClick,
          preference: 1,
        },
      ],
      {
        element: 'clickToAddress',
        type: 'text',
        label: 'Address',
        name: 'address',
        value: values.address || '',
        errors: errors.address || [],
        labelInside: true,
        addressType: 'home',
      },
      [
        {
          element: 'input',
          type: 'text',
          label: 'Policy Reference',
          name: 'externalRef',
          value:
            values.externalRef ||
            (values.person && values.person.externalRef) ||
            '',
          errors: errors.externalRef || [],
          labelInside: true,
        },
        {
          element: 'checkbox',
          label: 'Active',
          name: 'active',
          value: values.active || '',
          errors: errors.active || [],
          disabled,
        },
      ],
    ]}
  />
);

CustomerForm.propTypes = {
  values: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    firstName: PropTypes.string,
    middleName: PropTypes.string,
    lastName: PropTypes.string,
    dateOfBirth: PropTypes.string,
    dateOfDeath: PropTypes.string,
    maritalStatus: PropTypes.string,
    occupation: PropTypes.string,
    nationalInsuranceNumber: PropTypes.string,
    phones: PropTypes.shape({
      mobilePhone: PropTypes.string,
      homePhone: PropTypes.string,
    }),
    emails: PropTypes.shape({
      workEmail: PropTypes.string,
      homeEmail: PropTypes.string,
    }),
  }),
  errors: PropTypes.shape(),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  onSufixClick: PropTypes.func,
};

export default CustomerForm;
