import axios from "axios";
import { camelizeKeys, decamelizeKeys } from "humps";

/* UTILS */
import RestActions from "utils/rest/actions";

/* REST ACTIONS */
import { fetchSession } from "./session";

/* PATHS */
import { getUserPath } from "utils/paths";

const restUserActions = new RestActions("user");

const {
  fetchStart,
  fetchFinished,
  updateStart,
  updateFinished
} = restUserActions;

export const fetchUser = () => dispatch => {
  dispatch(fetchStart());
  dispatch(fetchFinished());
};

export const updateUser = (id, changed) => dispatch => {
  dispatch(updateStart());
  return axios.put(getUserPath(id), decamelizeKeys(changed)).then(
    ({ data }) => {
      dispatch(updateFinished(camelizeKeys(data.result)));
      dispatch(fetchSession());
    },
    error => {
      dispatch(
        updateFinished({
          hasErrors: true,
          errorText: error.response.data
        })
      );
    }
  );
};
