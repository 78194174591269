import React from 'react';
import PropTypes from 'prop-types';

const Edit = ({ width = 15, height = 14, className = '', fill = '#FFF' }) => (
  <div className={`la-icon icon-svg-edit ${className}`}>
    <svg width={width} height={height} viewBox="0 0 15 16">
      <path
        fill={fill}
        fillRule="nonzero"
        d="M12.496 14.382c.252 0 .46.285.46.616 0 .33-.197.616-.449.616H.493c-.241 0-.45-.286-.45-.616s.198-.616.45-.616h12.003zM.043 10.127l7.464-7.533 2.579 2.579-7.464 7.532H.043v-2.578zm12.01-6.922L10.9 4.495l-2.578-2.58L9.543.696a.656.656 0 0 1 .95 0l1.56 1.56a.656.656 0 0 1 0 .95z"
      />
    </svg>
  </div>
);

Edit.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default Edit;
