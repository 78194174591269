import React from 'react';
import PropTypes from 'prop-types';

const ArchiveBox = ({
  width = 17,
  height = 17,
  className = '',
  fill = '#000000',
  style = {},
}) => (
  <div className={`la-icon icon-svg-tick ${className}`} style={style}>
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 973.199 973.199"
    >
      <g>
        <path
          d="M924,828.75V315.649c-3.6,0.3-7.199,0.5-10.801,0.5H60c-3.6,0-7.3-0.2-10.8-0.5V828.75c0,33.1,26.9,60,60,60H864
      C897.1,888.75,924,861.949,924,828.75z M687,513.35c0,16.6-13.4,30-30,30H316.2c-16.6,0-30-13.4-30-30v-79.6
      c0-16.601,13.4-30,30-30H656.9c16.6,0,30,13.399,30,30v79.6H687z"
          fill={fill}
        />
        <path
          d="M973.199,196.149v-51.7c0-33.1-26.898-60-60-60H60c-33.1,0-60,26.9-60,60v51.7c0,29.4,21.2,53.9,49.2,59
      c3.5,0.6,7.1,1,10.8,1h853.1c3.701,0,7.301-0.4,10.801-1C952,250.049,973.199,225.549,973.199,196.149z"
          fill={fill}
        />
      </g>
    </svg>
  </div>
);

ArchiveBox.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default ArchiveBox;
