import {
  ALLOWED_DOCUMENT_FILE_TYPES,
  ALLOWED_FILE_TYPES,
  ALLOWED_IMAGE_FILE_TYPES,
  ALLOWED_VIDEO_FILE_TYPES,
  FILE_TYPES,
} from '../utils/constants';
import { VALIDATION_ERROR_TEXT } from '../utils/validationErrors';
const fileUploadingValidation = (type = FILE_TYPES.image, file = {}) => {
  let error;
  let isAllowedExtention;
  const { type: fileType, name } = file;

  if (!fileType && !name) {
    return (error = VALIDATION_ERROR_TEXT.missedFileType);
  }

  const isImageFile = fileType?.split('/')[0] === 'image';
  const fileExtention =
    type === FILE_TYPES.image || isImageFile
      ? fileType.split('/')[1]
      : name.split('.')[1];

  if (!fileExtention)
    return (error = VALIDATION_ERROR_TEXT.missedFileExtention);

  switch (type) {
    case FILE_TYPES.video:
      isAllowedExtention = ALLOWED_VIDEO_FILE_TYPES.find(
        (type) => type === fileExtention.toLowerCase()
      );
      if (!isAllowedExtention) error = VALIDATION_ERROR_TEXT.videoFileUploading;
      break;
    case FILE_TYPES.image:
      isAllowedExtention = ALLOWED_IMAGE_FILE_TYPES.find(
        (type) => type === fileExtention.toLowerCase()
      );
      if (!isAllowedExtention) error = VALIDATION_ERROR_TEXT.imageFileUploading;
      break;
    case FILE_TYPES.document:
      isAllowedExtention = ALLOWED_DOCUMENT_FILE_TYPES.find(
        (type) => type === fileExtention.toLowerCase()
      );
      if (!isAllowedExtention)
        error = VALIDATION_ERROR_TEXT.documentFileUploading;
      break;
    case FILE_TYPES.fileUpload:
      isAllowedExtention = ALLOWED_FILE_TYPES.find(
        (type) => type === fileExtention.toLowerCase()
      );
      if (!isAllowedExtention) error = VALIDATION_ERROR_TEXT.fileUploading;
      break;
    default:
      break;
  }

  return error;
};

export default fileUploadingValidation;
