import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Grid from 'components/Layout/Grid';
import Button from 'components/Button';
import AsideMenu from 'components/AsideMenu';

import {
  fetchCustomer,
  setCustomerField,
  updateCustomer,
} from 'actions/customer';

import checkValidations, { validateDiff } from 'utils/validator';
import { customersAppPath } from 'utils/paths';
import mapAddress from 'utils/mapCraftyObject';
import { isThompsons } from 'utils/helpers';

import { rootPath } from '../../../utils/paths';
import validations, { dateOfDeathValidation } from '../../../utils/validations';

import CustomerForm from '../../../components/CustomerForm';

class CustomerEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
  }

  componentDidMount() {
    this.props.fetchCustomer(this.props.match.params.id).then(() => {
      this.setState({ fields: this.props.customer.data });
    });
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.customer.updated && this.props.customer.updated) {
      this.props.history.push(`${rootPath}/${this.props.match.params.id}`);
    }
  }

  onFormChange = (val, key) => {
    let value = val;
    const addressType = 'home';
    if (typeof val === 'object') value = mapAddress(val, addressType);

    this.props.setCustomerField(key, value);

    const { fields } = this.props.customer;

    if ((fields.emailType == null || fields.emailType === undefined) && value) {
      this.setInitialEmailPreference(key);
    }
  };

  onSubmit = () => {
    const { fields, changedFields } = this.props.customer;
    const { dateOfBirth, dateOfDeath } = fields;

    const errors = checkValidations(validations, fields);

    if (dateOfBirth && dateOfDeath) {
      const error = validateDiff(
        dateOfDeathValidation,
        dateOfBirth,
        dateOfDeath
      );

      if (error) errors.dateOfDeath = [error];
      else if (errors.dateOfDeath)
        errors.dateOfDeath = errors.dateOfDeath.filter(
          (err) => err.type !== 'difference'
        );
    }

    const fieldsWithPreferedPhone = {
      ...changedFields,
      phoneType: 0,
    };

    if (Object.keys(changedFields).length === 0) {
      this.props.history.push(`${rootPath}/${this.props.match.params.id}`);
    } else if (Object.keys(errors).length > 0) {
      this.setState({ errors });
    } else {
      this.props.updateCustomer(fields.id, fieldsWithPreferedPhone);
      this.setState({ errors: {} });
    }
  };

  onClear = () => {
    this.setState({
      errors: {},
      changedFields: this.props.customer,
    });
  };

  onRedirect = () => {
    this.setState({
      navigate: `${customersAppPath}/${this.props.match.params.id}`,
    });
  };

  onSufixClick = (name, value, preference) => {
    const { fields } = this.props.customer;

    if (!value && fields.emailType === undefined) return false;

    if (!value && fields.emailType === preference) {
      this.clearPreferences(fields, value, name);
    }

    if (value && name.includes('Email')) {
      this.props.setCustomerField('emailType', preference);
    }

    return false;
  };

  setInitialEmailPreference = (key) => {
    let pref;

    switch (key) {
      case 'workEmail':
        pref = 0;
        break;
      case 'homeEmail':
        pref = 1;
        break;
      default:
        return false;
    }
    return this.props.setCustomerField('emailType', pref);
  };

  clearPreferences = (fields, val, key) => {
    const { emailType } = fields;

    if (emailType !== undefined && !val && key.includes('Email')) {
      const newObj = Object.assign({}, fields);
      delete newObj.emailType;

      this.props.setCustomerField('emailType', undefined);
    }
  };

  render() {
    const { fields, updating } = this.props.customer;
    const { errors } = this.state;
    // const { firstName, lastName, refId } = data;
    const rootCustomerPath = `${customersAppPath}/${this.props.match.params.id}`;
    // const breadcrumbs = [
    //   {
    //     id: 1,
    //     pathTo: rootPath,
    //     value: `${firstName || ''} ${lastName || ''} ${refId || ''}`,
    //   },
    // ];

    if (this.state.navigate) {
      return <Redirect to={this.state.navigate} push />;
    }

    const { isMobile } = this.props.browser;
    const isTmpMobile = isMobile && isThompsons() ? true : false;

    return (
      <Fragment>
        {/* <EntityInfoBuiltInHeader
          breadcrumbs={breadcrumbs}
          rootPath={rootCustomerPath}
          links={headerLinks}
          // status={status}
        /> */}

        <Grid.Main className="main-wrapper-with-aside">
          <Grid.Content>
            <Grid.Controls>
              <div className="">
                <h2 className="h2">
                  {isThompsons() ? 'Client Details' : 'Customer details'}
                </h2>
              </div>
              <div className="flex justify-center items-center">
                <Button
                  type=""
                  value="Cancel"
                  pathTo={rootCustomerPath}
                  className="cancel"
                />
                <Button
                  type="primary"
                  value={updating ? <div className="loader" /> : 'Save'}
                  onClick={this.onSubmit}
                  className="update"
                />
              </div>
            </Grid.Controls>

            {Object.keys(fields).length > 0 && (
              <CustomerForm
                onChange={this.onFormChange}
                values={fields}
                errors={errors}
                onSufixClick={this.onSufixClick}
                className="col-lg-8 col-md-10 pb60"
                isTmpMobile={isTmpMobile}
              />
            )}
          </Grid.Content>

          <Grid.Aside>
            <AsideMenu id={this.props.match.params.id} />
          </Grid.Aside>
        </Grid.Main>
      </Fragment>
    );
  }
}

CustomerEdit.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  customer: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      firstName: PropTypes.string,
      middleName: PropTypes.string,
      lastName: PropTypes.string,
      dateOfBirth: PropTypes.string,
      dateOfDeath: PropTypes.string,
      maritalStatus: PropTypes.string,
      occupation: PropTypes.string,
      nationalInsuranceNumber: PropTypes.string,
      phones: PropTypes.shape({
        mobilePhone: PropTypes.string,
        homePhone: PropTypes.string,
      }),
      emails: PropTypes.shape({
        workEmail: PropTypes.string,
        homeEmail: PropTypes.string,
      }),
      refId: PropTypes.string,
    }),
    changedFields: PropTypes.objectOf(String),
    fields: PropTypes.objectOf(String),
    updated: PropTypes.bool,
    updating: PropTypes.bool,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  fetchCustomer: PropTypes.func.isRequired,
  setCustomerField: PropTypes.func.isRequired,
  updateCustomer: PropTypes.func.isRequired,
};

CustomerEdit.defaultProps = {
  customer: {
    data: {
      id: null,
      title: '',
      firstName: '',
      middleName: '',
      lastName: '',
      dateOfBirth: '',
      dateOfDeath: '',
      maritalStatus: '',
      occupation: '',
      nationalInsuranceNumber: '',
      phones: {
        mobilePhone: '',
        homePhone: '',
      },
      emails: {
        workEmail: '',
        homeEmail: '',
      },
    },
    updated: false,
  },
};

const mapStateToProps = (state) => ({
  customer: state.customer,
  browser: state.browser,
});

const mapDispatchToProps = {
  fetchCustomer,
  setCustomerField,
  updateCustomer,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerEdit);
