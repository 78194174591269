import React from "react";
import { Route, Switch } from "react-router-dom";

import Layout from "../../components/Layout";
import RefreshEmail from "./containers/RefreshEmail";

import { refreshEmailPath } from "utils/paths";

const NewEmail = () => {
  return (
    <Layout.Auth>
      <Switch>
        <Route exact path={refreshEmailPath} component={RefreshEmail} />
      </Switch>
    </Layout.Auth>
  );
};

export default NewEmail;
