import axios from "axios";
import { decamelizeKeys } from "humps";
import { messagePath } from "utils/paths";

export const sendMessage = obj => {
  return axios.post(messagePath, decamelizeKeys(obj)).then(
    data => {
      return data;
    },
    err => {
      return false;
    }
  );
};
