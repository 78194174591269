export const headerLinks = [
  {
    id: 1,
    pathTo: "",
    value: "Details"
  },
  {
    id: 2,
    pathTo: "claims",
    value: "Claims"
  },
  {
    id: 3,
    pathTo: "documents",
    value: "Documents"
  }
];

export default headerLinks;
