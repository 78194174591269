import React from 'react';
import PropTypes from 'prop-types';

const Tick = ({
  width = 17,
  height = 17,
  className = '',
  fill = '#CFD3D9',
  style = {},
}) => (
  <div className={`la-icon icon-svg-tick ${className}`} style={style}>
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 45 45"
    >
      <g>
        <g>
          <path
            fill={fill}
            d="M20.687,38.332c-2.072,2.072-5.434,2.072-7.505,0L1.554,26.704c-2.072-2.071-2.072-5.433,0-7.504
			c2.071-2.072,5.433-2.072,7.505,0l6.928,6.927c0.523,0.522,1.372,0.522,1.896,0L36.642,7.368c2.071-2.072,5.433-2.072,7.505,0
			c0.995,0.995,1.554,2.345,1.554,3.752c0,1.407-0.559,2.757-1.554,3.752L20.687,38.332z"
          />
        </g>
      </g>
    </svg>
  </div>
);

Tick.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default Tick;
