import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import Layout from '../../components/Layout';
import SignIn from './containers/SignIn';
import Recovery from './containers/Recovery';
import RecoverySuccess from './containers/RecoverySuccess';
import Help from './containers/Help';

import { signInPath, recoveryPath, recoverySuccessPath, helpPath } from './utils/paths';
import { isThompsons } from 'utils/helpers';

import './styles.scss';

class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Layout.Auth>
        <Switch>
          <Route exact path={signInPath} component={SignIn} />
          <Route exact path={recoveryPath} component={Recovery} />
          <Route exact path={recoverySuccessPath} component={RecoverySuccess} />
          {isThompsons() && <Route exact path={helpPath} component={Help} />}
        </Switch>
      </Layout.Auth>
    );
  }
}

export default Auth;
