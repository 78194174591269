export const fieldsOptions = [
  {
    id: 1,
    name: 'Claim type',
    value: 'claim_type_name',
  },
  {
    id: 2,
    name: 'Claim ref',
    value: 'ref_id',
  },
  {
    id: 3,
    name: 'External ref',
    value: 'external_ref',
  },
  {
    id: 4,
    name: 'Customer',
    value: 'users_roles_name_eq=customer&users_full_name',
  },
  {
    id: 5,
    name: 'Status',
    value: 'status',
  },
  {
    id: 6,
    name: 'Agent',
    value: 'users_roles_name_eq=agent&users_full_name',
  },
  {
    id: 7,
    name: 'Created',
    value: 'Created',
  },
  {
    id: 8,
    name: 'Modified',
    value: 'Modified',
  },
  {
    id: 9,
    name: 'Archived',
    value: 'archived',
  },
];

export const filterOptions = [
  {
    id: 1,
    name: 'Contains',
    value: 'cont',
  },
  {
    id: 2,
    name: 'Does not contain',
    value: 'not_cont',
  },
  {
    id: 3,
    name: 'Equals',
    value: 'ci_eq',
  },
  {
    id: 4,
    name: 'Does not equal',
    value: 'not_eq',
  },
  {
    id: 5,
    name: 'Ends with',
    value: 'end',
  },
  {
    id: 6,
    name: 'Does not end with',
    value: 'not_end',
  },
];

export const filterOptionsForStatus = [
  {
    id: 1,
    name: 'Equals',
    value: 'eq',
  },
  {
    id: 2,
    name: 'Does not equal',
    value: 'not_eq',
  },
];

export const filterOptionsForArchived = [
  {
    id: 1,
    name: 'Claims',
    value: 'eq',
  },
  // {
  //   id: 2,
  //   name: "Tasks",
  //   value: "not_eq"
  // }
];

export const filterOptionsValuesForStatus = [
  {
    id: 1,
    name: 'Open',
    value: 'open',
  },
  {
    id: 2,
    name: 'Closed',
    value: 'closed',
  },
  {
    id: 3,
    name: 'Declined',
    value: 'declined',
  },
  {
    id: 4,
    name: 'Stopped',
    value: 'stopped',
  },
  {
    id: 5,
    name: 'Completed',
    value: 'completed',
  },
  {
    id: 6,
    name: 'Enquiry accepted',
    value: 'enquiry_accepted',
  },
  {
    id: 7,
    name: 'Enquiry declined',
    value: 'enquiry_declined',
  },
  {
    id: 8,
    name: 'Lead converted',
    value: 'lead_converted',
  },
  {
    id: 9,
    name: 'Sar sent',
    value: 'sar_sent',
  },
  {
    id: 10,
    name: 'Loss adjuster instructed',
    value: 'loss_adjuster_instructed',
  },
];

export const filterOptionsValuesForArchived = [
  {
    id: 1,
    name: 'Yes',
    value: true,
  },
  {
    id: 2,
    name: 'No',
    value: false,
  },
];

export const createdFilterOptions = [
  {
    id: 1,
    name: 'After',
    value: 'created_at_gteq',
  },
  {
    id: 2,
    name: 'Before',
    value: 'created_at_lteq',
  },
];
export const updatedFilterOptions = [
  {
    id: 1,
    name: 'After',
    value: 'updated_at_gteq',
  },
  {
    id: 2,
    name: 'Before',
    value: 'updated_at_lteq',
  },
];

export const permittedTmpDomains = [
  { id: 0, value: '@thompsons.law.co.uk' },
  { id: 1, value: '@thompsons.law' },
];

export const STATUSES = {
  completed: 'completed',
  completed_form: 'completed_form',
  closed: 'closed',
  open: 'open',
  archived: 'archived',
};

export const ROLES = {
  agent: 'agent',
  customer: 'customer',
  admin: 'admin',
  supervisor: 'supervisor',
  user: 'user',
};

export const CLAIM_STATUSES = {
  open: 'open',
  closed: 'closed',
  declined: 'declined',
  stopped: 'stopped',
  completed: 'completed',
  enquiry_accepted: 'enquiry_accepted',
  enquiry_declined: 'enquiry_declined',
  lead_converted: 'lead_converted',
};

export const TASK_STATUSES = {
  inProgress: 'in_progress',
};

export const ENTITIES = {
  accessKey: 'accessKey',
  answerOption: 'answerOption',
  claim: 'claim',
  claimType: 'claimType',
  companyIntegration: 'companyIntegration',
  contact: 'contact',
  deliveredNotification: 'deliveredNotification',
  detail: 'detail',
  document: 'document',
  documentTemplate: 'documentTemplate',
  form: 'form',
  formType: 'formType',
  image: 'image',
  imageTask: 'imageTask',
  integration: 'integration',
  integrationSetting: 'integrationSetting',
  linkedClaim: 'linkedClaim',
  metadataDocument: 'metadataDocument',
  notificationTemplate: 'notificationTemplate',
  organisation: 'organisation',
  person: 'person',
  question: 'question',
  role: 'role',
  trustlyPayment: 'trustlyPayment',
  user: 'user',
};

export const PERMISSION_TYPE = {
  create: 'create',
  read: 'read',
  update: 'update',
  delete: 'delete',
};

export const LINK_ENTITY_NAME = {
  tasks: 'Tasks',
  details: 'Details',
  contacts: 'Contacts',
  claims: 'Claims',
  documents: 'Documents',
  correspondence: 'Correspondence',
  people: 'People',
  organisations: 'Organisations',
  linkedClaims: 'Linked Claims',
};

export const DEFAULT_COLOR = '#050e3b';

export const ALLOWED_DOCUMENT_FILE_TYPES = [
  'pdf',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'csv',
  'msword',
];

export const ALLOWED_VIDEO_FILE_TYPES = [
  '3gpp',
  'mp4',
  'mpeg',
  'mov',
  'ogg',
  'quicktime',
  'webm',
  'x-m4v',
  'ms-asf',
  'x-ms-wmv',
  'x-ms',
];

export const ALLOWED_IMAGE_FILE_TYPES = ['gif', 'jpeg', 'jpg', 'png', 'heic'];

export const ALLOWED_FILE_TYPES = [
  ...ALLOWED_DOCUMENT_FILE_TYPES,
  ...ALLOWED_IMAGE_FILE_TYPES,
  ...ALLOWED_VIDEO_FILE_TYPES,
];

export const ANSWER_TYPES = {
  number: 'number',
  cardVerification: 'card_verification',
  association: 'association',
  text: 'text',
  document: 'document',
  picture: 'picture',
  pictures: 'pictures',
  video: 'video',
  date: 'date',
  birthDate: 'birth_date',
  time: 'time',
  phone: 'phone',
  payment: 'payment',
  idVerification: 'id_verification',
  videoThumbnail: 'videoThumbnail',
  list: 'list',
  listCheckbox: 'list_checkbox',
  address: 'address',
  checkbox: 'checkbox',
  bdeo: 'bdeo',
  name: 'name',
  drivingLicence: 'driving_licence',
  voice: 'voice',
  mediaUpload: 'media_upload',
  fileUpload: 'file_upload',
  filteredSearch: 'filtered_search',
  message: 'message',
};

export const INPUT_TYPES = {
  text: 'text',
  number: 'number',
};

export const SELECTED_CLAIMS_LIMIT = 10;

export const LOCATION_SERVICE = {
  googleMaps: 'google_maps',
  craftyClicks: 'crafty_clicks',
};

export const SETTING_NAMES = {
  disableAutosaveFunctionality: 'disableAutosaveFunctionality',
  filterClaims: 'filterClaims',
  filterOrganisations: 'filterOrganisations',
};

export const CORRESPONDENCE_TYPE = {
  sms: 'SMS',
  email: 'Email',
};

export const CORRESPONDENCE_EVENT_TYPE = {
  delivered: 'delivered',
  opened: 'opened',
  clicked: 'clicked',
  rejected: 'rejected',
  softBounced: 'soft_bounced',
  hardBounced: 'hard_bounced',
  sent: 'sent',
  undelivered: 'undelivered',
};

export const FILE_TYPES = {
  image: 'image',
  video: 'video',
  document: 'document',
  fileUpload: ANSWER_TYPES.fileUpload,
};

export const GOOGLE_MAPS_NEW_KEY_URLS = [
  'pentest.portal.claimtechnology.co.uk',
];

export const AUTO_LOGOUT_TIME = 30 * 60000; // 30 minutes

export const BUTTON_TYPES = {
  delete: 'delete',
  archive: 'archive',
  duplicate: 'duplicate',
  switchAgent: 'switchAgent',
  linkClaims: 'linkClaims',
};

export const PUZZLE_URL = 'https://euwa.puzzel.com/loader/index.js';

export const SGDomains = ['demo', 'sandgresponse'];

export const COMPANY = {
  sandgresponse: 'sandgresponse',
  test: 'test',
  eversure: 'eversure',
};

export const SYMBOLS_LIMIT = 5000;

export const SHOW_SYMBOLS_COUNTER_LIMIT = 4000;
