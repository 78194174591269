import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const ButtonGroup = ({ className = '', children = null, type = '' }) => {
  const groupType = type ? `button-group-${type}` : '';
  return (
    <nav className="button-group-wrapper">
      <ul className={`button-group ${className} ${groupType}`}>
        {(children.length > 0 &&
          children.map((item) => (
            <li
              key={children.indexOf(item)}
              className="button-group-item-wrapper"
            >
              {item}
            </li>
          ))) || <li className="button-group-item-wrapper">{children}</li>}
      </ul>
    </nav>
  );
};

ButtonGroup.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.node,
};

export default ButtonGroup;
