const generateValidation = password => ({
  lastName: [
    {
      type: "isRequired",
      message: "Please enter the last name."
    }
  ],
  firstName: [
    {
      type: "isRequired",
      message: "Please enter the first name."
    }
  ],
  email: [
    {
      type: "isRequired",
      message: "Please enter the email."
    },
    {
      type: "email",
      message: "Please provide a valid email address."
    }
  ],
  companyDomainUrl: [
    {
      type: "isRequired",
      message: "Please enter the company name."
    }
  ],
  confirmPassword: [
    {
      type: "isEquivalent",
      message: "Passwords don't match",
      condition: password
    }
  ]
});

export default generateValidation;
