import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';
import initHelpHero from 'helphero';
import { Loader } from '@googlemaps/js-api-loader';

import './styles.scss';

import Tasks from 'pages/Tasks';
import Customers from 'pages/Customers';
import Claims from 'pages/Claims';
import Account from 'pages/Account';
import ReusableComponents from 'pages/ReusableComponents';
import People from 'pages/People';
import Organizations from 'pages/Organization';
import Fingerprints from 'pages/Fingerprints';

/* AUTH */
import isAuthenticated, { authRoles } from 'components/Auth';
import Ring from 'components/Ring';
import {
  isCustomerRole,
  isThompsons,
  isAdminRole,
  isUnionMemberRole,
  isSupervisorRole,
  isAgentRole,
  isAdmiral,
  runPuzzleChat,
  getExternalRef,
} from 'utils/helpers';

import { fetchClaims, fetchClaim } from 'actions/claim';
import { endSession, deleteSession } from 'actions/session';

import IdleTimer from 'react-idle-timer';

/* PATH */
import {
  rootAppPath,
  tasksAppPath,
  accountAppPath,
  organizationsAppPath,
  customersAppPath,
  claimsAppPath,
  ReusableComponentsPath,
  peoplesAppPath,
  loginAppPath,
  fingerprintsAppPath,
} from 'utils/paths';
import config from 'utils/config';
import ErrorBoundary from './Layout/ErrorBoundary';
import { AUTO_LOGOUT_TIME, GOOGLE_MAPS_NEW_KEY_URLS } from 'utils/constants';

// const Tasks = lazy(() => import('pages/Tasks'));
// const Customers = lazy(() => import('pages/Customers'));
// const Claims = lazy(() => import('pages/Claims'));
// const Account = lazy(() => import('pages/Account'));
// const ReusableComponents = lazy(() => import('pages/ReusableComponents'));
// const People = lazy(() => import('pages/People'));
// const Organizations = lazy(() => import('pages/Organization'));
// const Fingerprints = lazy(() => import('pages/Fingerprints'));

class AuthRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideLoader: false,
      shouldUpdate: true,
      query: {
        filters: {},
        search: {
          list: {},
          value: '',
          role: '',
        },
        page: this.props.claim.pageCurrent || 1,
        perPage: 20,
        sort: {
          field: '',
          order: '',
        },
      },
    };
    this.idleTimer = null;
  }

  logOut = () => {
    this.props.deleteSession();
    this.props.endSession();
    this.props.history.push(loginAppPath);
    window.location.reload();
  };

  componentDidMount() {
    const { fetchClaims, session, claim } = this.props;
    const { query } = this.state;
    const { data } = session;
    const { roles, showDesignTour, id } = data;
    if (!isCustomerRole(roles) && showDesignTour) {
      let hlp = initHelpHero(config.helpHeroId);
      hlp.anonymous({
        isPortal: true,
        isNotCustomer: !isCustomerRole(roles),
      });
    }

    if (!claim.fetched && Object.keys(claim.data).length === 0) {
      if (isThompsons()) {
        if (isAdminRole(roles) || isUnionMemberRole(roles)) {
          fetchClaims(query, [
            {
              variable: 'status',
              filter: 'eq',
              filterValue: 'open',
            },
          ]).then(() => {
            this.hideLoader();
          });
        } else {
          fetchClaims(query, [
            {
              variable: 'users_id',
              filter: 'eq',
              filterValue: id + '',
            },
          ]).then(() => {
            this.hideLoader();
          });
        }
      } else if (
        isAdminRole(roles) ||
        isUnionMemberRole(roles) ||
        isSupervisorRole(roles) ||
        (isAgentRole(roles) && isAdmiral())
      ) {
        fetchClaims(query, [
          {
            variable: 'status',
            filter: 'eq',
            filterValue: 'open',
          },
        ]).then(() => {
          this.hideLoader();
        });
      } else {
        fetchClaims(query).then((res) => {
          this.hideLoader();
        });
      }
    } else {
      this.hideLoader();
    }

    const { hostname } = window.location;
    const googleApiKey =
      hostname === GOOGLE_MAPS_NEW_KEY_URLS.find((url) => url === hostname)
        ? // hostname === 'pentest.portal.claimtechnology.co.uk'
          config.googleMapsKeyNew
        : config.googleMapsKey;

    const loader = new Loader({
      apiKey: googleApiKey,
      version: 'weekly',
      libraries: ['places'],
    });

    loader
      .importLibrary('places')
      .then(async () => {
        console.log('Google Maps API loaded');
      })
      .catch((e) => {
        console.log('During loading Google Maps API an error happened', e);
      });

    const sessionData =
      session?.data && Object.keys(session?.data) ? session?.data : null;

    if (sessionData) {
      const partners = sessionData.partners;
      const companyExternalRef = getExternalRef(partners);

      if (companyExternalRef) {
        runPuzzleChat({
          configId: companyExternalRef,
        });
      }
    }
  }

  hideLoader = () => {
    this.setState({
      hideLoader: true,
    });
  };

  shouldComponentUpdate() {
    return this.state.shouldUpdate ? true : false;
  }

  componentDidUpdate() {
    const { props, state, handlePropsChanges } = this;
    if (handlePropsChanges(props, state)) {
      this.setState({
        shouldUpdate: false,
      });
    }
  }

  handlePropsChanges = (props, state) => {
    const { roles } = props.session.data;
    const { hideLoader } = state;
    const { fetchedOne: fetchedOneClaim } = props.claim;
    const { fetchedOne, created } = props.session;
    const isSessionExist = fetchedOne || created;
    const claimForCustomerFetched =
      isCustomerRole(roles) || fetchedOneClaim || hideLoader;

    if (claimForCustomerFetched && isSessionExist && hideLoader) {
      return true;
    }
    return false;
  };

  render() {
    const { list } = this.props.claim;
    const { redirectUrl, data } = this.props.session;
    const { roles } = data;

    const renderRouter = this.handlePropsChanges(this.props, this.state);

    const redirectPath = isThompsons()
      ? isCustomerRole(roles)
        ? list && list.length > 0
          ? list.length > 1
            ? redirectUrl
              ? redirectUrl
              : claimsAppPath
            : redirectUrl
            ? redirectUrl
            : `${claimsAppPath}/${list[0].id}`
          : redirectUrl
          ? redirectUrl
          : claimsAppPath
        : redirectUrl
        ? redirectUrl
        : claimsAppPath
      : list && list.length > 0
      ? list.length > 1
        ? redirectUrl
          ? redirectUrl
          : claimsAppPath
        : redirectUrl
        ? redirectUrl
        : `${claimsAppPath}/${list[0].id}/tasks`
      : redirectUrl
      ? redirectUrl
      : tasksAppPath;
    // const TasksPage = isAuthenticated(Tasks, authRoles.user);
    // const CustomersPage = isAuthenticated(Customers, authRoles.user);
    // const OrganizationsPage = isAuthenticated(Organizations, authRoles.user);
    // const PeoplePage = isAuthenticated(People, authRoles.user);
    // const ClaimsPage = isAuthenticated(Claims, authRoles.user);

    return renderRouter ? (
      <React.Fragment>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          timeout={AUTO_LOGOUT_TIME}
          onIdle={this.logOut}
          debounce={250}
        />
        <Router>
          <ErrorBoundary>
            <Switch>
              <Redirect from="/" exact to={redirectPath} />
              <Route
                exact
                path={rootAppPath}
                component={isAuthenticated(Tasks, authRoles.user)}
              />
              <Route
                path={tasksAppPath}
                component={isAuthenticated(Tasks, authRoles.user)}
              />
              <Route
                path={customersAppPath}
                component={isAuthenticated(Customers, authRoles.user)}
              />
              <Route
                path={organizationsAppPath}
                component={isAuthenticated(Organizations, authRoles.user)}
              />
              <Route
                path={peoplesAppPath}
                component={isAuthenticated(People, authRoles.user)}
              />
              <Route
                path={claimsAppPath}
                component={isAuthenticated(Claims, authRoles.user)}
              />
              <Route path={accountAppPath} component={Account} />
              <Route path={fingerprintsAppPath} component={Fingerprints} />
              <Route
                path={ReusableComponentsPath}
                component={ReusableComponents}
              />
            </Switch>
            {/* <Suspense fallback={<Ring />}>
              <Switch>
                <Redirect from="/" exact to={redirectPath} />
                <Route
                  exact
                  path={rootAppPath}
                  component={(props) => <TasksPage {...props} />}
                />
                <Route
                  path={tasksAppPath}
                  component={(props) => <TasksPage {...props} />}
                />
                <Route
                  path={customersAppPath}
                  component={(props) => <CustomersPage {...props} />}
                />
                <Route
                  path={organizationsAppPath}
                  component={(props) => <OrganizationsPage {...props} />}
                />
                <Route
                  path={peoplesAppPath}
                  component={(props) => <PeoplePage {...props} />}
                />
                <Route
                  path={claimsAppPath}
                  component={(props) => <ClaimsPage {...props} />}
                />
                <Route
                  path={accountAppPath}
                  component={(props) => <Account {...props} />}
                />
                <Route
                  path={fingerprintsAppPath}
                  component={(props) => <Fingerprints {...props} />}
                />
                <Route
                  path={ReusableComponentsPath}
                  component={(props) => <ReusableComponents {...props} />}
                />
              </Switch>
            </Suspense> */}
          </ErrorBoundary>
        </Router>
      </React.Fragment>
    ) : (
      <Ring />
    );
  }
}

const mapStateToProps = ({ session, claim }) => ({
  session,
  claim,
});

const mapDispatchToProps = {
  fetchClaims,
  fetchClaim,
  endSession,
  deleteSession,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthRouter);
