import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Icons from 'common/Icons';
import { DEFAULT_COLOR } from 'utils/constants';

import './styles.scss';

class NewEntityPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };

    this.node = null;
  }

  onBodyClick = (e) => {
    e.stopPropagation();
  };

  getContainerRef = (node) => {
    this.node = node;
  };

  togglePopup = (e) => {
    e.stopPropagation();
    this.props.onRowPupupClick(this.props.id);

    const { isOpen } = this.state;

    if (!isOpen) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState({ isOpen: !isOpen });
  };

  handleOutsideClick = (e) => {
    if (this.node && this.node.contains(e.target)) {
      return;
    }

    this.togglePopup(e);
  };

  render() {
    const {
      wrapperClass,
      title,
      content,
      cellSpan,
      primaryColor,
      alreadyHasRef,
      additionalClassName,
    } = this.props;
    const { isOpen } = this.state;
    const cellSpanColor = alreadyHasRef ? primaryColor : DEFAULT_COLOR;
    const cellSpanContent = alreadyHasRef ? (
      <span style={{ color: cellSpanColor }}>{cellSpan}</span>
    ) : (
      cellSpan
    );

    return (
      <Fragment>
        <button
          className="flex items-center justify-center table-popup-handler"
          onClick={this.togglePopup}
        >
          {!alreadyHasRef && (
            <Icons
              className="flex justify-center margin-10-right wrapped-round"
              fill={primaryColor || '#e73055'}
              iconName="plus"
            />
          )}
          {cellSpanContent}
        </button>
        {isOpen && (
          <div
            ref={this.getContainerRef}
            onClick={this.onBodyClick}
            className={`popup flex direction-column popup-wrapper ${wrapperClass} ${additionalClassName}`}
            role="button"
            tabIndex={0}
          >
            <div className="popup-inner">
              <h4>{title}</h4>

              <div className="popup-inner-content">{content}</div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

NewEntityPopup.propTypes = {
  wrapperClass: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.node,
  cellSpan: PropTypes.string,
  id: PropTypes.number,
  onRowPupupClick: PropTypes.func,
  primaryColor: PropTypes.string,
  alreadyHasRef: PropTypes.bool,
  additionalClassName: PropTypes.string,
};

NewEntityPopup.defaultProps = {
  wrapperClass: '',
  title: '',
  content: null,
  cellSpan: '',
  id: null,
  onRowPupupClick: null,
  primaryColor: '',
  alreadyHasRef: false,
  additionalClassName: '',
};

export default NewEntityPopup;
