import React, { Fragment } from 'react';

import Icons from 'common/Icons';
import { peoplesAppPath, organizationsAppPath } from 'utils/paths';

import './styles.scss';

export const Suggestion = (item, onSuggestionClick) => {
  return (
    <button
      className="suggestions-list-item flex justify-between items-center"
      key={item.id}
      onClick={() => onSuggestionClick({ ...item, id: item.contactableId })}
    >
      <div className="suggestions-left flex justify-center items-center">
        <span>{item.name}</span>
      </div>
    </button>
  );
};

export const PopupSuggestion = (item, onSuggestionClick) => (
  <button
    className="suggestions-list-item windowed flex justify-between items-center"
    key={item.id}
    onClick={() => onSuggestionClick(item)}
  >
    <div className="suggestions-left flex justify-center items-center">
      <span>{item.name}</span>
    </div>
  </button>
);

export const SuggestionSelected = (
  suggestion,
  onLinkClick,
  onDeleteClick,
  label,
  labelInside,
  isRequired,
  hideRedDot,
  fullWidth = false
) => (
  <Fragment>
    {!labelInside && !hideRedDot && (
      <label className="form-control-label">
        <span>{label}</span>
        {isRequired && <sup className="text-color-danger">*</sup>}
      </label>
    )}
    <div className="form-control-wrapper-label-inside">
      <div className="suggestions-selected flex justify-between items-center form-control-label-animate form-control-valid">
        <div
          className={`suggestions-left flex justify-center items-center ${
            fullWidth ? 'full-width' : null
          }`}
        >
          {/* <div className="suggestions-left flex justify-center items-center "> */}
          <input
            value={suggestion.name}
            className="form-control suggestion-input"
            disabled
          />
          <label className="form-control-label">{label}</label>
        </div>
        <div className="suggestions-right flex justify-center items-center">
          <button
            onClick={() => {
              if (suggestion.contactableType === 'Person')
                onLinkClick(`${peoplesAppPath}/${suggestion.id}`);
              else {
                onLinkClick(`${organizationsAppPath}/${suggestion.id}`);
              }
            }}
            className="suggestion-link"
          >
            <Icons iconName="link" />
          </button>
          <button onClick={onDeleteClick}>
            <Icons iconName="close" />
          </button>
        </div>
      </div>
    </div>
  </Fragment>
);
