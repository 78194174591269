export const setPassValidations = {
  password: [
    {
      type: "isRequired",
      message: "Please enter the password."
    },
    // {
    //   type: "thompsonsPassword",
    //   message: "Password should contain uppercase, lowercase and numeric characters."
    // },
  ],
  confirmPassword: [
    {
      type: "isRequired",
      message: "Please confirm the password."
    },
    {
      type: "isSame",
      message: "The passwords don\u0027t match."
    },
    // {
    //   type: "thompsonsPassword",
    //   message: "Password should contain uppercase, lowercase and numeric characters."
    // }
  ]
};

export default setPassValidations;
