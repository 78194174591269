import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Select from './select';

import './style.scss';

class MultiSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.selectValue,
      options: props.options,
      placeholder: props.placeholder,
    };

    if (props.options && props.options.length === 1 && this.props.onChange) {
      this.props.onChange(props.options[0].value, props.name);
      this.state.value = props.options[0].value;
    }
  }

  componentDidMount() {
    const { initialValue } = this.props;

    if (initialValue && Object.keys(initialValue).length > 0) {
      this.setInitialValue(initialValue);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { initialValue } = this.props;

    if (
      initialValue &&
      Object.keys(initialValue).length > 0 &&
      !prevState.value
    ) {
      this.setInitialValue(initialValue);
    }
  }

  onChange = (e) => {
    this.setState({ value: e.target.value });
    this.props.onChange(e.target.value, e.target.name);
  };

  setInitialValue = (initialValue) => {
    this.setState({ value: initialValue });
  };

  updateValue = (value, name) => {
    this.setState({ value });
    this.props.onChange(value, name);
  };

  setHTML = (label, isRequired, subLabel) => {
    const sup = isRequired ? '<sup className="text-color-gray">*</sup>' : '';
    const subLabelEl = subLabel
      ? `<span className="sub-label">${subLabel}</span>`
      : '';
    return {
      __html: `<div class="pointer-without-events"><span>${label}<span>${sup}${subLabelEl}</div>`,
    };
  };

  render() {
    const { selectValue, wrapperClass, labelInside, style, newDesign } =
      this.props;
    const { value } = this.state;
    const activeClass =
      (value && Object.keys(value).length > 0) ||
      (selectValue && Object.keys(selectValue).length > 0)
        ? 'control-active'
        : 'control-empty';
    const labelInsideClass = labelInside
      ? 'form-control-select-label-inside'
      : 'form-control-select-label-outside';

    const newDesignStyles = newDesign ? 'new-design-field' : null;

    return (
      <div
        className={`${wrapperClass} form-control-select-wrapper ${activeClass} ${labelInsideClass} ${newDesignStyles}`}
        style={style}
      >
        <Select {...this.props} />
      </div>
    );
  }
}

Select.propTypes = {
  selectId: PropTypes.string,
  selectValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ),
  label: PropTypes.string,
  className: PropTypes.string,
  wrapperClass: PropTypes.string,
  onChange: PropTypes.func,
  isRequired: PropTypes.bool,
  labelInside: PropTypes.bool,
  subLabel: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  errors: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ),
};

Select.defaultProps = {
  selectId: null,
  selectValue: null,
  options: [],
  label: null,
  className: '',
  wrapperClass: null,
  onChange: null,
  isRequired: false,
  labelInside: false,
  subLabel: '',
  disabled: false,
  placeholder: '',
  name: '',
  defaultValue: '',
  errors: [],
  initialValue: '',
};

export default MultiSelect;
