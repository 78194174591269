import React, { Component } from "react";
import PropTypes from "prop-types";

import "./styles.scss";

class Info extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placeholder: props.placeholder
    };
  }

  render() {
    const {
      label,
      className,
      wrapperClass,
      labelInside,
      name,
      id,
      value,
      style
    } = this.props;
    const activeClass =
      label && label.length > 0 && label !== " "
        ? "control-active"
        : "control-empty";
    const labelInsideClass = labelInside
      ? "form-control-select-label-inside"
      : "form-control-select-label-outside";

    return (
      <div
        className={`${wrapperClass} form-control-select-wrapper ${activeClass} ${labelInsideClass}`}
        style={style}
      >
        {!labelInside && (
          <label htmlFor={id} className="form-control-select-label">
            <span className="form-control-select-label-inner">{label}</span>
          </label>
        )}
        <div className="form-control-select-group">
          <div
            id={id}
            value={value || ""}
            className={`form-control form-control-info disabled ${className}`}
            onChange={this.onChange}
            name={name}
          >
            {value || "This information must be completed by the customer."}
          </div>
          {labelInside && (
            <label htmlFor={id} className="form-control-select-label">
              <span className="form-control-select-label-inner">{label}</span>
            </label>
          )}
        </div>
      </div>
    );
  }
}

Info.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  wrapperClass: PropTypes.string,
  labelInside: PropTypes.bool,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.string
};

Info.defaultProps = {
  label: null,
  className: "",
  wrapperClass: null,
  labelInside: false,
  placeholder: "",
  name: "",
  value: "",
  id: ""
};

export default Info;
