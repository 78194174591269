import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import ReactRouterPropTypes from 'react-router-prop-types';
import { get } from 'lodash';

// import Logo from 'components/Logo';
import Well from 'components/Well';
import Form from 'components/Form';
import Button from 'components/Button';

import {
  createSession,
  createPingOneSession,
  fetchPingOneRedirectUrl,
  createAzureSession,
  fetchAzureRedirectUrl,
} from 'actions/session';
import { fetchStaticTexts } from 'actions/staticTexts';
import { unlockAccount } from 'actions/account';

import checkValidations from 'utils/validator';
import {
  isThompsons,
  isHorwich,
  isAdmiral,
  isXceedance,
  setHeroImageStyles,
} from 'utils/helpers';
import config from 'utils/config';

import { recoveryPath, helpPath } from '../utils/paths';
import { signInValidations } from '../utils/validation';
// import tmp_logo from 'assets/images/tmp-logo.png';
import Icons from 'common/Icons';
import { displayLogo, setLoginStyles } from '../utils/helpers';
import XceedanceLogo from 'components/Logos/Xceedance';

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      credentials: {
        email: '',
        password: '',
      },
      errors: {},
      redirectUrl: '',
      companySubdomain: window.location.hostname.split('.')[0],
    };
  }

  componentDidMount() {
    const { companySubdomain } = this.state;

    if (isThompsons()) {
      this.props.fetchStaticTexts();
    }
    let redirectUrl = this.props.location.search.split('?');
    let formattedRedirectUrl = redirectUrl
      .slice(1, redirectUrl.length)
      .join('?');
    this.setState((prev) => ({
      ...prev,
      redirectUrl: formattedRedirectUrl,
    }));

    if (isHorwich()) {
      const { hash } = window.location;

      if (hash) {
        const accessToken = hash.split('=')[1];
        const apiPath = config.apiPath;
        const pingOneLoginPath = `${apiPath}/ping_one?subdomain=${companySubdomain}&access_token=${accessToken}`;

        this.props.createPingOneSession(pingOneLoginPath, formattedRedirectUrl);
      }
    }

    // Azure
    if (isAdmiral()) {
      const { hash } = window.location;

      if (hash) {
        const accessToken = hash.split('=')[1];
        const apiPath = config.apiPath;
        const azureLoginPath = `${apiPath}/azure?subdomain=${companySubdomain}&access_token=${accessToken}`;

        this.props.createAzureSession(
          azureLoginPath,
          formattedRedirectUrl,
          accessToken
        );
      }
    }

    const { search } = window.location;

    let tokenName = search.replace('?', '').split('=')[0];
    let tokenValue = search.replace('?', '').split('=')[1];

    if (tokenName === 'unlock_token') {
      // send a request to unlock account
      this.props.unlockAccount(tokenValue);
      window.location.search = '';
    }
  }

  UNSAFE_componentWillMount() {
    const token = cookie.load('token');

    if (token) this.props.history.push('/');
  }

  componentDidUpdate(prevProps) {
    const { hasErrors, created } = this.props.session;

    if (prevProps.session.creating && created && !hasErrors) {
      this.redirectTo('/');
    }

    if (!prevProps.session.hasErrors && this.props.session.hasErrors) {
      this.clearPassword();
    }
  }

  onChange = (value, key) =>
    this.setState({
      credentials: {
        ...this.state.credentials,
        [key]: value,
      },
      errors: {},
    });

  onClick = () => {
    const { credentials } = this.state;
    const errors = checkValidations(signInValidations, credentials);

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
    } else {
      this.setState({ errors: {} });
      this.props.createSession(credentials, this.state.redirectUrl);
    }
  };

  handleLoginPingOne = () => {
    const apiPath = config.apiPath;
    const getRedirectUrlPath = `${apiPath}/ping_one/get_ping_one_url?subdomain=${this.state.companySubdomain}`;

    this.props.fetchPingOneRedirectUrl(getRedirectUrlPath);
  };

  handleLoginAzure = () => {
    const apiPath = config.apiPath;
    const getRedirectUrlPath = `${apiPath}/azure/get_azure_sso_url?subdomain=${this.state.companySubdomain}`;

    this.props.fetchAzureRedirectUrl(getRedirectUrlPath);
  };

  onKeyPress = (val, name, charCode) => {
    const { credentials } = this.state;
    const errors = checkValidations(signInValidations, credentials);

    if (charCode === 13) {
      if (Object.keys(errors).length > 0) {
        this.setState({ errors });
      } else {
        this.setState({ errors: {} });
        this.props.createSession(credentials);
      }
    }
  };

  forgotCredentialsClick = () => {
    this.props.history.push(recoveryPath);
  };

  onNeedHelpClick = () => {
    this.props.history.push(helpPath);
  };

  redirectTo = (path) => {
    this.props.history.push(path);
  };

  clearPassword = () => {
    this.setState({
      credentials: {
        ...this.state.credentials,
        password: '',
      },
    });
  };

  render() {
    const { credentials, errors } = this.state;
    const { errorText } = this.props.session;
    const { companyLogo, enableFaqButton } = this.props.settings.data;
    const { staticTexts } = this.props;
    const footer_copyright = get(staticTexts.data, 'footer_copyright', '');
    const tmp_legal_text = footer_copyright
      ? footer_copyright
      : 'Place holder for legal text';
    const forgot_credentials = isThompsons()
      ? 'btn-forgot-credentials-tmp'
      : 'btn-forgot-credentials';

    const loginStyles = setLoginStyles({ window });
    const wellStyles = isXceedance() ? 'xceedance-login-well' : null;

    return (
      <Fragment>
        {isXceedance() ? (
          <XceedanceLogo
            className="margin-30-bottom xceedance-logo"
            width={300}
            height={70}
            fullLogo
          />
        ) : null}
        <div className={loginStyles}>
          {displayLogo({ companyLogo })}
          <Fragment>
            <Well size="small" className={`login-well ${wellStyles}`}>
              <Form
                className="form-compact"
                onSubmit={this.onClick}
                formType="login"
                isTmp={isThompsons()}
                fields={[
                  {
                    element: 'input',
                    type: 'text',
                    label: 'Username...',
                    name: 'email',
                    onChange: this.onChange,
                    onKeyPress: this.onKeyPress,
                    labelAnimate: true,
                    errors: errors.email || [],
                    value: credentials.email,
                  },
                  {
                    element: 'input',
                    type: 'password',
                    label: 'Password...',
                    name: 'password',
                    onChange: this.onChange,
                    onKeyPress: this.onKeyPress,
                    labelAnimate: true,
                    errors: errors.password || [],
                    value: credentials.password,
                  },
                ]}
              />
              <Button
                size="large"
                className="landing btn-login"
                value="Login"
                onClick={this.onClick}
              />
              {isThompsons() && (
                <div className="tmp-login-btns-holder">
                  <Button
                    size="large"
                    className="landing btn-tmp-reset-password"
                    value="Reset password"
                    onClick={this.forgotCredentialsClick}
                  />
                  {enableFaqButton && (
                    <Button
                      size="large"
                      className="landing btn-tmp-need-help"
                      value="Need help"
                      onClick={this.onNeedHelpClick}
                    >
                      <Icons
                        iconName="question"
                        fill="#191B36"
                        height={30}
                        width={30}
                        className="remove_person"
                      />
                    </Button>
                  )}
                </div>
              )}
              {isHorwich() && (
                <Button
                  size="large"
                  className="landing btn-login-pingone"
                  value="Login with PingOne"
                  onClick={this.handleLoginPingOne}
                />
              )}
              {/* Azure */}
              {isAdmiral() && (
                <Button
                  size="large"
                  className="landing btn-login-azure"
                  value="Login with Azure"
                  onClick={this.handleLoginAzure}
                />
              )}
              {errorText && (
                <div className="flex justify-center text-color-danger padding-20-top">
                  {errorText}
                </div>
              )}
            </Well>
            {!isThompsons() && (
              <Button
                className={`btn-transparent white-low-opacity ${forgot_credentials}`}
                value="Forgot credentials?"
                onClick={this.forgotCredentialsClick}
              />
            )}
          </Fragment>
          {isThompsons() && (
            <p className="tmp-login-legal-text">{tmp_legal_text}</p>
          )}
        </div>
        {!isThompsons() && (
          <div style={setHeroImageStyles} className="hero-img" />
        )}
      </Fragment>
    );
  }
}

SignIn.propTypes = {
  createSession: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  session: PropTypes.shape({
    created: PropTypes.bool,
    errorText: PropTypes.string,
    hasErrors: PropTypes.bool,
  }).isRequired,
  settings: PropTypes.shape({
    data: PropTypes.shape({
      companyLogo: PropTypes.string,
    }),
  }).isRequired,
};

SignIn.defaultProps = {};

const mapDispatchToProps = {
  createSession,
  fetchStaticTexts,
  createPingOneSession,
  fetchPingOneRedirectUrl,
  unlockAccount,
  createAzureSession,
  fetchAzureRedirectUrl,
};

const mapStateToProps = (state) => ({
  session: state.session,
  settings: state.settings,
  staticTexts: state.staticTexts,
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
