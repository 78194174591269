import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';

/* ACTIONS */
import { fetchSession } from 'actions/session';
import { setToken } from 'actions/auth';

/* UTILS */
import {
  loginAppPath,
  rootAppPath,
  recoveryPath,
  setPasswordPath,
  refreshEmailPath,
  errorPath,
} from 'utils/paths';
import setAuthToken from 'utils/setAuthToken';

const ROLE_GUEST = 'guest';
const ROLE_USER = 'user';

export default function Auth(ComposedComponent, role) {
  const Component = (props) => {
    let token = cookie.load('token');

    if (props.location.search) {
      const searchParams = new URLSearchParams(props.location.search);
      token = searchParams.get('token') || token;
      if (token) {
        setToken(token);
      }
    }

    switch (role) {
      case ROLE_GUEST:
        return <ComposedComponent {...props} />;
      case ROLE_USER:
        if (props.auth.isAuthorized) {
          // Disable auth
          return <ComposedComponent {...props} />;
        }

        if (!props.session.fetchedOne && !props.session.fetchingOne && token) {
          setAuthToken(token);
          props.fetchSession();
          return false;
        }

        if (
          !props.session.fetchingOne &&
          (props.session.fetchedOne || !token)
        ) {
          const { pathname } = props.location;
          const redirectUrl =
            pathname === rootAppPath || pathname === loginAppPath
              ? loginAppPath
              : pathname === '/recovery' ||
                pathname === recoveryPath ||
                pathname === setPasswordPath ||
                pathname === refreshEmailPath ||
                pathname === errorPath
              ? pathname
              : `${loginAppPath}?${pathname}`;
          props.history.push(redirectUrl);
        }

        return false;
      default:
        throw new Error(`${role} is invalid role!`);
    }
  };

  Component.propTypes = {
    fetchSession: PropTypes.func.isRequired,
    history: ReactRouterPropTypes.history.isRequired,
    location: ReactRouterPropTypes.location.isRequired,
    auth: PropTypes.shape({
      isAuthorized: PropTypes.bool,
    }).isRequired,
    session: PropTypes.shape({
      fetchedOne: PropTypes.bool,
      fetchingOne: PropTypes.bool,
    }).isRequired,
  };

  const mapDispatchToProps = {
    fetchSession,
  };

  const mapStateToProps = (state) => ({
    session: state.session,
    auth: state.auth,
  });

  return connect(mapStateToProps, mapDispatchToProps)(withRouter(Component));
}

export const authRoles = {
  guest: ROLE_GUEST,
  user: ROLE_USER,
};
