import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Grid from 'components/Layout/Grid';
import Button from 'components/Button';
import AsideMenu from 'components/AsideMenu';

import { fetchCustomer, clearCustomerData } from 'actions/customer';

import { customersAppPath } from 'utils/paths';

import CustomerForm from '../../../components/CustomerForm';

import { isThompsons } from 'utils/helpers';

class CustomerInfo extends Component {
  componentDidMount() {
    this.props.clearCustomerData();
    this.props.fetchCustomer(this.props.match.params.id);
  }

  render() {
    const { data, fetchingOne } = this.props.customer;
    const { isMobile } = this.props.browser;
    const isTmpMobile = isMobile && isThompsons() ? true : false;
    const customerLoaded = data && Object.keys(data).length > 0;

    return (
      <Fragment>
        <Grid.Main className="main-wrapper-with-aside">
          <Grid.Content>
            <Grid.Controls>
              <div className="">
                <h2 className="h2">
                  {isThompsons() ? 'Client Details' : 'Customer details'}
                </h2>
              </div>
              <div className="">
                {!isThompsons() && (
                  <Button
                    type="success"
                    pathTo={`${customersAppPath}/${this.props.match.params.id}/edit`}
                    value={fetchingOne ? <div className="loader" /> : 'Edit'}
                    className="edit"
                  />
                )}
                {isThompsons() &&
                  (fetchingOne ? <div className="loader" /> : null)}
              </div>
            </Grid.Controls>

            {customerLoaded && (
              <CustomerForm
                disabled
                values={data}
                className="col-lg-8 col-md-10"
                isTmpMobile={isTmpMobile}
              />
            )}
          </Grid.Content>

          <Grid.Aside>
            <AsideMenu id={this.props.match.params.id} />
          </Grid.Aside>
        </Grid.Main>
      </Fragment>
    );
  }
}

CustomerInfo.propTypes = {
  customer: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      firstName: PropTypes.string,
      middleName: PropTypes.string,
      lastName: PropTypes.string,
      dateOfBirth: PropTypes.string,
      dateOfDeath: PropTypes.string,
      maritalStatus: PropTypes.string,
      occupation: PropTypes.string,
      nationalInsuranceNumber: PropTypes.string,
      phones: PropTypes.shape({
        mobilePhone: PropTypes.string,
        homePhone: PropTypes.string,
      }),
      emails: PropTypes.shape({
        workEmail: PropTypes.string,
        homeEmail: PropTypes.string,
      }),
      refId: PropTypes.string,
    }),
    fetchingOne: PropTypes.bool,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  fetchCustomer: PropTypes.func.isRequired,
  clearCustomerData: PropTypes.func.isRequired,
};

CustomerInfo.defaultProps = {
  customer: {
    id: null,
    title: '',
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: '',
    dateOfDeath: '',
    maritalStatus: '',
    occupation: '',
    nationalInsuranceNumber: '',
    phones: {
      mobilePhone: '',
      homePhone: '',
    },
    emails: {
      workEmail: '',
      homeEmail: '',
    },
  },
};

const mapStateToProps = (state) => ({
  customer: state.customer,
  session: state.session,
  browser: state.browser,
});

const mapDispatchToProps = {
  fetchCustomer,
  clearCustomerData,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerInfo);
