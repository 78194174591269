import React from 'react';
import PropTypes from 'prop-types';

const Star = ({
  width = 17,
  height = 16,
  className = '',
  fill = '#CFD3D9',
}) => (
  <div className={`la-icon icon-svg-star ${className}`}>
    <svg width={width} height={height} viewBox="0 0 17 16">
      <path
        fill={fill}
        fillRule="nonzero"
        d="M9.008.264l2.134 5.05 5.464.47a.43.43 0 0 1 .245.755l-4.144 3.59 1.242 5.342a.43.43 0 0 1-.642.465L8.61 13.105l-4.695 2.831a.43.43 0 0 1-.642-.465l1.242-5.342L.371 6.538a.43.43 0 0 1 .245-.755l5.464-.47L8.214.264a.43.43 0 0 1 .794 0z"
      />
    </svg>
  </div>
);

Star.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default Star;
