import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const Well = ({ className = '', children, gutter, size = '' }) => (
  <div
    className={`
        well ${className}
        ${gutter ? `padding-${gutter}` : ''}
        ${size ? `well-${size}` : ''}
    `}
  >
    {children}
  </div>
);

Well.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  gutter: PropTypes.number,
  children: PropTypes.node,
};

export default Well;
