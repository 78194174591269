import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';

import GoogleMapReact from 'google-map-react';

import InputErrors from '../InputErrors';

import './styles.scss';

const MapComponent = ({ text }) => (
  <div className="map-component">
    <span className="point-text">{text}</span>
  </div>
);

class GoogleAutocomplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: props.value || '',
      addressResult: {},
      showMap: props.isShowMap,
    };
  }

  componentDidUpdate() {
    const { address, addressResult, showMap } = this.state;

    if (address && Object.keys(addressResult).length === 0 && showMap) {
      geocodeByAddress(address).then((results) => {
        this.setState({ addressResult: results[0], showMap: false }, () => {
          this.setState({ showMap: true });
        });
      });
    }
  }

  onBlur = () => {
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  };

  onChange = (address) => {
    this.setState({ address });
  };

  onKeyPress = (e) => {
    if (e.charCode === 13) {
      this.props.onChange(e.target.value, e.target.name);
      return;
    }

    if (this.props.onKeyPress) {
      this.props.onKeyPress(e.target.value, e.target.name, e.charCode);
    }
  };

  handleSelect = (address) => {
    const { name } = this.props;

    this.setState({ address });
    this.props.onChange(address, name);

    geocodeByAddress(address).then((results) => {
      this.setState({ addressResult: results[0], showMap: false }, () => {
        this.setState({ showMap: true });
      });
    });
  };

  render() {
    const {
      name,
      mandatory,
      className,
      wrapperClass,
      isRequired,
      errors,
      disabled,
      labelAnimate,
      inputType,
      labelInside,
      value,
      style,
      newDesign,
    } = this.props;
    let label = mandatory ? `${this.props.label} *` : this.props.label;

    const { address, addressResult, showMap } = this.state;

    const activeClass =
      (labelInside || labelAnimate) && value && value.length > 0
        ? 'form-control-valid'
        : 'form-control-empty';
    const labelWrapperClass =
      labelInside || labelAnimate
        ? 'form-control-label-animate'
        : 'form-control-label-static';
    const errorArray = this.state.errors || errors;
    const formControlType = inputType ? `form-control-${inputType}` : '';
    const errorClass =
      errorArray && errorArray.length > 0 ? 'has-error' : 'no-error';
    const labelInsideClass = labelInside
      ? 'form-control-wrapper-label-inside'
      : '';
    const newDesignStyles = newDesign ? 'new-design-google-field' : null;
    const newDesignLabel = newDesign
      ? 'new-design-google-autocomplete-label'
      : null;

    const newDesignPromptStyles = newDesign ? 'new-design-prompt' : '';
    const inputLabel = label && (
      <label htmlFor={name} className={`form-control-label ${newDesignLabel}`}>
        <span className={`${newDesignPromptStyles}`}>{label}</span>
        {isRequired && <sup className="text-color-gray">*</sup>}
      </label>
    );

    return (
      <div
        className={`form-control-wrapper ${wrapperClass} ${
          !labelAnimate && !labelInside
            ? 'form-control-wrapper-label-static'
            : ''
        } ${labelInsideClass} ${newDesignStyles}`}
        style={style}
      >
        {!labelAnimate && !labelInside && inputLabel}

        <PlacesAutocomplete
          value={address}
          onChange={this.onChange}
          onSelect={this.handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div
              className={`form-control-container ${labelWrapperClass} ${activeClass}`}
            >
              <input
                {...getInputProps({
                  className: `form-control ${className} ${formControlType} ${errorClass} ${newDesignLabel}`,
                  disabled,
                })}
              />
              {(labelInside || labelAnimate) && inputLabel}

              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className: 'suggestion-item',
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </PlacesAutocomplete>

        {Object.keys(addressResult).length > 0 && showMap && (
          <div className="map-wrap">
            <GoogleMapReact
              defaultCenter={{
                lat:
                  addressResult.geometry &&
                  addressResult.geometry.location.lat(),
                lng:
                  addressResult.geometry &&
                  addressResult.geometry.location.lng(),
              }}
              defaultZoom={17}
            >
              <MapComponent
                lat={
                  addressResult.geometry &&
                  addressResult.geometry.location.lat()
                }
                lng={
                  addressResult.geometry &&
                  addressResult.geometry.location.lng()
                }
                text={addressResult.formatted_address || address}
              />
            </GoogleMapReact>
          </div>
        )}

        <InputErrors errors={errorArray} />
      </div>
    );
  }
}

GoogleAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  inputType: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.string,
  className: PropTypes.string,
  wrapperClass: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  errors: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ),
  labelAnimate: PropTypes.bool,
  labelInside: PropTypes.bool,
  withDelay: PropTypes.bool,
  isShowMap: PropTypes.bool,
};

GoogleAutocomplete.defaultProps = {
  value: '',
  label: null,
  className: null,
  wrapperClass: null,
  isRequired: false,
  errors: [],
  disabled: false,
  labelAnimate: false,
  labelInside: false,
  inputType: '',
  onChange: null,
  onKeyPress: null,
  onBlur: null,
  withDelay: false,
  isShowMap: false,
};

export default GoogleAutocomplete;
