import React from 'react';
import PropTypes from 'prop-types';

const InfoCircleDark = ({
  width = 24,
  height = 24,
  className = '',
  fill = '#0F2241',
}) => (
  <div className={`la-icon icon-svg-search ${className}`}>
    <svg width={width} height={height} viewBox="0 0 24 24">
      <path
        fill={fill}
        d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm1,15a1,1,0,0,1-2,0V11a1,1,0,0,1,2,0ZM12,8a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,12,8Z"
      />
    </svg>
  </div>
);

InfoCircleDark.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default InfoCircleDark;
