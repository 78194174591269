import React from 'react';
import Icons from 'common/Icons';
import './styles.scss';
import { formatedAcceptedDocumentTypes } from 'utils/helpers';

const MediaUploadEmpty = ({ disabled, loadFile, name }) => (
  <label
    onChange={loadFile}
    className={`form-control-file-wrapper flex justify-center items-center`}
  >
    <input
      type="file"
      id={name}
      className={`form-control form-control-file `}
      name={name}
      disabled={disabled}
      accept={formatedAcceptedDocumentTypes}
    />
    <Icons iconName="plus" fill="#ff6081" height={40} width={40} />
  </label>
);

export default MediaUploadEmpty;
