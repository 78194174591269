import React from 'react';
import PropTypes from 'prop-types';

const Plus = ({
  width = 30,
  height = 30,
  className = '',
  fill = '#3C3835',
}) => (
  <div className={`la-icon icon-svg-plus ${className}`}>
    <svg width={width} height={height} viewBox="0 0 30 30">
      <path
        fill={fill}
        id="a"
        d="M15.941 14.25h3.75v1.5h-3.75v3.75h-1.5v-3.75h-3.75v-1.5h3.75V10.5h1.5v3.75z"
      />
    </svg>
  </div>
);

Plus.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default Plus;
