export const signInValidations = {
  email: [
    {
      type: 'isRequired',
      message: 'Please enter the email.'
    },
    {
      type: 'email',
      message: 'Please provide a valid email address.'
    }
  ],
  password: [
    {
      type: 'isRequired',
      message: 'Please enter the password.'
    },
    // {
    //   type: 'loginPassword',
    //   message: 'Incorrect username or password'
    // }
  ]
};

export const recoveryValidations = {
  email: [
    {
      type: 'isRequired',
      message: 'Please enter the email.'
    },
    {
      type: 'email',
      message: 'Please provide a valid email address.'
    }
  ]
};
