export const listHead = [
  {
    dataField: "refId",
    dataValue: "ID",
    dataSort: true,
    isKey: true
  },
  {
    dataField: "externalRef",
    dataValue: "POLICY REFERENCE",
    dataSort: true,
    isKey: false
  },
  {
    dataField: "firstName",
    dataValue: "NAME",
    dataSort: true,
    isKey: false
  },
  {
    dataField: "lastName",
    dataValue: "SURNAME",
    dataSort: true,
    isKey: false
  },
  {
    dataField: "phone",
    dataValue: "PHONE",
    dataSort: true,
    isKey: false
  },
  {
    dataField: "dateOfBirth",
    dataValue: "DATE OF BIRTH",
    dataSort: true,
    isKey: false
  },
  {
    dataField: "createdAt",
    dataValue: "CREATED",
    dataSort: true,
    isKey: false
  },
  {
    dataField: "updatedAt",
    dataValue: "MODIFIED",
    dataSort: true,
    isKey: false
  }
];

export const customerClaimsHead = [
  {
    dataField: "type",
    dataValue: "CLAIM TYPE",
    dataSort: true,
    isKey: false
  },
  {
    dataField: "internalRef",
    dataValue: "CLAIM REF",
    dataSort: true,
    isKey: true
  },
  {
    dataField: "externalRef",
    dataValue: "POLICY REFERENCE",
    dataSort: true,
    isKey: false
  },
  {
    dataField: "status",
    dataValue: "STATUS",
    dataSort: true,
    isKey: false
  },
  {
    dataField: "userFullName",
    dataValue: "USER",
    dataSort: true,
    isKey: false
  },
  {
    dataField: "createdAt",
    dataValue: "CREATED",
    dataSort: true,
    isKey: false
  },
  {
    dataField: "updatedAt",
    dataValue: "MODIFIED",
    dataSort: true,
    isKey: false
  }
];

export const mattersSettings = {
  mode: "checkbox",
  clickToSelect: true
};

export const mattersHead = [
  {
    dataField: "id",
    dataValue: "ID",
    isKey: true,
    dataSort: true
  },
  {
    dataField: "status",
    dataValue: "Status",
    isKey: false,
    dataSort: true
  },
  {
    dataField: "user",
    dataValue: "User",
    isKey: false,
    dataSort: true
  },
  {
    dataField: "open",
    dataValue: "Open",
    isKey: false,
    dataSort: true
  },
  {
    dataField: "modified",
    dataValue: "Modified",
    isKey: false,
    dataSort: true
  }
];

export const documentsHead = [
  {
    dataField: "filename",
    dataValue: "FILE NAME",
    isKey: true,
    dataSort: true
  },
  {
    dataField: "contentType",
    dataValue: "TYPE",
    isKey: false,
    dataSort: true
  },
  {
    dataField: "createdAt",
    dataValue: "CREATED",
    isKey: false,
    dataSort: true
  },
  {
    dataField: "event",
    dataValue: "",
    isKey: false,
    dataSort: false
  }
];
