import React, { useEffect } from 'react';

import './style.scss';

const ProgressBar = () => {
  useEffect(() => getProgress(), []);

  const getProgress = () => {
    const elem = document.querySelector('.my-bar');
    let width = 1;

    const id = setInterval(() => {
      width++;

      if (width >= 100) clearInterval(id);
      else elem.style.width = width + '%';
    }, 10);
  };

  return (
    <div className="my-progress">
      <div className="my-bar"></div>
    </div>
  );
};

export default ProgressBar;
