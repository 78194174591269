import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Well from 'components/Well';
import Link from 'components/Link';

const RecoverySuccess = ({ location }) => {
  const email = location?.state?.email;
  const emailText = email ? `to ${email}` : '';

  return (
    <Fragment>
      <Well size="small" gutter={40}>
        <p className="text-align-center">
          We have sent an email {emailText} with a link to create a new
          password.
        </p>
      </Well>
      <Link className="to-login-link" pathTo="/login" value="Back to login" />
    </Fragment>
  );
};

RecoverySuccess.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
};

export default RecoverySuccess;
