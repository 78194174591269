import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';

import { SHOW_SYMBOLS_COUNTER_LIMIT, SYMBOLS_LIMIT } from 'utils/constants';

import InputErrors from '../InputErrors';

import './styles.scss';

class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || '',
      maxLengthRestriction: props.maxLength || null,
    };
    this.timeoutDelay = null;
  }

  onBlur = () => {
    const { isRequired } = this.props;

    if (this.props.onBlur) {
      this.props.onBlur();
    }

    if (isRequired && !!this.state.value && this.state.value.length > 0) {
      this.setState({ errors: [] });
    }
  };

  onChange = (e) => {
    e.persist();
    const { withDelay, maxLength, onChange, name, type, settings } = this.props;
    const { symbolsLimit: symbolsLimitSetting } = settings;
    const isCheckBox = type.toLowerCase() === 'checkbox';

    const symbolsLimit = maxLength ?? symbolsLimitSetting ?? SYMBOLS_LIMIT;
    const value = this.isTextTypeWithLimit(symbolsLimit, type)
      ? e.target.value.slice(0, symbolsLimit)
      : e.target.value;

    this.props.onChange(isCheckBox ? e.target.checked : value, e.target.name);

    if (this.timeoutDelay) clearTimeout(this.timeoutDelay);

    if (withDelay) {
      this.timeoutDelay = setTimeout(() => {
        onChange(this.state.value, name);
        clearTimeout(this.timeoutDelay);
      }, 300);
    }
  };

  withDebounceChange = (e) => {
    this.setState({ value: e.target.value });
  };

  changeOnBlur = (e) => {
    e.persist();
    this.onChange(e);
  };

  onKeyPress = (e) => {
    if (e.charCode === 13) {
      this.props.onChange(e.target.value, e.target.name);
    }

    if (this.props.onKeyPress) {
      this.props.onKeyPress(e.target.value, e.target.name, e.charCode);
    }
  };

  onSufixClick = (name, value, preference) => {
    if (this.props.onSufixClick)
      this.props.onSufixClick(name, value, preference);
  };

  onSecondSufixClick = (name, value, preference) => {
    if (this.props.onSecondSufixClick)
      this.props.onSecondSufixClick(name, value, preference);
  };

  setHTML = (label, isRequired) => {
    const sup = isRequired ? '<sup class="text-color-gray">*</sup>' : '';
    return {
      __html: `<div class="ellipsis"><span class="pointer-without-events">${label}<span>${sup}</div>`,
    };
  };

  processJsonValue(val) {
    let processedValue = '';
    if (Array.isArray(val)) {
      // eslint-disable-next-line
      val.map((obj) => {
        let lineObject = '';
        // eslint-disable-next-line
        Object.keys(obj).map((k) => {
          lineObject += `${k}: ${obj[k]}\n`;
        });
        processedValue += `${lineObject} \n\n`;
      });
    }
    return processedValue;
  }

  isTextTypeWithLimit = (maxLength, type, value) => {
    if (
      (type &&
        typeof type === 'string' &&
        type.toLowerCase() === 'text' &&
        maxLength &&
        Number.parseInt(maxLength) &&
        Number.parseInt(maxLength) > 0) ||
      value?.length >= SHOW_SYMBOLS_COUNTER_LIMIT
    ) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const {
      name,
      type,
      mandatory,
      className,
      isRequired,
      errors,
      disabled,
      autoFocus,
      sufix,
      prefix,
      labelAnimate,
      placeholder,
      inputType,
      labelInside,
      searchInput,
      preference,
      secondSufix,
      dangerouslySetInnerHTML,
      style,
      parsedJsonAnswer,
      maxLength,
      withDebounce,
      withCounter = false,
      settings,
    } = this.props;
    const { maxLengthRestriction, value: inputValue } = this.state;
    let label = mandatory ? `${this.props.label} *` : this.props.label;
    let { wrapperClass } = this.props;
    let { value } = this.props;
    const { symbolsLimit: symbolsLimitSetting } = settings;

    if (type === 'date' && value) {
      value = moment(new Date(value)).format('YYYY-MM-DD');
    }

    if (type === 'checkBox') {
      if (value === 't') {
        value = true;
      } else if (value === '' && value === '_') {
        value = false;
      }
    }

    const filledInputLabelClass = value ? 'form-control-label-filled' : '';

    const activeClass =
      (labelInside || labelAnimate) && value && value.length > 0
        ? 'form-control-valid'
        : 'form-control-empty';
    const labelWrapperClass =
      labelInside || labelAnimate
        ? 'form-control-label-animate'
        : 'form-control-label-static';
    const errorArray = this.state.errors || errors;
    const formControlType = inputType ? `form-control-${inputType}` : '';
    const errorClass =
      errorArray && errorArray.length > 0 ? 'has-error' : 'no-error';
    const sufixClass = sufix ? 'form-control-has-sufix' : '';
    const prefixClass = prefix ? 'form-control-has-prefix' : '';
    const labelInsideClass = labelInside
      ? 'form-control-wrapper-label-inside'
      : '';
    const selectable = searchInput ? 'selectable' : '';
    const isDisplayingJsonClass = parsedJsonAnswer ? 'display-json' : null;
    const inputLabel = label && (
      <label
        htmlFor={name}
        id={isDisplayingJsonClass}
        className={`form-control-label ${filledInputLabelClass} ${filledInputLabelClass} ${isDisplayingJsonClass}`}
        dangerouslySetInnerHTML={this.setHTML(label, isRequired)}
      ></label>
    );

    const symbolsLimit = maxLength ?? symbolsLimitSetting ?? SYMBOLS_LIMIT;
    const count2 = value ? `${value.length} / ${symbolsLimit}` : null;
    const symbolsCounter2 =
      (maxLength || withCounter) &&
      this.isTextTypeWithLimit(symbolsLimit, type) &&
      count2 ? (
        <div className="input-symbols-counter2">{count2}</div>
      ) : null;
    const inputWithCouterClass = this.isTextTypeWithLimit(symbolsLimit, type)
      ? 'form-control-with-counter'
      : null;
    const counterError =
      (maxLength || withCounter) &&
      value &&
      maxLengthRestriction - value.length < 0
        ? [`Max number of symbols is ${symbolsLimit}`]
        : '';

    const onBlur = withDebounce ? this.changeOnBlur : this.onBlur;

    const onChange = withDebounce ? this.withDebounceChange : this.onChange;

    return (
      <div
        className={`form-control-wrapper ${wrapperClass} ${
          !labelAnimate && !labelInside
            ? 'form-control-wrapper-label-static'
            : ''
        } ${labelInsideClass} ${selectable}`}
        style={style}
      >
        {dangerouslySetInnerHTML && (
          <>
            <div
              className="form-control-wrapper-innerhtml"
              dangerouslySetInnerHTML={dangerouslySetInnerHTML}
            ></div>
          </>
        )}
        {!dangerouslySetInnerHTML && (
          <>
            {!labelAnimate && !labelInside && inputLabel}
            <div
              className={`form-control-container form-multiline-input-conatiner ${labelWrapperClass} ${activeClass}`}
            >
              {(labelInside || labelAnimate) && inputLabel}
              {prefix && <div className="form-control-prefix">{prefix}</div>}
              <textarea
                className={`form-control ${className} ${formControlType} ${errorClass} ${sufixClass} ${prefixClass} ${isDisplayingJsonClass}  ${inputWithCouterClass} input-textarea`}
                id={name}
                name={name}
                type={type}
                value={
                  parsedJsonAnswer
                    ? this.processJsonValue(parsedJsonAnswer)
                    : withDebounce
                    ? inputValue
                    : value
                }
                disabled={disabled}
                autoFocus={autoFocus} //eslint-disable-line
                placeholder={placeholder}
                onBlur={onBlur}
                onChange={onChange}
                onKeyPress={this.onKeyPress}
                autoComplete="off" //eslint-disable-line
                max="2145-01-01"
                min="0"
                rows="6"
              />

              {sufix && (
                <div
                  onClick={() => this.onSufixClick(name, value, preference)}
                  className="form-control-sufix"
                  role="button"
                  tabIndex={0}
                >
                  {sufix}
                </div>
              )}
              {secondSufix && (
                <div
                  onClick={() =>
                    this.onSecondSufixClick(name, value, preference)
                  }
                  className="form-control-sufix-second"
                  role="button"
                  tabIndex={0}
                >
                  {secondSufix}
                </div>
              )}
            </div>
            {symbolsCounter2}
            <InputErrors errors={errorArray} />
            {value?.length >= symbolsLimit && (maxLength || withCounter) && (
              <InputErrors errors={counterError} />
            )}
          </>
        )}
      </div>
    );
  }
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  inputType: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  placeholder: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  wrapperClass: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  errors: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ),
  sufix: PropTypes.node,
  prefix: PropTypes.node,
  labelAnimate: PropTypes.bool,
  labelInside: PropTypes.bool,
  searchInput: PropTypes.bool,
  withDelay: PropTypes.bool,
  onSufixClick: PropTypes.func,
  onSecondSufixClick: PropTypes.func,
  preference: PropTypes.number,
};

Input.defaultProps = {
  value: '',
  placeholder: '',
  label: null,
  className: null,
  wrapperClass: null,
  isRequired: false,
  errors: [],
  disabled: false,
  autoFocus: false,
  sufix: null,
  secondSufix: null,
  prefix: null,
  labelAnimate: false,
  labelInside: false,
  inputType: '',
  onChange: null,
  onKeyPress: null,
  onBlur: null,
  searchInput: false,
  withDelay: false,
  onSufixClick: null,
  onSecondSufixClick: null,
  preference: null,
};

const mapStateToProps = ({ settings }) => ({ settings });

export default connect(mapStateToProps)(Input);
