import React from "react";

import Layout from "components/Layout";
import Grid from "components/Layout/Grid";
import Button from "components/Button";

import { tasksAppPath } from "utils/paths";

import "./styles.scss";

const NotFound = () => (
  <Layout.Base>
    <Grid.Main className="height-auto">
      <Grid.Content className="not-found-content mb20 mt20 flex justify-center items-center">
        <div className="container-fluid flex row mt20 mb20 justify-center items-center">
          <div className="flex direction-column justify-center items-center">
            <h1 className="half-opacity">404</h1>
            <h2>PAGE NOT FOUND</h2>
            <Button
              type="success"
              value="Take me back to homepage"
              pathTo={tasksAppPath}
              className="padding-20-left padding-20-right"
            />
          </div>
        </div>
      </Grid.Content>
    </Grid.Main>
  </Layout.Base>
);

export default NotFound;
