import React from 'react';
import PropTypes from 'prop-types';

import Input from '../Input';

const Search = ({
  onChange = () => {},
  onKeyPress = () => {},
  onSufixClick = () => {},
  ...rest
}) => (
  <Input
    onChange={onChange}
    onKeyPress={onKeyPress}
    onSufixClick={onSufixClick}
    {...rest}
  />
);

Search.propTypes = {
  onSufixClick: PropTypes.func,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
};

export default Search;
