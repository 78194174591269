import React from 'react';
import './styles.scss';
import success_image from 'assets/images/trustly_success.png';

const trystly_avatar =
  'https://avatars2.githubusercontent.com/u/1732317?s=280&v=4';

const TrustySuccess = () => {
  return (
    <div className="trustly">
      <img
        src={success_image}
        alt="Success"
        className="trustly-success-image"
      />
      <h1 className="trustly-header">Account Selection Success</h1>
      <p className="trustly-content">
        Thank you for selecting your account. We will now begin processing your
        payment and will be in contact soon
      </p>
      <p className="trustly-content">
        Please now close this tab and return to the previous page to continue.
      </p>
      <img
        src={trystly_avatar}
        alt="Trystly Avatar"
        className="trustly-avatar-image"
      />
    </div>
  );
};

export default TrustySuccess;
