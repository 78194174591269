import React from 'react';
import PropTypes from 'prop-types';

const PlayButton = ({
  width = 15,
  height = 14,
  className = '',
  fill = '#FFF',
}) => (
  <div className={`la-icon icon-svg-PlayButton ${className}`}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 512 512"
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      // style="enable-background:new 0 0 512 512;"
    >
      <g>
        <g>
          <path
            fill={fill}
            fillRule="nonzero"
            opacity="1"
            d="M437.019,74.98C388.667,26.628,324.38,0,256,0C187.619,0,123.332,26.629,74.98,74.98C26.629,123.332,0,187.62,0,256
			c0,68.381,26.629,132.668,74.98,181.02C123.332,485.371,187.619,512,256,512c68.38,0,132.667-26.629,181.019-74.98
			C485.371,388.667,512,324.38,512,256S485.371,123.333,437.019,74.98z M400.887,268.482L208.086,397.014
			c-2.51,1.674-5.411,2.52-8.321,2.52c-2.427,0-4.859-0.588-7.077-1.775c-4.877-2.609-7.922-7.693-7.922-13.225V127.467
			c0-5.532,3.045-10.615,7.922-13.225c4.876-2.61,10.795-2.325,15.398,0.745L400.887,243.52c4.173,2.782,6.68,7.466,6.68,12.481
			C407.566,261.017,405.06,265.699,400.887,268.482z"
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  </div>
);

PlayButton.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default PlayButton;
