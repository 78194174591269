import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import Layout from 'components/Layout';
import Ring from 'components/Ring';

import { listPath, newPath, infoPath } from './utils/paths';

const ClaimList = lazy(() => import('./containers/List'));
const ClaimNew = lazy(() => import('./containers/New'));
const ClaimDetails = lazy(() => import('./containers/Details'));

const ClaimPage = () => (
  <Layout.Base>
    <Suspense fallback={<Ring />}>
      <Switch>
        <Route
          exact
          path={listPath}
          component={(props) => <ClaimList {...props} />}
        />
        <Route path={newPath} component={(props) => <ClaimNew {...props} />} />
        <Route
          path={infoPath}
          component={(props) => <ClaimDetails {...props} />}
        />
      </Switch>
    </Suspense>
  </Layout.Base>
);

export default ClaimPage;
