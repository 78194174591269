import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const Badge = ({ className = '', type = '', text = '' }) => {
  const badgeType = type ? `badge-${type}` : '';
  return (
    <div className="badge-wrapper">
      <div className={`badge ${badgeType} ${className}`} />
      {text && <div className="badge-text">{text}</div>}
    </div>
  );
};

Badge.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string,
};

export default Badge;
