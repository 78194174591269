import { isThompsons, isXceedance } from 'utils/helpers';

export const setAuthWrapper = () => {
  if (isThompsons()) return 'tmp-auth-wrapper';
  if (isXceedance()) return 'xceedance-auth-wrapper';

  return 'auth-wrapper';
};

export const setAuthBackground = ({ settings }) => {
  const xceedanceBackground = settings?.data?.primaryColor;
  return isXceedance() && xceedanceBackground
    ? { backgroundColor: xceedanceBackground }
    : null;
};
