import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './styles.scss';

export const VehicleInspectorPlaceholder = ({ onChange = () => {} }) => (
  <div onClick={onChange}>
    <div className="vehicle-inspector"></div>
  </div>
);

const VehicleInspector = ({ url = '' }) => {
  const [loading, setLoading] = useState(true);

  const hideSpinner = () => setLoading(false);

  return (
    <Fragment>
      <div className="vehicle-inspector-container">
        {loading ? (
          <div className="spinner-container">
            <div className="blink">
              <span>Loading...</span>
            </div>
          </div>
        ) : null}
        <iframe
          width="1000"
          height="550"
          src={url}
          onLoad={hideSpinner}
          frameBorder="0"
          allow="fullscreen"
          title="Claim Technology"
          scrolling="auto"
          referrerpolicy="origin"
        ></iframe>
      </div>
    </Fragment>
  );
};

VehicleInspector.propTypes = {
  className: PropTypes.string,
  wrapperClass: PropTypes.string,
  name: PropTypes.string,
  onUploadVideoClick: PropTypes.func,
  document: PropTypes.shape({
    previews: PropTypes.array,
    hidden: PropTypes.array,
  }),
  removeSelectedVideo: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.arrayOf(Object),
  task: PropTypes.shape({
    fetchingOne: PropTypes.bool,
    fetchedOne: PropTypes.bool,
  }).isRequired,
  disabled: PropTypes.bool,
  previewLoaded: PropTypes.bool,
  withoutAutosave: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  document: state.document,
  task: state.task,
});

export default connect(mapStateToProps)(VehicleInspector);
