import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';

import Grid from 'components/Layout/Grid';
import Button from 'components/Button';

import { updateUser } from 'actions/user';
import { setAccField, clearAccountState, setAccFields } from 'actions/account';

import checkValidations from 'utils/validator';

import { rootPath } from '../utils/paths';
import generateValidation from '../utils/validation';
import { accountFormClasses } from '../utils/constants';

import AccountForm from './../components/AccountForm';
import CustomerAccountForm from '../components/CustomerAccountForm';
import { isCustomerRole, isThompsons } from 'utils/helpers';

class AccountEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changedFields: {},
      fields: props.session.data,
    };
  }

  componentDidUpdate(prevProps) {
    const { user, history } = this.props;
    const { errorText, hasErrors } = user;

    if (prevProps.user.updating && user.updated && !hasErrors) {
      history.push(rootPath);
    }

    if (!prevProps.user.hasErrors && hasErrors) {
      this.setApiErrors(errorText);
    }
  }

  onClick = () => {
    const { fields, changedFields } = this.props.account;
    const errors = checkValidations(
      generateValidation(changedFields.password),
      fields
    );

    if (Object.keys(changedFields).length === 0) return;

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
    } else {
      Object.keys(changedFields).forEach((el) => {
        if (el === 'confirmPassword') {
          delete changedFields[el];
        }
      });
      this.props.updateUser(fields.id, changedFields);
    }
  };

  setApiErrors = (errors) => {
    this.setState({ errors });
  };

  onCancelClick = () => {
    this.props.clearAccountState(() => {
      this.props.setAccFields(this.props.customer.fields);
      this.props.history.push(rootPath);
    });
  };

  onChange = (val, key) => {
    this.props.setAccField(key, val);
  };

  render() {
    const { fields } = this.props.account;
    const { updating } = this.props.user;
    const isMobile = this.props.browser.windowSize[0] < 576;
    const { data } = this.props.session;
    const { roles } = data;

    return (
      <Fragment>
        <Grid.Main className="height-auto">
          <Grid.Content className="mb20">
            <Grid.Controls>
              <div
                className={`flex justify-between container-fluid ${
                  isMobile ? 'direction-column' : ''
                }`}
              >
                <div className="">
                  <h2 className="h2">
                    {isThompsons() ? 'My details' : 'Account details'}
                  </h2>
                </div>

                <div className="btn-wrap">
                  <Button type="" value="Cancel" onClick={this.onCancelClick} />

                  <Button
                    type="primary"
                    value={updating ? <div className="loader" /> : 'Save'}
                    onClick={this.onClick}
                  />
                </div>
              </div>
            </Grid.Controls>

            <div
              className={`${
                isThompsons() ? 'accountThompsons' : ''
              } container-fluid row col-xs-8 flex justify-start mt0 mb20 col-sd-12`}
            >
              {!isCustomerRole(roles) ? (
                <AccountForm
                  onChange={this.onChange}
                  values={fields}
                  errors={this.state.errors}
                  className={accountFormClasses}
                />
              ) : (
                <CustomerAccountForm
                  onChange={this.onChange}
                  values={fields}
                  errors={this.state.errors}
                  className={accountFormClasses}
                  roles={roles}
                />
              )}
            </div>
          </Grid.Content>
        </Grid.Main>
      </Fragment>
    );
  }
}

AccountEdit.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  updateUser: PropTypes.func.isRequired,
  setAccField: PropTypes.func.isRequired,
  session: PropTypes.shape({
    data: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  }).isRequired,
  account: PropTypes.shape({
    fields: PropTypes.shape(),
    changedFields: PropTypes.shape(),
  }).isRequired,
  user: PropTypes.shape({
    updating: PropTypes.bool,
    updated: PropTypes.bool,
  }).isRequired,
  clearAccountState: PropTypes.func.isRequired,
};

AccountEdit.defaultProps = {};

const mapDispatchToProps = {
  updateUser,
  setAccField,
  clearAccountState,
  setAccFields,
};

const mapStateToProps = ({ browser, customer, user }) => ({
  browser,
  customer,
  user,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AccountEdit)
);
