import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

import InputErrors from '../InputErrors';

import './styles.scss';

class DatePickerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // currentDate: null,
      currentDate: props.value ? this.formatDate(props.value) : null,
      today: moment(new Date()),
    };
  }

  componentDidMount() {
    const { currentDate } = this.state;
    const formattedNewDate = this.formatDate(this.props.value);

    if (!currentDate && formattedNewDate) {
      this.updateValue(formattedNewDate);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentDate } = this.state;
    const prevDate = prevState.currentDate;
    const formattedCurrentDate = this.formatDate(currentDate);
    const formattedPrevDate = this.formatDate(prevDate);
    const formattedNewDate = this.formatDate(this.props.value);
    const isSame = moment(formattedCurrentDate).isSame(
      formattedPrevDate,
      'day'
    );
    const isValid = formattedCurrentDate && formattedPrevDate;

    if (!isSame && isValid) {
      this.updateValue(formattedCurrentDate);
    }

    if (!currentDate && formattedNewDate) {
      this.updateValue(formattedNewDate);
    }
  }

  onFocus = () => this.setState({ isFocused: true });

  onBlur = () => this.setState({ isFocused: false });

  handleChange = (date) => {
    const { name, chatbotAdaptiveDate } = this.props;

    if (!date) {
      this.setState({ currentDate: null });
      this.props.onChange(null, name);
    } else {
      const newDate = moment(date);
      let totalDate;
      if (chatbotAdaptiveDate) totalDate = newDate.format('Do MMMM Y');
      else totalDate = newDate.format();

      this.setState({ currentDate: newDate });
      this.props.onChange(totalDate, name);
    }
  };

  formatDate = (date) => {
    let result = null;

    if (date && date.length > 0 && date.includes('UTC')) {
      const cutted = date.replace(' UTC', '');
      result = moment(cutted);
    } else if (date && (date.length > 0 || Object.keys(date).length > 0)) {
      if (moment(date).isValid()) {
        result = moment(date);
      } else {
        result = moment(date, 'Do MMMM YYYY');
      }
    }
    return result;
  };

  updateValue = (date) => {
    this.setState({ currentDate: date });
  };

  setHTML = (label, isRequired) => {
    const { newDesign } = this.props;
    const newDesignPromptStyles = newDesign ? 'new-design-prompt' : '';
    const sup = isRequired ? '<sup class="text-color-gray">*</sup>' : '';
    return {
      __html: `<div class="ellipsis"><span class="pointer-without-events ${newDesignPromptStyles}">${label}<span>${sup}</div>`,
    };
  };

  render() {
    const {
      className,
      dateFormat,
      customInput,
      shouldCloseOnSelect,
      calendarSize,
      wrapperClass,
      labelAnimate,
      labelInside,
      isRequired,
      mandatory,
      prefix,
      sufix,
      errors,
      name,
      disabled,
      validation,
      noFutureDates,
      noPastDates,
      style,
      value,
      newDesign,
    } = this.props;
    let label = mandatory ? `${this.props.label} *` : this.props.label;

    const { currentDate, isFocused, today } = this.state;

    const errorClass = errors && errors.length > 0 ? 'has-error' : 'no-error';
    const sufixClass = sufix ? 'form-control-has-sufix' : '';
    const prefixClass = prefix ? 'form-control-has-prefix' : '';
    const activeClass =
      ((labelInside || labelAnimate) &&
        currentDate &&
        Object.keys(currentDate).length > 0) ||
      isFocused
        ? 'form-control-valid'
        : 'form-control-empty';
    const sizeClass = calendarSize
      ? `date-picker-${calendarSize}`
      : 'date-picker-default';
    const labelWrapperClass =
      labelInside || labelAnimate
        ? 'form-control-label-animate'
        : 'form-control-label-static';
    const labelInsideClass = labelInside
      ? 'form-control-wrapper-label-inside'
      : '';
    const inputLabel = label && (
      <label
        htmlFor={name}
        className="form-control-label"
        dangerouslySetInnerHTML={this.setHTML(label, isRequired)}
      />
    );

    const newDesignStyles = newDesign ? 'new-design-field' : null;

    return (
      <div
        className={`form-control-wrapper datepicker ${wrapperClass} ${
          !labelAnimate && !labelInside
            ? 'form-control-wrapper-label-static'
            : ''
        } ${labelInsideClass}`}
        style={style}
      >
        {!labelAnimate && !labelInside && inputLabel}
        <div
          className={`form-control-container ${labelWrapperClass} ${activeClass}`}
        >
          {prefix && <div className="form-control-prefix">{prefix}</div>}
          <DatePicker
            id={name}
            name={name}
            showMonthDropdown
            showYearDropdown
            disabled={disabled}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            dateFormat={dateFormat}
            className={`form-control date-picker-wrapper form-control-date ${className} ${errorClass} ${sufixClass} ${prefixClass} ${newDesignStyles}`}
            calendarClassName={sizeClass}
            selected={disabled ? this.formatDate(value) : currentDate}
            onChange={this.handleChange}
            customInput={customInput}
            shouldCloseOnSelect={shouldCloseOnSelect}
            maxDate={validation.noFutureDates || noFutureDates ? today : null}
            minDate={validation.noPastDates || noPastDates ? today : null}
            scrollableYearDropdown
            yearDropdownItemNumber={85}
            dropdownMode="select"
          />
          {(labelInside || labelAnimate) && inputLabel}
          {sufix && (
            <div
              onClick={() => {}}
              className="form-control-sufix"
              role="button"
              tabIndex={0}
            >
              {sufix}
            </div>
          )}
        </div>
        <InputErrors errors={errors} />
      </div>
    );
  }
}

DatePickerComponent.propTypes = {
  className: PropTypes.string,
  dateFormat: PropTypes.string,
  calendarSize: PropTypes.string,
  onChange: PropTypes.func,
  customInput: PropTypes.node,
  shouldCloseOnSelect: PropTypes.bool,
  wrapperClass: PropTypes.string,
  labelAnimate: PropTypes.bool,
  labelInside: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  prefix: PropTypes.node,
  sufix: PropTypes.node,
  errors: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ),
  name: PropTypes.string,
  disabled: PropTypes.bool,
  chatbotAdaptiveDate: PropTypes.bool,
  validation: PropTypes.objectOf(String),
  noFutureDates: PropTypes.bool,
};

DatePickerComponent.defaultProps = {
  className: '',
  dateFormat: 'DD MMM YYYY',
  customInput: null,
  shouldCloseOnSelect: true,
  calendarSize: '',
  wrapperClass: '',
  labelAnimate: false,
  labelInside: false,
  isRequired: false,
  label: '',
  value: {},
  prefix: null,
  sufix: null,
  errors: [],
  name: '',
  disabled: false,
  onChange: null,
  chatbotAdaptiveDate: false,
  validation: {},
  noFutureDates: false,
};

export default DatePickerComponent;
