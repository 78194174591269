import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

/* PAGES */
import AuthRouter from 'components/AuthRouter';
import Login from 'pages/Login';
import SetPassword from 'pages/SetPassword';
import RefreshEmail from 'pages/RefreshEmail';
import NotFound from 'pages/NotFound';
import TrustySuccess from 'pages/TrustySuccess';

/* AUTH */
import isAuthenticated, { authRoles } from 'components/Auth';

/* PATH */
import {
  loginAppPath,
  setPasswordPath,
  refreshEmailPath,
  errorPath,
  trustySuccessPath,
  repairStatus,
} from 'utils/paths';
// import Ring from 'components/Ring';
import ErrorBoundary from './Layout/ErrorBoundary';
import RepairStatusPage from 'pages/RepairStatus';
import { isSG } from 'utils/helpers';

// const AuthRouter = lazy(() => import('components/AuthRouter'));
// const Login = lazy(() => import('pages/Login'));
// const SetPassword = lazy(() => import('pages/SetPassword'));
// const RefreshEmail = lazy(() => import('pages/RefreshEmail'));
// const NotFound = lazy(() => import('pages/NotFound'));
// const TrustySuccess = lazy(() => import('pages/TrustySuccess'));

// const MainRouter = () => {
//   <Router>
//     <ErrorBoundary>
//       <Switch>
//         <Route path={loginAppPath} component={Login} />
//         <Route path={setPasswordPath} component={SetPassword} />
//         <Route path={refreshEmailPath} component={RefreshEmail} />
//         <Route path={errorPath} component={NotFound} />
//         <Route path={trustySuccessPath} component={TrustySuccess} />
//         <Route
//           path="/"
//           component={isAuthenticated(AuthRouter, authRoles.user)}
//         />
//       </Switch>
//       {/* <Suspense fallback={<Ring />}>
//         <Switch>
//           <Route
//             path={loginAppPath}
//             component={(props) => <Login {...props} />}
//           />
//           <Route
//             path={setPasswordPath}
//             component={(props) => <SetPassword {...props} />}
//           />
//           <Route
//             path={refreshEmailPath}
//             component={(props) => <RefreshEmail {...props} />}
//           />
//           <Route
//             path={errorPath}
//             component={(props) => <NotFound {...props} />}
//           />
//           <Route
//             path={trustySuccessPath}
//             component={(props) => <TrustySuccess {...props} />}
//           />
//           <Route
//             path="/"
//             component={isAuthenticated(AuthRouter, authRoles.user)}
//           />
//         </Switch>
//       </Suspense> */}
//     </ErrorBoundary>
//   </Router>;
// };
class MainRouter extends Component {
  render() {
    return (
      <Router>
        <ErrorBoundary>
          <Switch>
            <Route path={loginAppPath} component={Login} />
            <Route path={setPasswordPath} component={SetPassword} />
            <Route path={refreshEmailPath} component={RefreshEmail} />
            <Route path={errorPath} component={NotFound} />
            <Route path={trustySuccessPath} component={TrustySuccess} />
            {isSG() ? (
              <Route path={repairStatus} component={RepairStatusPage} />
            ) : null}

            <Route
              path="/"
              component={isAuthenticated(AuthRouter, authRoles.user)}
            />
          </Switch>
        </ErrorBoundary>
      </Router>
    );
  }
}

export default MainRouter;
