import React from 'react';
import PropTypes from 'prop-types';

const Mail = ({ width = 15, height = 14, className = '', fill = '#FFF' }) => (
  <div className={`la-icon icon-svg-mail ${className}`}>
    <svg width={width} height={height} viewBox="0 0 13 16">
      <path
        fill={fill}
        fillRule="nonzero"
        d="M13.34.49H1.66C.855.49.198 1.149.198 1.952v13.142l2.92-2.92h10.222c.803 0 1.46-.658 1.46-1.46V1.95c0-.803-.657-1.46-1.46-1.46zM5.31 7.063H3.85v-1.46h1.46v1.46zm2.92 0H6.77v-1.46h1.46v1.46zm2.92 0H9.69v-1.46h1.46v1.46z"
      />
    </svg>
  </div>
);

Mail.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default Mail;
