import React, { Fragment } from "react";
import { Switch, Route } from "react-router-dom";

import CustomerInfo from "./containers/Info";
import CustomerEdit from "./containers/Edit";
import CustomerDocuments from "./containers/Documents";
import CustomerClaims from "./containers/Claims";

import {
  infoPath,
  editPath,
  documentsPath,
  claimsPath
} from "../../utils/paths";

const CustomerDetails = () => (
  <Fragment>
    <Switch>
      <Route exact path={infoPath} component={CustomerInfo} />
      <Route path={editPath} component={CustomerEdit} />
      <Route path={documentsPath} component={CustomerDocuments} />
      <Route path={claimsPath} component={CustomerClaims} />
    </Switch>
  </Fragment>
);

export default CustomerDetails;
