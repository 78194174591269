import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const CustomCheckbox = ({
  value,
  name,
  label,
  onChange = () => {},
  style,
  disabled,
}) => (
  <label htmlFor={name} className="container" style={style}>
    <span className="container-text">{label}</span>
    <input
      type="checkbox"
      name={name}
      id={name}
      hidden
      defaultChecked={value}
      onChange={(event) => {
        onChange(event.target.checked, name);
      }}
      disabled={disabled}
    />
    <span className="checkmark"></span>
  </label>
);

CustomCheckbox.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default CustomCheckbox;
