import axios from "axios";

// UTILS
import { staticTextsPath } from "utils/paths";
import RestActions from "utils/rest/actions";

const restStaticTextsActions = new RestActions("staticTexts");
const { fetchStart, fetchFinished } = restStaticTextsActions;

const staticTextsToObject = array => {
  const obj = {};
  if (array instanceof Array) {
    array.forEach(i => {
      obj[i.code] = i.text;
    });
  }
  if (obj && Object.keys(obj).length > 0) {
    return obj;
  }
};

/* ACTIONS */
export const fetchStaticTexts = () => dispatch => {
  dispatch(fetchStart());
  return axios.get(staticTextsPath, {headers: {Authorization: ""}}).then(
    ({ data }) => {
      dispatch(fetchFinished(staticTextsToObject(data.result)));
    },
    error => {
      dispatch(fetchFinished(error));
    }
  );
};
