import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Input from "../Input";
import List from "../List";

import {
  fetchPersonForSuggestion,
  fetchOrganizationForSuggestion
} from "actions/task";

import { peoplesAppPath } from "utils/paths";

class Autocomplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSelected: false,
      selectedSuggestion: {},
      value: props.value || "",
      preloadedDataLoaded: false
    };
  }

  componentDidMount() {
    document.addEventListener("click", this.listener, false);

    const {
      fetchOrganizationForSuggestion,
      fetchPersonForSuggestion
    } = this.props;
    try {
      const newVal = JSON.parse(this.props.value);
      if (typeof newVal === "object") {
        if (newVal.valuable_type === "Person") {
          fetchPersonForSuggestion(newVal.valuable_id).then(({ person }) => {
            this.setPreloadedData({
              address: person.address,
              contactableId: person.id,
              contactableType: "Person",
              id: person.id,
              name: `${person.first_name} ${person.last_name}`
            });
          });
        } else {
          fetchOrganizationForSuggestion(newVal.valuable_id).then(
            ({ organization }) => {
              this.setPreloadedData({
                address: organization.address,
                contactableId: organization.id,
                contactableType: "Organization",
                id: organization.id,
                name: organization.name
              });
            }
          );
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  componentDidUpdate() {
    const { isSelected, preloadedDataLoaded } = this.state;

    if (
      !isSelected &&
      !preloadedDataLoaded &&
      Object.keys(this.props.preloadedData).length > 0
    ) {
      this.setPreloadedData(this.props.preloadedData);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.listener, false);
  }

  onChange = (value, name, selected) => {
    this.setState({ value, clickedOutside: false });
    if (!/^ *$/.test(value)) {
      this.props.onChange(value, name, selected, null, true, this.props.roleId);
    }
  };

  onSuggestionClick = suggestion => {
    const { name, onSuggestionClick } = this.props;
    const selected = true;

    this.setState({
      isSelected: true,
      selectedSuggestion: suggestion
    });

    if (onSuggestionClick) {
      onSuggestionClick(suggestion);
    }

    this.onChange(
      `{"valuable_id": "${suggestion.id}", "valuable_type": "${suggestion.contactableType}"}`,
      name,
      selected
    );
  };

  onLinkClick = url => {
    window.open(url, "_blank");
  };

  onDeleteClick = () => {
    const { name } = this.props;
    const selected = false;

    this.setState({
      value: "",
      isSelected: false,
      selectedSuggestion: {}
    });
    this.props.onChange("", name, selected);
  };

  setPreloadedData = data => {
    this.setState({
      isSelected: true,
      selectedSuggestion: data,
      preloadedDataLoaded: true
    });
  };

  listener = event => {
    const { clickedOutside } = this.state;

    if (!event.target.closest(".suggestions-list-item") && !clickedOutside) {
      this.setState({ clickedOutside: true });
    }
  };

  onAutocompleteChange = (val, key, selected) => {
    clearTimeout(this.timeout);

    const query = {
      ...this.state.query,
      search: {
        value: val
      },
      page: 1
    };

    if (!val) {
      const currentFields = {
        ...this.state.enteredFields,
        [key]: val
      };

      delete currentFields[key];

      if (key === "claimId" && currentFields.userId)
        delete currentFields.userId;

      this.setState({ enteredFields: currentFields });
    } else {
      this.setState({
        enteredFields: {
          ...this.state.enteredFields,
          [key]: val
        },
        selected
      });
    }

    this.timeout = setTimeout(() => {
      this.props.fetchClaimsSuggestions(key, query);
      this.setState({ query, autocomplete: val });
    }, 500);
  };

  render() {
    const {
      name,
      label,
      className,
      errors,
      autoFocus,
      sufix,
      secondSufix,
      prefix,
      labelAnimate,
      placeholder,
      labelInside,
      button,
      SuggestionSelected,
      Suggestion,
      suggestions,
      searchInput,
      isRequired,
      disabled,
      onSufixClick,
      onSecondSufixClick,
      hideRedDot,
      roleId,
      style,
      portalQuestionIcons
    } = this.props;
    let { isSelected, selectedSuggestion, value, clickedOutside } = this.state;
    try {
      value = typeof JSON.parse(value) === "object" ? "" : value;
    } catch (e) {
      console.log(e);
    }

    return (
      <div className={`autocomplete-wrapper ${className}`} style={style}>
        <div className="autocomplete-form-control-wrapper">
          {isSelected &&
            SuggestionSelected(
              selectedSuggestion,
              this.onLinkClick,
              this.onDeleteClick,
              label,
              labelInside,
              isRequired,
              hideRedDot
            )}
          {!isSelected && (
            <Input
              // className={isList}
              value={value || ""}
              name={name}
              type="text"
              onChange={this.onChange}
              label={label}
              errors={errors}
              autoFocus={autoFocus}
              sufix={sufix}
              onSufixClick={onSufixClick}
              secondSufix={secondSufix}
              onSecondSufixClick={onSecondSufixClick}
              prefix={prefix}
              labelAnimate={labelAnimate}
              placeholder={placeholder}
              labelInside={labelInside}
              disabled={isSelected || disabled}
              SuggestionSelected={SuggestionSelected}
              selectedSuggestion={selectedSuggestion}
              searchInput={searchInput}
              isRequired={isRequired}
              roleId={roleId}
            />
          )}
          {!isSelected &&
            !clickedOutside &&
            value &&
            suggestions &&
            suggestions.length > 0 && (
              <div className="autocomplete-dropdown suggestions">
                <List type="clear" className="with-scroll-y">
                  {suggestions.map(item =>
                    Suggestion(item, this.onSuggestionClick)
                  )}
                </List>
                {button}
              </div>
            )}
        </div>
        {suggestions.length === 0 && !isSelected && !disabled ? (
          <div className="create-wrapper" style={portalQuestionIcons ? {
            right: '-290px'
          } : null}>
            <Link 
              to={`${peoplesAppPath}/new`} 
              className="new-person" 
            >
              <button className="add-contact-btn btn btn-success">
                + New Person
              </button>
            </Link>
            <Link to={`${peoplesAppPath}/new`} className="new-organization">
              <button className="add-contact-btn btn btn-success">
                + New Organisation
              </button>
            </Link>
          </div>
        ) : null}
      </div>
    );
  }
}

Autocomplete.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  autoFocus: PropTypes.bool,
  errors: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ),
  sufix: PropTypes.node,
  secondSufix: PropTypes.node,
  prefix: PropTypes.node,
  labelAnimate: PropTypes.bool,
  labelInside: PropTypes.bool,
  onChange: PropTypes.func,
  button: PropTypes.node,
  SuggestionSelected: PropTypes.func,
  Suggestion: PropTypes.func,
  suggestions: PropTypes.arrayOf(Object),
  searchInput: PropTypes.bool,
  isRequired: PropTypes.bool,
  onSuggestionClick: PropTypes.func,
  preloadedData: PropTypes.shape()
};

Autocomplete.defaultProps = {
  name: "",
  placeholder: "",
  label: null,
  className: "",
  errors: [],
  autoFocus: false,
  sufix: null,
  prefix: null,
  labelAnimate: false,
  labelInside: false,
  onChange: null,
  button: null,
  SuggestionSelected: null,
  Suggestion: null,
  suggestions: [],
  searchInput: false,
  isRequired: false,
  onSuggestionClick: null,
  preloadedData: {},
  hideRedDot: false
};

const mapDispatchToProps = {
  fetchPersonForSuggestion,
  fetchOrganizationForSuggestion
};

export default connect(null, mapDispatchToProps)(Autocomplete);
