import { claimsAppPath } from "utils/paths";

// APP paths
export const listPath = `${claimsAppPath}`;
export const newPath = `${claimsAppPath}/new`;
export const infoPath = `${claimsAppPath}/:id`;
export const editPath = `${claimsAppPath}/:id/edit`;
export const tasksPath = `${claimsAppPath}/:id/tasks`;
export const contactsPath = `${claimsAppPath}/:id/contacts`;
export const documentsPath = `${claimsAppPath}/:id/documents`;
export const documentsIdPath = `${claimsAppPath}/:id/documents/:docId`;
export const chatPath = `${claimsAppPath}/:id/chat`;
export const correspondencePath = `${claimsAppPath}/:id/correspondence`;
export const createMessagePath = `${claimsAppPath}/:id/create-message`;
export const linkedClaimsPath = `${claimsAppPath}/:id/linked-claims`;
