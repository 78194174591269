import axios from 'axios';
import moment from 'moment';
import { camelizeKeys, decamelizeKeys } from 'humps';

/* UTILS */
import serialize from 'utils/rest/serialize';
import RestActions from 'utils/rest/actions';
import { parseFilters } from 'utils/helpers';

/* PATH */
import {
  claimsCmsPath,
  claimsPathSecond,
  getClaimPath,
  getUpdateClaimPath,
  claimTypesPath,
  getDeleteClaimAnswerPath,
  claimLinksPath,
  getLinkedClaimsPath,
  getUpdateLinkedClaimsPath,
  getLinkedClaimsSearchPath,
  deleteClaimsPath,
  restoreClaimPath,
  duplicateClaimPath,
} from 'utils/paths';

/* TYPES */
import {
  SET_CLAIM_GROUPS,
  SET_CLAIM_ANSWER,
  FETCH_CLAIM_TYPES_START,
  FETCH_CLAIM_TYPES_FINISH,
  CLEAR_CLAIM_ANSWERS,
  DELETE_EMPTY_ANSWERS,
  CLEAR_CLAIM_DATA,
  CLEAR_CLAIM_GROUP,
  LINK_CLAIMS_START,
  LINK_CLAIMS_FINISHED,
  FETCH_LINKED_CLAIMS_START,
  FETCH_LINKED_CLAIMS_FINISHED,
  UPDATE_LINKED_CLAIMS_START,
  UPDATE_LINKED_CLAIMS_FINISHED,
  FETCH_LINKED_CLAIMS_SEARCH_START,
  FETCH_LINKED_CLAIMS_SEARCH_FINISHED,
} from './types';
import { isFiltering } from 'pages/Claims/utils/helpers';

const restClaimActions = new RestActions('claim');

const {
  fetchAllStart,
  fetchAllFinished,
  fetchFinished,
  updateFinished,
  createFinished,
  fetchStart,
  updateStart,
  deleteStart,
  deleteFinished,
  createStart,
} = restClaimActions;

const formattedClaims = (data) => {
  return data.claims.map((claim) => ({
    ...claim,
    userFullName: claim.agent
      ? `${claim.agent.first_name} ${claim.agent.last_name}`
      : '',
    customerFullName: claim.customer
      ? `${claim.customer.first_name || ''} ${claim.customer.last_name || ''}`
      : '',
    type: claim.claim_type ? claim.claim_type.name : '',
    status: claim.status
      ? `${claim.status
          .toLowerCase()
          .replace(/_/g, ' ')
          .replace(/^\w/, (c) => c.toUpperCase())}`
      : '',
    internalRef: claim.ref_id,
  }));
};

/* ACTIONS */
export const fetchClaims =
  (params = {}, filters = []) =>
  (dispatch) => {
    dispatch(fetchAllStart());
    const processedParams = { ...params };
    const containsArchivedFilter = filters.find(
      (filter) =>
        filter.variable === 'archived' && filter.filterValue === 'true'
    );
    const notArchivedFilter = containsArchivedFilter
      ? [...filters]
      : [
          ...filters,
          {
            variable: 'archived',
            filter: 'eq',
            filterValue: 'false',
          },
        ];
    const searching = processedParams.search?.value;
    const filtering =
      isFiltering(filters) &&
      processedParams?.filterPage &&
      !processedParams?.search?.value;

    if (searching) {
      processedParams.page = processedParams.searchPage || 1;
    }

    if (filtering) {
      processedParams.page = processedParams?.filterPage || 1;
    }

    return axios
      .get(
        `${claimsPathSecond}${serialize(processedParams, true)}${parseFilters(
          notArchivedFilter,
          processedParams
        )}`
      )
      .then(
        ({ data }) => {
          const res = {
            ...data,
            result: formattedClaims(data),
          };

          dispatch(fetchAllFinished(camelizeKeys(res)));
          dispatch(clearClaimData());
          return res;
        },
        (error) => {
          dispatch(fetchAllFinished(error));
        }
      );
  };

export const fetchClaim = (id, withoutDispatch) => (dispatch) => {
  dispatch(fetchStart());
  return axios.get(getClaimPath(id)).then(
    ({ data }) => {
      const formattedClaim = {
        ...data.claim,
        type: data.claim.claim_type.name,
        formattedCreatedAt: moment(data.claim.created_at).format('DD MMM YYYY'),
        customerFullName: data.claim.customer
          ? `${data.claim.customer.first_name || ''} ${
              data.claim.customer.last_name || ''
            }`
          : '',
        customerId: data.claim.customer ? data.claim.customer.id : '',
        customerRefId: data.claim.customer ? data.claim.customer.ref_id : '',
        internalRef: data.claim.ref_id,
        user: data.claim.customer,
      };
      if (!withoutDispatch) {
        dispatch(fetchFinished(camelizeKeys(formattedClaim)));
      }

      return formattedClaim;
    },
    (error) => {
      dispatch(fetchFinished(error));
    }
  );
};

export const updateClaim =
  (id, params = {}) =>
  (dispatch) => {
    dispatch(updateStart());
    return axios.put(getClaimPath(id), decamelizeKeys(params)).then(
      ({ data }) => {
        dispatch(updateFinished(camelizeKeys(data.claim)));
      },
      (error) => {
        dispatch(updateFinished(error));
      }
    );
  };

export const restoreClaim =
  (id, params = {}) =>
  (dispatch) => {
    dispatch(updateStart());
    return axios.post(restoreClaimPath(id), decamelizeKeys(params)).then(
      ({ data }) => {
        dispatch(updateFinished(camelizeKeys(data.claim)));
      },
      (error) => {
        dispatch(updateFinished(error));
      }
    );
  };

export const duplicateClaim =
  (id, params = {}) =>
  (dispatch) => {
    dispatch(updateStart());
    return axios.post(duplicateClaimPath(id), decamelizeKeys(params)).then(
      ({ data }) => {
        dispatch(updateFinished(camelizeKeys(data.claim)));
      },
      (error) => {
        dispatch(updateFinished(error));
      }
    );
  };

export const deleteClaim =
  (params = {}) =>
  (dispatch) => {
    dispatch(deleteStart());
    return axios.delete(deleteClaimsPath, params).then(
      (result) => {
        dispatch(deleteFinished(result));
      },
      (error) => {
        dispatch(deleteFinished(error));
      }
    );
  };

export const updateClaimAnswers = (id, params) => (dispatch) => {
  dispatch(updateStart());
  return axios.put(getUpdateClaimPath(id), decamelizeKeys(params)).then(
    ({ data }) => {
      dispatch(updateFinished(camelizeKeys(data.result)));
    },
    (error) => {
      dispatch(updateFinished(error));
    }
  );
};

export const createClaim =
  (params = {}) =>
  (dispatch) => {
    dispatch(createStart());
    return axios
      .post(claimsCmsPath, decamelizeKeys(params, { split: /(?=[A-Z0-9])/ }))
      .then(
        ({ data }) => {
          const formattedClaim = {
            ...data.result,
            type: data.result.claim_type.name,
            formattedCreatedAt: moment(data.result.createdAt).format(
              'DD MMM YYYY'
            ),
            customerFullName: data.result.user
              ? `${data.result.user.first_name || ''} ${
                  data.result.user.last_name || ''
                }`
              : '',
            customerId: data.result.user ? data.result.user.id : '',
          };
          dispatch(createFinished(camelizeKeys(formattedClaim)));
        },
        (error) => {
          dispatch(
            createFinished({
              hasErrors: true,
              errors: camelizeKeys(error.response.data.result),
            })
          );
        }
      );
  };

export const setClaimGroups = (payload) => (dispatch) => {
  dispatch({
    type: SET_CLAIM_GROUPS,
    payload,
  });
};

export const clearClaimGroups = () => (dispatch) => {
  dispatch({
    type: CLEAR_CLAIM_GROUP,
  });
};

export const setClaimAnswer = (answ) => (dispatch) => {
  dispatch({
    type: SET_CLAIM_ANSWER,
    payload: answ,
  });
};

export const fetchClaimTypes = () => (dispatch) => {
  dispatch({ type: FETCH_CLAIM_TYPES_START });
  return axios.get(`${claimTypesPath}?status_eq=enabled&per_page=10000`).then(
    ({ data }) => {
      dispatch({
        type: FETCH_CLAIM_TYPES_FINISH,
        payload: camelizeKeys(data),
      });
    },
    (error) => {
      dispatch({
        type: FETCH_CLAIM_TYPES_FINISH,
        payload: error,
      });
    }
  );
};

export const closeClaim =
  (id, params = {}) =>
  (dispatch) => {
    dispatch(updateStart());
    return axios
      .put(getClaimPath(id), decamelizeKeys(params, { split: /(?=[A-Z0-9])/ }))
      .then(
        ({ data }) => {
          const formattedClaim = {
            ...data.claim,
            type: data.claim.claim_type.name,
            formattedCreatedAt: moment(data.claim.createdAt).format(
              'DD MMM YYYY'
            ),
            customerFullName: data.claim.customer
              ? `${data.claim.customer.first_name || ''} ${
                  data.claim.customer.last_name || ''
                }`
              : '',
            customerId: data.claim.customer ? data.claim.customer.id : '',
          };
          dispatch(updateFinished(camelizeKeys(formattedClaim)));
        },
        (error) => {
          dispatch(updateFinished(error));
        }
      );
  };

export const clearClaimAnswers = () => (dispatch) => {
  dispatch({
    type: CLEAR_CLAIM_ANSWERS,
  });
};

export const deleteEmptyAnswers = (claimId, answers) => (dispatch) => {
  axios
    .all(
      answers.map((answ) =>
        axios.delete(getDeleteClaimAnswerPath(claimId, answ.questionId))
      )
    )
    .then(
      axios.spread(
        (...res) => {
          dispatch({
            type: DELETE_EMPTY_ANSWERS,
            payload: res,
          });
        },
        (error) => {
          dispatch({
            type: DELETE_EMPTY_ANSWERS,
            payload: error,
          });
        }
      )
    );
};

export const clearClaimData = (payload) => (dispatch) => {
  dispatch({
    type: CLEAR_CLAIM_DATA,
    payload,
  });
};

export const linkClaimsStart = () => ({
  type: LINK_CLAIMS_START,
});

export const linkClaimsFinished = (data) => ({
  type: LINK_CLAIMS_FINISHED,
  payload: data,
});

export const linkClaims = (ids) => (dispatch) => {
  dispatch(linkClaimsStart());

  return axios.post(claimLinksPath, { claim_ids: ids }).then(
    ({ data }) => {
      dispatch(linkClaimsFinished(camelizeKeys(data)));
      return data;
    },
    (error) => {
      dispatch(linkClaimsFinished(error));
    }
  );
};

export const fetchLinkedClaimsStart = () => ({
  type: FETCH_LINKED_CLAIMS_START,
});

export const fetchLinkedClaimsFinished = (data) => ({
  type: FETCH_LINKED_CLAIMS_FINISHED,
  payload: data,
});

export const fetchLinkedClaims =
  (id, params = {}) =>
  (dispatch) => {
    dispatch(fetchLinkedClaimsStart());

    return axios
      .get(`${getLinkedClaimsPath(id)}${serialize(params, true)}`)
      .then(
        ({ data }) => {
          const formattedLinks = data.linked_claims.map((item) => ({
            ...item,
            claim: {
              ...item.claim,
              userFullName: item.claim.agent
                ? `${item.claim.agent.first_name} ${item.claim.agent.last_name}`
                : '',
              customerFullName: item.claim.customer
                ? `${item.claim.customer.first_name || ''} ${
                    item.claim.customer.last_name || ''
                  }`
                : '',
              type: item.claim.claim_type ? item.claim.claim_type.name : '',
              status: item.claim.status
                ? `${item.claim.status.replace('_', ' ')}`
                : '',
              internalRef: item.claim.ref_id,
            },
          }));

          const res = {
            ...data,
            result: formattedLinks,
          };

          dispatch(fetchLinkedClaimsFinished(camelizeKeys(res)));
        },
        (error) => {
          dispatch(fetchLinkedClaimsFinished(error));
        }
      );
  };

export const updateLinkedClaimsStart = () => ({
  type: UPDATE_LINKED_CLAIMS_START,
});

export const updateLinkedClaimsFinished = (data) => ({
  type: UPDATE_LINKED_CLAIMS_FINISHED,
  payload: data,
});

export const updateLinkedClaims = (id, ids) => (dispatch) => {
  dispatch(updateLinkedClaimsStart());

  return axios.put(getUpdateLinkedClaimsPath(id), { claim_ids: ids }).then(
    ({ data }) => {
      dispatch(updateLinkedClaimsFinished(camelizeKeys(data)));
      return data;
    },
    (error) => {
      dispatch(updateLinkedClaimsFinished(error));
    }
  );
};

export const fetchLinkedClaimsSearchStart = () => ({
  type: FETCH_LINKED_CLAIMS_SEARCH_START,
});

export const fetchLinkedClaimsSearchFinished = (data) => {
  return {
    type: FETCH_LINKED_CLAIMS_SEARCH_FINISHED,
    payload: data,
  };
};

export const fetchLinkedClaimsSearch =
  (id, params = {}) =>
  (dispatch) => {
    dispatch(fetchLinkedClaimsSearchStart());

    return axios
      .get(`${getLinkedClaimsSearchPath(id)}${serialize(params, true)}`)
      .then(
        ({ data }) => {
          const res = {
            ...data,
            result: formattedClaims(data),
          };

          dispatch(fetchLinkedClaimsSearchFinished(camelizeKeys(res)));
          return res;
        },
        (error) => {
          dispatch(fetchLinkedClaimsSearchFinished(error));
        }
      );
  };
