import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icons from 'common/Icons';

import './styles.scss';
import InputErrors from 'components/InputErrors';
import fileUploadingValidation from 'validation/fileUploadingValidation';
import { FILE_TYPES } from 'utils/constants';

class VideoLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputName: null,
      fileLoading: false,
      deleting: null,
      validationError: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { fileLoading } = this.state;
    const { task, value, previewLoaded, fieldErrors } = this.props;
    const questionAnswerDocument = this.getValueFromTask();

    if (prevProps.task.fetchingOne && task.fetchedOne) {
      this.clear();
    }

    if (
      prevProps.value?.length === 0 &&
      (value?.length > 0 || questionAnswerDocument?.length > 0) &&
      fileLoading
    ) {
      this.completeLoading();
    }

    // Clear errors if there were fieldErrors before and now aren't
    if (
      prevProps &&
      this.props &&
      prevProps.fieldErrors &&
      fieldErrors &&
      Object.keys(prevProps.fieldErrors).length > 0 &&
      Object.keys(fieldErrors).length === 0
    ) {
      this.setState({ validationError: null });
    }
    if (!prevProps.previewLoaded && previewLoaded) {
      this.completeLoading();
    }
  }

  getFileData = (document) => {
    const result = {
      input: this.props.name,
      fileId: document.id,
      file: document.url ? document.url.original : '',
      name: document.description,
    };
    return result;
  };

  completeLoading = () => {
    this.setState({ fileLoading: false });
  };

  loadFile = async (e) => {
    e.persist();

    const video = e.target.files[0];
    const inputName = e.target.name;
    const { validationError } = this.state;
    const { setFieldErrors, clearFieldError, onUploadVideoClick } = this.props;
    let videoUploadingError;

    if (video) {
      videoUploadingError = fileUploadingValidation(FILE_TYPES.video, video);
      if (videoUploadingError) {
        this.setState({
          validationError: videoUploadingError,
        });
        setFieldErrors({ [inputName]: videoUploadingError });

        return;
      }

      if (!videoUploadingError && validationError) {
        this.setState(
          {
            validationError: null,
          },
          () => {
            clearFieldError(inputName);
          }
        );
      }
    }

    if (!videoUploadingError) {
      this.setState({
        inputName,
        fileLoading: true,
      });
      await onUploadVideoClick(e);
    }
  };

  removeFile = (e, fileId) => {
    e.persist();
    e.preventDefault();
    e.stopPropagation();

    const { deleting, inputName } = this.state;
    const { withoutAutosave, removeSelectedVideo, name } = this.props;

    if (deleting) return;

    if (!withoutAutosave) {
      this.setState({ deleting: fileId });
    }

    const questionId = inputName ? inputName : name;

    removeSelectedVideo(questionId, fileId);
  };

  clear = () => {
    this.setState({ deleting: null });
  };

  getValueFromTask = () => {
    let result = null;
    const { task, id } = this.props;
    const taskAnswers = task?.data?.answers;
    if (taskAnswers?.length) {
      const currentQuestion = taskAnswers.find(
        (answer) => Number(answer?.questionId) === Number(id)
      );
      if (currentQuestion?.documents?.length)
        result = currentQuestion.documents;
    }

    return result;
  };

  render() {
    const {
      className,
      wrapperClass,
      name,
      label,
      disabled,
      style,
      document,
      value,
      newDesign,
    } = this.props;
    const { previews, hidden } = document;
    const { fileLoading, deleting, validationError } = this.state;
    const listLength = 12;
    let questionAnswerDocument = this.getValueFromTask();
    let loadedFile;

    if (questionAnswerDocument?.length) {
      loadedFile = this.getFileData(questionAnswerDocument[0]);
    } else if (!questionAnswerDocument && value?.length) {
      loadedFile = this.getFileData(value[0]);
    } else {
      loadedFile = previews.find(
        (prev) => prev.input === name && prev.file && !hidden.includes(prev.id)
      );
    }

    const deletingClassName = deleting ? 'deleting' : '';
    const fileId = loadedFile ? loadedFile?.fileId ?? loadedFile?.id : null;

    const newDesignStyles = newDesign ? 'new-design-field' : null;
    const newDesignLabel = newDesign ? 'new-design-video-upload-label' : null;

    return (
      <Fragment>
        <div
          className={`form-control-file-container ${newDesignStyles}`}
          style={style}
        >
          <span className={`form-control-file-label ${newDesignLabel}`}>
            {label}
          </span>
          <label
            htmlFor={name}
            onChange={this.loadFile}
            className={`${wrapperClass} form-control-file-wrapper flex justify-center items-center`}
          >
            <input
              type="file"
              id={name}
              className={`form-control form-control-file ${className}`}
              name={name}
              disabled={disabled}
            />
            {(fileLoading || (fileId && fileId === deleting)) && (
              <div className="lds-spinner">
                {Array.from(Array(listLength), (_, i) => (
                  <div key={i} />
                ))}
              </div>
            )}

            {loadedFile && !fileLoading && (
              <div className={`btn-background ${deletingClassName}`}>
                {deleting && <div className="loader white absolute" />}

                <video
                  src={loadedFile.file}
                  controls
                  className="video-content"
                />

                {!disabled && (
                  <button
                    className="background-remove"
                    onClick={(e) => this.removeFile(e, fileId)}
                  >
                    {!deleting && (
                      <Icons
                        iconName="close"
                        fill="#ff6081"
                        height={30}
                        width={30}
                      />
                    )}
                  </button>
                )}
              </div>
            )}

            {!loadedFile && !fileLoading && !disabled ? (
              <Icons iconName="plus" fill="#ff6081" height={40} width={40} />
            ) : null}
            {/* {!loadedFile && !fileLoading && !disabled && (
              <Icons iconName="plus" fill="#ff6081" height={40} width={40} />
            )} */}
          </label>
          {fileLoading && !deleting ? (
            <span className="form-control-file-sublabel">Uploading...</span>
          ) : null}
          {/* {fileLoading && !deleting && !videoUploadingError && (
            <span className="form-control-file-sublabel">Uploading...</span>
          )} */}
          {fileId && fileId === deleting ? (
            <span className="form-control-file-sublabel">Deleting...</span>
          ) : null}
          {loadedFile && !fileLoading && !deleting ? (
            <span className="form-control-file-sublabel">
              {loadedFile.name}
            </span>
          ) : null}
          {validationError ? <InputErrors errors={[validationError]} /> : null}
        </div>
      </Fragment>
    );
  }
}

VideoLoader.propTypes = {
  className: PropTypes.string,
  wrapperClass: PropTypes.string,
  name: PropTypes.string,
  onUploadVideoClick: PropTypes.func,
  document: PropTypes.shape({
    previews: PropTypes.array,
    hidden: PropTypes.array,
  }),
  removeSelectedVideo: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.arrayOf(Object),
  task: PropTypes.shape({
    fetchingOne: PropTypes.bool,
    fetchedOne: PropTypes.bool,
  }).isRequired,
  disabled: PropTypes.bool,
  previewLoaded: PropTypes.bool,
  withoutAutosave: PropTypes.bool,
  // videoUploadingError: PropTypes.string,
  fieldErrors: PropTypes.object,
};

VideoLoader.defaultProps = {
  className: '',
  wrapperClass: null,
  name: '',
  document: {},
  removeSelectedVideo: null,
  label: '',
  value: [],
  disabled: false,
  previewLoaded: false,
  withoutAutosave: false,
  onUploadVideoClick: null,
  // videoUploadingError: '',
  fieldErrors: {},
};

const mapStateToProps = (state) => ({
  document: state.document,
  task: state.task,
});

export default connect(mapStateToProps, {})(VideoLoader);
