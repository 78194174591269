import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

export const Popup = ({ isOpen, onBackdropClick, content }) =>
  isOpen && (
    <div
      onClick={onBackdropClick}
      className="popup-backdrop flex justify-center items-center"
      role="button"
      tabIndex={0}
    >
      {content}
    </div>
  );

Popup.propTypes = {
  content: PropTypes.node.isRequired,
};

export default Popup;
