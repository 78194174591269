import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';

/* UTILS */
import RestActions from 'utils/rest/actions';
import serialize from 'utils/rest/serialize';

/* TYPES */
import {
  SET_CUSTOMER_FIELD,
  SET_CUSTOMER_FIELDS,
  SET_CUSTOMER_EDIT_MODE,
  FETCH_AGENTS_START,
  FETCH_AGENTS_FINISHED,
  CLEAR_CUSTOMER_DATA,
} from './types';

import { setAccFields } from 'actions/account';

/* PATH */
import { usersPath, getUserPath, usersPathSecond } from 'utils/paths';

const restCustomerActions = new RestActions('customer');

const {
  fetchStart,
  fetchFinished,
  fetchAllStart,
  fetchAllFinished,
  deleteStart,
  deleteFinished,
  updateStart,
  updateFinished,
  createStart,
  createFinished,
} = restCustomerActions;

/* ACTIONS */
export const setCustomerField = (name, value) => (dispatch) => {
  dispatch({
    type: SET_CUSTOMER_FIELD,
    payload: { name, value },
  });
};

export const setCustomerFields = (fields) => (dispatch) => {
  dispatch({
    type: SET_CUSTOMER_FIELDS,
    payload: fields,
  });
};

export const fetchCustomers =
  (params = {}) =>
  (dispatch) => {
    dispatch(fetchAllStart());
    return axios
      .get(
        `${usersPathSecond}${serialize(params, true)}&roles_name_eq=customer`
      )
      .then(
        ({ data }) => {
          dispatch(fetchAllFinished(camelizeKeys(data)));
        },
        (error) => {
          dispatch(fetchAllFinished(error));
        }
      );
  };

export const fetchCustomer = (id, forAccount) => (dispatch) => {
  dispatch(fetchStart());
  return axios.get(getUserPath(id)).then(
    ({ data }) => {
      const formattedCustomer = {
        ...data.user,
        mobilePhone: data.user.phones.mobile_phone,
        workPhone: data.user.phones.work_phone,
        homePhone: data.user.phones.home_phone,
        workEmail: data.user.emails.work_email,
        homeEmail: data.user.emails.home_email,
      };
      dispatch(fetchFinished(camelizeKeys(formattedCustomer)));
      if (forAccount) {
        dispatch(setAccFields(camelizeKeys(formattedCustomer)));
      }
      dispatch(setCustomerFields(camelizeKeys(formattedCustomer)));
    },
    (error) => {
      dispatch(fetchFinished(error));
    }
  );
};

export const deleteCustomer = (id) => (dispatch) => {
  dispatch(deleteStart());
  return axios.delete(getUserPath(id)).then(
    (result) => {
      dispatch(deleteFinished(result));
    },
    (error) => {
      dispatch(deleteFinished(error));
    }
  );
};

export const updateCustomer =
  (id, params = {}) =>
  (dispatch) => {
    dispatch(updateStart());
    return axios.put(getUserPath(id), decamelizeKeys(params)).then(
      ({ data }) => {
        dispatch(updateFinished(data.result));
      },
      (error) => {
        dispatch(
          updateFinished({
            hasErrors: true,
            errors: error,
          })
        );
      }
    );
  };

export const createCustomer =
  (params = {}) =>
  (dispatch) => {
    dispatch(createStart());
    return axios
      .post(usersPath, decamelizeKeys(params, { split: /(?=[A-Z0-9])/ }))
      .then(
        ({ data }) => {
          dispatch(createFinished(camelizeKeys(data.user)));
        },
        (error) => {
          dispatch(
            createFinished({
              hasErrors: true,
              errors: camelizeKeys(error.response.data.result),
            })
          );
        }
      );
  };

export const setIsEditMode = (payload) => (dispatch) => {
  dispatch({
    type: SET_CUSTOMER_EDIT_MODE,
    payload,
  });
};

export const fetchAgents =
  (params = {}) =>
  (dispatch) => {
    dispatch({ type: FETCH_AGENTS_START });
    return axios
      .get(`${usersPathSecond}${serialize(params, true)}&roles_name_eq=agent`)
      .then(
        ({ data }) => {
          dispatch({
            type: FETCH_AGENTS_FINISHED,
            payload: camelizeKeys(data.users ? data.users : data.result),
          });
        },
        (error) => {
          dispatch({
            type: FETCH_AGENTS_FINISHED,
            payload: camelizeKeys(error),
          });
        }
      );
  };

export const clearCustomerData = () => (dispatch) => {
  dispatch({
    type: CLEAR_CUSTOMER_DATA,
  });
};
