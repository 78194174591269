import { stringify } from 'querystring';
import { decamelize, decamelizeKeys } from 'humps';

export const removeEmptyFields = (obj) => {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key]) {
      newObj[key] = obj[key];
    }
  });
  return newObj;
};

const serialize = (params, querify = false, searchFullValue = false) => {
  // searchFullValue parameter should be true in case we need to make a search
  // with full value, including whitespaces. For example, "test customer"

  const querifyer = (obj, filter = '_cont') =>
    Object.keys(obj).reduce((acc, key) => {
      const { search } = params;
      // Inner function to combine result query
      const constructKey = () => {
        const role = search?.role;
        const isOnlyActive = search?.onlyActive;
        const activeObj =
          isOnlyActive && 'active' in isOnlyActive && 'role' in isOnlyActive
            ? isOnlyActive
            : null;
        let result = `${key}${filter}`;

        if (role) {
          const roleQuery = `roles_name_eq=${role}&`;
          if (
            activeObj &&
            (activeObj?.active ?? null) &&
            (activeObj?.role ?? null) &&
            activeObj?.role === role
          ) {
            const activeQuery = `active=${activeObj?.active}&`;
            result = `${roleQuery}${activeQuery}${result}`;
          } else {
            result = `${roleQuery}${result}`;
          }
        }

        return result;
      };

      const contList =
        obj.search && obj.search.list && obj.search.list.cont
          ? obj.search.list.cont.join('_or_')
          : '';
      const objVal =
        obj.search && obj.search.value ? obj.search.value.toString() : '';
      const searchObj = {};
      const splittedVal =
        objVal && !searchFullValue
          ? objVal.split(' ').filter((v) => v.length !== 0)
          : '';

      if (splittedVal && splittedVal.length > 1) {
        searchObj[contList] = splittedVal.map((item) => item);
      } else {
        searchObj[contList] = searchFullValue
          ? objVal
          : objVal.replace(' ', '');
      }

      if (!obj[key]) return { ...acc };
      let newKey;
      switch (key) {
        case 'sort':
        case 's':
          return obj[key].field
            ? { ...acc, s: `${decamelize(obj[key].field)} ${obj[key].order}` }
            : { ...acc };
        case 'assoc':
        case 'page':
        case 'per_page':
          newKey = key;
          break;
        case 'filter':
        case 'filters':
          return { ...acc, ...querifyer(obj[key]) };
        case 'search':
          return obj[key].list
            ? { ...acc, ...querifyer(searchObj) }
            : { ...acc };
        default:
          newKey = constructKey();
      }

      return { ...acc, [newKey]: obj[key] };
    }, {});

  if (Object.keys(params).length > 0) {
    return querify
      ? `?${decodeURIComponent(stringify(querifyer(decamelizeKeys(params))))}`
      : `?${decodeURIComponent(stringify(decamelizeKeys(params)))}`;
  }
  return '';
};

export default serialize;
