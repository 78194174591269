import axios from "axios";
import { camelizeKeys } from "humps";
import _, { get } from "lodash";

/* UTILS */
import RestActions from "utils/rest/actions";

/* PATH */
import { recoveryPath, sessionsPathSecond } from "utils/paths";

/* REST ACTIONS */
import { setIsAuthorized, setToken, clearToken } from "./auth";
import { setAccFields } from "./account";

/* TYPES */
import { SET_NEW_PASSWORD, SET_REDIRECT_URL } from "./types";

const restSessionActions = new RestActions("session");
const {
  fetchStart,
  fetchFinished,
  createStart,
  createFinished
} = restSessionActions;

/* ACTIONS */
export const fetchSession = () => dispatch => {
  dispatch(fetchStart());
  return axios.get(sessionsPathSecond).then(
    ({ data }) => {
      setToken(data.user && data.user.auth_token ? data.user.auth_token : data.result.auth_token);
      dispatch(setIsAuthorized(true));
      dispatch(createFinished(camelizeKeys(data.user ? data.user : data.result)));
      dispatch(setAccFields(camelizeKeys(data.user ? data.user : data.result)));
    },
    error => {
      clearToken();
      dispatch(fetchFinished(error));
      dispatch(setIsAuthorized(false));
    }
  );
};

export const setRedirectUrl = payload => ({
  type: SET_REDIRECT_URL,
  payload
})

export const createSession = (credentials, redirectUrl) => dispatch => {
  dispatch(createStart());
  return axios.post(sessionsPathSecond, credentials).then(
    ({ data }) => {
      setToken(data.user && data.user.auth_token ? data.user.auth_token : data.result.auth_token);
      dispatch(setRedirectUrl(redirectUrl));
      dispatch(setIsAuthorized(true));
      dispatch(createFinished(camelizeKeys(data.user ? data.user : data.result)));
      dispatch(setAccFields(camelizeKeys(data.user ? data.user : data.result)));
    },
    error => {
      dispatch(
        createFinished({
          hasErrors: true,
          errorText: _.get(error, 'response.data.error', 'Incorrect email or password.')
        })
      );
      dispatch(setIsAuthorized(false));
      clearToken();
    }
  );
};

export const endSession = () => dispatch => {
  dispatch(setIsAuthorized(false));
  clearToken();
};

export const deleteSession = () => () => {
    axios.delete(sessionsPathSecond);
};

export const setNewPassword = payload => dispatch => {
  axios.put(recoveryPath, payload).then(
    ({ data }) => {
      dispatch({
        type: SET_NEW_PASSWORD,
        payload: camelizeKeys(data.success)
      });
    },
    error => {
      dispatch({
        type: SET_NEW_PASSWORD,
        error: get(error, 'response.data.result', null)
      });
    }
  );
};

export const createPingOneSession = (pingOneLoginPath, redirectUrl = '/claims') => dispatch => {
  dispatch(createStart());
  return axios.get(pingOneLoginPath)
    .then(res => {
      const data = get(res, 'data.result', null)
      if(data) {
        setToken(data.user && data.user.auth_token ? data.user.auth_token : data.result.auth_token);
        dispatch(setRedirectUrl(redirectUrl));
        dispatch(setIsAuthorized(true));
        dispatch(createFinished(camelizeKeys(data.user ? data.user : data.result)));
        dispatch(setAccFields(camelizeKeys(data.user ? data.user : data.result)));
      }
    })
    .catch(error => {
      dispatch(
        createFinished({
          hasErrors: true,
          errorText: error.message ?
            error.message :
            error.response.data.result
        })
      );
      dispatch(setIsAuthorized(false));
      clearToken();
    });
};

export const createAzureSession = (azureLoginPath, redirectUrl = '/claims', token) => dispatch => {
  dispatch(createStart());
  return axios.get(azureLoginPath)
    .then(res => {
      const data = get(res, 'data.result', null)
      if(data) {
        const parsedData = JSON.parse(data)
        const authToken = parsedData.user.auth_token
        if(parsedData) {
          if(authToken) {
            setToken(authToken);
          }
          dispatch(setRedirectUrl(redirectUrl));
          dispatch(setIsAuthorized(true));
          dispatch(createFinished(camelizeKeys(parsedData.user? parsedData.user : parsedData.result)));
          dispatch(setAccFields(camelizeKeys(parsedData.user ? parsedData.user : parsedData.result)));
        }
      }
    })
    .catch(error => {
      dispatch(
        createFinished({
          hasErrors: true,
          errorText: error.message ?
            error.message :
            error.response.data.result
        })
      );
      dispatch(setIsAuthorized(false));
      clearToken();
    });
};


export const fetchPingOneRedirectUrl = (path) => dispatch => {
  dispatch(fetchStart());
  return axios.get(path).then(
    ({ data }) => {
      let redirectUrl = get(data, 'result.url', null);
      if(redirectUrl) {
        window.location.href = redirectUrl
      }
    },
    error => {
      dispatch(fetchFinished(error));
    }
  );
};

export const fetchAzureRedirectUrl = (path) => dispatch => {
  dispatch(fetchStart());
  return axios.get(path).then(
    ({ data }) => {
      let redirectUrl = get(data, 'result.url', null);
      if(redirectUrl) {
        window.location.href = redirectUrl
      }
    },
    error => {
      dispatch(fetchFinished(error));
    }
  );
};
