import React from 'react';
import PropTypes from 'prop-types';

const ChangeAgentV2 = ({
  width = 18,
  height = 20,
  className = '',
  fill = '#000',
  onClick = () => {},
}) => (
  <div
    className={`la-icon icon-svg-menu ${className}`}
    onClick={onClick}
    role="presentation"
  >
    <svg width={width} height={height} viewBox="0 0 24 24">
      <path
        d="M8.5 8.5C8.5 6.56625 10.0662 5 12 5C13.9338 5 15.5 6.56625 15.5 8.5C15.5 10.4338 13.9338 12 12 12C10.0662 12 8.5 10.4338 8.5 8.5Z"
        fill={fill}
      />
      <path
        d="M12 13.75C9.66375 13.75 5 14.9225 5 17.25V19H19V17.25C19 14.9225 14.3363 13.75 12 13.75Z"
        fill={fill}
      />
      <path
        d="M21.4025 8.58002L21.99 9.17168L18.6567 12.505L16.99 10.8425L17.5817 10.255L18.6567 11.3259L21.4025 8.58002Z"
        fill={fill}
      />
    </svg>
  </div>
);

ChangeAgentV2.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default ChangeAgentV2;
