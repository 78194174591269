import React from 'react';
import PropTypes from 'prop-types';

const Chain = ({
  width = 13,
  height = 13,
  className = '',
  fill = '#bbbdca',
}) => (
  <div className={`la-icon icon-svg-link ${className}`}>
    <svg width={width} height={height} viewBox="0 0 13 13">
      <g transform="translate(-430.000000, -658.000000)" fill={fill}>
        <path
          d="M442.053416,660.943226 L440.518935,659.424925 C439.839745,658.756271 438.73686,658.751756 438.056165,659.4174 L436.353486,661.078312 C435.671661,661.74358 435.668651,662.827274 436.34596,663.495552 L437.319027,662.560866 C437.220441,662.263226 437.34198,661.922314 437.582801,661.686385 L438.666119,660.630159 C439.009289,660.297149 439.559791,660.298654 439.898445,660.633922 L440.819962,661.544526 C441.158616,661.879794 441.157111,662.41976 440.816199,662.753522 L439.732881,663.810501 C439.503725,664.034013 439.127442,664.156681 438.835446,664.078038 L437.882322,665.013476 C438.56076,665.683636 439.662892,665.686646 440.345093,665.021002 L442.045891,663.361595 C442.729596,662.695199 442.731854,661.612633 442.053416,660.943226 Z"
          id="Path"
        ></path>
        <path
          d="M435.819164,667.021697 C435.900065,667.312564 435.770624,667.688847 435.5362,667.917627 L434.524375,668.906121 C434.177443,669.244776 433.616029,669.242894 433.270601,668.901606 L432.330647,667.975574 C431.985972,667.634662 431.98823,667.080397 432.335162,666.743248 L433.347739,665.754753 C433.594581,665.515061 433.949039,665.393146 434.258343,665.495495 L435.234421,664.528448 C434.543566,663.847 433.419233,663.843613 432.725368,664.520922 L431.083646,666.123887 C430.389028,666.802325 430.385642,667.905209 431.07612,668.588162 L432.639199,670.133555 C433.329301,670.814251 434.452882,670.817637 435.148252,670.140329 L436.789974,668.537364 C437.483839,667.858174 437.486849,666.756042 436.797499,666.073841 L435.819164,667.021697 Z"
          id="Path"
        ></path>
        <path
          d="M434.70915,665.64592 C434.88183,665.821013 435.164448,665.822715 435.340519,665.64819 L437.447438,663.543668 C437.622944,663.368575 437.624075,663.085361 437.449699,662.909701 C437.276454,662.735175 436.994401,662.73404 436.81946,662.907998 L434.711976,665.011952 C434.536753,665.186478 434.535622,665.469692 434.70915,665.64592 Z"
          id="Path"
        ></path>
      </g>
    </svg>
  </div>
);

Chain.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default Chain;
