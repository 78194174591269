import React, { Component } from "react";
import PhoneInput from "react-phone-number-input";
import PropTypes from "prop-types";

import InputErrors from "../InputErrors";

import "./styles.scss";

class InternationalPhoneInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || ""
    };
    this.timeoutDelay = null;
  }

  onBlur = () => {
    const { isRequired } = this.props;

    if (this.props.onBlur) {
      this.props.onBlur();
    }

    if (isRequired && !!this.state.value && this.state.value.length > 0) {
      this.setState({ errors: [] });
    }
  };

  onChange = (val, key) => {
    const { withDelay } = this.props;

    this.props.onChange(val, key);

    if (this.timeoutDelay) clearTimeout(this.timeoutDelay);

    if (withDelay) {
      this.timeoutDelay = setTimeout(() => {
        this.props.onChange(this.state.value, this.props.name);
        clearTimeout(this.timeoutDelay);
      }, 300);
    }
  };

  onKeyPress = e => {
    if (e.charCode === 13) {
      this.props.onChange(e.target.value, e.target.name);
      return;
    }

    if (this.props.onKeyPress) {
      this.props.onKeyPress(e.target.value, e.target.name, e.charCode);
    }
  };

  onSufixClick = (name, value, preference) => {
    if (this.props.onSufixClick)
      this.props.onSufixClick(name, value, preference);
  };

  setHTML = (label, isRequired, isTmpMobile) => {
    const sup = isRequired ? !isTmpMobile ? '<sup class="text-color-gray">*</sup>' : '' : "";
    return {
      __html: `<div class="ellipsis"><span class="pointer-without-events">${label}<span>${sup}</div>`
    };
  };

  render() {
    const {
      name,
      mandatory,
      className,
      wrapperClass,
      isRequired,
      errors,
      disabled,
      sufix,
      prefix,
      labelAnimate,
      labelInside,
      preference,
      value,
      showPlaceholder,
      isTmpMobile,
      style
    } = this.props;

    let label = mandatory ? `${this.props.label} *` : this.props.label;

    const labelWrapperClass =
      labelInside || labelAnimate
        ? "form-control-label-animate"
        : "form-control-label-static";
    const errorArray = errors || this.state.errors;
    const errorClass =
      errorArray && errorArray.length > 0 ? "has-error" : "no-error";
    const sufixClass = sufix ? "form-control-has-sufix" : "";
    const prefixClass = prefix ? "form-control-has-prefix" : "";
    const labelInsideClass = labelInside
      ? "form-control-wrapper-label-inside"
      : "";
    const inputLabel = label && (
      <label
        htmlFor={name}
        className={`form-control-label ${
          disabled ? (value.length === 0 ? "disabled-label" : "") : ""
        }`}
        dangerouslySetInnerHTML={this.setHTML(label, isRequired, isTmpMobile)}
      />
    );

    return (
      <div
        className={`form-control-wrapper international-phone ${wrapperClass} ${
          !labelAnimate && !labelInside
            ? "form-control-wrapper-label-static"
            : ""
        } ${labelInsideClass}`}
        style={style}
      >
        {!labelAnimate && !labelInside && inputLabel}
        <div
          className={`form-control-container ${labelWrapperClass} form-control-valid`}
        >
          {prefix && <div className="form-control-prefix">{prefix}</div>}
          <PhoneInput
            id={name}
            placeholder={
              disabled ? "" : !!showPlaceholder ? "Enter phone number" : ""
            }
            value={value}
            name={name}
            onChange={val => this.onChange(val, name)}
            onBlur={this.onBlur}
            disabled={disabled}
            showCountrySelect={!disabled}
            limitMaxLength
            country="GB"
            defaultCountry="GB"
            className={`form-control ${className} ${errorClass} ${sufixClass} ${prefixClass}`}
          />
          {(labelInside || labelAnimate) && inputLabel}
          {sufix && (
            <div
              onClick={() => this.onSufixClick(name, value, preference)}
              className="form-control-sufix"
              role="button"
              tabIndex={0}
            >
              {sufix}
            </div>
          )}
        </div>
        <InputErrors errors={errorArray} />
      </div>
    );
  }
}

InternationalPhoneInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.string,
  className: PropTypes.string,
  wrapperClass: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  errors: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ),
  sufix: PropTypes.node,
  prefix: PropTypes.node,
  labelAnimate: PropTypes.bool,
  labelInside: PropTypes.bool,
  withDelay: PropTypes.bool,
  onSufixClick: PropTypes.func,
  preference: PropTypes.number
};

InternationalPhoneInput.defaultProps = {
  value: "",
  label: null,
  className: null,
  wrapperClass: null,
  isRequired: false,
  errors: [],
  disabled: false,
  sufix: null,
  prefix: null,
  labelAnimate: false,
  labelInside: false,
  onChange: null,
  onKeyPress: null,
  onBlur: null,
  withDelay: false,
  onSufixClick: null,
  preference: null
};

export default InternationalPhoneInput;
