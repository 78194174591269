import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import Layout from "../../components/Layout";
import NewPassword from "./containers/NewPassword";

import { newPasswordPath } from "./utils/paths";

class Password extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Layout.Auth>
        <Switch>
          <Route exact path={newPasswordPath} component={NewPassword} />
        </Switch>
      </Layout.Auth>
    );
  }
}

export default Password;
