import React from 'react';
import PropTypes from 'prop-types';

const Account = ({
  width = 15,
  height = 14,
  className = '',
  fill = '#FFF',
}) => (
  <div className={`la-icon icon-svg-account ${className}`}>
    <svg width={width} height={height} viewBox="0 0 15 14">
      <path
        fill={fill}
        fillRule="nonzero"
        d="M7.247 7a3.39 3.39 0 0 0 3.38-3.38A3.39 3.39 0 0 0 7.248.24a3.39 3.39 0 0 0-3.38 3.38A3.39 3.39 0 0 0 7.247 7zm0 1.69c-2.281 0-6.76 1.099-6.76 3.38v1.69h13.52v-1.69c0-2.281-4.478-3.38-6.76-3.38z"
        opacity="1"
      />
    </svg>
  </div>
);

Account.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default Account;
