import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import Link from 'components/Link';
import Icon from 'common/Icons';
import Icons from 'common/Icons';
import PropTypes from 'prop-types';
import './styles.scss';
import isPermitted from 'utils/isPermitted';
import { ENTITIES, PERMISSION_TYPE } from 'utils/constants';

const permissibleTypes = [
  'pdf',
  'msword',
  'ppt',
  'pptx',
  'vnd.openxmlformats-officedocument.wordprocessingml.document',
  'docx',
  'doc',
  'xls',
  'xlsx',
  'csv',
  'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'vnd.ms-excel',
];

const isDocumentFileType = (type) => {
  const documentTypes = [
    'docx',
    'doc',
    'msword',
    'vnd.ms-excel',
    'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'csv',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ];

  return !!documentTypes.find((d) => d === type.toLowerCase());
};

const imageTypes = ['png', 'gif', 'jpeg', 'jpg'];
const ExternalLink = (url, fileType) => (
  <Link
    wrapperClass="preview-open-image"
    onClick={(e) => {
      e.persist();
      e.preventDefault();
      window.open(
        `${
          fileType && isDocumentFileType(fileType)
            ? `https://docs.google.com/viewer?url=${encodeURIComponent(url)}`
            : url
        }`
      );
    }}
    value={<Icon iconName="link" fill="#000" width={22} height={22} />}
  />
);

const PDFViewer = ({
  url = '',
  classWrap = '',
  classContent = '',
  fileType = '',
  isMobile = false,
  hideDelete = true,
  handleDeleteDocument = () => {},
  session,
  iFrameClass = null,
}) => {
  const iframe = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    let timerId = '';
    const preview = iframe.current;

    if (preview !== null) {
      setIsLoaded(false);
      timerId = setInterval(() => {
        if (preview !== null) {
          // eslint-disable-next-line no-self-assign
          preview.src = preview.src;
        } else {
          clearInterval(timerId);
        }
      }, 2000);

      preview.onload = () => {
        clearInterval(timerId);
        setIsLoaded(true);
      };
    }

    return () => {
      clearInterval(timerId);
    };
  }, [url]);

  const roles = session && session.data ? session.data.roles : null;
  const isAllowedDeleteDocument = isPermitted(
    roles,
    ENTITIES.document,
    PERMISSION_TYPE.delete
  );

  return (
    <div className={`pdf-viewer ${classWrap}`}>
      <div
        className={`${classContent} container-fluid flex justify-center direction-column`}
      >
        {imageTypes.some((el) => fileType.toLowerCase().includes(el)) && (
          <>
            <div className="external-link">
              {isAllowedDeleteDocument && !hideDelete && (
                <button
                  className="delete-user-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDeleteDocument();
                  }}
                >
                  <Icons
                    iconName="trashcan"
                    fill="white"
                    height={12}
                    width={14}
                    className="remove_person"
                    onClick={(e) => {
                      e.preventDefault();
                      handleDeleteDocument();
                    }}
                  />
                </button>
              )}
              {ExternalLink(url)}
            </div>
            <div className="image-preview-holder">
              <img src={url} alt="" />
            </div>
          </>
        )}
        {permissibleTypes.some((el) => fileType.toLowerCase().includes(el)) && (
          <>
            <div className="external-link">
              {handleDeleteDocument && !hideDelete && (
                <button
                  className="delete-user-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDeleteDocument();
                  }}
                >
                  <Icons
                    iconName="trashcan"
                    fill="white"
                    height={12}
                    width={14}
                    className="remove_person"
                    onClick={(e) => {
                      e.preventDefault();
                      handleDeleteDocument();
                    }}
                  />
                </button>
              )}
              {ExternalLink(url, fileType)}
            </div>
            <div className={isLoaded ? 'loaded' : 'loading'}>
              <iframe
                className={iFrameClass}
                title="docPreview"
                ref={iframe}
                id="docsLoader"
                height="500px"
                width="100%"
                src={
                  fileType.toLowerCase().includes('pdf')
                    ? isMobile
                      ? `https://docs.google.com/viewer?url=${encodeURIComponent(
                          url
                        )}&embedded=true`
                      : url
                    : fileType &&
                      (fileType.toLowerCase() === 'docx' ||
                        fileType.toLowerCase() === 'doc' ||
                        fileType ===
                          'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
                    ? `https://docs.google.com/viewer?url=${encodeURIComponent(
                        url
                      )}&embedded=true`
                    : `https://docs.google.com/viewer?url=${url}&embedded=true`
                }
              ></iframe>

              <div className="loader fullfilled" />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ session }) => ({
  session,
});

PDFViewer.propTypes = {
  url: PropTypes.string.isRequired,
  classWrap: PropTypes.string.isRequired,
  classContent: PropTypes.string,
  navClass: PropTypes.string,
  fileType: PropTypes.string,
  handleDeleteDocument: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(PDFViewer);
