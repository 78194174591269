import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const Breadcrumb = ({
  className = '',
  children = null,
  withoutTabs = false,
}) => (
  <nav className={`breadcrumb-wrapper ${withoutTabs}`}>
    <ul className={`breadcrumb-list ${className}`}>
      {(children &&
        children.length > 0 &&
        children.map((item) => (
          <li
            key={children.indexOf(item)}
            className="breadcrumb-list-item-wrapper"
          >
            {item}
          </li>
        ))) || <li className="breadcrumb-list-item-wrapper">{children}</li>}
    </ul>
  </nav>
);

Breadcrumb.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  withoutTabs: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default Breadcrumb;
