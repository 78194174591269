import React from 'react';

import Input from 'components/Input';
import Button from 'components/Button';

export const addRefPopup = (label, labelInside, value, onChange, onClick) => (
  <div className="flex justify-between items-center direction-column">
    <Input
      labelInside={labelInside}
      label={label}
      onChange={onChange}
      className={'windowed'}
      name={'ref'}
      value={value}
      type="text"
    />
    <div className="popup-complete flex">
      <Button
        type="success"
        className="text-capitalize plus"
        value="Save"
        onClick={onClick}
      />
    </div>
  </div>
);

const Popup = {
  addRefPopup,
};

export default Popup;
