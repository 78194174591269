import axios from "axios";
import { camelizeKeys, decamelizeKeys } from "humps";

/* UTILS */
import RestActions from "utils/rest/actions";
import serialize from "utils/rest/serialize";

/* PATH */
import { getCustomerClaimsPath, getCustomerClaimPath } from "utils/paths";

/* TYPES */
import { SET_CUSTOMER_PRELOAD, CLEAR_CUSTOMER_PRELOAD } from "./types";

const restCustomerClaimActions = new RestActions("customerClaim");

const {
  fetchAllStart,
  fetchAllFinished,
  updateStart,
  updateFinished
} = restCustomerClaimActions;

/* ACTIONS */
export const fetchCustomerClaims = (id, params = {}) => dispatch => {
  dispatch(fetchAllStart());
  return axios
    .get(`${getCustomerClaimsPath(id)}${serialize(params, true)}`)
    .then(
      ({ data }) => {
        const formattedClaims = data.result.map(claim => ({
          ...claim,
          userFullName: claim.user
            ? `${claim.user.first_name} ${claim.user.last_name}`
            : "",
          type: claim.claim_type.name
        }));
        const res = {
          ...data,
          result: formattedClaims
        };

        dispatch(fetchAllFinished(camelizeKeys(res)));
      },
      error => {
        dispatch(fetchAllFinished(error));
      }
    );
};

export const updateClaim = (id, targetClaimId, params = {}) => dispatch => {
  dispatch(updateStart());
  return axios
    .put(getCustomerClaimPath(id, targetClaimId), decamelizeKeys(params))
    .then(
      ({ data }) => {
        dispatch(updateFinished(camelizeKeys(data.result)));
      },
      error => {
        dispatch(updateFinished(error));
      }
    );
};

export const setCustomerPreload = payload => dispatch => {
  dispatch({
    type: SET_CUSTOMER_PRELOAD,
    payload
  });
};

export const clearCustomerPreload = payload => dispatch => {
  dispatch({
    type: CLEAR_CUSTOMER_PRELOAD,
    payload
  });
};
