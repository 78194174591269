import React from 'react';
import PropTypes from 'prop-types';

const Download2 = ({
  width = 15,
  height = 14,
  className = '',
  fill = '#FFF',
}) => (
  <div className={`la-icon icon-svg-Download2 ${className}`}>
    <svg
      fill={fill}
      width={width}
      height={height}
      viewBox="0 0 512 512"
      version="1.1"
      x="0px"
      y="0px"
    >
      <g>
        <g>
          <polygon points="422,302 422,422 90,422 90,302 0,302 0,512 512,512 512,302 		" />
        </g>
      </g>
      <g>
        <g>
          <polygon points="286,210 286,0 226,0 226,210 166,210 256,345 346,210 		" />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  </div>
);

Download2.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default Download2;
