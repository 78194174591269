import { ROLES } from './constants';

export const roleIdsAdapter = roles => {
  return roles.map(el => ({
    id: el.id,
    name: el.name,
    value: el.id
  }));
};

export const roleIdsAdapterGetter = (roles = []) => {
  return roles.map(el => ({
    id: el.id,
    name: el.name,
    value: el.id
  }));
};

export const contactablesSortAdapter = obj => {
  obj.contactables.sort(function(a, b) {
    if (a.name < b.name) {
      return -1;
    }

    if (a.name > b.name) {
      return 1;
    }

    return 0;
  });

  return obj;
};

export const rolesAdapter = obj => {
  obj.roles.forEach(({ name }, index) => {
    if (
      name === ROLES.admin || name === ROLES.agent || 
      name === ROLES.customer || name === ROLES.supervisor) {
      obj.roles[index].name = name.charAt(0).toUpperCase() + name.slice(1);
    }
  });

  return obj;
};
