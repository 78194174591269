import React from 'react';
import PropTypes from 'prop-types';

export const Main = ({ className = '', children }) => (
  <main className={`main-wrapper flex ${className}`}>{children}</main>
);

export const Controls = ({ children }) => (
  <section className="main-controls flex">
    <div className="main-controls-content flex direction-row justify-between items-center">
      {children}
    </div>
  </section>
);

export const Aside = ({ children = [] }) => (
  <aside
    className={`main-aside display-none ${
      children.length === 0 ? 'clear' : ''
    }`}
  >
    {children || null}
  </aside>
);

export const Content = ({ title = '', className = '', children }) => (
  <section className="main-content">
    <div className="container-fluid">
      {title ? (
        <div className="row">
          <div className="col-xs-12">
            <h1 className="content-title">{title}</h1>
          </div>
        </div>
      ) : null}

      <div className={`row ${className}`}>{children}</div>
    </div>
  </section>
);

Content.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
};

Controls.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Aside.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Main.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

const Grid = {
  Main,
  Aside,
  Content,
  Controls,
};

export default Grid;
