import React from 'react'
import Icons from 'common/Icons';
import Link from 'components/Link';
import { loginAppPath } from 'utils/paths';

const pageTitle = 'Client Hub & Chatbot: Client Advice and Support'

const Help = ({ history }) => {
  const handleNavigation = e => {
    e.persist()
    setActiveClass(e.target.name)
  }

  const setActiveClass = name => {
    const previousActiveLink = document.querySelector('a.active-help')
    if(previousActiveLink) {
      previousActiveLink.classList.remove('active-help')
    }
    const activeLink = document.querySelector('a.' + name)
    if(activeLink) {
      activeLink.classList.add('active-help')
    }
  }

  return (
    <div className="help">
      <div className="help-goback-holder">
        <Link
          className="help-goback"
          value={<Icons iconName="leftArrow"  fill="#EB312D"  />}
          type="primary-secondary"
          onClick={e => {
            e.preventDefault();
            history.push(loginAppPath);
          }}
        />
      </div>
    
      <h1 className="help-title">{pageTitle}</h1>

      <div className="help-content">
      <div className="help-menu">
        {/* <h2 className="help-content-title">Contents</h2> */}
          <a name="general-help" onClick={handleNavigation} href="#general-help" className="help-table-content general-help">General Help</a>
            <a name="supported-browser" onClick={handleNavigation} href="#supported-browser" className="help-table-subcontent supported-browser">Using a Supported Internet Browser</a>
            <a name="uptodate-browser" onClick={handleNavigation} href="#uptodate-browser" className="help-table-subcontent uptodate-browser">Making Sure Your Internet Browser Is Up To Date</a>
            <a name="browser-cookies" onClick={handleNavigation} href="#browser-cookies" className="help-table-subcontent browser-cookies">Clearing Your Internet Browser Cookies And Cache</a>
            <a name="ad-blocker" onClick={handleNavigation} href="#ad-blocker" className="help-table-subcontent ad-blocker">Disabling Your Ad Blocker</a>
          <a name="using-chatbot" onClick={handleNavigation} href="#using-chatbot" className="help-table-content using-chatbot">Using the Chatbot</a>
            <a name="internet-connection" onClick={handleNavigation} href="#internet-connection" className="help-table-subcontent internet-connection">Having a Stable Internet Connection</a>
            <a name="correct-link" onClick={handleNavigation} href="#correct-link" className="help-table-subcontent correct-link">Making Sure to Use the Correct Link</a>
            <a name="chatbot-dates" onClick={handleNavigation} href="#chatbot-dates" className="help-table-subcontent chatbot-dates">Entering Dates in the Chatbot</a>
            <a name="uploading-photos" onClick={handleNavigation} href="#uploading-photos" className="help-table-subcontent uploading-photos">Uploading Photos to Support Your Claim</a>
            <a name="mandatory-questions" onClick={handleNavigation} href="#mandatory-questions" className="help-table-subcontent mandatory-questions">Mandatory Questions</a>
          <a name="using-hub" href="#using-hub" onClick={handleNavigation} className="help-table-content using-hub">Using the Hub</a>
            <a name="setting-password" onClick={handleNavigation} href="#setting-password" className="help-table-subcontent setting-password">Setting a Password and Logging in for the First Time</a>
              <a name="securely-logging" onClick={handleNavigation} href="#securely-logging" className="help-table-subcontent-items securely-logging">Securely Logging in to the Hub</a>
              <a name="reseting-password" onClick={handleNavigation} href="#reseting-password" className="help-table-subcontent-items reseting-password">Resetting Your Password</a>
            <a name="changing-username" onClick={handleNavigation} href="#changing-username" className="help-table-subcontent changing-username">Changing Your Username/Email Address</a>
      </div>

      <div className="help-main">
        <h2 className="help-content-title" id="general-help">General Help</h2>

          <h3 id="supported-browser" className="help-content-subtitle">Using a Supported Internet Browser</h3>
            <p className="help-content-text">The chatbot and Hub is currently only compatible with the following web browsers:</p>
            <ul className="help-list">
              <li>Google Chrome</li>
              <li>Apple’s Safari</li>
              <li>Microsoft Edge</li>
            </ul>
            <p className="help-content-text">Any browser not listed above is incompatible and the chatbot will not function. This includes Mozilla Firefox and Internet Explorer.</p>
            <p className="help-content-text">
              If you don’t already have access to any of the browsers listed above, you can download Google Chrome form: 
              <a 
                className="help-content-text-link"
                href="https://www.google.co.uk/chrome" 
                target="_blank"
                rel="noopener noreferrer"
              > https://www.google.co.uk/chrome
              </a>
            </p>

          <h3 id="uptodate-browser" className="help-content-subtitle mt-3">Making Sure Your Internet Browser Is Up to Date</h3>
            <p className="help-content-text">
              If you run into an issue, we may ask you ensure your internet browser is up to date. 
              It’s advisable to keep your browser up to date anyway as browser updates often include security and bug fixes which keep you safe on the web.
            </p>
            <p className="help-content-text">
              Please follow this link to find out how to check if your browser is up to date:
              <br />
              <a 
                className="help-content-text-link"
                href="https://www.whatismybrowser.com/guides/how-to-update-your-browser/" 
                target="_blank"
                rel="noopener noreferrer"
              > https://www.whatismybrowser.com/guides/how-to-update-your-browser/
              </a>
            </p>
            <p className="help-content-text">
              You may also want to consider updating your operating system to ensure you have the latest security patches
              <br />
              <a 
                className="help-content-text-link"
                href="https://www.whatismybrowser.com/guides/how-to-update-your-operating-system/" 
                target="_blank"
                rel="noopener noreferrer"
              > https://www.whatismybrowser.com/guides/how-to-update-your-operating-system/
              </a>
            </p>

          <h3 id="browser-cookies" className="help-content-subtitle mt-3">Clearing Your Internet Browser Cookies and Cache</h3>
            <p className="help-content-text">
              If you run into an issue, we may ask you to clear your web browser cookies and cache. 
              This will allow the chatbot or Hub web page to be completely reloaded. And not to worry, any previously provided data and answers won’t be cleared).
            </p>
            <p className="help-content-text">
              Please follow this link to find out how to clear your browser cache and cookies:
              <br />
              <a 
                className="help-content-text-link"
                href="https://www.whatismybrowser.com/guides/how-to-clear-cookies-browsing-history-and-cache/" 
                target="_blank"
                rel="noopener noreferrer"
              > https://www.whatismybrowser.com/guides/how-to-clear-cookies-browsing-history-and-cache/
              </a>
            </p>

          <h3 id="ad-blocker" className="help-content-subtitle mt-3">Disabling Your Ad-Blocker</h3>
            <p className="help-content-text">
              If you use an ad-blocker (sometimes referred to as a script blocker or content blocker) you must disable it. 
              This can be a browser extension, an independent application, tunnel, VPN, proxy, etc.
            </p>
            <p className="help-content-text">
              We have found that some ad-blockers interfere with the chatbot and possibly make it appear as though the chatbot cannot connect.
            </p>

          <div className="help-page-break"></div>

          <h2 id="using-chatbot" className="help-content-title">Using the Chatbot</h2>

          <h3 id="internet-connection" className="help-content-subtitle">Having a Stable Internet Connection</h3>
            <p className="help-content-text">
              So that none of the answers you provide are lost, even when your Wi-Fi or mobile data internet connection is lost, 
              you must have a stable internet connection while using the chatbot. This enables us to record your answers in real-time.
            </p>
            <p className="help-content-text">
              You may find that questions take a while to load or the chatbot reports it has lost connection if you do not have a stable internet connection. 
              We advise trying to use a wired or Wi-Fi connection (if Wi-Fi is more stable than mobile data) if you run into connection issues.
            </p>

          <h3 id="correct-link" className="help-content-subtitle mt-3">Making Sure to Use the Correct Link</h3>
            <p className="help-content-text">
              Depending on how long it takes for you to complete the chatbot’s questions you may receive reminder/chaser emails and text messages.
            </p>
            <p className="help-content-text">
              If you do, you must always use the link from the most recent email or text you have received. 
              This is because links in older emails and texts are disabled so that any answers you’ve provided are secure.
            </p>

          <h3 id="chatbot-dates" className="help-content-subtitle mt-3">Entering Dates in the Chatbot</h3>
            <p className="help-content-text">
              When the chatbot asks you for a date you must enter the date in a DD/MM/YYYY format.
            </p>
            <p className="help-content-text">
              This means you must include the forward slashes ( / ) in your answer.
            </p>

          <h3 id="uploading-photos" className="help-content-subtitle mt-3">Uploading Photos to Support Your Claim</h3>
            <p className="help-content-text">
              When completing the chatbot questions you may be asked to upload photos of the incident or damage. 
              If you wish to upload more than one photo you must select all the photos you wish to upload at once.
            </p>
            <p className="help-content-text">
              On a Windows PC this would involve selecting multiple photos in the Explorer window when prompted:
            </p>
            <img src="https://api-stage.claimtechnology.co.uk/images/faq/photos_banch.png" alt="Upload photos" className="help-image" />

          <h3 id="mandatory-questions" className="help-content-subtitle mt-3">Mandatory Questions</h3>
            <p className="help-content-text">
              Some questions the chatbot will ask are mandatory and must be answered in order for us to be able to process your claim.
            </p>
            <p className="help-content-text">
              While most questions the chatbot will ask can be skipped, 
              if any mandatory questions are skipped you will be asked when you want to answer them at the end of the initial conversation with the chatbot:
            </p>
            <img src="https://api-stage.claimtechnology.co.uk/images/faq/thompsons_chat_dialog.png" alt="Remind me later" className="help-image" />
            <p className="help-content-text">
              If you chose “Answer now” the skipped questions will be re-asked immediately. Choosing “Remind me later” will end the conversation with the chatbot however, 
              you’ll instead receive reminder/chaser emails and/or text messages prompting you to complete the skipped questions.
            </p>
            <img src="https://api-stage.claimtechnology.co.uk/images/faq/thompsons_chat_dialog_2.png" alt="Skip" className="help-image" />
            <p className="help-content-text">
              If you are returning to the chatbot to complete skipped questions your previous answers will be displayed, 
              and you’ll be prompted to begin answering any previously skipped questions:
            </p>

          <div className="help-page-break"></div>

          <h2 id="using-hub" className="help-content-title">Using the Hub</h2>

          <h3 id="setting-password" className="help-content-subtitle">Setting a Password and Logging in for the First Time</h3>
            <p className="help-content-text">
              When you are first provided access to the Hub you will be required to set a password. 
              The password you set will be used to secure access to your documents, messages and claim information held on the Hub.
            </p>
            <p className="help-content-text">
              Whether setting your password for the first time or changing it you must set a password which meets the following requirements:
            </p>
            <ul className="help-list">
              <li>Must be at least 8 characters long</li>
              <li>Must contain at least one UPPERCASE letter</li>
              <li>Must contain at least one lowercase letter</li>
              <li>Must contain at least one number</li>
              <li>Must contain at least one symbol or special character</li>
            </ul>

          <h3 id="securely-logging" className="help-content-subtitle mt-3">Securely Logging in to the Hub</h3>
            <p className="help-content-text">
              When logging into the Hub please bear in mind that if you enter your password incorrectly 5 times within a 5-minute period your account will temporally locked for 20 minutes.
            </p>
            <p className="help-content-text">
              This security measure is to prevent malicious third parties from attempting to repeatedly guess account passwords on the Hub.
            </p>
            <p className="help-content-text">
              While your account is locked you will not be able to login to the Hub and will be notified that you have entered an invalid password or username.
            </p>
            <p className="help-content-text">
              You’ll also receive an email notifying you that your account is locked. 
              The email will contain a link that will allow you to immediately unlock your account.Please ensure to check your junk or spam email folders for the account unlock email.
            </p>

          <h3 id="reseting-password" className="help-content-subtitle mt-3">Resetting Your Password</h3>
            <p className="help-content-text">
              Please follow this link to find out how to clear your browser cache and cookies:
            </p>
            <a 
              className="help-content-text-link"
              href="https://clienthub.thompsons.law/" 
              target="_blank"
              rel="noopener noreferrer"
            > https://clienthub.thompsons.law/
            </a>
            <p className="help-content-text">
              Then from the login page click “Forgot credentials?”:
            </p>
            <img src="https://api-stage.claimtechnology.co.uk/images/faq/reset_password_selected.png" alt="Forgot credentials" className="help-image" />
            <p className="help-content-text">
              On the next page enter the email address which you provided Thompsons and click the “Send reset” button. 
              You’ll then be notified that an email has been sent to you:
            </p>
            <img src="https://api-stage.claimtechnology.co.uk/images/faq/link_with_new_password_sent.png" alt="Send reset" className="help-image" />
            <p className="help-content-text">
              You’ll now need to wait to receive an email containing a link to reset your password. Please ensure to check your junk or spam email folders.
            </p>
            <p className="help-content-text">
              Once the email has been received follow the link in the email to set a new password.
            </p>
            <p className="help-content-text">
              Please note the following restrictions in relation to the link you will receive:
            </p>
            <ul className="help-list">
              <li>The link will expire after being used <b>once</b></li>
              <li>The link will expire after <b>48 hours</b></li>
              <li><b>The link will still appear to work after it has been used once or after 48 hours has passed</b></li>
            </ul>
            <p className="help-content-text">
              If your account had been previously locked due to multiple failed login attempts 
              (see <a href="#securely-logging" className="help-content-subtitle">Securely Logging in to the Hub</a>) you account will be unlocked as soon as you set a new password.
            </p>

          <h3 id="changing-username" className="help-content-subtitle mt-3">Changing Your Username/Email Address</h3>
            <p className="help-content-text">
              Whether you contact Thompsons to update your email address or change your email address on the Hub you will receive an email 
              (sent to your new email address) asking you to confirm the change.
            </p>
            <p className="help-content-text">
              Please ensure to check your junk or spam email folders. Once the email has been received follow the link to begin confirming your new email address.
            </p>
            <p className="help-content-text">
              The page the link will take you to will prompt you to confirm the change, to do so click the “Confirm Email Address Change” button:
            </p>
            <img src="https://api-stage.claimtechnology.co.uk/images/faq/confirm_email_address_change.png" alt="Confirm Email Address Change" className="help-image" />
            <p className="help-content-text">
              You’ll then be forwarded to the Hub login page, where you can now login using your new email address and previously set password.
            </p>
            <p className="help-content-text">
              Please note the following restrictions in relation to the link you will receive:
            </p>
            <ul className="help-list">
              <li>The link will expire after being used <b>once</b></li>
              <li>The link will expire after <b>48 hours</b></li>
              <li>If the link expires your account’s email address will revert to the previous email address</li>
            </ul>
        </div>
      </div>
    </div>
  )
}

export default Help
