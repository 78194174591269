import { apiPathSecond } from 'utils/paths';

export const URL = (license) =>
  `${apiPathSecond}/status_page/claims?search_by[CLvehicleReg]=${license}`;

export const findNewest = (arr) => {
  let res = arr[0];

  for (const el of arr) {
    if (el['updated_at'] > res['updated_at']) res = el;
  }

  return res;
};

export const fieldsMapping = {
  'Repairer details': {
    'Repairer company name': 'RepairerName',
    'Repairer telephone number': 'RepairerTelMain',
    'Repairer email contact': 'RepairerEmailMain',
    'Estimate arranged date': 'EstimateToEngneer',
    'Estimate costs sent to engineer to authorise': 'EstimatedRecovery',
    'Repair costs authorised on': 'EstimateBID',
    'Repair start': 'RepairStartDate',
    'Repairs due to be complete by': 'EstimatedRepairCompletion',
    'Repairs completed': 'RepairCompletionDate',
  },
  'Vehicle details': {
    Make: 'CLVehicleMake',
    Model: 'CLVehicleModel',
    'Vehicle registration': 'CLvehicleReg',
    'Vehicle Damage': 'VehicleDamageDetail',
    'Can the vehicle be driven?': 'LegallyDriveable',
    'Is the vehicle a write off?': 'CLVehicleWriteOff',
  },
  'Your insurance details': {
    'Policy excess': 'ClientInsuranceExcess',
  },
  'Accident details': {
    'Accident description': 'AccidentDetails',
    'Date of accident': '',
  },
};

export const dateFields = [
  'Date of accident',
  'Estimate arranged date',
  'Repair costs authorised on',
  'Repair start',
  'Repairs due to be complete by',
  'Repairs completed',
];

export const costFields = [
  'Estimate costs sent to engineer to authorise',
  'Policy excess',
];

export const formatDateValue = (date) => {
  if (!date) return '';
  let formattedDate = new Date(date);

  const options = { day: 'numeric', month: 'long', year: 'numeric' };

  const resultDate = new Intl.DateTimeFormat('en-GB', options).format(
    formattedDate
  );

  return resultDate;
};

export const processFieldValue = (fieldName, fieldValue) => {
  if (!fieldValue) return '';

  let processedValue = fieldValue;

  if (dateFields.includes(fieldName)) {
    processedValue = formatDateValue(fieldValue);
  } else if (costFields.includes(fieldName)) {
    processedValue = `£ ${fieldValue}`;
  } else if (fieldName === 'Is the vehicle a write off?') {
    processedValue = fieldValue === 'Y' ? 'Yes' : 'No';
  }

  return processedValue;
};

export const mapResult = (data) => {
  if (!data) return null;

  const result = {};

  for (const section in fieldsMapping) {
    if (Object.prototype.hasOwnProperty.call(fieldsMapping, section)) {
      const sectionFields = fieldsMapping[section];
      result[section] = {};

      for (const fieldKey in sectionFields) {
        if (Object.prototype.hasOwnProperty.call(sectionFields, fieldKey)) {
          const fieldValue = sectionFields[fieldKey];
          const targetDetail = data.find((item) => item.name === fieldValue);

          result[section][fieldKey] = processFieldValue(
            fieldKey,
            targetDetail?.value
          );
        }
      }
    }
  }
  return result;
};

export const defineProgress = (mappedFields) => {
  let progressStatus = '';

  if (
    mappedFields['Repairer details']['Repairer company name'] &&
    !(
      mappedFields['Repairer details']['Estimate arranged date'] &&
      mappedFields['Repairer details']['Repair costs authorised on'] &&
      mappedFields['Repairer details']['Repair start'] &&
      mappedFields['Repairer details']['Repairs due to be complete by'] &&
      mappedFields['Repairer details']['Repairs completed']
    )
  ) {
    progressStatus = 'Repairer Instructed';
  } else if (
    mappedFields['Repairer details']['Estimate arranged date'] &&
    !(
      mappedFields['Repairer details']['Repair costs authorised on'] &&
      mappedFields['Repairer details']['Repair start'] &&
      mappedFields['Repairer details']['Repairs due to be complete by'] &&
      mappedFields['Repairer details']['Repairs completed']
    )
  ) {
    progressStatus = 'Vehicle Estimated';
  } else if (
    mappedFields['Repairer details']['Repair costs authorised on'] &&
    !(
      mappedFields['Repairer details']['Repair start'] &&
      mappedFields['Repairer details']['Repairs completed'] &&
      mappedFields['Repairer details']['Repairs due to be complete by']
    )
  ) {
    progressStatus = 'Repairs Authorised';
  } else if (
    mappedFields['Repairer details']['Repair start'] &&
    !(
      mappedFields['Repairer details']['Repairs completed'] &&
      mappedFields['Repairer details']['Repairs due to be complete by']
    )
  ) {
    progressStatus = 'Repairs Started';
  } else if (
    mappedFields['Repairer details']['Repairs due to be complete by'] &&
    !mappedFields['Repairer details']['Repairs completed']
  ) {
    progressStatus = 'Estimated Completion Date';
  } else if (mappedFields['Repairer details']['Repairs completed']) {
    progressStatus = 'Repairs Completed';
  }

  return progressStatus;
};

export const getEstimatedCompletionDate = (status, data) => {
  if (!data) return '';

  const targetDetail = data.find((item) => item.name === status);

  return targetDetail?.value ?? '';
};
