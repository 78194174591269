import React from 'react';
import Settings from './Settings';

import Window from './Window';
import MainRouter from './Router';

import history from 'utils/history';
import { errorPath } from 'utils/paths';

import 'assets/scss/index.scss';

class App extends React.Component {
  componentDidCatch(error, errorInfo) {
    history.push(errorPath);
  }
  static getDerivedStateFromError(error) {
    history.push(errorPath);
  }

  render() {
    return (
      <Window>
        <Settings>
          <MainRouter />
        </Settings>
      </Window>
    );
  }
}

export default App;
