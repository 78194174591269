import React from 'react';
import PropTypes from 'prop-types';

const Phone = ({ width = 15, height = 14, className = '', fill = '#FFF' }) => (
  <div className={`la-icon icon-svg-phone ${className}`}>
    <svg width={width} height={height} viewBox="0 0 15 14">
      <path
        fill={fill}
        fillRule="nonzero"
        d="M14.057 10.696c-.037-.112-.274-.278-.71-.496a21.826 21.826 0 0 0-.506-.28 47.091 47.091 0 0 1-.594-.328 19.84 19.84 0 0 1-.5-.29 12.306 12.306 0 0 0-.234-.164 2.63 2.63 0 0 0-.332-.2.612.612 0 0 0-.267-.066c-.125 0-.28.089-.467.266a4.555 4.555 0 0 0-.515.58 5.64 5.64 0 0 1-.495.58c-.175.178-.319.267-.43.267a.636.636 0 0 1-.211-.047 2.182 2.182 0 0 1-.192-.08 3.496 3.496 0 0 1-.224-.13 5.77 5.77 0 0 0-.178-.108 9.83 9.83 0 0 1-2.198-1.627 9.825 9.825 0 0 1-1.628-2.199 5.431 5.431 0 0 0-.108-.177 3.371 3.371 0 0 1-.13-.225 2.026 2.026 0 0 1-.08-.192.633.633 0 0 1-.047-.21c0-.112.089-.256.267-.43.177-.175.37-.34.58-.496.209-.156.402-.328.58-.515.177-.187.266-.342.266-.467a.61.61 0 0 0-.065-.267 2.601 2.601 0 0 0-.201-.332 12.28 12.28 0 0 0-.164-.234c-.094-.156-.19-.322-.29-.5-.1-.178-.209-.376-.327-.594a22.25 22.25 0 0 0-.281-.505C4.158.793 3.993.556 3.88.519a.524.524 0 0 0-.196-.028c-.169 0-.388.031-.66.093a3.704 3.704 0 0 0-.64.197c-.312.13-.643.511-.992 1.14-.318.587-.477 1.167-.477 1.74 0 .169.01.333.033.492.022.159.06.338.117.538.056.2.101.347.135.444.034.097.098.27.192.52.093.249.15.401.168.457.219.612.477 1.157.777 1.637.492.799 1.164 1.623 2.015 2.475.852.85 1.676 1.523 2.474 2.015.48.3 1.026.559 1.638.777.056.019.208.075.458.168.25.094.422.158.519.192.096.034.245.08.444.136.2.056.379.095.538.117.16.022.323.033.491.033.574 0 1.154-.16 1.74-.477.63-.35 1.01-.68 1.141-.992.069-.156.134-.37.196-.64.063-.272.094-.492.094-.66a.521.521 0 0 0-.028-.197z"
      />
    </svg>
  </div>
);

Phone.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default Phone;
