const validation = {
  claim: [
    {
      type: 'isRequired',
      message: 'Please choice the claim.',
    },
  ],
};

export const fileTypeValidation = {
  fileType: [
    {
      type: 'fileType',
      message: 'Sorry this file type is not supported',
    },
  ],
};

export default validation;
