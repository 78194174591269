import React from 'react';
import PropTypes from 'prop-types';

const UnActiveLinkArrow = ({
  width = 11,
  height = 11,
  className = '',
  fill = '#1E201D',
}) => (
  <div className={`la-icon icon-svg-link ${className}`}>
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 22.936 22.936"
      width={width}
      height={height}
    >
      <path
        fill={fill}
        d="M0.008,7.73c0-0.619,0.492-1.111,1.111-1.111c0.619,0,1.127,0.492,1.127,1.111v11.381L21.023,0.333
        c0.429-0.444,1.143-0.444,1.571,0c0.444,0.444,0.444,1.143,0,1.587L3.833,20.682h11.364c0.619,0,1.111,0.508,1.111,1.127
        c0,0.619-0.492,1.127-1.111,1.127H1.119c-0.619,0-1.111-0.508-1.111-1.127V7.73z"
      />
    </svg>
  </div>
);

UnActiveLinkArrow.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default UnActiveLinkArrow;
