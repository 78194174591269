import React from 'react';
import './styles.scss';

const SPINNER_LIST = 12;

const Spinner = () => (
  <div className="media-upload-loader">
    {Array.from(Array(SPINNER_LIST), (_, i) => (
      <div key={i} />
    ))}
  </div>
);

export default Spinner;
