import React from 'react';

import List from 'components/List';
import Link from 'components/Link';
import Icons from 'common/Icons';

import './styles.scss';

const AsideMenu = () => (
  <List className="icons-list flex">
    <Link
      className="active"
      pathTo="#"
      value={<Icons iconName="edit" fill="" height={17} width={17} />}
    />
    <Link
      className=""
      pathTo="#"
      value={<Icons iconName="mail" fill="" height={17} width={17} />}
    />
    <Link
      className=""
      pathTo="#"
      value={<Icons iconName="message" fill="" height={17} width={17} />}
    />
    <Link
      className=""
      pathTo="#"
      value={<Icons iconName="phone" fill="" height={17} width={17} />}
    />
  </List>
);

export default AsideMenu;
