import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import InputErrors from "../InputErrors";

import "./styles.scss";

class AmountInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || "",
      maxLengthRestriction: props.maxLength || null
    };
    this.timeoutDelay = null;
  }

  onFocus = () => {
    if (this.props.type === "password") {
      this.setState({
        showFakePassword: false
      });
    }
  };

  onBlur = () => {
    const { isRequired, onBlur, type } = this.props;
    const { value } = this.state;

    if (onBlur) {
      onBlur();
    }

    if (isRequired && !!value && value.length > 0) {
      this.setState({ errors: [] });
    }

    if (type === "password" && this.props.value.length === 0) {
      this.setState({
        showFakePassword: true
      });
    }
  };

  validateInput = value => {
    const allovedSymbolsRegex = /^[0-9\-[\]$%()\\£.,%/<>;:]+$/;
    return value.split('').filter(s => allovedSymbolsRegex.test(s)).join('');
  };


  onChange = e => {
    e.persist()
    const value = this.validateInput(e.target.value);
    this.props.onChange(
      value,
      e.target.name
    );
  };

  onKeyPress = e => {
    if (e.charCode === 13) {
      this.props.onChange(e.target.value, e.target.name);
    }

    if (this.props.onKeyPress) {
      this.props.onKeyPress(e.target.value, e.target.name, e.charCode);
    }
  };

  onSufixClick = (name, value, preference) => {
    if (this.props.onSufixClick)
      this.props.onSufixClick(name, value, preference);
  };

  onSecondSufixClick = (name, value, preference) => {
    if (this.props.onSecondSufixClick)
      this.props.onSecondSufixClick(name, value, preference);
  };

  setHTML = (label, isRequired, isTmpMobile) => {
    const sup = isRequired ? !isTmpMobile ? '<sup class="text-color-gray">*</sup>' : '' : "";
    return {
      __html: `<div class="ellipsis"><span class="pointer-without-events">${label}<span>${sup}</div>`
    };
  };

  render() {
    const {
      name,
      className,
      isRequired,
      errors,
      autoFocus,
      sufix,
      prefix,
      labelAnimate,
      inputType,
      labelInside,
      searchInput,
      preference,
      secondSufix,
      mandatory,
      additionalClassName,
      labelClass,
      isTmp,
      isTmpMobile,
      style
    } = this.props;

    let label = mandatory ? `${this.props.label} *` : this.props.label;
    let { wrapperClass } = this.props;
    let { value, type, disabled } = this.props;
    // const allovedSymbolsRegex = /^[0-9\-\[\]$%()\\£.,%\/<>;:]+$/;
    // const processedValue = value.split('').filter(s => allovedSymbolsRegex.test(s)).join('')
    const tmpLabel = isTmp ? "tmp-label" : "";

    if (type === "date" && value) {
      value = moment(new Date(value)).format("YYYY-MM-DD");
    } else if (type === "checkBox") {
      if (value === "t") {
        value = true;
      } else if (value === "" && value === "_") {
        value = false;
      }
    } else if (type === "info") {
      value = value || "This information must be completed by the customer.";
      type = "text";
      disabled = true;
    }

    const activeClass =
      (labelInside || labelAnimate) && value && value.length > 0
        ? "form-control-valid"
        : "form-control-empty";
    const labelWrapperClass =
      labelInside || labelAnimate
        ? "form-control-label-animate"
        : "form-control-label-static";
    const errorArray = this.state.errors || errors;
    const formControlType = inputType ? `form-control-${inputType}` : "";
    const errorClass =
      errorArray && errorArray.length > 0 ? "has-error" : "no-error";
    const sufixClass = sufix ? "form-control-has-sufix" : "";
    const prefixClass = prefix ? "form-control-has-prefix" : "";
    const labelInsideClass = labelInside
      ? "form-control-wrapper-label-inside"
      : "";
    const selectable = searchInput ? "selectable" : "";
    const filledInputLabelClass = value ? "form-control-label-filled" : "";
    const inputLabel = label && (
      <label
        htmlFor={name}
        className={`form-control-label ${filledInputLabelClass} ${labelClass} ${tmpLabel}`}
        dangerouslySetInnerHTML={this.setHTML(label, isRequired, isTmpMobile)}
      ></label>
    );


    return (
      <div
        className={`form-control-wrapper ${wrapperClass} ${
          !labelAnimate && !labelInside
            ? "form-control-wrapper-label-static"
            : ""
        } ${labelInsideClass} ${selectable}`}
        style={style}
      >
        {!labelAnimate && !labelInside && inputLabel}
        <div
          className={`form-control-container ${labelWrapperClass} ${activeClass} ${filledInputLabelClass}`}
        >
          {prefix && <div className="form-control-prefix">{prefix}</div>}
          <input
            className={
              `form-control ${className} ${formControlType} ${errorClass} ${sufixClass} ${prefixClass} ${additionalClassName}`
            }
            id={name}
            name={name}
            type="text"
            value={value}
            disabled={disabled}
            autoFocus={autoFocus} //eslint-disable-line
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            onChange={this.onChange}
            onKeyPress={this.onKeyPress}
            autoComplete="off" //eslint-disable-line
            max="2145-01-01"
            min="0"
          />
          {(labelInside || labelAnimate) && inputLabel}
          {sufix && (
            <div
              onClick={() => this.onSufixClick(name, value, preference)}
              className="form-control-sufix"
              role="button"
              tabIndex={0}
            >
              {sufix}
            </div>
          )}
          {secondSufix && (
            <div
              onClick={() => this.onSecondSufixClick(name, value, preference)}
              className="form-control-sufix-second"
              role="button"
              tabIndex={0}
            >
              {secondSufix}
            </div>
          )}
        </div>
        <InputErrors errors={errorArray} />
      </div>
    );
  }
}

AmountInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  inputType: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  placeholder: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  wrapperClass: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  errors: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ),
  sufix: PropTypes.node,
  prefix: PropTypes.node,
  labelAnimate: PropTypes.bool,
  labelInside: PropTypes.bool,
  searchInput: PropTypes.bool,
  withDelay: PropTypes.bool,
  onSufixClick: PropTypes.func,
  onSecondSufixClick: PropTypes.func,
  preference: PropTypes.number,
  isTmp: PropTypes.bool
};

AmountInput.defaultProps = {
  value: "",
  placeholder: "",
  label: null,
  className: null,
  wrapperClass: null,
  isRequired: false,
  errors: [],
  disabled: false,
  autoFocus: false,
  sufix: null,
  secondSufix: null,
  prefix: null,
  labelAnimate: false,
  labelInside: false,
  inputType: "",
  onChange: null,
  onKeyPress: null,
  onBlur: null,
  searchInput: false,
  withDelay: false,
  onSufixClick: null,
  onSecondSufixClick: null,
  preference: null,
  onFocus: () => {}
};

export default AmountInput;
