export const mapAddress = (address, addressType) => {
  let result;

  if (typeof address === "string") return address;

  if (!address || address === null) return false;

  const {
    postcode,
    countyCity,
    countryName,
    addressLine_1,
    addressLine_2
  } = address;

  switch (addressType) {
    case "home":
      result = `${addressLine_1 || ""}, ${addressLine_2 || ""}, ${countyCity ||
        ""}, ${postcode || ""}, ${countryName || ""}`.trim();
      break;
    case "work":
      result = `${addressLine_1 || ""}, ${addressLine_2 || ""}, ${countyCity ||
        ""}, ${postcode || ""}, ${countryName || ""}`.trim();
      break;
    default:
      break;
  }

  return result.replace(/^[,\s]+|[,\s]+$/g, "").replace(/,[,\s]*,/g, ",");
};

export default mapAddress;
