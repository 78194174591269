import { tasksAppPath } from "utils/paths";

// APP paths

export const listPath = `${tasksAppPath}`;
export const infoPath = `${tasksAppPath}/:id`;
export const editPath = `${tasksAppPath}/:id/edit`;
export const newPath = `${tasksAppPath}/new`;
export const documentsPath = `${tasksAppPath}/:id/documents`;
export const formsPath = `${tasksAppPath}/:id/forms`;
export const chatPath = `${tasksAppPath}/:id/chat`;
export const contactPath = `${tasksAppPath}/:id/contacts`;
export const correspondencePath = `${tasksAppPath}/:id/correspondence`;
