import React, { useState, useEffect } from 'react';

import Icons from 'common/Icons';
import './styles.scss';

export const Popup = ({
  isOpen,
  onBackdropClick,
  selectedFile,
  loadedPreview,
}) => {
  const [selectedImage, setSelectedImage] = useState('');

  const handleChangeImage = (event, direction) => {
    event.preventDefault();
    event.stopPropagation();
    const selectedImageIndex = loadedPreview.files.findIndex(
      (img) => img.id === selectedImage.id
    );
    let nextImageIndex = 0;
    if (direction === 'left') {
      nextImageIndex =
        selectedImageIndex === 0
          ? loadedPreview.files.length - 1
          : selectedImageIndex - 1;
    } else {
      nextImageIndex =
        selectedImageIndex === loadedPreview.files.length - 1
          ? 0
          : selectedImageIndex + 1;
    }
    const nextImage = loadedPreview.files[nextImageIndex];
    if (nextImage) {
      setSelectedImage(nextImage);
    }
  };

  useEffect(() => {
    if (selectedFile) {
      setSelectedImage(selectedFile);
    }
  }, [selectedFile]);

  const listenChangeImage = (e) => {
    if (e.key === 'ArrowLeft') {
      handleChangeImage(e, 'left');
    }
    if (e.key === 'ArrowRight') {
      handleChangeImage(e, 'right');
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', listenChangeImage);
    return () => {
      window.removeEventListener('keydown', listenChangeImage);
    };
    // eslint-disable-next-line
  }, [selectedImage]);

  const image = <img className="modal-img" src={selectedImage.url} alt="" />;

  return isOpen ? (
    <div
      onClick={onBackdropClick}
      className="popup-backdrop flex justify-center items-center"
      role="button"
      tabIndex={0}
    >
      <div className="modal-content">
        <div
          className="modal-content-switch-left-block"
          onClick={(e) => handleChangeImage(e, 'left')}
        ></div>
        <div
          className="modal-content-switch-right-block"
          onClick={(e) => handleChangeImage(e, 'right')}
        ></div>
        <Icons
          iconName="leftArrow"
          fill="#191B36"
          className="modal-content-switch-left"
          width={18}
          height={18}
          onClick={(e) => handleChangeImage(e, 'left')}
        />
        <Icons
          iconName="rightArrow"
          fill="#191B36"
          className="modal-content-switch-right"
          width={18}
          height={18}
          onClick={(e) => handleChangeImage(e, 'right')}
        />
        {image}
        {/* <span className="modalClose-btn" onClick={onBackdropClick}>X</span> */}
      </div>
    </div>
  ) : null;
};

export default Popup;
