import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icons from 'common/Icons';
import GlobalPopup from 'components/PopupGlobal';
import InputErrors from 'components/InputErrors';

import { FILE_TYPES } from 'utils/constants';
import fileUploadingValidation from 'validation/fileUploadingValidation';
import './styles.scss';

class UniversalFileLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputName: null,
      fileLoading: false,
      deleting: null,
      isModalOpen: false,
      validationError: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { fileLoading } = this.state;
    const { fieldErrors, previewLoaded, task, value } = this.props;
    const questionAnswerDocument = this.getValueFromTask();

    if (prevProps.task.fetchingOne && task.fetchedOne) {
      this.clear();
    }

    if (
      prevProps.value.length === 0 &&
      (value?.length > 0 || questionAnswerDocument?.length > 0) &&
      fileLoading
    ) {
      this.completeLoading();
    }

    if (!prevProps.previewLoaded && previewLoaded) {
      this.completeLoading();
    }

    // Clear errors if there were fieldErrors before and now aren't
    if (
      prevProps &&
      this.props &&
      prevProps.fieldErrors &&
      fieldErrors &&
      Object.keys(prevProps.fieldErrors).length > 0 &&
      Object.keys(fieldErrors).length === 0
    ) {
      this.setState({ validationError: null });
    }
  }

  getFileData = (document = {}) => {
    const result = {
      input: this.props.name,
      fileId: document.id,
      file:
        typeof document.url === 'object' ? document.url.original : document.url,
      name: document.name ?? document.description,
    };

    return result;
  };

  completeLoading = () => {
    this.setState({ fileLoading: false });
  };

  loadFile = (e) => {
    e.persist();
    const { validationError } = this.state;
    const { setFieldErrors, clearFieldError, onUploadDocumentClick } =
      this.props;
    const pictures = e.target.files;
    const inputName = e.target.name;
    const hasData = pictures && Object.keys(pictures).length;

    if (hasData) {
      let hasError;
      for (const picture in pictures) {
        if (Object.hasOwnProperty.call(pictures, picture)) {
          const element = pictures[picture];
          const pictureUploadingError = fileUploadingValidation(
            FILE_TYPES.fileUpload,
            element
          );

          if (pictureUploadingError) {
            hasError = pictureUploadingError;
            this.setState({
              validationError: pictureUploadingError,
            });

            setFieldErrors({ [inputName]: pictureUploadingError });

            return;
          }
        }
      }

      if (!hasError && validationError) {
        this.setState(
          {
            validationError: null,
          },
          () => {
            clearFieldError(inputName);
          }
        );
      }

      if (hasError) return;
    }

    this.setState({
      inputName,
      fileLoading: true,
    });
    const fileExtention = pictures[0]?.name?.split('.')[1];
    onUploadDocumentClick(e, false, {
      fileType: FILE_TYPES.fileUpload,
      noTypeValidation: true,
      files: pictures,
      fileExtention,
    });
  };

  removeFile = async (e, fileId) => {
    e.persist();
    e.preventDefault();
    e.stopPropagation();

    const { deleting, inputName } = this.state;
    const { withoutAutosave, name, removeSelectedDocument } = this.props;

    if (deleting) return;

    if (!withoutAutosave) {
      this.setState({ deleting: fileId });
    }

    const questionId = inputName ? inputName : name;
    await removeSelectedDocument(questionId, fileId);
  };

  clear = () => {
    this.setState({ deleting: null });
  };

  switchModal = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  getValueFromTask = () => {
    let result = null;
    const { task, id } = this.props;
    const taskAnswers = task?.data?.answers;
    if (taskAnswers?.length) {
      const currentQuestion = taskAnswers.find(
        (answer) => Number(answer?.questionId) === Number(id)
      );
      if (currentQuestion?.documents?.length)
        result = currentQuestion.documents;
    }

    return result;
  };

  render() {
    const {
      className,
      wrapperClass,
      name,
      label,
      disabled,
      style,
      newDesign,
      document,
      value,
    } = this.props;
    const { previews, hidden } = document;
    const { fileLoading, deleting, validationError } = this.state;
    const listLength = 12;
    let loadedFile;
    let questionAnswerDocument = this.getValueFromTask();

    if (questionAnswerDocument?.length) {
      loadedFile = this.getFileData(questionAnswerDocument[0]);
    } else if (!questionAnswerDocument && value?.length) {
      questionAnswerDocument = value[0]?.documents;
      loadedFile = this.getFileData(value[0]);
    } else {
      loadedFile = previews.find(
        (prev) => prev.input === name && prev.file && !hidden.includes(prev.id)
      );
    }

    const fileId = loadedFile ? loadedFile?.fileId ?? loadedFile?.id : null;

    const newDesignStyles = newDesign ? 'new-design-field' : null;
    const newDesignLabel = newDesign ? 'new-design-file-upload-label' : null;

    return (
      <Fragment>
        <GlobalPopup
          isOpen={this.state.isModalOpen}
          onBackdropClick={this.switchModal}
          content={
            <div className="modal-content">
              <img
                className="modal-img"
                src={!!loadedFile ? loadedFile.file : ''}
                alt=""
              />
              <span className="modalClose-btn">X</span>
            </div>
          }
        />
        <div
          className={`form-control-file-container ${newDesignStyles}`}
          style={style}
        >
          <span className={`form-control-file-label ${newDesignLabel}`}>
            {label}
          </span>
          <label
            htmlFor={name}
            onChange={this.loadFile}
            className={`${wrapperClass} form-control-file-wrapper flex justify-center items-center`}
          >
            <input
              type="file"
              id={name}
              className={`form-control form-control-file ${className}`}
              name={name}
              disabled={disabled}
            />
            {(fileLoading || (fileId && fileId === deleting)) && (
              <div className="lds-spinner">
                {Array.from(Array(listLength), (_, i) => (
                  <div key={i} />
                ))}
              </div>
            )}

            {loadedFile && !fileLoading && !deleting && (
              <div className="btn-background" onClick={this.switchModal}>
                <img className="background-img" src={loadedFile.file} alt="" />
                {!disabled && (
                  <button
                    className="background-remove"
                    onClick={(e) => this.removeFile(e, fileId)}
                  >
                    <Icons
                      iconName="close"
                      fill="#ff6081"
                      height={30}
                      width={30}
                    />
                  </button>
                )}
              </div>
            )}

            {!loadedFile && !fileLoading && !disabled && (
              <Icons iconName="plus" fill="#ff6081" height={40} width={40} />
            )}
          </label>
          {fileLoading && !deleting && (
            <span className="form-control-file-sublabel">Uploading...</span>
          )}
          {fileId && fileId === deleting && (
            <span className="form-control-file-sublabel">Deleting...</span>
          )}
          {loadedFile && !fileLoading && !deleting && (
            <span className="form-control-file-sublabel">
              {loadedFile.name}
            </span>
          )}
          {validationError ? <InputErrors errors={[validationError]} /> : null}
        </div>
      </Fragment>
    );
  }
}

UniversalFileLoader.propTypes = {
  className: PropTypes.string,
  wrapperClass: PropTypes.string,
  name: PropTypes.string,
  onUploadDocumentClick: PropTypes.func.isRequired,
  document: PropTypes.shape({
    previews: PropTypes.array,
    hidden: PropTypes.array,
  }),
  removeSelectedDocument: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.arrayOf(Object),
  task: PropTypes.shape({
    fetchingOne: PropTypes.bool,
    fetchedOne: PropTypes.bool,
  }).isRequired,
  disabled: PropTypes.bool,
  previewLoaded: PropTypes.bool,
  withoutAutosave: PropTypes.bool,
};

UniversalFileLoader.defaultProps = {
  className: '',
  wrapperClass: null,
  name: '',
  document: {},
  removeSelectedDocument: null,
  label: '',
  value: [],
  disabled: false,
  previewLoaded: false,
  withoutAutosave: false,
};

const mapStateToProps = (state) => ({
  document: state.document,
  task: state.task,
});

export default connect(mapStateToProps, {})(UniversalFileLoader);
