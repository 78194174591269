import React from 'react';
import PropTypes from 'prop-types';

const TrashCan = ({
  width = 10,
  height = 12,
  className = '',
  fill = '#000',
  onClick = () => {},
}) => (
  <div
    className={`la-icon icon-svg-menu ${className}`}
    onClick={onClick}
    role="presentation"
  >
    <svg width={width} height={height} viewBox="0 0 10 12">
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Delete" transform="translate(-10.000000, -9.000000)" fill={fill}>
          <path
            d="M11,19.6666563 L11,12 L19,12 L19,19.6666563 C19,20.4033438 18.4033437,21 17.6666875,21 L12.3333437,21 C11.5966562,21 11,20.4033438 11,19.6666563 Z M17.3333437,9.66665625 L19.6666562,9.66665625 L19.6666562,11 L10.3333437,11 L10.3333437,9.66665625 L12.6666562,9.66665625 L13.3333437,9 L16.6666562,9 L17.3333437,9.66665625 Z"
            id="Combined-Shape-Copy-2"
          />
        </g>
      </g>
    </svg>
  </div>
);

TrashCan.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default TrashCan;
