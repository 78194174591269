import React from 'react';
import UniversalFileLoader from './UniversalFileLoader';
import { ALLOWED_DOCUMENT_FILE_TYPES } from 'utils/constants';
import MediaUpload from '../MediaUpload';

const UniversalFileLoaderWrapper = (props) => {
  const filename = props?.value[0]?.description ?? props?.value[0]?.filename;
  const fileExtention = filename ? filename.split('.')[1] : null;

  return fileExtention &&
    ALLOWED_DOCUMENT_FILE_TYPES.includes(fileExtention) ? (
    <MediaUpload {...props} />
  ) : (
    <UniversalFileLoader {...props} />
  );
};

export default UniversalFileLoaderWrapper;
