import React from 'react';
import PropTypes from 'prop-types';

const Duplicate = ({
  width = 17,
  height = 17,
  className = '',
  fill = '#000000',
  style = {},
}) => (
  <div className={`la-icon icon-svg-tick ${className}`} style={style}>
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 512.007 512.007"
    >
      <g>
        <g>
          <path
            fill={fill}
            d="M507.733,196.27h-153.6c-2.355,0-4.267-1.911-4.267-4.267v-128c0-2.355-1.911-4.267-4.267-4.267H140.809
			c-2.355,0-4.267,1.911-4.267,4.275l0.597,443.733c0,2.355,1.911,4.258,4.267,4.258h366.327c2.355,0,4.267-1.911,4.267-4.267
			v-307.2C512,198.181,510.089,196.27,507.733,196.27z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            fill={fill}
            d="M294.4,0.003H4.267C1.911,0.003,0,1.915,0,4.27v435.2c0,2.355,1.911,4.267,4.267,4.267H115.2
			c2.355,0,4.267-1.911,4.267-4.267V46.937c0-2.355,1.911-4.267,4.267-4.267H294.4c2.355,0,4.267-1.911,4.267-4.267V4.27
			C298.667,1.915,296.755,0.003,294.4,0.003z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            fill={fill}
            d="M510.353,171.327L374.298,60.761c-2.935-2.389-7.364-0.324-7.364,3.43v110.566c0,2.458,2.014,4.446,4.506,4.446h136.055
			C511.718,179.203,513.621,173.981,510.353,171.327z"
          />
        </g>
      </g>
    </svg>
  </div>
);

Duplicate.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default Duplicate;
