import Icons from 'common/Icons';
import React from 'react';
import '../styles.scss';

const RemoveDocumentButton = ({ removeFile }) => {
  return (
    <button className="remove-entity-btn" onClick={(e) => removeFile(e)}>
      <Icons iconName="close" fill="#ff6081" height={30} width={30} />
    </button>
  );
};

export default RemoveDocumentButton;
