import React from 'react';
import PropTypes from 'prop-types';

const Link = ({
  width = 11,
  height = 11,
  className = '',
  fill = '#3C3835',
}) => (
  <div className={`la-icon icon-svg-link ${className}`}>
    <svg width={width} height={height} viewBox="0 0 11 11">
      <g className="icon-svg-link-arrow">
        <path
          fill={fill}
          fillRule="nonzero"
          d="M7.782.57H3.377a.55.55 0 0 0 0 1.102h3.076L.785 7.339a.55.55 0 1 0 .779.78L7.232 2.45v3.076a.55.55 0 0 0 1.1 0V1.121a.55.55 0 0 0-.55-.55z"
        />
      </g>
      <g>
        <path
          fill={fill}
          fillRule="nonzero"
          d="M9.58 4.077a.55.55 0 0 0-.551.55v4.956H1.32V1.874h4.956a.55.55 0 0 0 0-1.101H.769a.55.55 0 0 0-.55.55v8.811c0 .304.246.55.55.55h8.81a.55.55 0 0 0 .551-.55V4.628a.55.55 0 0 0-.55-.551z"
        />
      </g>
    </svg>
  </div>
);

Link.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default Link;
