import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Icons from 'common/Icons';
import './styles.scss';

export const VideoThumbnail = ({ onChange, style }) => (
  <div onClick={onChange} style={style}>
    <div className="video-thumbnail" style={style}>
      <Icons iconName="VideoThumbnail" fill="#ffffff" height={60} width={100} />
    </div>
  </div>
);
class VideoPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidUpdate(prevProps) {
    // const { fileLoading } = this.state;
  }

  hideSpinner = () => {
    this.setState({
      loading: false,
    });
  };

  render() {
    const { src } = this.props;

    return (
      <Fragment>
        <div className="video-container">
          {this.state.loading ? (
            <div className="spinner-container">
              <div class="blink">
                <span>Loading...</span>
              </div>
            </div>
          ) : null}
          <iframe
            width="100%"
            height="550"
            src={src}
            onLoad={this.hideSpinner}
            frameborder="0"
            allow="fullscreen"
            title="Claim Technology"
            scrolling="no"
          ></iframe>
        </div>
      </Fragment>
    );
  }
}
VideoPlayer.propTypes = {
  className: PropTypes.string,
  wrapperClass: PropTypes.string,
  name: PropTypes.string,
  onUploadVideoClick: PropTypes.func,
  document: PropTypes.shape({
    previews: PropTypes.array,
    hidden: PropTypes.array,
  }),
  removeSelectedVideo: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.arrayOf(Object),
  task: PropTypes.shape({
    fetchingOne: PropTypes.bool,
    fetchedOne: PropTypes.bool,
  }).isRequired,
  disabled: PropTypes.bool,
  previewLoaded: PropTypes.bool,
  withoutAutosave: PropTypes.bool,
};
VideoPlayer.defaultProps = {
  className: '',
  wrapperClass: null,
  name: '',
  document: {},
  removeSelectedVideo: null,
  label: '',
  value: [],
  disabled: false,
  previewLoaded: false,
  withoutAutosave: false,
  onUploadVideoClick: null,
};

const mapStateToProps = (state) => ({
  document: state.document,
  task: state.task,
});

export default connect(mapStateToProps, {})(VideoPlayer);
