import React from 'react';
import PropTypes from 'prop-types';

const ChangeAgentV1 = ({
  width = 22,
  height = 20,
  className = '',
  fill = '#000',
  onClick = () => {},
}) => (
  <div
    className={`la-icon icon-svg-menu ${className}`}
    onClick={onClick}
    role="presentation"
  >
    <svg width={width} height={height} viewBox="0 0 24 20">
      <path
        d="M19.75 14V11.75H22V10.25H19.75V8H18.25V10.25H16V11.75H18.25V14H19.75Z"
        fill={fill}
      />
      <path
        d="M11 4C8.79 4 7 5.79 7 8C7 10.21 8.79 12 11 12C13.21 12 15 10.21 15 8C15 5.79 13.21 4 11 4Z"
        fill={fill}
      />
      <path
        d="M3 18C3 15.34 8.33 14 11 14C13.67 14 19 15.34 19 18V20H3V18Z"
        fill={fill}
      />
    </svg>
  </div>
);

ChangeAgentV1.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default ChangeAgentV1;
